import React, { Component } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import renderInputForField from '../helpers/renderInputForField';
import StateField from '../fields/StateField';
import { capitalizeFirst } from '../../utils/stringUtils';

class AddressFormPartial extends Component {
  render() {
    const { headerText } = this.props;
    return (
      <div>
        {headerText !== undefined && headerText.length ? <h4>{headerText}</h4> : null}
        <Field
          name="address"
          label="Street Address"
          stackedlabel
          component={renderInputForField}
          {...{
            itemProps: {},
            inputProps: {
              type: 'text',
            },
          }}
        />
        <Field
          name="address_alt"
          label="Apt., Suite, Bldg."
          stackedlabel
          component={renderInputForField}
          {...{
            itemProps: {},
            inputProps: {
              type: 'text',
            },
          }}
        />
        <Field
          name="city"
          label="City"
          component={renderInputForField}
          stackedlabel
          format={capitalizeFirst}
          {...{
            itemProps: {},
            inputProps: {
              type: 'text',
            },
          }}
        />
        <StateField />
        <Field
          name="zip"
          label="Zip"
          stackedlabel
          component={renderInputForField}
          {...{
            itemProps: {},
            inputProps: {
              type: 'text',
            },
          }}
        />
      </div>
    );
  }
}

AddressFormPartial.propTypes = {
  headerText: PropTypes.string,
  showHotel: PropTypes.bool,
  showIsPrimary: PropTypes.bool,
};

export default AddressFormPartial;
