import { CLEAN_USERS, RESET_INTEGRATION_STATUS } from '../actions/integrationActions';
import {
  GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS,
  GET_IMPORT_STATUS_SUCCESS,
  GET_INTEGRATIONS_SUCCESS,
  GET_USERS_BY_LOCATION_SUCCESS,
  IMPORT_USER_FAILURE,
  IMPORT_USER_SUCCESS,
} from '../apiActions/integrationApiActions';

const initialUserState = {
  integrationResults: [],
  integrationStatus: {
    status: 'not_started',
    started: false,
    error: false,
  },
  locations: [],
};

export default (state = initialUserState, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_SUCCESS: {
      return { ...state, integrationResults: action.data };
    }
    case GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS: {
      return { ...state, locations: action.data };
    }
    case GET_IMPORT_STATUS_SUCCESS: {
      return {
        ...state,
        integrationStatus: {
          ...state.integrationStatus,
          ...action.data,
        }
      };
    }
    case IMPORT_USER_SUCCESS: {
      return {
        ...state,
        integrationStatus: {
          started: true,
          error: false
        },
      };
    }
    case IMPORT_USER_FAILURE: {
      return {
        ...state,
        integrationStatus: {
          started: false,
          error: true
        },
      };
    }
    case RESET_INTEGRATION_STATUS: {
      return {
        ...state,
        integrationStatus: {
          status: 'not_started',
          started: false,
          error: false,
        },
      };
    }
    case GET_USERS_BY_LOCATION_SUCCESS: {
      const users = action.data.map((user) => (
        {
          ...user,
          location_name: state.locations.find(
            (location) => location.id === user.location_id
          )?.name ?? user.location_id,
        }
      ));

      return {
        ...state,
        users: [...state?.users || [], ...users],
      };
    }
    case CLEAN_USERS: {
      const locationId = action?.locationId;
      const users = locationId ?
        state.users.filter((user) => user.location_id !== parseInt(locationId))
        : undefined;

      return {
        ...state,
        users,
      };
    }
    default:
      return state;
  }
};
