import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, FormGroup } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderSelectForField from './helpers/renderSelectForField';
import StateField from './fields/StateField';
import subscriptionStatuses from '../data/subscriptionStatuses.json';
import { capitalizeFirst } from '../utils/stringUtils';


const validate = () => {
  const errors = {};
  return errors;
};

class TeamSearchForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError, onReset, membershipOptions, businessTypes } = this.props;
    const plans = membershipOptions.map(p => ({
      value: p.id,
      label: `${p.name} - ${p.interval}ly`,
    }));
    const businessTypeOptions = businessTypes.sort().map(t => ({
      value: t,
      label: t,
    }));
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          render={({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col md={6}>
                  <Field
                    name="name"
                    component={renderInputForField}
                    label="Business Name"
                    format={capitalizeFirst}
                    stackedlabel
                    {...{
                      inputProps: {
                        type: 'text',
                      },
                      itemProps: {},
                    }}
                  />
                  <Field
                    name="first_name"
                    component={renderInputForField}
                    label="Owner First Name"
                    format={capitalizeFirst}
                    stackedlabel
                    {...{
                      inputProps: {
                        type: 'text',
                      },
                      itemProps: {},
                    }}
                  />
                  <Field
                    name="last_name"
                    component={renderInputForField}
                    label="Owner Last Name"
                    format={capitalizeFirst}
                    stackedlabel
                    {...{
                      inputProps: {
                        type: 'text',
                      },
                      itemProps: {},
                    }}
                  />
                  <Field
                    name="email"
                    component={renderInputForField}
                    label="Owner Email"
                    stackedlabel
                    {...{
                      inputProps: {
                        type: 'email',
                      },
                      itemProps: {},
                    }}
                  />
                  {/* <StateField isClearable={true} /> */}
                  
                  <FormGroup className="stacked centered">
                    <label>Created Date</label>
                    <div className="d-flex centered range-container">
                      <Field
                        name="start_created_at"
                        component={renderDatepickerForField}
                        stackedlabel
                        {...{
                          itemProps: { row: false },
                          inputProps: {
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            dropdownMode: 'select',
                            maxDate: new Date(),
                          },
                        }}
                      />
                      <div className="caption">to</div>
                      <Field
                        name="end_created_at"
                        component={renderDatepickerForField}
                        stackedlabel
                        {...{
                          itemProps: { row: false },
                          inputProps: {
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            dropdownMode: 'select',
                            maxDate: new Date(),
                          },
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <Field
                    name="product_id"
                    label="Subscription Plan"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: plans,
                        isMulti: true,
                      },
                    }}
                  />
                  <Field
                    name="subscription_status"
                    label="Subscription Status"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: subscriptionStatuses,
                        isMulti: true,
                      },
                    }}
                  />
                  <Field
                    name="business_type"
                    label="Business Type"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: businessTypeOptions,
                        isMulti: true,
                      },
                    }}
                  />
                  <Field
                    name="team_id"
                    component={renderInputForField}
                    label="Team ID"
                    stackedlabel
                    {...{
                      inputProps: {
                        type: 'text',
                      },
                      itemProps: {},
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <button
                    className="btn btn-lg btn-secondary fixed-width-md"
                    type="reset"
                    onClick={() => {
                      form.reset();
                      onReset();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-lg btn-primary fixed-width-md ml-3 btn-search-with-reset"
                    type="submit"
                    disabled={submitting}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

TeamSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onReset: PropTypes.func,
  membershipOptions: PropTypes.instanceOf(Array),
  businessTypes: PropTypes.instanceOf(Array),
};

export default TeamSearchForm;
