import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import tableIcons from '../../utils/muiIcons';

const tableStyle = {
  backgroundColor: '#fff',
  borderColor: '#EAECF0',
};

const GroupTable = ({ groups, onEditClick, onDeleteClick, isEditable = true }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const includesSearchQuery = (value, query) => {
    return value.toLowerCase().includes(query.toLowerCase());
  };

  const groupsFiltered = useMemo(() => groups.filter((group) => {
      const groupNameMatches = includesSearchQuery(group.name, searchQuery);
      const adminNameMatches = group.admins.some((admin) => includesSearchQuery(`${admin.first_name} ${admin.last_name}`, searchQuery));
      return groupNameMatches || adminNameMatches;
    }).sort((a, b) => a.name.localeCompare(b.name)), [searchQuery.length, groups]);

  const tableProps = {
    padding: 'dense',
    sorting: true,
    showTitle: false,
    search: false,
    toolbarButtonAlignment: 'left',
    pageSize: groups.length < 20 ? groups.length : 20,
    paging: groups.length > 20,
  };

  return (
    <div className="user-group-table">
      <div className="search-input-container">
        <span className="search-icon">
          <em className="fa fa-search" />
        </span>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          className="search-input"
          onChange={handleSearch}
        />
      </div>

      <MaterialTable
        columns={isEditable ? [
          { title: 'Group Name', field: 'name', defaultSort: 'asc', render: (rowData) => <Link to={`/dashboard/group/${rowData.id}`}>{rowData.name}</Link> },
          { title: 'Coach', field: 'admins', render: (rowData) => rowData.admins.map((admin) => `${admin.first_name} ${admin.last_name}`).join(', ') },
          { title: 'Members', field: 'members', render: (rowData) => rowData.members.length },
          {
            title: 'Edit',
            field: '',
            render: (rowData) => (
              <div onClick={() => onEditClick(rowData.id)}>
                <img src="/img/icons/pencil.svg" height="28px" width="32px" style={{ cursor: 'pointer' }} />
              </div>
            )
          },
          {
            title: 'Delete',
            field: '',
            render: (rowData) => (
              <div onClick={() => onDeleteClick(rowData.id)}>
                <img src="/img/icons/trash_red.svg" height="28px" width="28px" style={{ cursor: 'pointer', }} />
              </div>
            )
          },
        ] : [
          { title: 'Group Name', field: 'name', defaultSort: 'asc', render: (rowData) => <Link to={`/dashboard/group/${rowData.id}`}>{rowData.name}</Link> },
          { title: 'Coach', field: 'admins', render: (rowData) => rowData.admins.map((admin) => `${admin.first_name} ${admin.last_name}`).join(', ') },
          { title: 'Members', field: 'members', render: (rowData) => rowData.members.length },
        ]}
        data={groupsFiltered}
        key={groupsFiltered.length} // https://github.com/mbrn/material-table/issues/1480
        options={tableProps}
        style={tableStyle}
        icons={tableIcons}
      />
    </div>
  );
};

GroupTable.propTypes = {
  groups: PropTypes.instanceOf(Array),
  isEditable: PropTypes.bool,
};

export default GroupTable;
