export const memberCountOptions = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1 to 20',
    value: '1 to 20',
  },
  {
    label: '21 to 50',
    value: '21 to 50',
  },
  {
    label: '51 to 100',
    value: '51 to 100',
  },
  {
    label: '101 to 150',
    value: '101 to 150',
  },
  {
    label: '151 to 225',
    value: '151 to 225',
  },
  {
    label: '226 to 300',
    value: '226 to 300',
  },
  {
    label: 'More than 300',
    value: 'More than 300',
  },
];
