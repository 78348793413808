import { useEffect } from 'react';

const useAddAndRemoveClass = (enabled, className) => {
  useEffect(() => {
    if (enabled) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }

    return () => {
      document.body.classList.remove(className);
    };
  }, [enabled]);
};

export default useAddAndRemoveClass;
