import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import renderSelectForField from './helpers/renderSelectForField';

const validate = (values) => {
  const errors = {};
  if (!values.team_id) {
    errors.firstname = 'Team is required';
  }
  return errors;
};

class SelectTeamForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError, onCancel, teamOptions } = this.props;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <Field
                    name="team_id"
                    label=""
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: teamOptions,
                        isClearable: false,
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button color="primary" size="lg" type="submit" className="fixed-width-md">
                    Continue
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SelectTeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
  user: PropTypes.instanceOf(Object),
};

export default SelectTeamForm;
