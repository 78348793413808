import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import RecipeDetailComponent from '../../components/RecipeDetail';
import RecipeSearchForm from '../../forms/RecipeSearchForm';
import { submitRecipeSearchRequest, clearRecipeSearchForm } from '../../actions/foodActions';
import browserHistory from '../../history';
import { printRecipe } from '../../actions/printActions';
import ENV_VARS from '../../utils/envVars';

class RecipeDetail extends React.Component {
  componentDidMount() {
    const {
      doPrintRecipe,
      match: {
        params: { recipe_id },
      },
    } = this.props;
    doPrintRecipe(recipe_id);
  }

  printRecipe = (recipe) => {
    window.open(`${ENV_VARS.BASE_PRINT_URL}/printrecipe/${recipe.recipe_id}`, '_blank');
    // browserHistory.push(`/printrecipe/${recipe.recipe_id}`);
  };

  render() {
    const { recipe, currentUser } = this.props;
    return (
      <ContentWrapper>
        {recipe && recipe.name ? (
          <>
            <Row>
              <Col xs={12}>
                <PageTitle title={recipe.name} />
                <div className="mb-4 mt-n2">
                  <Link to="/recipes?refresh=false">
                    <em className="fa-lg mr-2 fas fa-arrow-left" />
                    Back to Recipes
                  </Link>
                </div>
              </Col>
            </Row>
            <div className="recipe-detail-container">
              <RecipeDetailComponent
                recipe={recipe}
                showNotes={true}
                showServingInfo={true}
                showPhoto={true}
                showName={false}
                showActions={true}
                currentUser={currentUser}
              />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

RecipeDetail.propTypes = {
  recipe: PropTypes.instanceOf(Object),
  currentUser: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  recipe: state.print.recipe,
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doPrintRecipe: (recipe_id) => dispatch(printRecipe(recipe_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDetail);
