import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderUploadForField from '../helpers/renderUploadForField';

class UploadPhoto extends Component {
  uploadPhoto = () => {
    alert('Photo upload here');
  };

  render() {
    const { fieldName = 'photoUrl', className, label, user, team, type = 'icon' } = this.props;
    return (
      <Field
        name={fieldName}
        component={renderUploadForField}
        iconClassName={className}
        label={label}
        type={type}
        user={user}
        team={team}
        {...{
          inputProps: {
            type: 'text',
            className: 'd-none',
          },
          itemProps: {},
        }}
      />
    );
  }
}

UploadPhoto.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

export default UploadPhoto;
