import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

class PieChart extends Component {
  render() {
    const { pie = {} } = this.props;
    return <Pie data={pie.data} redraw options={pie.options} />;
  }
}

PieChart.propTypes = {
  pie: PropTypes.instanceOf(Object),
};

export default PieChart;
