import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import testimonials from '../../data/testimonials';
import { randomIntFromInterval } from '../../utils/dataGeneratorUtils';

class Testimonial extends Component {
  render() {
    const { signup: { testimonial } } = this.props;
    // const testimonial = testimonials[randomIntFromInterval(0, testimonials.length - 1)];
    return (
      <div className="testimonial-container">
        <div className="testimonial-quote-container">
          <h2 className="mb-3">{testimonial.title}</h2>
          <p>
            <img src="/img/signup/quotation-mark.svg" className="mr-2" />
            {testimonial.quote}
          </p>
        </div>
        <div
          className="testimonial-photo-container"
          style={{ backgroundImage: `url(${testimonial.photo})` }}
        ></div>
      </div>
    );
  }
}

Testimonial.propTypes = {
  signup: PropTypes.instanceOf(Object),
};

export default Testimonial;
