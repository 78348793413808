import { CHANGE_SETTING, TOGGLE_SETTING } from '../actions/layoutActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SETTING: {
      const { name, value } = action;
      return {
        ...state,
        [name]: value,
      };
    }
    case TOGGLE_SETTING: {
      const { name } = action;
      return {
        ...state,
        [name]: !state[name],
      };
    }
    default: {
      return state;
    }
  }
};
