import { call, put, take, race, all, delay } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import {
  GET_ALL_CHALLENGES,
  GET_CHALLENGE,
  GET_ACTIONS,
  ADD_CHALLENGE,
  GET_LEADERBOARD,
  UPSERT_CHALLENGE_CLIENTS,
  GET_USER_SCORES_FOR_CHALLENGE,
  ADD_BONUS_POINTS,
  UPDATE_CHALLENGE,
  ARCHIVE_CHALLENGE,
  getLeaderboard as getLeaderboardAction,
} from '../actions/challengeActions';
import {
  GET_ALL_CHALLENGES_SUCCESS,
  GET_ALL_CHALLENGES_FAILURE,
  GET_CHALLENGE_FAILURE,
  GET_CHALLENGE_SUCCESS,
  GET_ACTIONS_SUCCESS,
  GET_ACTIONS_FAILURE,
  ADD_CHALLENGE_SUCCESS,
  ADD_CHALLENGE_FAILURE,
  GET_LEADERBOARD_SUCCESS,
  GET_LEADERBOARD_FAILURE,
  UPSERT_CHALLENGE_CLIENTS_SUCCESS,
  UPSERT_CHALLENGE_CLIENTS_FAILURE,
  GET_USER_SCORES_FOR_CHALLENGE_SUCCESS,
  GET_USER_SCORES_FOR_CHALLENGE_FAILURE,
  ADD_BONUS_POINTS_SUCCESS,
  ADD_BONUS_POINTS_FAILURE,
  UPDATE_CHALLENGE_SUCCESS,
  UPDATE_CHALLENGE_FAILURE,
  RECALCULATE_CHALLENGE_SCORES_SUCCESS,
  RECALCULATE_CHALLENGE_SCORES_FAILURE,
  ARCHIVE_CHALLENGE_SUCCESS,
  ARCHIVE_CHALLENGE_FAILURE,
  getChallenge,
  getAllChallenges,
  getActions,
  addChallenge,
  getLeaderboard,
  upsertChallengeClients,
  getUserScoresForChallenge,
  addBonusPoints,
  updateChallenge,
  recalculateChallengeScores,
  archiveChallenge,
} from '../apiActions/challengeApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';
import { buildChallengePayload } from '../utils/formUtils';

export default function* challengeSagas() {
  yield all([
    getAllChallengesSaga(),
    getChallengeSaga(),
    getActionsSaga(),
    addChallengeSaga(),
    getLeaderboardSaga(),
    upsertChallengeClientsSaga(),
    getUserScoresForChallengeSaga(),
    addBonusPointsSaga(),
    updateChallengeSaga(),
    archiveChallengeSaga(),
  ]);
}

function* getAllChallengesSaga() {
  while (true) {
    const { team_id } = yield take(GET_ALL_CHALLENGES);
    yield put(getAllChallenges(team_id));
    const { success, failure } = yield race({
      success: take(GET_ALL_CHALLENGES_SUCCESS),
      failure: take(GET_ALL_CHALLENGES_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getChallengeSaga() {
  while (true) {
    const { challenge_id } = yield take(GET_CHALLENGE);
    yield put(getChallenge(challenge_id));
    const { success, failure } = yield race({
      success: take(GET_CHALLENGE_SUCCESS),
      failure: take(GET_CHALLENGE_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getLeaderboardSaga() {
  while (true) {
    const { challenge_id } = yield take(GET_LEADERBOARD);
    yield put(getLeaderboard(challenge_id));
    const { success, failure } = yield race({
      success: take(GET_LEADERBOARD_SUCCESS),
      failure: take(GET_LEADERBOARD_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getUserScoresForChallengeSaga() {
  while (true) {
    const { user_id, challenge_id } = yield take(GET_USER_SCORES_FOR_CHALLENGE);
    yield put(getUserScoresForChallenge(user_id, challenge_id));
    const { success, failure } = yield race({
      success: take(GET_USER_SCORES_FOR_CHALLENGE_SUCCESS),
      failure: take(GET_USER_SCORES_FOR_CHALLENGE_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addBonusPointsSaga() {
  while (true) {
    const { user_id, challenge_id, values, toggleModal } = yield take(ADD_BONUS_POINTS);
    values.user_id = user_id;
    values.challenge_id = challenge_id;
    yield put(addBonusPoints(values));
    const { success, failure } = yield race({
      success: take(ADD_BONUS_POINTS_SUCCESS),
      failure: take(ADD_BONUS_POINTS_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(
        showAlert(
          'Success',
          'Bonus points awarded successfully!',
          'success',
          undefined,
          getLeaderboardAction(challenge_id),
          undefined,
          toggleModal
        )
      );
    }
  }
}

function* getActionsSaga() {
  while (true) {
    yield take(GET_ACTIONS);
    yield put(getActions());
    const { success, failure } = yield race({
      success: take(GET_ACTIONS_SUCCESS),
      failure: take(GET_ACTIONS_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addChallengeSaga() {
  while (true) {
    const { team_id, values } = yield take(ADD_CHALLENGE);
    const payload = buildChallengePayload(values);
    yield put(addChallenge(team_id, payload));
    const { success, failure } = yield race({
      success: take(ADD_CHALLENGE_SUCCESS),
      failure: take(ADD_CHALLENGE_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      // TODO: go to select clients screen. Need challenge_id first
      const { data } = success;
      yield put(
        showAlert(
          'Success!',
          'Challenge added successfully!',
          'success',
          `/challenges/users/${data.id}`
        )
      );
    }
  }
}

function* upsertChallengeClientsSaga() {
  while (true) {
    const { challenge_id, values } = yield take(UPSERT_CHALLENGE_CLIENTS);
    const user_ids = [];
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      const val = Object.keys(values)[i];
      if (values[val] === true) {
        user_ids.push(val.replace('user_', ''));
      }
    }
    yield put(upsertChallengeClients(challenge_id, user_ids));
    const { success, failure } = yield race({
      success: take(UPSERT_CHALLENGE_CLIENTS_SUCCESS),
      failure: take(UPSERT_CHALLENGE_CLIENTS_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      // yield put(showAlert('Success!', 'Challenge participants updated successfully!', 'success'));
      yield put(
        showAlert(
          'Success!',
          'Challenge participants updated successfully!',
          'success',
          `/challenges/${challenge_id}`
        )
      );
    }
  }
}

function* updateChallengeSaga() {
  while (true) {
    const { values } = yield take(UPDATE_CHALLENGE);
    const payload = buildChallengePayload(values);
    yield put(updateChallenge(payload));
    const { success, failure } = yield race({
      success: take(UPDATE_CHALLENGE_SUCCESS),
      failure: take(UPDATE_CHALLENGE_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      const { data } = success;
      yield put(recalculateChallengeScores(data.id));
      const { success_scores, failure_scores } = yield race({
        success: take(RECALCULATE_CHALLENGE_SCORES_SUCCESS),
        failure: take(RECALCULATE_CHALLENGE_SCORES_FAILURE),
      });
      if (failure_scores) {
        yield put(showAlert('Oops!', buildErrorMessage(failure_scores), 'error'));
      } else {
        yield put(
          showAlert(
            'Success!',
            'Challenge updated successfully!',
            'success',
            `/challenges/${data.id}`
          )
        );
      }
    }
  }
}

function* archiveChallengeSaga() {
  while (true) {
    const { challenge_id } = yield take(ARCHIVE_CHALLENGE);
    yield put(archiveChallenge(challenge_id));
    const { success, failure } = yield race({
      success: take(ARCHIVE_CHALLENGE_SUCCESS),
      failure: take(ARCHIVE_CHALLENGE_FAILURE),
    });
    yield put(spinnerDecrement());
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Success!', 'Challenge deleted successfully!', 'success', '/challenges'));
    }
  }
}
