import React from 'react';
import { Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import TeamDetailContainer from '../../containers/Teams/TeamDetailContainer';
import { submissionError } from '../../actions/formActions';
import { updateTeam, getTeamProfile } from '../../actions/teamActions';
import { makeSimpleValue } from '../../utils/formUtils';

class TeamDetail extends React.Component {
  componentDidMount() {
    const {
      doGetTeamProfile,
      match: {
        params: { team_id },
      },
    } = this.props;
    doGetTeamProfile(team_id);
  }

  render() {
    const { team, showSubmissionError, doUpdateTeam } = this.props;
    return (
      <ContentWrapper className="team-detail-container user-detail-container">
        <Row>
          <Col xs={12}>
            <PageTitle title="Teams" />
            <div className="mb-4 mt-n2">
              <Link to="/teams">
                <em className="fa-lg mr-2 fas fa-arrow-left" />
                Back to Teams
              </Link>
            </div>
          </Col>
        </Row>
        <div className="main-content-container">
          {team && team.name ? (
            <TeamDetailContainer
              team={team}
              showSubmissionError={showSubmissionError}
              doUpdateTeam={doUpdateTeam}
            />
          ) : (
            <Loading />
          )}
        </div>
      </ContentWrapper>
    );
  }
}

TeamDetail.propTypes = {
  team: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  doUpdateTeam: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  doGetTeamProfile: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.context.team,
  // clients: state.clients,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doUpdateTeam: (id, values) => {
    values.state = makeSimpleValue(values.state);
    dispatch(updateTeam(id, values));
  },
  doGetTeamProfile: (team_id) => dispatch(getTeamProfile(team_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetail);
