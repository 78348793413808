import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ChallengeForm from '../../forms/ChallengeForm';
import { updateChallenge, getChallenge } from '../../actions/challengeActions';
import { submissionError } from '../../actions/formActions';
import browserHistory from '../../history';

class EditChallenge extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { challenge_id },
      },
      doGetChallenge,
    } = this.props;
    doGetChallenge(challenge_id);
  }

  render() {
    const { user, team, showSubmissionError, doUpdateChallenge, challenge = {} } = this.props;
    const initialValues = {
      ...challenge,
      actions: undefined,
      start_on_date: undefined,
      through_date: undefined,
    };
    if (challenge.start_on_date !== undefined) {
      initialValues.start_on_date = moment(challenge.start_on_date).toDate();
    }
    if (challenge.through_date !== undefined) {
      initialValues.through_date = moment(challenge.through_date).toDate();
    }
    if (challenge.is_visible !== undefined) {
      initialValues.is_visible = challenge.is_visible.toString();
    }

    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Edit Challenge" />
            <div className="mb-4 mt-n2">
              <Link to={`/challenges/${challenge.id}`}>
                <em className="fa-lg mr-2 fas fa-arrow-left" />
                Back to Challenge
              </Link>
            </div>
          </Col>
        </Row>
        {user && team && challenge && challenge.id ? (
          <div className="main-content-container">
            <Row>
              <Col xs={12} className="add-challenge-container">
                <ChallengeForm
                  initialValues={initialValues}
                  view="edit"
                  actions={challenge.actions}
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => {
                    doUpdateChallenge(values);
                  }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

EditChallenge.propTypes = {
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doUpdateChallenge: PropTypes.func,
  doGetChallenge: PropTypes.func,
  showSubmissionError: PropTypes.func,
};
const mapStateToProps = (state) => ({
  actions: state.challenges.actions,
  user: state.auth.user,
  team: state.team,
  challenge: state.challenges.challenge,
});
const mapDispatchToProps = (dispatch) => ({
  doGetChallenge: (challenge_id) => dispatch(getChallenge(challenge_id)),
  doUpdateChallenge: (values) => dispatch(updateChallenge(values)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditChallenge);
