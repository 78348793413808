/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ENV_VARS from '../../utils/envVars';

(function () {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${ENV_VARS.INTERCOM_APP_ID}`;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
})();

class IntercomWidget extends Component {
  render() {
    const { user } = this.props;
    const { first_name, last_name, email, user_id, created_at } = user;
    window.intercomSettings = {
      app_id: ENV_VARS.INTERCOM_APP_ID,
      name: `${first_name} ${last_name}`,
      email,
      user_id,
      // created_at: moment(created_at).unix(), // Signup date as a Unix timestamp
      custom_launcher_selector: '.intercom',
      hide_default_launcher: true,
    };

    return (
      <li>
        {/* <button className="btn-text intercom" title="Intercom" id="Intercom" href={void 0}> */}
        <button className="btn-text intercom" title="Intercom" id="Intercom" onClick={() => ''}>
          <em className="icon-bubbles"></em>
          <span>StaxChat</span>
        </button>
      </li>
    );
  }
}

IntercomWidget.propTypes = {
  user: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(IntercomWidget);
