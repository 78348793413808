import React, { Component } from 'react';
import { Row, Col, Button, Collapse } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { firstBy } from 'thenby';
import { addBonusPoints, getUserScoresForChallenge } from '../../actions/challengeActions';
import UserScoresModal from '../../modals/UserScoresModal';
import BonusPointsModal from '../../modals/BonusPointsModal';
import DeleteChallengeModal from '../../modals/DeleteChallengeModal';
import Pill from '../../components/Common/Pill';
import UserAvatar from '../../components/Common/UserAvatar';

class ChallengeDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActions: false,
      showLeaderboard: true,
      userScoresModalOpen: false,
      deleteChallengeModalOpen: false,
      firstName: '',
      bonusPointsModalOpen: false,
      user_id: '',
    };
  }

  toggleActions = () => {
    const { showActions } = this.state;
    this.setState({
      showActions: !showActions,
    });
  };

  toggleLeaderboard = () => {
    const { showLeaderboard } = this.state;
    this.setState({
      showLeaderboard: !showLeaderboard,
    });
  };

  toggleUserScoresModal = () => {
    const { userScoresModalOpen } = this.state;
    this.setState({
      userScoresModalOpen: !userScoresModalOpen,
    });
  };

  toggleBonusPointsModal = () => {
    const { bonusPointsModalOpen } = this.state;
    this.setState({
      bonusPointsModalOpen: !bonusPointsModalOpen,
    });
  };

  toggleDeleteChallengeModal = () => {
    const { deleteChallengeModalOpen } = this.state;
    this.setState({
      deleteChallengeModalOpen: !deleteChallengeModalOpen,
    });
  };

  render() {
    const {
      team,
      user,
      challenge,
      actions,
      leaderboard,
      clients,
      doGetUserScoresForChallenge,
      doAddBonusPoints,
      isAdmin = false,
    } = this.props;
    const {
      showActions,
      showLeaderboard,
      userScoresModalOpen,
      firstName,
      bonusPointsModalOpen,
      deleteChallengeModalOpen,
      user_id,
    } = this.state;
    const { name, start_on_date, through_date, is_visible, description, id } = challenge;

    let lbDisplay = [];
    let exportHeaders = [];
    if (leaderboard.length) {
      for (let i = 0; i < leaderboard.length; i += 1) {
        const row = leaderboard[i];
        const { user_id, score } = row;
        const client = clients.find((c) => c.id === user_id);
        if (client) {
          const { first_name, last_name, id } = client;
          lbDisplay.push({
            first_name,
            last_name,
            score,
            user_id: id,
          });
        }
      }
      lbDisplay = lbDisplay.sort(firstBy('score', 'desc').thenBy('last_name').thenBy('first_name'));
      let prevRank = 0;
      let prevScore = -1;
      const dupes = 0;
      for (let i = 0; i < lbDisplay.length; i += 1) {
        const lb = lbDisplay[i];
        let rank;
        prevRank += 1;
        if (lb.score === prevScore) {
          rank = '';
          // dupes += 1;
        } else {
          // prevRank += 1;
          rank = `${prevRank}.`;
          // dupes = 0;
        }
        lbDisplay[i].rank = rank;
        prevScore = lb.score;
      }
      exportHeaders = [
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Score', key: 'score' },
      ];
    }

    return (
      <div className="challenge-detail-container">
        <Row>
          <Col>
            <div className="d-flex flex-row align-items-center mb-2">
              <h4 className="no-margin-bottom">{name}</h4>
              <Pill
                className={`ml-2 ${challenge.is_visible ? 'active' : 'error'}`}
                title={is_visible ? 'Visible to Clients' : 'Not Visible to Clients'}
              />
            </div>

            {description}
          </Col>
          <Col>
            <div className="d-flex animated justify-content-end">
              {isAdmin !== true && (
                <>
                  <Link to={`/challenges/edit/${challenge.id}`}>
                    <Button color="primary" className="mr-2">
                      Edit
                    </Button>
                  </Link>
                  <Button
                    color="danger"
                    className="mr-2"
                    onClick={(e) => {
                      this.toggleDeleteChallengeModal();
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}

              {leaderboard.length ? (
                <CSVLink
                  data={lbDisplay}
                  headers={exportHeaders}
                  // filename={filename}
                  filename="challenge.csv"
                  // filename={`${exportFilenamePrefix}${moment().format('YYYYMMDDHHmmss')}.csv`}
                  ref={this.csvLink}
                  target="_blank"
                  id="csv-link"
                >
                  <Button>
                    <div className="d-flex flex-row align-items-center">
                      <em className="font-size-one-point-two-five icon-cloud-download mr-2" />
                      <div>Export to CSV</div>
                    </div>
                  </Button>
                </CSVLink>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="d-flex flex-column mt-4">
            <label>Dates of Challenge</label>
            {moment(start_on_date).format('MMMM D, YYYY')} to{' '}
            {moment(through_date).format('MMMM D, YYYY')}
          </Col>
        </Row>
        <Row>
          <Col className="mt-4 cursor-pointer d-flex flex-row" onClick={this.toggleLeaderboard}>
            <h4 className="mr-2">
              Leaderboard{' '}
              <em className={`ml-2 ${showLeaderboard ? 'icon-arrow-down' : 'icon-arrow-up'}`} />
            </h4>
          </Col>
        </Row>

        <Collapse isOpen={showLeaderboard}>
          {leaderboard.length ? (
            <>
              {/* TODO: only allow this if the challenge isn't over? */}
              {!isAdmin && (
                <Row className="mb-3">
                  <Col>
                    <Link to={`/challenges/users/${challenge.id}`}>
                      <Button>Manage clients</Button>
                    </Link>{' '}
                  </Col>
                </Row>
              )}
              <Row className="mb-2">
                <Col className="d-flex align-items-end text-bold" md={8} xs={8}>
                  Name
                </Col>
                <Col xs={2} className="text-bold text-center">
                  Points
                </Col>
                <Col xs={2} className="text-bold text-center">
                  Actions
                </Col>
              </Row>
              {lbDisplay.map((l) => (
                <Row key={l.user_id}>
                  <Col xs={8} md={8} className="d-flex flex-row leaderboard-row">
                    <div style={{ width: '2rem' }}>{l.rank}</div>
                    <div><UserAvatar user={l} /></div>
                    <div className="d-flex justify-content-center align-items-center ml-4">
                      <Link
                        to={{
                          pathname: `/${isAdmin ? 'users' : 'clients'}/${l.user_id}${
                          isAdmin ? `?team_id=${challenge.team_id}` : ''
                        }`,
                          state: { from: 'Challenge Details' }
                        }}
                      >
                        {l.first_name} {l.last_name}
                      </Link>
                    </div>
                  </Col>
                  <Col xs={2} className="text-center leaderboard-row">
                    {l.score}
                  </Col>
                  <Col xs={2} className="text-center leaderboard-row">
                    <span
                      className="span-link"
                      onClick={() => {
                        this.setState({ firstName: l.first_name }, () => {
                          doGetUserScoresForChallenge(l.user_id, challenge.id);
                          this.toggleUserScoresModal();
                        });
                      }}
                    >
                      Details
                    </span>
                    {!isAdmin && (
                      <>
                        <span className="hidden-on-mobile"> | </span>
                        <span className="only-on-mobile"> </span>
                        <span
                          className="span-link"
                          title="Give Bonus Points"
                          onClick={() => {
                            this.setState({ firstName: l.first_name, user_id: l.user_id }, () => {
                              this.toggleBonusPointsModal();
                            });
                          }}
                        >
                          Bonus
                        </span>
                      </>
                    )}
                  </Col>
                </Row>
              ))}
            </>
          ) : (
            <Row className="mb-2">
              <Col>
                There are no clients participating in this challenge.{' '}
                {!isAdmin && (
                  <>
                    <Link to={`/challenges/users/${challenge.id}`}>Add clients</Link>{' '}
                  </>
                )}
              </Col>
            </Row>
          )}
        </Collapse>

        <Row>
          <Col className="mt-4 cursor-pointer d-flex flex-row" onClick={this.toggleActions}>
            <h4 className="mr-2">
              Challenge Scoring{' '}
              <em className={`ml-2 ${showActions ? 'icon-arrow-down' : 'icon-arrow-up'}`} />
            </h4>
          </Col>
        </Row>
        <Collapse isOpen={showActions}>
          <Row className="mb-2">
            <Col className="d-flex align-items-end text-bold">Daily Action</Col>
            <Col xs={2} className="d-flex align-items-end justify-content-center text-bold">
              Points
            </Col>
            <Col
              xs={2}
              className="d-flex align-items-end justify-content-center text-bold text-center"
            >
              Max Awards
              <br />
              Per Week
            </Col>
          </Row>
          {challenge.actions.map((a) => (
            <Row key={a.id} className="mb-2 action-row">
              <Col>
                {a.name}
                <div className="caption">{a.description}</div>
              </Col>
              <Col xs={2} className="text-center">
                {a.points}
              </Col>
              <Col xs={2} className="text-center">
                {a.max_awards ? a.max_awards : '--'}
              </Col>
            </Row>
          ))}
        </Collapse>
        <UserScoresModal
          firstName={firstName}
          isOpen={userScoresModalOpen}
          toggle={this.toggleUserScoresModal}
        />
        <BonusPointsModal
          firstName={firstName}
          isOpen={bonusPointsModalOpen}
          toggle={this.toggleBonusPointsModal}
          minDate={moment(start_on_date).toDate()}
          maxDate={new Date()}
          onSubmit={(values) =>
            doAddBonusPoints(user_id, challenge.id, values, this.toggleBonusPointsModal)}
        />
        <DeleteChallengeModal
          firstName={firstName}
          isOpen={deleteChallengeModalOpen}
          toggle={this.toggleDeleteChallengeModal}
          challenge_id={id}
        />
      </div>
    );
  }
}

ChallengeDetailContainer.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  challenge: PropTypes.instanceOf(Object),
  actions: PropTypes.instanceOf(Array),
  leaderboard: PropTypes.instanceOf(Array),
  clients: PropTypes.instanceOf(Array),
  firstName: PropTypes.string,
  doGetUserScoresForChallenge: PropTypes.func,
  doAddBonusPoints: PropTypes.func,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doGetUserScoresForChallenge: (user_id, challenge_id) => {
    dispatch(getUserScoresForChallenge(user_id, challenge_id));
  },
  doAddBonusPoints: (user_id, challenge_id, values, toggleBonusPointsModal) =>
    dispatch(addBonusPoints(user_id, challenge_id, values, toggleBonusPointsModal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetailContainer);
