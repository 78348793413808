import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Button } from 'reactstrap';

export class RenderRadioButtonsForFieldComponent extends Component {
  render() {
    const {
      input,
      label,
      options,
      optionstype,
      meta: { error },
      itemProps,
      inputCaption,
      required,
    } = this.props;

    const { name, onChange, onBlur, onFocus } = input;

    let labelClassName = 'col-form-label';
    let inputClassName = 'col-form-label';

    if (itemProps.row) {
      labelClassName += ' col-xl-3 col-form-label';
      inputClassName += ' col-xl-9';
    }

    const showLabel = () => {
      if (label && label.length) {
        return (
          <div className="radio-label-container">
            <label className={labelClassName}>
              {required && <span className="required-field">*</span>}
              {label}
            </label>
          </div>
        );
      }
      return null;
    };

    const inputValue = input.value;
    const cols = itemProps.cols !== undefined ? itemProps.cols : 1;
    const checkboxes = options.map((option) => {
      const handleChange = (e) => {
        onBlur(e.target.value);
        return onChange(e.target.value);
      };
      const checked = inputValue === option.value;

      return (
        <div className={`c-radio ${cols === 2 ? 'col-6' : ''}`} key={option.value}>
          <label>
            <Input
              type="radio"
              name={`${name}`}
              value={option.value}
              checked={checked}
              onChange={handleChange}
              onFocus={onFocus}
            />
            {optionstype === 'icon' && (
              <>
                <em
                  className={
                    checked
                      ? option.icon
                        ? `${option.icon} checked`
                        : 'fas fa-check-circle checked'
                      : option.icon
                      ? `${option.icon} 'unchecked'`
                      : 'far fa-circle unchecked'
                  }
                />
                {option.label}
              </>
            )}
            {optionstype === 'button' && (
              <div
                className={`toggle-button ${
                  checked ? 'checked' : inputValue.length ? 'unchecked' : ''
                }`}
              >
                {option.label}
              </div>
            )}
          </label>
        </div>
      );
    });

    return (
      <FormGroup {...itemProps}>
        {showLabel()}
        <div className={inputClassName}>
          <div
            className={`${cols !== 1 ? 'd-flex flex-wrap-wrap flex-row centered' : ''} ${
              itemProps.wrapperclass
            }`}
          >
            {checkboxes}
          </div>
          {inputCaption !== undefined ? (
            <div className="input-caption caption">{inputCaption}</div>
          ) : null}
        </div>
      </FormGroup>
    );
  }
}

RenderRadioButtonsForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  inputCaption: PropTypes.string,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  refField: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    stackedLabel: PropTypes.bool,
    row: PropTypes.bool,
    cols: PropTypes.number,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    options: PropTypes.instanceOf(Array),
    multi: PropTypes.bool,
    simpleValue: PropTypes.bool,
  }),
};

export default forwardRef((props, ref) => (
  <RenderRadioButtonsForFieldComponent innerRef={ref} {...props} />
));
