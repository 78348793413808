import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ChallengeForm from '../../forms/ChallengeForm';
import { getActions, addChallenge } from '../../actions/challengeActions';
import { submissionError } from '../../actions/formActions';
import browserHistory from '../../history';

class AddChallenge extends React.Component {
  componentDidMount() {
    const { doGetActions } = this.props;
    doGetActions();
  }

  render() {
    const { actions, user, team, showSubmissionError, doAddChallenge } = this.props;
    const { team_id } = team;
    const initialValues = {
      is_visible: 'true',
    };
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Add a Challenge" />
          </Col>
        </Row>
        {user && team && actions ? (
          <div className="main-content-container">
            <Row>
              <Col xs={12} className="add-challenge-container">
                <ChallengeForm
                  initialValues={initialValues}
                  actions={actions}
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => {
                    doAddChallenge(team_id, values);
                  }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

AddChallenge.propTypes = {
  doGetActions: PropTypes.func,
  actions: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doAddChallenge: PropTypes.func,
  showSubmissionError: PropTypes.func,
};
const mapStateToProps = (state) => ({
  actions: state.challenges.actions,
  user: state.auth.user,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  doGetActions: () => {
    dispatch(getActions());
  },
  doAddChallenge: (team_id, values) => dispatch(addChallenge(team_id, values)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddChallenge);
