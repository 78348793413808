import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import MaterialTable, { MTableToolbar } from 'material-table';
import tableIcons from '../utils/muiIcons';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import CardIcon from '../components/Cards/CardIcon';
import { validateAddClientForm } from '../utils/formUtils';

const validate = (values, rows) => {
  return validateAddClientForm(values, rows);
};

class AddClientsFromCsvForm extends Component {
  constructor(props) {
    super(props);
    const { users } = this.props;
    this.state = {
      select: 'None',
      users,
    };
  }

  selectAllOrNone = (form) => {
    const { select, users } = this.state;
    const checked = select === 'All';
    for (let i = 0; i < users.length; i += 1) {
      form.change(`user_${i}`, checked);
    }
    setTimeout(() => {
      this.setState({
        select: select === 'All' ? 'None' : 'All',
      });
    });
  };

  checkValues = (value, values, id) => {
    if (!value) {
      this.setState({
        select: 'All',
      });
    } else {
      values[`user_${id}`] = true;
      const { users } = this.state;
      let selected = 0;
      for (let i = 0; i < users.length; i += 1) {
        if (values[`user_${i}`]) {
          selected += 1;
        }
      }
      this.setState({
        select: selected === users.length ? 'None' : 'All',
      });
    }
  };

  updateUser = (id, newVal, field) => {
    const { users } = this.state;
    const user = users[id];
    user[field] = newVal;
    this.setState({
      users,
    });
  }

  buildFormValues = (values) => {
    const { users } = this.state;
    const ret = {
      send_invitations: values.send_invitations,
    };
    for (let i = 0; i < users.length; i += 1) {
      if (values[`user_${i}`] === true) {
        const user = users[i];
        const { first_name, last_name, email } = user;
        ret[`first_name_${i}`] = first_name.trim();
        ret[`last_name_${i}`] = last_name.trim();
        ret[`email_${i}`] = email.trim();
      }
    }
    return ret;
  }

  render() {
    const { initialValues, onSubmit, showSubmissionError } = this.props;
    const { select, users } = this.state;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            // get payload into same shape as AddClientForm
            const formValues = this.buildFormValues(values);
            const errors = validate(formValues, users.length);
            if (Object.keys(errors).length === 0) {
              onSubmit(formValues);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row className="mt-3">
                <Col xs={12}>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Add',
                        editable: 'never',
                        render: (rowData) => (
                          <div className="d-flex align-items-center checkbox-row">
                            <Field
                              name={`user_${rowData.tableData.id}`}
                              component={renderCheckboxForField}
                              {...{
                                itemProps: {},
                                checkboxIcon: 'fa-check',
                                checkboxIconUnchecked: 'fa-close',
                                inputProps: {},
                              }}
                            />
                            <OnChange name={`user_${rowData.tableData.id}`}>
                              {(value) => {
                                this.checkValues(value, values, rowData.tableData.id);
                              }}
                            </OnChange>
                          </div>
                        ),
                        sorting: false,
                        width: null,
                        cellStyle: {
                          width: 100,
                          maxWidth: 100,
                        },
                      },
                      { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
                      { title: 'First Name', field: 'first_name' },
                      {
                        title: 'Email',
                        field: 'email',
                      },
                    ]}
                    data={users}
                    title="CSV Upload Results"
                    key={users.length} // https://github.com/mbrn/material-table/issues/1480
                    options={{
                      padding: 'dense',
                      pageSize: 25,
                      search: true,
                      paging: users.length > 25,
                      sorting: true,
                      thirdSortClick: false,
                      showTitle: true,
                      toolbar: true,
                      pageSizeOptions: [25, 50, 100],
                    }}
                    style={{
                      backgroundColor: '#f7f7f7',
                    }}
                    icons={tableIcons}
                    components={{
                      Toolbar: (props) => (
                        <div>
                          <MTableToolbar {...props} />
                          <div className="ml-3">
                            <span
                              className="span-link"
                              onClick={() => this.selectAllOrNone(form, values)}
                            >
                              Select {select}
                            </span>
                          </div>
                        </div>
                      ),
                    }}
                    cellEditable={{
                      onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve) => {
                          this.updateUser(rowData.tableData.id, newValue, columnDef.field);
                          setTimeout(resolve, 100);
                        });
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <div className="d-flex align-items-center checkbox-row">
                    <Field
                      name="send_invitations"
                      component={renderCheckboxForField}
                      {...{
                        itemProps: {},
                        checkboxIcon: 'fa-check',
                        checkboxIconUnchecked: 'fa-close',
                        inputProps: {},
                      }}
                    />
                    <div>
                      Send selected clients an email invitation to join Macrostax. &nbsp;
                      <CardIcon helpText="SEND_INVITATIONS" popoverClassName="text-left" />
                    </div>
                  </div>
                  <Button color="primary" size="lg" type="submit" className="ml-3 fixed-width-md">
                    Add Clients
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

AddClientsFromCsvForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
  users: PropTypes.instanceOf(Array),
};

export default AddClientsFromCsvForm;
