export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function markNotificationAsRead(id) {
  return {
    type: MARK_NOTIFICATION_AS_READ,
    id,
  };
}

export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
  };
}
