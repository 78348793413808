import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Row, Col, Button } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import renderInputForField from './helpers/renderInputForField';
import renderRadioButtonsForField from './helpers/renderRadioButtonsForField';
import { capitalizeFirst } from '../utils/stringUtils';
import { FORM_TRANSITION_PROPS } from '../constants';

const validate = (values) => {
  const errors = {};
  if (!values.cancelationReason) {
    errors.cancelationReason = 'Please select a cancelation reason';
  }
  if (values.cancelationReason === 'other' && !values.other) {
    errors.other = 'Please let us know your reason for canceling';
  }
  return errors;
};

class CancelPlanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
    };
  }

  handleChangeReason = (reason) => {
    this.setState({
      reason,
    });
  };

  render() {
    const { initialValues, onSubmit, showSubmissionError, onCancel } = this.props;
    const { reason } = this.state;
    const typeOptions = [
      {
        value: 'price',
        label: 'The price is too damn high',
      },
      {
        value: 'usage',
        label: 'My clients are not using the app',
      },
      {
        value: 'competitor',
        label: 'I found a better option',
      },
      {
        value: 'other',
        label: 'Other',
      },
    ];
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <Field
                    name="cancelationReason"
                    options={typeOptions}
                    component={renderRadioButtonsForField}
                    label="Before you go, please tell us why Macrostax Team did not work out:"
                    optionstype="icon"
                    {...{
                      inputProps: {},
                      itemProps: {
                        cols: 1,
                        wrapperclass: 'rb-list-wrapper',
                      },
                    }}
                  />
                  <OnChange name="cancelationReason">
                    {(value) => {
                      this.handleChangeReason(value, form.change);
                    }}
                  </OnChange>
                  <CSSTransition
                    {...FORM_TRANSITION_PROPS}
                    in={reason === 'other'}
                    onExited={() => {}}
                  >
                    <Field
                      name="other"
                      component={renderInputForField}
                      label="Please let us know why you'd like to cancel:"
                      format={capitalizeFirst}
                      stackedlabel
                      labelClassName="full-size"
                      {...{
                        inputProps: {
                          type: 'textarea',
                          rows: 3,
                        },
                        itemProps: {},
                      }}
                    />
                  </CSSTransition>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={onCancel}
                    className="mr-3 fixed-width-md"
                  >
                    Never Mind
                  </Button>
                  <Button color="primary" size="lg" type="submit" className="fixed-width-md">
                    Continue
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

CancelPlanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CancelPlanForm;
