import { CLEAR_RECIPE } from '../actions/printActions';
import { PRINT_RECIPE_SUCCESS } from '../apiActions/printApiActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_RECIPE: {
      return {
        ...state,
        recipe: undefined,
      };
    }
    case PRINT_RECIPE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        recipe: data,
      };
    }
    default:
      return state;
  }
};
