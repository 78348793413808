import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css';

export class RenderImagePickerForFieldComponent extends Component {
  render() {
    const {
      input,
      label,
      itemProps,
      stackedlabel = true,
      backgroundColor = '#ffffff',
    } = this.props;
    const formGroupClassName = itemProps.row ? 'flex-row' : stackedlabel ? 'stacked' : 'not-stacked';
    let labelClassName = '';
    let inputClassName = 'color-picker-container';

    if (itemProps.row) {
      labelClassName = `col-xl-${labelCols} col-form-label`;
      inputClassName = `col-xl-${inputCols} color-picker-container`;
    }

    const showLabel = () => {
      if (label && label.length) {
        return (
          <label className={labelClassName} dangerouslySetInnerHTML={{ __html: label }} />
        );
      }
      return null;
    };

    const config2 = {
      borderRadius: '8px',
      language: 'en',
      width: '375px',
      height: '154px',
      objectFit: 'contain',
      compressInitial: null,
    };
    const initialImage = '';

    // const initialImage = input.value !== undefined && input.value.length > 0 ? input.value : '';
    // const initialImage = 'https://macrostax-team-images.s3.us-east-2.amazonaws.com/10459137-e2d7-4d88-a19b-fb7e4613a590/primary_logo.webp';

    const setBackgroundColor = () => {
      setTimeout(() => {
        const imageHolders = document.getElementsByClassName('image-holder-loaded');
        if (imageHolders.length > 0) {
          imageHolders[0].style.backgroundColor = backgroundColor;
          imageHolders[0].getElementsByTagName('img')[0].style.backgroundColor = backgroundColor;
        }
      });
    };
    setBackgroundColor();

    return (
      <FormGroup {...itemProps} className={formGroupClassName}>
        {showLabel()}
        <div className={inputClassName}>
          <ReactImagePickerEditor
            config={config2}
            imageSrcProp={initialImage}
            hideEditBtn
            hideDownloadBtn={true}
            imageChanged={(newDataUri) => {
              input.onChange(newDataUri);
              setBackgroundColor();
            }}
          />
        </div>
      </FormGroup>
    );
  }
}

RenderImagePickerForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  refField: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    stackedLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    options: PropTypes.instanceOf(Array),
    multi: PropTypes.bool,
    simpleValue: PropTypes.bool,
  }),
};

export default forwardRef((props, ref) => (
  <RenderImagePickerForFieldComponent innerRef={ref} {...props} />
));
