import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import SelectTeamForm from '../../forms/SelectTeamForm';
import { submissionError } from '../../actions/formActions';
import { sendResetPassword, authorizeRequest } from '../../actions/authActions';
import { getLocalStoreValue, INIT_EMAIL_KEY } from '../../utils/localStorage';

class SelectTeam extends React.Component {
  render() {
    const { showSubmissionError, doAuthorizeRequest, user } = this.props;
    let user_first_name;
    let email;
    let password;
    let teams_list;
    if (user !== undefined) {
      ({ user_first_name, email, password, teams_list } = user);
    }
    const teamOptions = [];
    if (teams_list !== undefined && teams_list.length) {
      for (let i = 0; i < teams_list.length; i += 1) {
        const team = teams_list[i];
        const { team_id, team_name } = team;
        teamOptions.push({
          label: team_name,
          value: team_id,
        });
      }
    }

    return (
      <ContentWrapper>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <PageTitle title="Select a Team" showTeam={false} />
            {teamOptions !== undefined && teamOptions.length && password !== undefined && password.length > 0 ? (
              <>
                <p className="mb-4">
                  Welcome, {user_first_name}! It looks like you have multiple active Macrostax
                  accounts. Please select which account you would like to use today:
                </p>
                <SelectTeamForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => doAuthorizeRequest(values)}
                  showPassword={false}
                  buttonText="Send Reset Instructions"
                  teamOptions={teamOptions}
                  initialValues={{
                    email,
                    password,
                  }}
                />
              </>
            ) : (
              <div>
                It seems as if you've reached this page in error. Please try{' '}
                <Link to="/login">logging in again</Link>.
              </div>
            )}
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SelectTeam.propTypes = {
  doAuthorizeRequest: PropTypes.func,
  showSubmissionError: PropTypes.func,
  user: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doAuthorizeRequest: (values) => dispatch(authorizeRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectTeam);
