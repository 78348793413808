import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import LineChart from '../Charts/LineChart';
import { capitalizeFirst } from '../../utils/stringUtils';

class LineChartCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'chart',
    };
  }

  toggleView = () => {
    const { view } = this.state;
    this.setState({
      view: view === 'chart' ? 'data' : 'chart',
    });
  };

  render() {
    const { title, chart, noResultsNoun, data, showEdit = false, form, render = true, showPdf = false } = this.props;
    const { view } = this.state;
    let caption;
    let legend;
    if (chart) {
      ({ caption, legend } = chart);
    }

    return (
      <Card className="card-default card-container mb-3 line-chart-card flex-grow-1">
        <CardHeader className="d-flex justify-space-between align-items-center">
          {showEdit ? (
            <div
              onClick={() => this.toggleView()}
              className={`cursor-pointer ${view === 'data' ? 'linechart' : 'editable'}`}
            >
              {title}
            </div>
          ) : (
            <div>{title}</div>
          )}
          {legend && (
            <div className="chart-legend d-flex justify-content-end flex-direction-row flex-wrap">
              {Object.keys(legend).map((k) => (
                <div key={k} className="legend-key d-flex ml-4 align-items-center">
                  <div className="legend-color" style={{ backgroundColor: legend[k] }}></div>
                  <div className="legend-value ml-2">{capitalizeFirst(k)}</div>
                </div>
              ))}
            </div>
          )}
        </CardHeader>
        <CardBody>
          {chart ? (
            view === 'chart' ? (
              <LineChart chart={chart} render={render} showPdf={showPdf} />
            ) : (
              <div className="animated">{form}</div>
            )
          ) : (
            <div className="no-results-container text-center mb-4">
              <h3>No results yet.</h3>
              <div className="mb-3">This client has not recorded any {noResultsNoun} yet.</div>
              {view !== 'chart' && <div className="animated">{form}</div>}
            </div>
          )}
        </CardBody>
        {caption && view === 'chart' && (
          <CardFooter>
            {caption.map((c, idx) => (
              <div key={idx} className="d-flex">
                {c.label}:
                {c.values.map((v) => (
                  <div className="ml-3" key={v}>
                    {v}
                  </div>
                ))}
              </div>
            ))}
          </CardFooter>
        )}
      </Card>
    );
  }
}

LineChartCard.propTypes = {
  chart: PropTypes.instanceOf(Object),
  noResultsNoun: PropTypes.string,
  showEdit: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  render: PropTypes.bool,
  showPdf: PropTypes.bool,
};

export default LineChartCard;
