import { firstBy } from 'thenby';

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Call this with an array of arrays that you want to merge and dedupe. Ex:
// input: [ [1, 2, 3], [101, 2, 1, 10], [2, 1] ]
// output: [1, 2, 3, 101, 10]
export const mergeAndDedupeArrays = (arr) => [...new Set([].concat(...arr))];

export const getArrayIntersection = (arr1, arr2) => {
  const a = new Set(arr1);
  const b = new Set(arr2);
  const intersectionSet = new Set([...a].filter((x) => b.has(x)));
  return [...intersectionSet];
};

export const pushObjectAndSortArray = (val, arr, sortField) => {
  arr.push(val);
  arr.sort((a, b) => {
    if (a[sortField] > b[sortField]) {
      return 1;
    }
    return -1;
  });
};

export const updateObjectAndSortArray = (val, arr, sortField) => {
  const idx = arr.findIndex((a) => a.id === val.id);
  if (idx !== undefined) {
    arr[idx] = val;
  } else {
    arr.push(val);
  }
  arr.sort((a, b) => (a[sortField] > b[sortField] ? 1 : b[sortField] > a[sortField] ? -1 : 0));
  return arr;
};
