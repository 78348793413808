export const subscriptionPlanOptions = [
  {
    label: 'Monthly',
    value: 'macrostax_monthly',
  },
  {
    label: 'Yearly',
    value: 'macrostax_yearly',
  },
  {
    label: 'Grandfathered',
    value: 'grandfathered',
  },
  {
    label: 'LMN Legacy Plan',
    value: 'lmn_app',
  },
];
