import ENV_VARS from '../utils/envVars';

function loadGtm(w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
}
window, document, 'script', 'dataLayer', 'GTM-MK2ZS8V';

const init = () => {
  loadGtm(window, document, 'script', 'dataLayer', ENV_VARS.GTM_TRACKING_ID);
  // window.dataLayer = window.dataLayer || [];
  // function gtag() {
  //   dataLayer.push(arguments);
  // }
  // window.gtag = gtag;
  // window.gtag('js', new Date());
  // window.gtag('config', `${ENV_VARS.GA_TRACKING_ID}`);
  // TODO: when we get the google ads pixel
  // window.gtag('config', `${ENV_VARS.GOOGLE_ADWORDS_CONVERSION_ID}`, { allow_enhanced_conversions: true });
};

const push = (event) => {
  dataLayer.push({ event });
};

const checkout = (signup) => {
  const { membershipConfirmation, name } = signup;
  const { amount } = membershipConfirmation;
  dataLayer.push({
    event: 'Team Sign Up Flow Step 4',
    TeamName: name,
    conversionValue: amount / 100,
    'Team Plan Revenue': amount / 100,
  });
  // dataLayer.push({'Team Plan Revenue': amount / 100});
};

export default {
  init,
  push,
  checkout,
};
