import React, { Component } from 'react';
import moment from 'moment-timezone';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { camelCaseToSentenceCase, addLineBreaksForDisplay } from './stringUtils';
import { formatDateWithTimezone } from './dateUtils';
import { helperCaptions } from '../enums/helpText';

export const renderActivityMetadata = (row) => {
  const { type, metadata, userId } = row;
  const timezone = moment.tz.guess();

  switch (type) {
    case 'JOB_CREATED':
    case 'CAREGIVER_CHECKOUT': {
      const { startDate, endDate, numberOfChildren } = metadata;
      return `Start: ${formatDateWithTimezone(
        startDate,
        timezone,
        'M/D/YY h:mm a z'
      )}<br />End: ${formatDateWithTimezone(
        endDate,
        timezone,
        'M/D/YY h:mm a z'
      )}<br />${numberOfChildren} child${numberOfChildren !== 1 ? 'ren' : ''}`;
    }
    case 'ACTIVITY_LEVEL_MODIFIED': {
      const { oldVal, newVal } = metadata;
      const ret = [];
      ret.push(`Old: ${oldVal}`);
      ret.push(`New: ${newVal}`);
      return ret.join('\n');
    }
    case 'ADDED_MEASUREMENT':
    case 'ADDED_WEIGH_IN': {
      const { date, value, type: measurementType } = metadata;
      const ret = [];
      ret.push(`Date: ${moment(date).format('M/D/YY')}`);
      if (type === 'ADDED_MEASUREMENT') {
        ret.push(`Type: ${measurementType}`);
      }
      ret.push(`Value: ${value} ${type === 'ADDED_WEIGH_IN' ? 'lbs' : 'in'}`);
      return ret.join('\n');
    }
    case 'PROFILE_MODIFIED':
    case 'SCHEDULE_MODIFIED': {
      const { modified, date } = metadata;
      const ret = [];
      if (type === 'SCHEDULE_MODIFIED') {
        ret.push(`Date: ${moment(date).format('M/D/YY')}`);
      }
      Object.keys(modified).forEach((k) => {
        let str = `${camelCaseToSentenceCase(k)} - `;
        const isDate = k === 'startDate' || k === 'endDate';
        if (modified[k].old !== undefined) {
          str += `Old: ${
            isDate
              ? formatDateWithTimezone(modified[k].old, timezone, 'M/D/YY h:mm a z')
              : modified[k].old
          }, `;
        }
        str += `New: ${
          isDate
            ? formatDateWithTimezone(modified[k].new, timezone, 'M/D/YY h:mm a z')
            : modified[k].new
        }`;
        ret.push(str);
      });
      return ret.join('\n');
    }
    case 'SENT_TO_CAREGIVERS': {
      const { matches } = metadata;
      return `Selected ${matches.length} caregiver${matches.length !== 1 ? 's' : ''}`;
    }
    case 'JOB_CONFIRMED':
    case 'CAREGIVER_REMOVED':
    case 'JOB_REASSIGNED': {
      const { caregiverFirstName, caregiverLastName, caregiverId } = metadata;
      let s = `${caregiverFirstName} ${caregiverLastName} `;
      if (eventType === 'JOB_REASSIGNED') {
        s += 'assigned';
      } else if (eventType === 'CAREGIVER_REMOVED') {
        s += 'removed';
      } else if (userId !== caregiverId) {
        s += 'assigned';
      } else {
        s += 'confirmed';
      }
      return s;
    }
    default:
      return '';
  }
};

export const renderColumnHeaderWithTooltip = (idx, text, popoverCopy, className = '', total) => {
  return (
    <div className={className}>
      {text}
      {/* {total !== undefined && (
        ({total})
      )} */}
      <span className="nowrap">
        {total !== undefined && <> ({total})</>}

        <button
          className="card-tool ml-2"
          id={`column-${idx}`}
          onClick={(e) => {
            e.currentTarget.focus();
            e.preventDefault();
          }}
        >
          <em className="far fa-question-circle" />
        </button>
      </span>

      <UncontrolledPopover trigger="hover" placement="top" target={`column-${idx}`}>
        <PopoverBody
          className="text-left"
          dangerouslySetInnerHTML={{ __html: helperCaptions[popoverCopy] }}
        />
      </UncontrolledPopover>
    </div>
  );
};
