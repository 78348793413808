import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ResetPasswordForm from '../../forms/ResetPasswordForm';
import { submissionError } from '../../actions/formActions';
import { resetPassword } from '../../actions/authActions';

class ResetPasswordScreen extends React.Component {
  render() {
    const { showSubmissionError, doResetPassword, user } = this.props;
    let email;
    let user_id;
    if (user) {
      ({ email, user_id } = user);
    }
    return (
      <ContentWrapper>
        <Row>
          <Col md={4}></Col>
          <Col md={4} className="pr-5">
            <PageTitle title="Reset Password" showTeam={false} />
            {email ? (
              <>
                <p className="mb-4">Please enter a new password:</p>
                <ResetPasswordForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => doResetPassword({ username: email, user_id, ...values })}
                  showPassword={false}
                  buttonText="Set Password"
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                  }}
                />
              </>
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

ResetPasswordScreen.propTypes = {
  doSendResetPassword: PropTypes.func,
  showSubmissionError: PropTypes.func,
  user: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doResetPassword: (values) => dispatch(resetPassword(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen);
