import swal from 'sweetalert';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { osName, osVersion, browserName, fullBrowserVersion } from 'react-device-detect';

export const defaultProfilePhoto =
  'https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg';

export const displayConfirm = (text, title, icon = 'info') =>
  new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes',
        },
      },
    }).then((value) => {
      if (value) {
        resolve(true);
      } else {
        reject(new Error('rejected'));
      }
    });
  });

export const getDeviceInfo = () => ({
  deviceId: `${browserName} ${fullBrowserVersion}`,
  systemName: osName,
  systemVersion: osVersion,
  appVersion: 'n/a',
  timezone: moment.tz.guess(),
});

export const copyToClipboard = (val, label) => {
  navigator.clipboard.writeText(val);
  toast(`${label} Copied to Clipboard!`, {
    type: 'info',
    position: 'top-center',
    autoClose: 3000,
  });
};

export const isMobileDevice = () => {
  let hasTouchScreen = false;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};
