import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';

class BaseNoNav extends Component {
  render() {
    const { children, history } = this.props;
    return (
      <div className="wrapper pre-auth">
        <Header history={history} type="noNav" />
        <section className="section-container">{children}</section>
        <Footer />
      </div>
    );
  }
}

BaseNoNav.propTypes = {
  children: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
};

export default BaseNoNav;
