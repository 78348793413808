export const INIT_USERNAME_REQUEST = 'INIT_USERNAME_REQUEST';
export const INIT_ROUTE_REQUEST = 'INIT_ROUTE_REQUEST';

export function initializeUsername(payload) {
  return {
    type: INIT_USERNAME_REQUEST,
    username: payload,
  };
}

export function initializeRoute(route) {
  return {
    type: INIT_ROUTE_REQUEST,
    route,
  };
}
