import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import FeedbackForm from '../forms/FeedbackForm';
import { submissionError } from '../actions/formActions';
import { submitFeedback } from '../actions/teamActions';

class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      submitted: false,
      email: '',
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  updateState = (email) => {
    this.setState({
      submitted: true,
      email,
    });
  };

  resetState = () => {
    const { initialState } = this.state;
    this.setState({
      ...initialState,
      initialState,
    });
  };

  render() {
    const {
      isOpen,
      toggle,
      doSubmitFeedback,
      showSubmissionError,
      user = {},
      team = {},
      device = {},
    } = this.props;
    const { user_id, first_name, last_name, email } = user;
    let settings;
    let name;
    let team_id;
    if (team && team.settings) {
      ({
        settings: { name, team_id },
      } = team);
    }

    const { submitted } = this.state;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/feedback', title: 'Send Feedback' });
    }
    // TODO: add CSSTransition between submitted states
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.resetState}
        className="modal-medium"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <PageTitle
            title="Send Feedback"
            showTeam={false}
            icon="fa-1x ms-icon- icon-feedback-outline"
          />
        </ModalHeader>
        <ModalBody>
          {!submitted ? (
            <>
              <Row>
                <Col xs={12}>
                  <FeedbackForm
                    showSubmissionError={showSubmissionError}
                    onSubmit={(values) => {
                      doSubmitFeedback(values);
                      // this.updateState(values.email);
                      toggle();
                    }}
                    onCancel={toggle}
                    user={user}
                    team={team}
                    initialValues={{
                      url: window.location.pathname,
                      first_name,
                      last_name,
                      user_id,
                      name,
                      email,
                      team_id,
                      device,
                    }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="callout-container d-flex flex-row justify-content-center align-items-center">
                  Thank you for your feedback! Our team reviews all submissions and will reach out
                  personally if we have any follow-up questions.
                </div>
                <div className="mt-5 text-center">
                  <Button color="primary" size="lg" onClick={toggle} className="fixed-width-lg">
                    Done
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  doSubmitFeedback: PropTypes.func,
  showSubmissionError: PropTypes.func,
  preview: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  device: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  team: state.team,
  device: state.device,
});
const mapDispatchToProps = (dispatch) => ({
  doSubmitFeedback: (values) => {
    dispatch(submitFeedback(values));
  },
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackModal);
