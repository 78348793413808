import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RemoveMembershipModal from '../../modals/RemoveMembershipModal';

class RemoveMembershipButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeMembershipModalOpen: false,
    };
  }

  toggleRemoveMembershipModal = () => {
    const { removeMembershipModalOpen } = this.state;
    this.setState({
      removeMembershipModalOpen: !removeMembershipModalOpen,
    });
  };

  render() {
    const { className, user } = this.props;
    const { removeMembershipModalOpen } = this.state;
    return (
      <>
        <button className={`btn-text ${className}`} onClick={this.toggleRemoveMembershipModal}>
          <em className="fa-lg mr-2 far fa-trash-alt" />
          Remove Membership
        </button>
        <RemoveMembershipModal
          user={user}
          isOpen={removeMembershipModalOpen}
          toggle={this.toggleRemoveMembershipModal}
        />
      </>
    );
  }
}

RemoveMembershipButton.propTypes = {
  className: PropTypes.string,
  user: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveMembershipButton);
