import numeral from 'numeral';

export const formatCurrency = (input, showZero) => {
  let transformedInput = '';
  let number = '';
  if (input !== undefined) {
    number = input.toString().replace(/[^0-9]+/g, '');
  }
  if (number.length) {
    if (number.length > 2) {
      transformedInput += `${number.substring(0, number.length - 2)}.${number.substring(
        number.length - 2,
        number.length
      )}`;
    } else if (number.length === 2) {
      transformedInput += `0.${number.substring(0, 2)}`;
    } else {
      transformedInput += `0.0${number}`;
    }
    transformedInput = numeral(transformedInput).format('$0,0.00');
  } else {
    transformedInput = '';
  }

  if (showZero) {
    // keep as-is
  } else if (transformedInput === '$0.00') {
    transformedInput = '';
  }
  return transformedInput;
};

export const formatCentsAsCurrency = (cents) => numeral(cents / 100).format('$0,000.00');
export const currencyFormatter = (input) => formatCentsAsCurrency(input).replace('$', '');
export const currencyParser = (input) => input.toString().replace(/[^0-9]+/g, '');
export const integersOnly = (input) => {
  if (input && input.toString().length) {
    const parsed = parseInt(input.toString().replace(/[^0-9]/g, ''));
    if (isNaN(parsed)) {
      return 0;
    }
    return parsed;
  }
  return 0;
};

export const displayWeightInPreferredUnit = (weight_pounds, preferred_weight_units = 'imperial') => {
  if (preferred_weight_units === 'imperial') {
    return weight_pounds;
  }
  return numeral(weight_pounds / 2.205).format('0.[0]');
};

export const displayMeasurementInPreferredUnit = (measurement, preferred_weight_units = 'imperial') => {
  if (preferred_weight_units === 'imperial') {
    return measurement;
  }
  return numeral(measurement * 2.54).format('0.[0]');
};
