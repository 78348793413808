import { all, take, put, call } from 'redux-saga/effects';
import swal from 'sweetalert';
import { SHOW_ALERT, showAlert } from '../actions/globalActions';
import { SUBMISSION_ERROR } from '../actions/formActions';
import { hashmapToArray } from '../utils/hashMapUtils';
import browserHistory from '../history';

export default function* globalSagas() {
  yield all([showAlertSaga(), submissionError()]);
}

function displayAlert(text, title, icon = 'error', nextState, scroll = true, nextFunction, content) {
  return swal({
    title,
    text,
    icon,
    content,
  }).then(() => {
    if (browserHistory !== undefined && nextState !== undefined) {
      browserHistory.push(nextState);
    } else if (nextFunction) {
      nextFunction();
    } else if (scroll) {
      window.scrollTo(0, 0);
    }
  });
}

function* showAlertSaga() {
  while (true) {
    const { text, title, icon, nextState, nextAction, scroll, nextFunction, content } = yield take(SHOW_ALERT);
    yield call(displayAlert, text, title, icon, nextState, scroll, nextFunction, content);
    if (nextAction) {
      yield put(nextAction);
    }
  }
}

function* submissionError() {
  while (true) {
    const { errors, scroll } = yield take(SUBMISSION_ERROR);
    const errs = hashmapToArray(errors);
    yield put(
      showAlert('Oops!', errs.join('\n'), 'warning', undefined, undefined, undefined, scroll)
    );
  }
}
