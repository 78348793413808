import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderInputForField from '../helpers/renderInputForField';
import { capitalizeFirst } from '../../utils/stringUtils';

class GroupNameField extends Component {
  render() {
    const {
      fieldName = 'name',
      inputCaption,
    } = this.props;

    return (
      <Field
        name={fieldName}
        component={renderInputForField}
        format={capitalizeFirst}
        inputCaption={inputCaption}
        {...{
          inputProps: {
            type: 'text',
          },
          itemProps: {},
        }}
      />
    );
  }
}

GroupNameField.propTypes = {
  fieldName: PropTypes.string,
  inputCaption: PropTypes.string,
};

export default GroupNameField;
