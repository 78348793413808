import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ButtonToolbar,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import CancelPlanButton from './CancelPlanButton';
import browserHistory from '../../history';

class DropdownSubMenu extends Component {
  constructor(props) {
    super(props);
    const { title = 'Actions' } = this.props;
    this.state = {
      ddOpen: false,
      title,
    };
  }

  toggleDD = () => {
    const { ddOpen } = this.state;
    this.setState({
      ddOpen: !ddOpen,
    });
  };

  doOnClick = (d) => {
    const { persist = true, obj } = this.props;
    if (d.onClick) {
      if (obj) {
        d.onClick(obj);
      } else {
        d.onClick();
      }
    } else if (d.url) {
      browserHistory.push(d.url);
    }

    if (persist) {
      this.setState({ title: d.label });
    }
  };

  renderButton = (d) => {
    switch (d) {
      case 'CancelPlanButton':
      default:
        <CancelPlanButton team={d.team} />;
    }
  };

  render() {
    const { buttons = [], showCarat = true } = this.props;
    const { ddOpen, title } = this.state;
    return (
      <ButtonToolbar className="dropdown-sub-menu">
        <ButtonDropdown
          isOpen={ddOpen}
          toggle={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.toggleDD();
          }}
        >
          <DropdownToggle>
            {title}
            {showCarat && <em className="fa-1x ml-2 fas fa-caret-down" />}
          </DropdownToggle>
          <DropdownMenu right>
            {buttons.map((d) => (
              <DropdownItem key={d.label} onClick={() => this.doOnClick(d)}>
                {d.button && this.renderButton(d)}
                {d.icon && (
                  <div className="dropdown-sub-menu-icon-container">
                    <em className={d.icon} />
                  </div>
                )}
                {d.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      </ButtonToolbar>
    );
  }
}

DropdownSubMenu.propTypes = {
  buttons: PropTypes.instanceOf(Array),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  persist: PropTypes.bool,
  showCarat: PropTypes.bool,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownSubMenu);
