import ReactGA from 'react-ga4';

const update = (page) => {
  // ReactGA.event({
  //   category: 'ecommerce',
  //   action: 'team_checkout_progress',
  //   label: page,
  // });
};

const checkout = (signup) => {
  const { membershipConfirmation } = signup;
  const { amount } = membershipConfirmation;
  // ReactGA.event({
  //   category: 'ecommerce',
  //   action: 'team_checkout_progress',
  //   label: 'purchase',
  //   value: amount / 100,
  // });
};

export default {
  update,
  checkout,
};
