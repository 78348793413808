import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { Channel, ChannelList, Chat, InfiniteScroll, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StreamChat } from 'stream-chat';
import ChannelInfo from './ChannelInfo';
import ENV_VARS from '../../utils/envVars';
import Loading from '../../components/Common/Loading';
import { formatFromNowDate } from '../../utils/dateUtils';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);
let connected = false;
chatClient.on('connection.changed', (e) => {
  if (e.online) {
    connected = true;
  }
});

const ChatContainer = ({
    team,
    currentUser,
    filters,
    sort,
  }) => {
  const [chatTokens, setChatTokens] = React.useState(null);
  const isConnected = useMemo(() => currentUser && team && chatTokens && connected, [connected, currentUser, team, chatTokens]);

  useEffect(() => {
    if (
      team !== undefined &&
      team.chatTokens !== undefined &&
      currentUser !== undefined &&
      currentUser.user_id !== undefined
    ) {
      setChatTokens(team.chatTokens);
    }
  }, [team, currentUser]);

  return isConnected ? (
    <div className="all-chats-container">
      <Row>
        <Col xs={12} className="d-flex width-100-percent">
          <Chat client={chatClient}>
            <ChannelList filters={filters} sort={sort} Paginator={InfiniteScroll} />
            <Channel>
              <Window>
                <ChannelInfo currentUser={currentUser} />
                <MessageList disableDateSeparator={false} formatDate={formatFromNowDate} />
                <MessageInput grow={true} />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </Col>
      </Row>
    </div>
  ) : <Loading />;
};

ChatContainer.propTypes = {
  currentUser: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  currentUser: state.auth.user,
  team: state.team,
});
const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer);
