import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import tableIcons from '../../utils/muiIcons';
import { capitalizeFirst, convertBoolToYesNo } from '../../utils/stringUtils';

class ChallengeTable extends Component {
  render() {
    const {
      challenges,
      title = 'All Challenges',
      showTitle = true,
      onRowClick,
      search = true,
    } = this.props;

    const columns = [
      { title: 'Name', field: 'name' },
      {
        title: 'Start Date',
        field: 'start_on_date',
        type: 'date',
        defaultSort: 'desc',
        width: '25%',
        render: (rowData) =>
          rowData.start_on_date ? moment(rowData.start_on_date).format('MMMM D, YYYY') : '--',
      },
      {
        title: 'End Date',
        field: 'through_date',
        type: 'date',
        render: (rowData) =>
          rowData.through_date ? moment(rowData.through_date).format('MMMM D, YYYY') : '--',
      },
      // {
      //   title: 'Status',
      //   field: 'status',
      //   render: (rowData) =>
      //     capitalizeFirst(rowData.status),
      // },
      {
        title: 'Visible?',
        field: 'is_visible',
        type: 'boolean',
        render: (rowData) => (
          <span className={`${rowData.is_visible ? '' : 'error'}`}>
            {convertBoolToYesNo(rowData.is_visible)}
          </span>
        ),
      },
    ];

    return (
      <MaterialTable
        columns={columns}
        data={challenges}
        title={title}
        key={challenges.length} // https://github.com/mbrn/material-table/issues/1480
        options={{
          pageSize: challenges.length < 20 ? challenges.length : 20,
          search,
          paging: challenges.length > 20,
          sorting: true,
          thirdSortClick: false,
          showTitle,
          toolbar: showTitle,
        }}
        style={{
          backgroundColor: '#f7f7f7',
        }}
        icons={tableIcons}
        onRowClick={onRowClick}
      />
    );
  }
}

ChallengeTable.propTypes = {
  title: PropTypes.string,
  onRowClick: PropTypes.func,
  challenges: PropTypes.instanceOf(Array),
  search: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default ChallengeTable;
