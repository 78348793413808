import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pill extends Component {
  render() {
    const { title, className } = this.props;
    return <div className={`pill ${className}`}>{title}</div>;
  }
}

Pill.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Pill;
