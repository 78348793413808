import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import BusinessSettingsForm from '../../forms/BusinessSettingsForm';
import { submissionError } from '../../actions/formActions';
import { updateTeam } from '../../actions/teamActions';
import { makeSimpleValue } from '../../utils/formUtils';

class BusinessSettings extends React.Component {
  render() {
    const { showSubmissionError, teamSettings, doUpdateTeam } = this.props;
    let team_id;
    let name;
    let phone;
    let email;
    let address;
    let address_alt;
    let city;
    let state;
    let zip;
    let primary_logo_path;
    let primary_background_color;
    if (teamSettings && teamSettings.name) {
      ({
        team_id,
        name,
        phone,
        email,
        address,
        address_alt,
        city,
        state,
        zip,
        primary_logo_path,
        primary_background_color,
      } = teamSettings);
    }
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Business Settings" />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className="main-content-container">
              {name && team_id ? (
                <BusinessSettingsForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={(values) => {
                    doUpdateTeam(team_id, values);
                  }}
                  showPhotoUpload={false}
                  teamSettings={teamSettings}
                  initialValues={{
                    name,
                    phone,
                    email,
                    address,
                    address_alt,
                    city,
                    state,
                    zip,
                    logo_background_color: primary_background_color,
                    logo_image: primary_logo_path,
                  }}
                />
              ) : (
                <Loading />
              )}
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

BusinessSettings.propTypes = {
  showSubmissionError: PropTypes.func,
  teamSettings: PropTypes.shape({
    team_id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    address_alt: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    logo_image: PropTypes.string,
    logo_background_color: PropTypes.string,
  }),
  doUpdateTeam: PropTypes.func,
};
const mapStateToProps = (state) => ({
  teamSettings: state.team.settings,
});
const mapDispatchToProps = (dispatch) => ({
  doUpdateTeam: (team_id, values) => {
    values.state = makeSimpleValue(values.state);
    dispatch(updateTeam(team_id, values));
  },
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSettings);
