import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import Portion from './Portion';

class MealCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accordionState: false,
    };
  }

  toggleAccordion = (id) => {
    const accordionState = !this.state.accordionState;
    this.setState({
      accordionState,
    });
  };

  render() {
    const { meals, meal_id } = this.props;
    const { accordionState } = this.state;
    const meal = meals.find((m) => m.uuid === meal_id);
    const { label, portions, macros } = meal;
    const foods = [];
    portions.map((p) => foods.push(p.name));

    return (
      <div>
        <Card className="card-default card-container mb-3 meal-card" onClick={() => this.toggleAccordion()}>
          <CardHeader>{label}</CardHeader>
          {portions && portions.length > 0 && (
            <>
              <CardBody className={accordionState ? 'expanded' : ''}>
                {!accordionState && (foods.join(', '))}
                <div className="macros d-flex flex-row">
                  <div className="macro first">
                    <div className="macro-label">C</div>
                    <div className="macro-value">{macros.carbs_met}{!accordionState && <>g</>}</div>
                    {accordionState && <div className="macro-target">/{macros.carbs_target}g</div>}
                  </div>
                  <div className="macro">
                    <div className="macro-label">P</div>
                    <div className="macro-value">{macros.protein_met}{!accordionState && <>g</>}</div>
                    {accordionState && <div className="macro-target">/{macros.protein_target}g</div>}
                  </div>
                  <div className="macro">
                    <div className="macro-label">F</div>
                    <div className="macro-value">{macros.fat_met}{!accordionState && <>g</>}</div>
                    {accordionState && <div className="macro-target">/{macros.fat_target}g</div>}
                  </div>
                  <div className="macro">
                    <div className="macro-label">Cal</div>
                    <div className="macro-value">{macros.calories_met}</div>
                    {accordionState && <div className="macro-target">/{macros.calories_target}</div>}
                  </div>
                </div>
              </CardBody>
              <Collapse isOpen={accordionState}>
                <CardBody>
                  {portions.map((p, idx) => (
                    <Portion className={idx === 0 ? 'first' : ''} key={p.uuid} portion={p} />
                  ))}
                </CardBody>
              </Collapse>
            </>
          )}
        </Card>
      </div>
    );
  }
}

MealCard.propTypes = {
  meals: PropTypes.instanceOf(Array),
  meal_id: PropTypes.string,
};

export default MealCard;
