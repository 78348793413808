import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';

class RecipeSearchForm extends Component {
  render() {
    const { initialValues, onSubmit, onReset } = this.props;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            onSubmit(values);
          }}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col md={9}>
                  <Field
                    name="name"
                    component={renderInputForField}
                    label="Recipe Name"
                    stackedlabel
                    {...{
                      inputProps: {
                        type: 'text',
                      },
                      itemProps: {},
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <button
                    className="btn btn-lg btn-secondary fixed-width-md"
                    type="reset"
                    onClick={() => {
                      form.reset();
                      onReset();
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-lg btn-primary fixed-width-md ml-3 btn-search-with-reset"
                    type="submit"
                    disabled={submitting}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

RecipeSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  onReset: PropTypes.func,
};

export default RecipeSearchForm;
