export const productOptions = [
  {
    label: 'V2',
    value: 'V2',
  },
  {
    label: 'Team',
    value: 'team',
  },
];
