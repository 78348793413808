import React from 'react';
import { useDropzone } from 'react-dropzone';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const DropzoneImage = ({ onDrop, onDelete, imageUrl }) => {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': ['.jpeg', '.png']
    },
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 5242880,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <>
      <li>
        {file.path}
      </li>

      {errors.map((e) => (
        <li key={e.code}>{e.message}</li>
        ))}
    </>
  ));

  return (
    <>
      <section>
        {imageUrl && (
          <button
            onClick={onDelete}
            type="button"
            className="btn"
            style={{
              color: '#000',
              position: 'absolute',
              top: 10,
              left: 150,
              fontSize: '1.4rem',
            }}
          >
            &times;
          </button>
        )}

        <div
          className="dropbox-container"
          style={{
            borderColor: getColor({ isDragAccept, isDragReject, isFocused }),
            backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
          }}
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          {!imageUrl && (
            <button type="button" className="btn" onClick={open}>
              Click or Drop to select file
            </button>
          )}

          <input {...getInputProps()} />
        </div>
      </section>
      <aside>
        {fileRejectionItems.length > 0 && (
          <>
            <h4 style={{ margin: 10 }}>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
          </>
        )}
      </aside>
    </>
  );
};
export default DropzoneImage;
