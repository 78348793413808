import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import LogInForm from '../../forms/LogInForm';
import { submissionError } from '../../actions/formActions';
import { sendResetPassword } from '../../actions/authActions';
import { getLocalStoreValue, INIT_EMAIL_KEY } from '../../utils/localStorage';

class ForgotPassword extends React.Component {
  render() {
    const { showSubmissionError, doSendResetPassword } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <PageTitle title="Forgot Password" showTeam={false} />
            <p className="mb-4">
              Enter your email address and we'll send you instructions for resetting your password.
            </p>
            <LogInForm
              showSubmissionError={showSubmissionError}
              onSubmit={(values) => doSendResetPassword(values)}
              showPassword={false}
              buttonText="Send Reset Instructions"
              initialValues={{
                email: getLocalStoreValue(INIT_EMAIL_KEY) || '',
              }}
            />
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

ForgotPassword.propTypes = {
  doSendResetPassword: PropTypes.func,
  showSubmissionError: PropTypes.func,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSendResetPassword: (values) => dispatch(sendResetPassword(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
