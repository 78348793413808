export const SHOW_ALERT = 'SHOW_ALERT';

export function showAlert(title, text, icon, nextState, nextAction, scroll, nextFunction, content) {
  return {
    type: SHOW_ALERT,
    title,
    text,
    icon,
    nextState,
    nextAction,
    scroll,
    nextFunction,
    content,
  };
}
