import ENV_VARS from '../utils/envVars';

export const PRINT_RECIPE_SUCCESS = 'PRINT_RECIPE_SUCCESS';
export const PRINT_RECIPE_FAILURE = 'PRINT_RECIPE_FAILURE';

export function printRecipeApi(recipe_id) {
  const config = {
    // baseURL: ENV_VARS.BASE_URL,
    // url: `/recipe-details?recipe_id=${recipe_id}`,
    baseURL: ENV_VARS.BASE_URL_NATIVE,
    url: `/print-recipe?recipe_id=${recipe_id}`,
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    // auth: true,
    auth: false,
    spinner: true,
    successAction: PRINT_RECIPE_SUCCESS,
    failureAction: PRINT_RECIPE_FAILURE,
  };
}
