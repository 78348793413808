import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
import tableIcons from '../../utils/muiIcons';

const UserPreviewImportTable = ({
  users,
  title = 'Users Preview',
  showTitle = true,
  search = true,
  exportButton = true,
  }) => {
  const tableProps = {
    padding: 'dense',
    sorting: true,
    showTitle,
    search,
    pageSize: users.length < 20 ? users.length : 20,
    paging: users.length > 20,
    thirdSortClick: false,
    toolbar: showTitle,
    exportButton,
  };

  return (
    <MaterialTable
      columns={[
        { title: 'Last Name', field: 'last_name' },
        { title: 'First Name', field: 'first_name' },
        { title: 'Email', field: 'email' },
        { title: 'Location Name', field: 'location_name', defaultSort: 'asc' },
      ]}
      data={users}
      title={title}
      key={users.length} // https://github.com/mbrn/material-table/issues/1480
      options={tableProps}
      icons={tableIcons}
    />
  );
};

UserPreviewImportTable.propTypes = {
  users: PropTypes.instanceOf(Array),
};

export default UserPreviewImportTable;
