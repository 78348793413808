import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class ButtonFilters extends Component {
  render() {
    const { buttons, onClick, activeButton, size = 'md', buttonClassName } = this.props;
    return (
      <>
        {buttons.map((button) => (
          <Button
            color={button.id === activeButton ? 'primary' : 'secondary'}
            key={button.id}
            size={size}
            onClick={() => onClick(button)}
            className={buttonClassName}
          >
            {button.title}
          </Button>
        ))}
      </>
    );
  }
}

ButtonFilters.propTypes = {
  buttons: PropTypes.instanceOf(Array),
  onClick: PropTypes.func,
  activeButton: PropTypes.string,
  size: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default ButtonFilters;
