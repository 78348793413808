import React, { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import scrollToComponent from 'react-scroll-to-component';
import DropdownSubMenu from '../../components/Common/DropdownSubMenu';
import Loading from '../../components/Common/Loading';
import UserTable from '../../components/Tables/UserTable';
import ChallengeTable from '../../components/Tables/ChallengeTable';
import AdminTable from '../../components/Tables/AdminTable';
import BillingContainer from './BillingContainer';
import TeamDashboardContainer from './TeamDashboardContainer';
import InviteClientModal from '../../modals/InviteClientModal';
import CancelPlanModal from '../../modals/CancelPlanModal';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import SendEmailButton from '../../components/Common/SendEmailButton';
import RemoveMembershipButton from '../../components/Common/RemoveMembershipButton';
import Calendar from '../../components/Common/Calendar';
import UserAvatar from '../../components/Common/UserAvatar';
import AddClientsControl from '../../components/AddClients/AddClientsControl';
import ManageInvitesControl from '../../components/AddClients/ManageInvitesControl';
import StatisticSelectCard from '../../components/Cards/StatisticSelectCard';
import LineChartCard from '../../components/Cards/LineChartCard';
import BusinessSettingsForm from '../../forms/BusinessSettingsForm';
import { submissionError } from '../../actions/formActions';
// import { updateUser } from '../../actions/userActions';
import {
  capitalizeFirst,
  formatPhone,
  formatHeightFromInches,
  arrayOfLabelsToString,
  getTimezoneLabel,
  formatAddress,
  convertBoolToYesNo,
} from '../../utils/stringUtils';
import { copyToClipboard } from '../../utils/global';
import { calculateAge } from '../../utils/dateUtils';
import { buildWeighInsLineChartData, buildMeasurementsLineChartData } from '../../utils/chartUtils';
import { makeSimpleValue } from '../../utils/formUtils';
import { FORM_TRANSITION_PROPS } from '../../constants';
import browserHistory from '../../history';
import {
  getAllClients,
  getAdmins,
  getTeamMembership,
  getPendingClients,
  clearPendingClients,
  getEngagementSummary,
  getClientStatusAndGoalDistribution,
  getEngagementSummaryCsv,
} from '../../actions/teamActions';
import { getAllChallenges } from '../../actions/challengeActions';

const tz = moment.tz.guess();
const now = moment();
let dashboardStartDate = now.clone().add(-30, 'days');
let dashboardEndDate = now;

class TeamDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'settings',
    };
  }

  componentDidMount() {
    if (window.location.hash) {
      const hash = structuredClone(window.location.hash);
      setTimeout(() => {
        this.toggleTab(hash.replace('#', ''), false);
      }, 100);
    } else {
      this.toggleTab('settings', false);
    }
  }

  toggleTab = (activeTab, doPush = true) => {
    const {
      doGetAllClients,
      doGetAdmins,
      doGetTeamMembership,
      team,
      doGetEngagementSummary,
      doGetClientStatusAndGoalDistribution,
      doGetAllChallenges,
    } = this.props;
    this.setState(
      {
        activeTab,
        timestamp: new Date().getTime(),
      },
      () => {
        if (activeTab === 'clients') {
          doGetAllClients(team.team_id);
        } else if (activeTab === 'admins') {
          doGetAdmins(team.team_id);
        } else if (activeTab === 'billing') {
          doGetTeamMembership(team.team_id);
        } else if (activeTab === 'manageinvites') {
          this.refreshPendingClients();
        } else if (activeTab === 'dashboard') {
          doGetTeamMembership(team.team_id);
          doGetEngagementSummary(team.team_id, dashboardStartDate, dashboardEndDate);
          doGetClientStatusAndGoalDistribution(team.team_id);
        } else if (activeTab === 'challenges') {
          doGetAllClients(team.team_id);
          doGetAllChallenges(team.team_id);
        }
        if (doPush) {
          window.history.pushState('', '', `/teams/${team.team_id}#${activeTab}`);
        }
      }
    );
  };

  toggleCancelPlanModal = () => {
    const { cancelPlanModalOpen } = this.state;
    this.setState({
      cancelPlanModalOpen: !cancelPlanModalOpen,
    });
  };

  goToUserDetail = (user, team_id) => {
    // browserHistory.push(`/users/${user.id}`);
    browserHistory.push(`/users/${user.id}?team_id=${team_id}`);
  };

  goToChallengeDetail = (challenge) => {
    browserHistory.push(`/teams/${challenge.team_id}/challenges/${challenge.id}`);
  };

  toggleForm = (form) => {
    const prevState = this.state[`show${form}`]; // eslint-disable-line
    this.setState({
      [`show${form}`]: !prevState,
    });
    setTimeout(() => {
      const component = prevState ? this.UserInfoContainer : this[form];
      if (component) {
        scrollToComponent(component, { duration: 250, offset: -20, align: 'top' });
      }
    }, 300);
  };

  refreshPendingClients = () => {
    const { doClearPendingClients, doGetPendingClients, team } = this.props;
    doClearPendingClients();
    doGetPendingClients(team.team_id);
  };

  updateEngagementSummary = (
    team_id,
    startDate,
    endDate,
    sortBy,
    order,
    page,
    showCount,
    clearData = false
  ) => {
    const { doGetEngagementSummary } = this.props;
    dashboardStartDate = startDate;
    dashboardEndDate = endDate;
    doGetEngagementSummary(
      team_id,
      dashboardStartDate,
      dashboardEndDate,
      sortBy,
      order,
      page,
      showCount,
      clearData
    );
  };

  render() {
    const {
      team = {},
      clients,
      showSubmissionError,
      doUpdateTeam,
      doGetEngagementSummary,
      doGetEngagementSummaryCsv,
    } = this.props;
    const {
      team_id,
      name,
      logoUrl,
      admins,
      phone,
      email,
      address,
      address_alt,
      city,
      state,
      zip,
      business_type,
      client_count,
      former_customer,
      membership,
      engagementSummary,
      counts,
      goal_distribution,
      dashboard,
      preferred_contact_method,
      primary_logo_path,
      primary_background_color,
    } = team;
    const { activeTab, cancelPlanModalOpen, timestamp, showBusinessSettingsForm } = this.state;

    const tabButtons = [
      {
        label: 'Settings',
        onClick: () => this.toggleTab('settings'),
      },
      {
        label: 'Dashboard',
        onClick: () => this.toggleTab('dashboard'),
      },
      {
        label: 'Clients',
        onClick: () => this.toggleTab('clients'),
      },
      {
        label: 'Admins',
        onClick: () => this.toggleTab('admins'),
      },
      // {
      //   label: 'Groups',
      //   onClick: () => this.toggleTab('groups'),
      // },
      // {
      //   label: 'Insights',
      //   onClick: () => this.toggleTab('insights'),
      // },
      {
        label: 'Billing',
        onClick: () => this.toggleTab('billing'),
      },
      {
        label: 'Challenges',
        onClick: () => this.toggleTab('challenges'),
      },
      {
        label: 'Add Clients',
        onClick: () => this.toggleTab('addclients'),
      },
      {
        label: 'Manage Invites',
        onClick: () => this.toggleTab('manageinvites'),
      },
    ];
    return (
      <>
        <Row>
          <Col md={6}>
            <div
              className="d-flex flex-row align-items-center"
              ref={(div) => {
                this.TeamInfoContainer = div;
              }}
            >
              {logoUrl && <UserAvatar team={team} />}
              <div className="ml-3">
                <div className="user-name">{name}</div>
              </div>
            </div>
          </Col>
          {/* <Col className="d-flex justify-content-end align-items-center">
            <div className="ml-2">
              <DropdownSubMenu buttons={actionMenuButtons} title="Quick Actions" persist={false} />
            </div>
          </Col> */}
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <div className="d-md-none">
              <DropdownSubMenu
                buttons={tabButtons}
                title={`${activeTab.charAt(0).toUpperCase()}${activeTab.substring(1)}`}
              />
            </div>
            <Nav tabs className="d-none d-md-flex">
              {tabButtons.map((t) => (
                <NavItem
                  key={t.label}
                  className={activeTab === t.label.toLowerCase().replace(' ', '') ? 'active' : ''}
                >
                  <NavLink onClick={t.onClick}>{t.label}</NavLink>
                  <div className="tab-indicator animated" />
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab} className="animated">
              <TabPane tabId="settings">
                <Row>
                  <Col sm="6">
                    <div
                      className="mb-4"
                    >
                      <h4>Business Settings</h4>
                      {/* <h4 className={showBusinessSettingsForm ? 'cancelable' : 'editable'}>
                        Business Settings
                      </h4> */}
                    </div>

                    <div ref={(el) => (this.container = el)}>
                      <CSSTransition
                        {...FORM_TRANSITION_PROPS}
                        in={showBusinessSettingsForm}
                        onExited={() => {
                          this.setState({
                            showBusinessSettingsForm: false,
                          });
                        }}
                      >
                        <div
                          ref={(div) => {
                            this.BusinessSettingsForm = div;
                          }}
                          className="mt-4"
                        >
                          <BusinessSettingsForm
                            showSubmissionError={showSubmissionError}
                            onSubmit={(values) => {
                              doUpdateTeam(team_id, values);
                              this.toggleForm('BusinessSettingsForm');
                            }}
                            showPhotoUpload={false}
                            showCancel
                            onCancel={() => this.toggleForm('BusinessSettingsForm')}
                            teamSettings={team}
                            initialValues={{
                              name,
                              phone,
                              email,
                              address,
                              address_alt,
                              city,
                              state,
                              zip,
                            }}
                          />
                        </div>
                      </CSSTransition>
                      {!showBusinessSettingsForm && (
                        <div>
                          <ToastContainer />
                          <div className="team-data-section">
                            <div className="team-row">
                              <label>Name of Gym</label>
                              <div>{name}</div>
                            </div>
                            <div
                              className="team-row cursor-pointer"
                              onClick={() => {
                                copyToClipboard(email, 'Email Address');
                              }}
                            >
                              <label>Email Address</label>
                              <div className={email ? 'copyable' : ''}>{email || '--'}</div>
                            </div>
                            <div className="team-row">
                              <label>Phone</label>
                              <div>{formatPhone(phone) || '--'}</div>
                            </div>
                            <div className="team-row">
                              <label>Preferred Contact Method</label>
                              <div>{capitalizeFirst(preferred_contact_method) || '--'}</div>
                            </div>
                            <div
                              className="team-row cursor-pointer"
                              onClick={() => {
                                copyToClipboard(team_id, 'Team ID');
                              }}
                            >
                              <label>Team ID</label>
                              <div className={team_id ? 'copyable' : ''}>{team_id || '--'}</div>
                            </div>
                            <div className="team-row">
                              <label>Address</label>
                              <div dangerouslySetInnerHTML={{ __html: formatAddress(team) }} />
                            </div>
                            <div className="team-row">
                              <label>Logo Image</label>
                              {primary_logo_path !== undefined && primary_logo_path.length > 0 ? (
                                <div
                                  className="logo-image-container"
                                  style={{ backgroundColor: primary_background_color }}
                                >
                                  <img src={primary_logo_path} className="logo-img" />
                                </div>
                              ) : (
                                <div>--</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  {!showBusinessSettingsForm && (
                    <Col xs={6}>
                      <div className="mb-4">
                        <h4>Business Profile</h4>
                      </div>
                      <div className="team-data-section">
                        <div className="team-row">
                          <label>Type of Business</label>
                          <div>{business_type}</div>
                        </div>
                        <div className="team-row">
                          <label>Number of Clients</label>
                          <div>{client_count}</div>
                        </div>
                        <div className="team-row">
                          <label>Have Used Macrostax Before?</label>
                          <div>{convertBoolToYesNo(former_customer)}</div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </TabPane>
              <TabPane tabId="dashboard" className="animated">
                <Row>
                  <Col sm="12">
                    {team && membership && engagementSummary && counts && goal_distribution ? (
                      <TeamDashboardContainer
                        team={team}
                        isTeamOwner={false}
                        doGetEngagementSummary={this.updateEngagementSummary}
                        startDate={dashboardStartDate}
                        endDate={dashboardEndDate}
                        doGetEngagementSummaryCsv={doGetEngagementSummaryCsv}
                      />
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="clients" className="animated">
                <Row>
                  <Col sm="12">
                    {team && team.clients ? (
                      <UserTable
                        users={team.clients}
                        title="Clients"
                        onRowClick={(e, rowData) => this.goToUserDetail(rowData, team_id)}
                        statusColumn="user_role_status"
                        search
                        showRemoveMembership={false}
                        isTeamView={true}
                        teamName={name}
                        isInternalAdmin={true}
                      />
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="admins" className="animated">
                <Row>
                  <Col sm="12">
                    {team && team.admins ? (
                      <UserTable
                        users={team.admins}
                        title="Admins"
                        onRowClick={(e, rowData) => this.goToUserDetail(rowData, team_id)}
                        statusColumn="user_role_status"
                        search
                        showRemoveMembership={false}
                        isTeamView={true}
                        showRole={true}
                        teamName={name}
                        isInternalAdmin={true}
                      />
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="billing" className="animated">
                <Row>
                  <Col sm="12">
                    {team && membership && (
                      <BillingContainer
                        team={team}
                        membership={membership}
                        showChangePlan={false}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="challenges" className="animated">
                <Row>
                  <Col sm="12">
                    {team && team.challenges && Array.isArray(team.challenges) ? (
                      <>
                        <ChallengeTable
                          challenges={team.challenges}
                          title="All Challenges"
                          onRowClick={(e, rowData) => this.goToChallengeDetail(rowData)}
                        />
                      </>
                    ) : (
                      <Loading />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="addclients" className="animated">
                <Row>
                  <Col sm="12">
                    {team && (
                      <AddClientsControl
                        team={team}
                        showSubmissionError={showSubmissionError}
                        toggleTab={this.toggleTab}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="manageinvites" className="animated">
                <Row>
                  <Col sm="12">
                    {team && (
                      <ManageInvitesControl
                        team={team}
                        showSubmissionError={showSubmissionError}
                        toggleTab={this.toggleTab}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <CancelPlanModal
          team={team}
          isOpen={cancelPlanModalOpen}
          toggle={this.toggleCancelPlanModal}
        />
      </>
    );
  }
}

TeamDetailContainer.propTypes = {
  team: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  doUpdateTeam: PropTypes.func,
  doGetClients: PropTypes.func,
  doGetAdmins: PropTypes.func,
  doGetTeamMembership: PropTypes.func,
  doClearPendingClients: PropTypes.func,
  doGetPendingClients: PropTypes.func,
  doGetEngagementSummary: PropTypes.func,
  doGetClientStatusAndGoalDistribution: PropTypes.func,
  doGetEngagementSummaryCsv: PropTypes.func,
  doGetAllChallenges: PropTypes.func,
};

const mapStateToProps = (state) => ({
  clients: state.context.team.clients,
  admins: state.context.team.admins,
  membership: state.context.team.membership,
});
const mapDispatchToProps = (dispatch) => ({
  doGetAllClients: (team_id) => dispatch(getAllClients(team_id)),
  doGetAdmins: (team_id) => dispatch(getAdmins(team_id)),
  doGetTeamMembership: (team_id) => dispatch(getTeamMembership(team_id)),
  doGetPendingClients: (team_id) => dispatch(getPendingClients(team_id)),
  doClearPendingClients: () => dispatch(clearPendingClients()),
  doGetEngagementSummaryCsv: (team_id, start_date, end_date) =>
    dispatch(getEngagementSummaryCsv(team_id, start_date, end_date)),
  doGetEngagementSummary: (
    team_id,
    dashboardStartDate,
    dashboardEndDate,
    sortBy,
    order,
    page,
    showCount,
    clearData
  ) =>
    dispatch(
      getEngagementSummary(
        team_id,
        dashboardStartDate,
        dashboardEndDate,
        sortBy,
        order,
        page,
        showCount,
        clearData
      )
    ),
  doGetClientStatusAndGoalDistribution: (team_id) =>
    dispatch(getClientStatusAndGoalDistribution(team_id)),
  doGetAllChallenges: (team_id) => dispatch(getAllChallenges(team_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetailContainer);
