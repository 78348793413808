import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { isTeamUser, userHasRole } from '../../utils/authUtils';
import { getTimeUntil } from '../../utils/dateUtils';
import { Roles } from '../../enums/roles';
import browserHistory from '../../history';
import { reactivateMembership } from '../../actions/teamActions';
import {
  setLocalStoreValue,
  getLocalStoreValue,
  COBRAND_PROMPT_KEY,
  WEBINAR_PROMPT_KEY,
} from '../../utils/localStorage';

class AlertBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localStorageCobranding: undefined,
      localStorageWebinar: undefined,
    };
  }

  goToBilling = () => {
    browserHistory.push('/billing');
  };

  goToBusinessSettings = () => {
    browserHistory.push('/businesssettings');
  };
  goToWebinar = () => {
    window.open('https://t.macrostax.com/macrostax-team-webinar-live-founder-qa', '_blank');
  };

  dismissCobrand = () => {
    const {
      team: { team_id },
    } = this.props;
    setLocalStoreValue(`${COBRAND_PROMPT_KEY}_${team_id}`, false);
    this.setState({
      localStorageCobranding: false,
    });
  };

  dismissWebinar = () => {
    const {
      team: { team_id },
    } = this.props;
    setLocalStoreValue(`${WEBINAR_PROMPT_KEY}_${team_id}`, false);
    this.setState({
      localStorageWebinar: false,
    });
  };

  render() {
    const { team = {}, user = {}, doReactivateMembership } = this.props;
    let { localStorageCobranding, localStorageWebinar } = this.state;
    const { roles } = user;
    const { membership = {}, team_id, settings } = team;
    const { status, next_bill_date, id } = membership;
    let primary_logo_path;
    if (settings) {
      ({ primary_logo_path } = settings);
    }
    localStorageCobranding = getLocalStoreValue(`${COBRAND_PROMPT_KEY}_${team_id}`);
    localStorageWebinar = getLocalStoreValue(`${WEBINAR_PROMPT_KEY}_${team_id}`);
    return (
      <>
        {/* {status === 'trialing' && userHasRole(user, Roles.TEAM_OWNER) && (
          <div className="alert-bar gray-100">
            Your free trial of Macrostax Team expires in{' '}
            <strong>{getTimeUntil(current_period_end, 'days')} days</strong>
            <Button className="ml-3" onClick={this.goToBilling} color="primary">
              Activate Now
            </Button>
          </div>
        )} */}
        {/* {status === 'pending-cancellation' && userHasRole(user, Roles.TEAM_OWNER) ? (
          <div className="alert-bar animated">
            Your Macrostax Team subscription has been canceled and will end on{' '}
            {moment(next_bill_date).format('MMMM Do, YYYY')}
            <Button className="ml-3" onClick={() => doReactivateMembership(team_id, id)}>
              Reactivate Now
            </Button>
          </div>
        ) : primary_logo_path === '' && localStorageCobranding !== false && userHasRole(user, Roles.TEAM_OWNER) ? (
          <div className="alert-bar animated info gray-200">
            Welcome! Show off your brand to your members by adding your logo!
            <Button className="ml-3" color="secondary" onClick={this.goToBusinessSettings}>
              Go to Settings
            </Button>
            <em
              className="dismiss fa fa-times hidden-on-mobile"
              onClick={() => {
                this.dismissCobrand();
              }}
            />
          </div>
        ) : null} */}

        {/* {localStorageWebinar !== false && userHasRole(user, Roles.TEAM_OWNER) ? (
          <div className="alert-bar animated marketing gray-200">
            Upcoming Live Founder Q&A to learn how to grow your fitness business, Thurs 8/31 @ 11 AM
            MT
            <Button className="ml-3" color="secondary" onClick={this.goToWebinar}>
              Register here
            </Button>
            <em
              className="dismiss fa fa-times hidden-on-mobile"
              onClick={() => {
                this.dismissWebinar();
              }}
            />
          </div>
        ) : null} */}
        {primary_logo_path === '' && localStorageCobranding !== false && userHasRole(user, Roles.TEAM_OWNER) ? (
          <div className="alert-bar animated info gray-200">
            Welcome! Show off your brand to your members by adding your logo!
            <Button className="ml-3" color="secondary" onClick={this.goToBusinessSettings}>
              Go to Settings
            </Button>
            <em
              className="dismiss fa fa-times hidden-on-mobile"
              onClick={() => {
                this.dismissCobrand();
              }}
            />
          </div>
        ) : null}
        {status === 'pending-cancellation' && userHasRole(user, Roles.TEAM_OWNER) ? (
          <div className="alert-bar animated">
            Your Macrostax Team subscription has been canceled and will end on{' '}
            {moment(next_bill_date).format('MMMM Do, YYYY')}
            <Button className="ml-3" onClick={() => doReactivateMembership(team_id, id)}>
              Reactivate Now
            </Button>
          </div>
        ) : null}
      </>
    );
  }
}

AlertBar.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  doReactivateMembership: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doReactivateMembership: (team_id, membership_id) =>
    dispatch(reactivateMembership(team_id, membership_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertBar);
