import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardHeader, Collapse } from 'reactstrap';

class Portion extends Component {
  render() {
    const { portion, className } = this.props;
    const {
      name,
      carbs_formatted,
      protein_formatted,
      fat_formatted,
      calories_formatted,
      portion_unit,
      portion_quantity,
      brand_name,
      portions,
    } = portion;

    return (
      <div className={`portion-container ${className}`}>
        <div className="d-flex flex-row justify-space-between">
          <div className="portion-name">
            {name}
            {brand_name && brand_name.length > 0 && <div className="brand_name">{brand_name}</div>}
          </div>
          {portions.length == 0 && (
            <div>
              <div className="portion-qty">
                {parseFloat(portion_quantity)} {portion_unit}
                {/* {portion_quantity.toString().replace('.00', '')} {portion_unit} */}
              </div>
            </div>
          )}
        </div>
        {portions.length == 0 ? (
          <div className="macros d-flex flex-row">
            <div className="macro first">
              <div className="macro-label">C</div>
              <div className="macro-value">{carbs_formatted}g</div>
            </div>
            <div className="macro">
              <div className="macro-label">P</div>
              <div className="macro-value">{protein_formatted}g</div>
            </div>
            <div className="macro">
              <div className="macro-label">F</div>
              <div className="macro-value">{fat_formatted}g</div>
            </div>
            <div className="macro">
              <div className="macro-label">Cal</div>
              <div className="macro-value">{calories_formatted}</div>
            </div>
          </div>
        ) : (
          <>
            {portions.map((p, idx) => (
              <div className="" key={p.uuid}>
                <div className="ml-4">
                <Portion className={idx === 0 ? 'first' : ''} portion={p} />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  }
}

Portion.propTypes = {
  portion: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};

export default Portion;
