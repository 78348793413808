import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import { getIntegrations } from '../../apiActions/integrationApiActions';
import IntegrationTable from '../../components/Tables/IntegrationTable';
import browserHistory from '../../history';

const Integrations = ({ doGetIntegrations, integrations }) => {
  useEffect(() => {
    doGetIntegrations();
  }, []);

  const handleRowClick = (rowData) => {
    if (rowData.enabled) {
      browserHistory.push({
        pathname: `/integrations/import/${rowData.id}`,
      });
    } else {
      browserHistory.push({
        pathname: `/integrations/contact-integration-provider/${rowData.id}`,
      });
    }
  };

  return (
    <ContentWrapper>
      <Row>
        <Col xs={12}>
          <PageTitle title="Integrations" />
        </Col>
      </Row>

      <div className="main-content-container">
        <Row className="mt-12">
          <Col xs={12}>
            {integrations && (
              <IntegrationTable
                data={integrations}
                onRowClick={(e, rowData) => handleRowClick(rowData)}
                title="Integrations"
              />
                )}
          </Col>
        </Row>
      </div>
    </ContentWrapper>
  );
};

Integrations.propTypes = {
  integrations: PropTypes.instanceOf(Array),
  doGetIntegrations: PropTypes.func,
};
const mapStateToProps = (state) => ({
  integrations: state.integrations.integrationResults,
});
const mapDispatchToProps = (dispatch) => ({
  doGetIntegrations: () => dispatch(getIntegrations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
