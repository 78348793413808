import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import { archiveCustomFeature } from '../actions/teamActions';

class DeleteCustomFeatureModal extends Component {
  render() {
    const { isOpen, toggle, doArchiveCustomFeature, customFeature } = this.props;
    let id;
    let type;
    if (customFeature !== undefined) {
      ({ id, content: { type } } = customFeature);
    }
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/archiveCustomFeature', title: 'Delete Custom Feature' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle
            title={`Delete ${type === 'profileMenuTeamTile' ? 'Link' : 'Content'}`}
            showTeam={false}
            icon="fa-1x ms-icon- icon-Browser_-Internet_-Web_-Network_-Close-1"
          />
        </ModalHeader>
        <ModalBody>
          <>
            <Row>
              <Col xs={12}>Are you sure you want to delete this {type === 'profileMenuTeamTile' ? 'link' : 'content'}?</Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={toggle}
                    className="mr-3 fixed-width-md"
                  >
                    Never Mind
                  </Button>
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => {
                      doArchiveCustomFeature(id);
                      toggle();
                    }}
                    className="fixed-width-md"
                  >
                    Confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteCustomFeatureModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  customFeature: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doArchiveCustomFeature: (id) => dispatch(archiveCustomFeature(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCustomFeatureModal);
