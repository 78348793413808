import { Avatar, useChannelStateContext } from 'stream-chat-react';
import React, { useMemo } from 'react';
import browserHistory from '../../history';

const ChannelInfo = ({ currentUser }) => {
  const { channel } = useChannelStateContext();
  const isGroupChat = useMemo(() => channel?.data?.member_count > 2, [channel]);
  const members = useMemo(() => Object.values(channel.state.members).filter(
    (user) => user.user_id !== currentUser.user_id
  ), [channel.id, currentUser]);

  const goToClient = (user_id) => {
      browserHistory.push({
      pathname: `/clients/${user_id}?chat=true`,
      state: { from: 'Messages' }
    });
  };

  const goToGroup = (group_id) => {
    browserHistory.push({
    pathname: `/dashboard/group/${group_id}`,
    state: { from: 'Messages' }
    });
  };

  const renderHeader = ({ id, onPress, title, subtitle, avatarName, image }) => {
    return (
      <div
        key={`member_header_${id}`}
        className="str-chat__header-livestream str-chat__channel-header"
        onClick={onPress}
        style={{ cursor: 'pointer' }}
      >
        {image ? <Avatar image={image} size={40} /> : <Avatar name={avatarName} size={40} />}
        <div className="str-chat__header-livestream-left str-chat__channel-header-end">
          <p className="str-chat__header-livestream-left--title str-chat__channel-header-title">
            {title}
          </p>
          {subtitle && (
          <p className="str-chat__header-livestream-left--subtitle str-chat__channel-header-subtitle" style={{ fontSize: '0.7rem' }}>
            {subtitle}
          </p>
          )}
        </div>
      </div>
    );
  };

  const renderGroup = () => {
    return renderHeader({
      id: channel.data.id,
      onPress: () => goToGroup(channel.data.id),
      title: `${channel.data?.name ?? 'Group'}`,
      subtitle: `${channel.data?.member_count} members`,
      avatarName: channel.data?.name ?? 'Group',
      image: channel.data?.image,
    });
  };

  const renderMembers = () => {
    return members.map(({ user, user_id }) =>
      renderHeader({
        id: user_id,
        onPress: () => goToClient(user_id),
        title: `${user.name}`,
        avatarName: `${user.name}`,
        image: user?.image,
      })
    );
  };

  return isGroupChat ? renderGroup() : renderMembers();
};

export default ChannelInfo;
