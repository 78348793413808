// Note: this form is self-contained, meaning all of the dispatch calls are defined
// within this instead of being passed in as props
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Row, Col, Button } from 'reactstrap';
import { formatDateToIso8601 } from '../utils/dateUtils';
import DeleteBodyFatModal from '../modals/DeleteBodyFatModal';
import { submissionError } from '../actions/formActions';
import { addBodyFat, updateBodyFat, deleteBodyFat } from '../actions/userActions';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';

const validate = (values) => {
  const errors = {};
  if (!values.date) {
    errors.date = 'Date is required';
  }
  if (!values.body_fat) {
    errors.body_fat = 'Body fat is required';
  }
  return errors;
};

class BodyFatsForm extends Component {
  constructor(props) {
    super(props);
    const {
      user: { body_fats },
    } = this.props;
    this.state = {
      body_fats,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      body_fats: props.user.body_fats,
    };
  }

  saveBodyFat = (body_fat) => {
    const { date } = body_fat;
    // editing an existing body_fat
    if (body_fat.id == undefined) {
      this.props.doUpdateBodyFat(this.props.user.user_id, {
        date: formatDateToIso8601(date),
        body_fat: body_fat.body_fat,
      });
    }
    // adding a new body_fat
    else {
      this.props.doAddBodyFat(this.props.user.user_id, {
        date: formatDateToIso8601(date),
        body_fat: body_fat.body_fat,
      });
    }
  };

  editBodyFat = (body_fat, status) => {
    const { body_fats } = this.state;
    const record = body_fats.find((w) => w.date === body_fat.date);
    if (record) {
      record.status = status; // mutate
      this.setState({
        body_fats,
      });
    }
  };

  addBodyFat = () => {
    const { body_fats } = this.state;
    body_fats.push({
      date: moment().toDate(),
      id: uuidv4(),
      body_fat: '',
      status: 'edit',
    });
    this.setState({
      body_fats,
    });
  };

  cancelEdit = (body_fat) => {
    // cancelling the edit of an existing body_fat
    if (body_fat.id == undefined) {
      const { body_fats } = this.state;
      const record = this.state.body_fats.find((w) => w.date === body_fat.date);
      if (record) {
        record.status = undefined; // mutate
        this.setState({ body_fats });
      }
    }
    // cancelling a newly-added body_fat
    else {
      this.setState({
        body_fats: this.state.body_fats.filter((w) => w.id == undefined),
      });
    }
  };

  toggleDeleteBodyFatModal = (date) => {
    const { deleteBodyFatModalOpen } = this.state;
    this.setState({
      deleteBodyFatModalOpen: !deleteBodyFatModalOpen,
      deleteBodyFatOnDate: date,
    });
  };

  render() {
    const { showSubmissionError, user } = this.props;
    const { user_id, timezone } = user;
    const { body_fats, deleteBodyFatModalOpen, bodyFatId } = this.state;
    return (
      <>
        {body_fats.length > 0 && (
          <Row>
            <Col xs={4} className="strong p-2">
              Date
            </Col>
            <Col xs={4} className="text-center strong border-left p-2">
              Body Fat %
            </Col>
            <Col xs={4} className="text-center strong border-left p-2">
              Actions
            </Col>
          </Row>
        )}
        {body_fats.map((w, idx) => (
          <Form
            key={`${idx}-${w.date}-${w.body_fat}`}
            onSubmit={(values) => {
              const errors = validate(values, body_fats);
              if (Object.keys(errors).length === 0) {
                this.saveBodyFat(values);
              } else {
                showSubmissionError(errors, false);
              }
            }}
            initialValues={{
              body_fat: w.body_fat,
              date: moment(w.date).toDate(),
              id: w.id,
            }}
            keepDirtyOnReinitialize={false}
            render={({ handleSubmit, submitting, form, values }) => (
              <form onSubmit={handleSubmit} className="weigh-ins-form">
                <Row className="border-top">
                  <Col xs={4} className="p-2 align-self-center">
                    {w.id == undefined ? (
                      `${moment(w.date).tz(timezone).format('MMMM D, YYYY')}`
                    ) : (
                      <Field
                        name="date"
                        component={renderDatepickerForField}
                        className="text-left"
                        {...{
                          itemProps: {},
                          inputProps: {
                            maxDate: moment().toDate(),
                          },
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    xs={4}
                    className="text-center border-left d-flex align-items-center justify-content-center"
                  >
                    {w.status !== 'edit' ? (
                      `${w.body_fat}`
                    ) : (
                      <div className="animated">
                        <Field
                          name="body_fat"
                          component={renderInputForField}
                          stackedlabel={false}
                          parse={(value) =>
                            value ? parseFloat(Number.parseFloat(value).toFixed(2)).toString() : ''
                          }
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="text-center border-left p-2">
                    {w.status !== 'edit' ? (
                      <div className="d-flex justify-content-around animated">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.editBodyFat(w, 'edit');
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleDeleteBodyFatModal(w.date);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-around animated">
                        <Button color="primary">Save</Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.cancelEdit(w);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </form>
            )}
          />
        ))}
        <Row className="border-top pt-3">
          <Col>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.addBodyFat();
              }}
              disabled={this.state.body_fats.filter((w) => w.id != undefined).length > 0}
            >
              Add Body Fat Measurement
            </Button>
          </Col>
        </Row>
        <DeleteBodyFatModal
          userId={user_id}
          dateToDelete={this.state.deleteBodyFatOnDate}
          isOpen={deleteBodyFatModalOpen}
          toggle={this.toggleDeleteBodyFatModal}
        />
      </>
    );
  }
}

BodyFatsForm.propTypes = {
  user: PropTypes.instanceOf(Object),
  doAddBodyFat: PropTypes.func,
  doUpdateBodyFat: PropTypes.func,
  showSubmissionError: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
  doAddBodyFat: (id, values) => dispatch(addBodyFat(id, values)),
  doUpdateBodyFat: (id, values) => dispatch(updateBodyFat(id, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyFatsForm);
