import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import AlcoholCalculator from '../../components/Utilities/AlcoholCalculator';

class Alcohol extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Alcohol Macro Calculator" />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <div className="main-content-container">
              <AlcoholCalculator />
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Alcohol.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Alcohol);
