// internal react-final-form
export const SUBMISSION_ERROR = 'SUBMISSION_ERROR';

export function submissionError(errors, scroll) {
  return {
    type: SUBMISSION_ERROR,
    errors,
    scroll,
  };
}
