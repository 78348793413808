import React, { Component } from 'react';
import { Badge, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';

/** Build a sub menu with items inside and attach collapse behavior */
class SidebarSubItem extends Component {
  render() {
    const {
      item: { path, name, icon, label, isChat },
      isActive,
      handler,
      children,
      isOpen,
      unreadChatCount,
    } = this.props;
    const { color = 'danger', value } = label || {};

    return (
      <li className={isActive ? 'active' : ''}>
        <div className="nav-item" onClick={handler}>
          {(label || (isChat && unreadChatCount !== undefined && unreadChatCount !== null && unreadChatCount !== '0' && unreadChatCount !== 0)) && (
            <Badge tag="div" className="float-right" color={color}>
              {value || unreadChatCount}
            </Badge>
          )}
          {icon && <em className={icon} />}
          <span>{name}</span>
        </div>
        <Collapse isOpen={isOpen}>
          <ul id={path} className="sidebar-nav sidebar-subnav">
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
}

SidebarSubItem.propTypes = {
  item: PropTypes.instanceOf(Object),
  isActive: PropTypes.bool,
  handler: PropTypes.func,
  children: PropTypes.instanceOf(Array),
  isOpen: PropTypes.bool,
  // unreadChatCount: PropTypes.string,
};

export default SidebarSubItem;
