import { call, put, take, race, all, delay } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import {
  SUBMIT_RECIPE_SEARCH_REQUEST,
  SHARE_RECIPE,
} from '../actions/foodActions';
import {
  recipeSearch,
  shareRecipe,
  RECIPE_SEARCH_SUCCESS,
  RECIPE_SEARCH_FAILURE,
  SHARE_RECIPE_SUCCESS,
  SHARE_RECIPE_FAILURE,
} from '../apiActions/foodApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';

export default function* userSagas() {
  yield all([
    recipeSearchSaga(),
    shareRecipeSaga(),
  ]);
}

function* recipeSearchSaga() {
  while (true) {
    const { values } = yield take(SUBMIT_RECIPE_SEARCH_REQUEST);
    yield put(recipeSearch(values));
    const { success, failure } = yield race({
      success: take(RECIPE_SEARCH_SUCCESS),
      failure: take(RECIPE_SEARCH_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* shareRecipeSaga() {
  while (true) {
    const { values, func } = yield take(SHARE_RECIPE);
    const { recipeId, message = '' } = values;
    const user_ids = [];
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      const val = Object.keys(values)[i];
      if (values[val] === true) {
        user_ids.push(val.replace('user_', ''));
      }
    }
    const payload = {
      recipe_id: recipeId,
      message,
      target_user_ids: user_ids,
    };
    yield put(shareRecipe(payload));
    const { success, failure } = yield race({
      success: take(SHARE_RECIPE_SUCCESS),
      failure: take(SHARE_RECIPE_FAILURE),
    });
    func();
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Success!', 'Recipe Shared Successfully!', 'success'));
    }
  }
}
