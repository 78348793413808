import { call, put, all, take, select, race } from 'redux-saga/effects';
import { StreamChat } from 'stream-chat';
import {
  initializeUsername,
  INIT_USERNAME_REQUEST,
  initializeTeamProfile,
} from '../actions/initActions';
import { getTeam, getTeamMembership } from '../actions/teamActions';
import {
  getTeamProfile,
  GET_TEAM_SUCCESS,
  TEAM_PROFILE_SUCCESS,
  GET_TEAM_MEMBERSHIP_SUCCESS,
} from '../apiActions/teamApiActions';
import {
  AUTH_USER_ID_RETRIEVED,
  AUTH_TOKEN_STORED,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGOUT,
  SWITCH_TEAMS,
  USER_IS_LOGGED_IN,
  beginAuthentication,
  getStreamToken,
  streamUnreadCount,
} from '../actions/authActions';
import { GET_STREAM_TOKEN_SUCCESS, GET_STREAM_TOKEN_FAILURE } from '../apiActions/authApiActions';
import { AUTH_USER_PROFILE_SUCCESS } from '../apiActions/userApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { setDeviceInfo } from '../actions/deviceActions';
import { getDeviceInfo } from '../utils/global';
import {
  INIT_EMAIL_KEY,
  getLocalStoreValue,
  setLocalStoreValue,
  USER_ID_KEY,
} from '../utils/localStorage';
import { getInitialRouteForUser } from '../utils/authUtils';
import ENV_VARS from '../utils/envVars';
import browserHistory from '../history';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);

export default function* initSagas() {
  yield all([
    getInitialUsernameSaga(),
    getUserDataSaga(),
    moveToMainSaga(),
    setDeviceSaga(),
    logoutSaga(),
  ]);
}

async function connectStream(user_id, token) {
  try {
    const conn = await chatClient.connectUser(
      {
        id: user_id,
      },
      token
    );
    return conn.me.unread_count;
  } catch (e) {
    console.warn('error in async call to stream', e);
  }
}

function* setDeviceSaga() {
  const deviceInfo = {
    ...getDeviceInfo(),
    appName: 'Macrostax Team',
  };
  yield put(setDeviceInfo(deviceInfo));
}

function* getInitialUsernameSaga() {
  // this gets called immediately on app load. See if we have an email
  // in localstorage and if so, this will kick off the authenticationSaga
  // to log the user in automatically via their jwt
  const username = yield call(getLocalStoreValue, INIT_EMAIL_KEY);
  if (username) {
    yield put(initializeUsername(username));
    yield put(beginAuthentication(username));
  }
}

function* getUserDataSaga() {
  while (true) {
    const { user_id, team_id } = yield take(AUTH_USER_ID_RETRIEVED);
    setLocalStoreValue(USER_ID_KEY, user_id);
    // TODO: if we want to pre-load any additional data this is where we would do it
    yield put(getTeam(team_id));
    yield put(getTeamProfile());
    if (team_id !== ENV_VARS.MACROSTAX_TEAM_ID) {
      yield put(getTeamMembership(team_id));
      yield put(getStreamToken(user_id));
      const { success, failure } = yield race({
        success: take(GET_STREAM_TOKEN_SUCCESS),
        failure: take(GET_STREAM_TOKEN_FAILURE),
      });
      if (success) {
        const unreadCount = yield call(connectStream, user_id, success.data.tokens[user_id]);
        yield put(streamUnreadCount(unreadCount.toString()));
      }
    }
  }
}

function* moveToMainSaga() {
  // this waits for all necessary 'loading' actions before
  // moving off interstitial screen
  while (true) {
    yield all([
      // take(AUTH_LOGIN_REQUEST),
      take(AUTH_TOKEN_STORED),
      take(USER_IS_LOGGED_IN),
      take(AUTH_USER_PROFILE_SUCCESS),
      take(GET_TEAM_SUCCESS),
      take(TEAM_PROFILE_SUCCESS),
      // take(GET_TEAM_MEMBERSHIP_SUCCESS),
    ]);
    const deviceInfo = {
      ...getDeviceInfo(),
      appName: 'Macrostax Team Dashboard',
    };
    yield put(setDeviceInfo(deviceInfo));

    const state = yield select();

    if (state.auth && state.auth.passwordUpdateRequired) {
      browserHistory.push('/resetpassword');
      yield put(spinnerDecrement());
    } else {
      // const nextRoute = state && state.context && state.context.initRoute ? state.context.initRoute : '/users';
      try {
        const nextRoute =
          state && state.context && state.context.initRoute
            ? state.context.initRoute
            : getInitialRouteForUser(state.auth.user, state.team.profile.clients);

        browserHistory.push(nextRoute);
      } catch (e) {
        browserHistory.push('/');
      }
    }
  }
}

function* logoutSaga() {
  while (true) {
    yield take(AUTH_LOGOUT);
    if (chatClient) {
      chatClient.disconnectUser();
    }
  }
}
