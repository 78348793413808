import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CancelPlanModal from '../../modals/CancelPlanModal';

class CancelPlanButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelPlanModalOpen: false,
    };
  }

  toggleCancelPlanModal = () => {
    const { cancelPlanModalOpen } = this.state;
    this.setState({
      cancelPlanModalOpen: !cancelPlanModalOpen,
    });
  };

  render() {
    const { team, type = 'icon', className } = this.props;
    const { cancelPlanModalOpen } = this.state;
    return (
      <>
        {type === 'icon' ? (
          <button className={`btn-text ${className}`} onClick={this.toggleCancelPlanModal}>
            <em className="fa-lg mr-2 far ms-icon- icon-Browser_-Internet_-Web_-Network_-Close-1" />
            Cancel Plan
          </button>
        ) : (
          <span onClick={this.toggleCancelPlanModal} className={`span-btn ${className}`}>
            Cancel Plan
          </span>
        )}
        <CancelPlanModal
          team={team}
          isOpen={cancelPlanModalOpen}
          toggle={this.toggleCancelPlanModal}
        />
      </>
    );
  }
}

CancelPlanButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  team: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CancelPlanButton);
