import React from 'react';
import { connect } from 'react-redux';

class SendEmailButton extends React.Component {
  render() {
    return (
      <button className="btn-text">
        <em className="fa-lg mr-2 far fa-envelope" />
        Send Email
      </button>
    );
  }
}

SendEmailButton.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailButton);
