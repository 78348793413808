import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import numeral from 'numeral';
import {
  UncontrolledPopover,
  PopoverBody,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CardIcon from '../Cards/CardIcon';
import { capitalizeFirst } from '../../utils/stringUtils';

// TODO: there's probably a more react-friendly way of doing this
let legend = '<div class="legend"><h4>Green Thumbs Calendar Key</h4>';
legend += '<div class="row"><div class="col">';
legend +=
  '<div class="row mb-1"><div class="col flex flex-row align-items-center"><img src="/img/green-thumb-dark.svg" alt="Green Thumb" class="green-thumb-img mr" /><div>Green Thumb</div></div></div>';
// legend +=
//   '<div class="row mb-1"><div class="col flex flex-row align-items-center"><div class="thumb overthumb mr-2"></div><div>Close (within Xg)</div></div></div>';
legend +=
  '<div class="row mb-1"><div class="col flex flex-row align-items-center"><div class="thumb graythumb mr-2"></div><div>Logged</div></div></div>';
// legend +=
//   '<div class="row mb-1"><div class="col flex flex-row align-items-center"><div class="thumb graythumb mr-2"></div><div>Nothing Recorded</div></div></div>';
legend += '</div>';
legend += '<div class="col"><img src="/img/dots-legend.svg" alt="Legend" /></div>';
legend += '</div>';
legend += '</div>';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: '',
      startDate: moment(),
      selectedDate: moment(),
    };
  }

  updateDates = (prevOrNext) => {
    const { user_id, getDailyMacros } = this.props;
    let { startDate, selectedDate = moment() } = this.state;
    let endDate;

    if (prevOrNext === 'prev') {
      selectedDate = selectedDate.add(-1, 'M');
    } else {
      selectedDate = selectedDate.add(1, 'M');
    }
    startDate = selectedDate.clone().startOf('month').add(-3, 'M');
    endDate = selectedDate.clone().endOf('month').add(2, 'M');
    this.setState(
      {
        startDate,
        endDate,
        selectedDate,
      },
      () => {
        getDailyMacros(user_id, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
      }
    );
  };

  getCircleClass = (status) => {
    switch (status) {
      case true:
        return 'greenthumb';
      case false:
        return 'graythumb';
      // return 'underthumb';
      default:
        return 'graythumb';
    }
  };

  getDayPopover = (macros, day) => {
    const {
      calories_target,
      calories_total,
      carbs_target,
      carbs_total,
      fat_target,
      fat_total,
      protein_target,
      protein_total,
      carbs_green_thumb,
      fat_green_thumb,
      protein_green_thumb,
      day_type_label,
      custom_macros = false,
    } = macros;
    const { getPlan, user_id, switchTab } = this.props;
    return (
      <>
        <div className="mb-1">{day.format('dddd, MMMM D, YYYY')}</div>
        {/* <div className="card-caption">{`${dayType} Day`}</div> */}
        <div className="text-center mb-1">{capitalizeFirst(day_type_label)} Day
          {custom_macros && (<>{' '} on Custom Macros</>)}
        </div>
        <div className="card-caption d-flex flex-row">
          <div
            className={`${
              carbs_green_thumb ? 'green-thumb-label' : ''
            } mr-1 width-50-percent text-right`}
          >
            Carbs:
          </div>
          <div className="width-50-percent text-left">
            {carbs_total} / {carbs_target}
            {carbs_green_thumb && (
              <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
            )}
          </div>
        </div>
        <div className="card-caption d-flex flex-row">
          <div
            className={`${
              protein_green_thumb ? 'green-thumb-label' : ''
            } mr-1 width-50-percent text-right`}
          >
            Protein:
          </div>
          <div className="width-50-percent text-left">
            {protein_total} / {protein_target}
            {protein_green_thumb && (
              <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
            )}
          </div>
        </div>
        <div className="card-caption d-flex flex-row">
          <div
            className={`${
              fat_green_thumb ? 'green-thumb-label' : ''
            } mr-1 width-50-percent text-right`}
          >
            Fat:
          </div>
          <div className="width-50-percent text-left">
            {fat_total} / {fat_target}
            {fat_green_thumb && (
              <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
            )}
          </div>
        </div>
        <div className="card-caption d-flex flex-row">
          <div className="width-50-percent text-right mr-1">Calories:</div>
          <div className="width-50-percent text-left">
            {calories_total} / {calories_target}
          </div>
        </div>
        <div className="card-caption d-flex flex-row justify-content-center mt-1">
          <div className="text-center">
            <span className="span-link" onClick={() => getPlan(user_id, day.format('YYYY-MM-DD'), switchTab)}>View Planner</span>
          </div>
        </div>
      </>
    );
  };

  renderDayFunc = (day) => {
    const { dailyMacros } = this.props;
    const id = `day_${day.format('YYYYMMDD')}`;
    let hasMacros = false;
    let macros;

    if (dailyMacros && dailyMacros.length) {
      macros = dailyMacros.find((m) => m.macros_date === day.format('YYYY-MM-DD'));
      if (macros) {
        hasMacros = true;
      }
    }

    // make sure they actually logged something
    if (hasMacros && macros.calories_total === 0) {
      hasMacros = false;
    }

    if (!hasMacros) {
      return (
        <>
          <div
            id={id}
            className={`green-thumbs-cell ${day.isSameOrBefore(moment(), 'd') ? 'not-logged' : ''}`}
          >
            <div className="thumbs-container d-flex justify-content-center mb-1">
              <div className="thumb" />
              <div className="thumb" />
              <div className="thumb" />
            </div>
            <div>{day.format('DD')}</div>
          </div>
        </>
      );
    }

    const { carbs_green_thumb, fat_green_thumb, protein_green_thumb } = macros;
    return (
      <>
        <div
          id={id}
          className={`green-thumbs-cell has-macros ${
            carbs_green_thumb && protein_green_thumb && fat_green_thumb ? 'all-green-thumbs' : ''
          }`}
          onClick={(e) => {
            e.currentTarget.focus();
            e.preventDefault();
          }}
        >
          <div className="thumbs-container d-flex justify-content-center mb-1">
            {carbs_green_thumb ? (
              <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="mr green-thumb" />
            ) : (
              <div className={`thumb ${this.getCircleClass(carbs_green_thumb)}`} />
            )}
            {protein_green_thumb ? (
              <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="mr green-thumb" />
            ) : (
              <div className={`thumb ${this.getCircleClass(protein_green_thumb)}`} />
            )}
            {fat_green_thumb ? (
              <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
            ) : (
              <div className={`thumb ${this.getCircleClass(fat_green_thumb)}`} />
            )}
          </div>
          <div>{day.format('DD')}</div>
        </div>
        <UncontrolledPopover trigger="legacy" placement="top" target={id}>
          <PopoverBody>{this.getDayPopover(macros, day)}</PopoverBody>
        </UncontrolledPopover>
      </>
    );
  };

  renderMonthFunc = (day) => {
    const { dailyMacros } = this.props;
    const now = moment();
    let pct = 0;
    let possibleThumbs = 0;
    let greenThumbs = 0;
    const firstOfMonth = day.clone().startOf('month');
    let lastOfMonth = day.clone().endOf('month');
    if (lastOfMonth.isAfter(now)) {
      lastOfMonth = now;
    }

    const days = Math.ceil(lastOfMonth.diff(firstOfMonth, 'days', true));
    possibleThumbs = days * 3;
    for (let i = 0; i < days; i += 1) {
      const day = firstOfMonth.clone().add(i, 'd');
      let hasMacros = false;
      let macros;

      if (dailyMacros && dailyMacros.length) {
        macros = dailyMacros.find((m) => m.macros_date === day.format('YYYY-MM-DD'));
        if (macros) {
          hasMacros = true;
        }
      }

      // make sure they actually logged something
      if (hasMacros && macros.calories_total === 0) {
        hasMacros = false;
      }

      if (hasMacros) {
        const { carbs_green_thumb, protein_green_thumb, fat_green_thumb } = macros;
        if (carbs_green_thumb) {
          greenThumbs += 1;
        }
        if (protein_green_thumb) {
          greenThumbs += 1;
        }
        if (fat_green_thumb) {
          greenThumbs += 1;
        }
      }
    }

    if (possibleThumbs > 0) {
      pct = greenThumbs / possibleThumbs;
    } else {
      possibleThumbs = 0;
    }

    if (pct > 0) {
      // pct = numeral(pct).format('0%');
      pct = numeral(pct).format('0.0%');
      if (pct.indexOf('.0') > -1) {
        pct = pct.replace('.0', '');
      }
    }

    return (
      <>
        <div style={{ marginTop: '-6px' }}>{day.format('MMMM, YYYY')}</div>
        {possibleThumbs > 0 && (
          <div className="caption" style={{ marginTop: '-4px', fontWeight: 'normal' }}>
            Green Thumbs Hit: {greenThumbs}/{possibleThumbs}
            {pct !== 0 && <> ({pct})</>}
          </div>
        )}
      </>
    );
  };

  render() {
    const { daySize = 40, render = true } = this.props;
    const { startDate } = this.state;

    return (
      <>
        <Card className="card-default card-container mb-3 calendar-card flex-grow-1 large-header-card">
          <CardHeader className="d-flex justify-space-between align-items-center header-normal-text">
            Green Thumbs Calendar
            <CardIcon customText={legend} />
          </CardHeader>
          <CardBody>
            {render && (
              <DayPickerRangeController
                onPrevMonthClick={() => this.updateDates('prev')}
                onNextMonthClick={() => this.updateDates('next')}
                onFocusChange={(focusedInput) => {
                  this.setState({ focusedInput });
                }}
                initialVisibleMonth={() => startDate.add(2, 'M')}
                renderDayContents={this.renderDayFunc}
                hideKeyboardShortcutsPanel
                daySize={daySize - 1}
                renderMonthText={this.renderMonthFunc}
              />
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

Calendar.propTypes = {
  daySize: PropTypes.number,
  timestamp: PropTypes.number,
  render: PropTypes.bool,
  dailyMacros: PropTypes.instanceOf(Array),
  user_id: PropTypes.string,
  getDailyMacros: PropTypes.func,
  getPlan: PropTypes.func,
  switchTab: PropTypes.func,
};

export default Calendar;
