import { MEMBERSHIP_OPTIONS_SUCCESS } from '../apiActions/signupApiActions';

const initialBillingState = {};

export default (state = initialBillingState, action) => {
  switch (action.type) {
    case MEMBERSHIP_OPTIONS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        membershipOptions: data,
      };
    }
    default:
      return state;
  }
};
