import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import ReactGA from 'react-ga4';
import PageTitle from '../components/Common/PageTitle';

class SampleClientProfileModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/addingClients', title: 'Adding Clients' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} scrollable={true}>
        <ModalHeader toggle={toggle}>
          <PageTitle title="Adding Clients" showTeam={false} />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={7}>
              {/* <div className="air-mail-border">
                <div className="air-mail-container"> */}
                  <a href="/img/invite-email.pdf" target="_blank"><img src="/img/invite-email.png" alt="Email Invitation" /></a>
                {/* </div>
              </div> */}
            </Col>
            <Col>
              <p>Let's get your clients on board!</p>
              <ul className="breathing-room">
                <li>Step 1: upload your client list to your account</li>
                <li>Step 2: send your clients invites to join your Team</li>
              </ul>
              <p>
                You can choose to upload and invite your clients at the same time, or get your
                client list set up first and send invites later.
              </p>

              <p>
                When you invite a client they will receive an email from Macrostax notifying them
                that they have been invited to join your Team. We'll also give them information on
                what Macrostax is and how to sign up for the Macrostax app.
              </p>

              <p>
                Check out the sample invite email to get a feel for the information your client will
                receive. Still have questions? Reach out to{' '}
                <span className="span-link intercom" id="Intercom" onClick={toggle}>
                  StaxChat
                </span>
                !
              </p>

              <p className="mt-4">
                <Button color="primary" onClick={() => window.open('/img/invite-email.pdf')}>View Sample Email</Button>
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

SampleClientProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default SampleClientProfileModal;
