import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import chartTrendline from 'chartjs-plugin-trendline';
// import PdfConverter from 'jspdf';
import PdfConverter, { jsPDF } from 'jspdf';
import DomToImage from 'dom-to-image';

class LineChart extends Component {
  convertToPdf = (chart) => {
    const { id, title, caption } = chart;
    const canvas = document.getElementById(id);
    const imgWidth = 180;
    const pdf = new PdfConverter('p', 'mm', [215, 279]);
    DomToImage.toPng(canvas).then((dataUrl) => {
      const imgHeight = (canvas.clientHeight * imgWidth) / canvas.clientWidth;
      pdf.text(title, 10, 10);
      pdf.addImage(dataUrl, 'PNG', 10, 20, imgWidth, imgHeight);
      const captionStart = 120;
      pdf.setFontSize(9);
      const captions = [];
      if (caption && caption.length) {
        for (let i = 0; i < caption.length; i += 1) {
          const cap = caption[i];
          const { label, values } = cap;
          let txt = cap.label;
          if (values && values.length) {
            txt += ':';
            for (let v = 0; v < values.length; v += 1) {
              txt += ` ${values[v]}`;
            }
          }
          captions.push(txt);
          // pdf.text(txt, 10, captionStart);
        }
      }
      pdf.text(captions, 10, captionStart, { lineHeightFactor: 1.5 });
      pdf.save(`${title.replaceAll(' ', '_').toLowerCase()}.pdf`);
    });
  };
  render() {
    const { chart = {}, render = true, showPdf = false } = this.props;
    return (
      <>
        {render && (
          <>
            <Line
              id={chart.id}
              data={chart.data}
              options={chart.options}
              plugins={[chartTrendline]}
            />
            {showPdf && (
              <>
                <br />
                <span className="span-link" onClick={() => this.convertToPdf(chart)}>
                  Save as PDF
                </span>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

LineChart.propTypes = {
  chart: PropTypes.instanceOf(Object),
  render: PropTypes.bool,
};

export default LineChart;
