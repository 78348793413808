import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** Component to display headings on sidebar */
class SidebarItemHeader extends Component {
  render() {
    const {
      item: { heading },
    } = this.props;

    return (
      <li className="nav-heading">
        <span>{heading}</span>
      </li>
    );
  }
}

SidebarItemHeader.propTypes = {
  item: PropTypes.instanceOf(Object),
};

export default SidebarItemHeader;
