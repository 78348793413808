import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, ModalFooter } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import UserAvatar from '../components/Common/UserAvatar';
import { removeMemberships, removeAdmins } from '../actions/teamActions';

class RemoveMembershipModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      submitted: false,
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  updateState = () => {
    this.setState({
      submitted: true,
    });
  };

  resetState = () => {
    const { initialState } = this.state;
    this.setState({
      ...initialState,
      initialState,
    });
  };

  render() {
    const {
      isOpen,
      toggle,
      doRemoveMemberships,
      doRemoveAdmins,
      users,
      type = 'membership',
      isPending = false,
      team,
      isInternalAdmin = false,
      toggleTab,
      beforeToggle,
    } = this.props;
    const { submitted } = this.state;
    if (isOpen) {
      const modalName = type === 'membership' ? 'removeMembership' : 'removeAdminUser';
      ReactGA.send({ hitType: 'pageview', page: `/modal/${modalName}`, title: 'Remove User' });
    }
    const title = type === 'adminUser' ? `Admin${users.length !== 1 ? 's' : ''}` : `Membership${users.length !== 1 ? 's' : ''}`;
    const icon = 'fa-lg mr-3 far fa fa-user-minus';
    const teamName = team && team.settings ? team.settings.name : team.name;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium" onClosed={this.resetState}>
        <ModalHeader toggle={toggle}>
          <PageTitle title={`Remove ${title}`} showTeam={false} icon={`fa-1x far ${icon}`} />
          <Col xs={12}>
            {type === 'adminUser' ? (
              <p>
                If you remove {users.length === 1 ? 'this ' : 'these '}
                client{users.length !== 1 ? 's' : ''} as admin{users.length !== 1 ? 's' : ''}, they will no longer be able to add clients,
                view, or edit client profiles.
              </p>
            ) : (
              <div>
                <p>
                  Are you sure you want to remove {users.length === 1 ? ' this client ' : ' these clients '} from your Team?
                  They will no longer be able to sign up for or use the Macrostax app through {teamName}.
                </p>
              </div>
            )}
          </Col>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              {users.map((u) => (
                <div className="user-row mt-1" key={u.id ? u.id : u.user_id}>
                  <UserAvatar user={u} showName />
                </div>
              ))}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="mt-4">
            <Col xs={12}>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  size="lg"
                  onClick={toggle}
                  className="mr-3 fixed-width-md"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  onClick={() => {
                    if (type === 'adminUser') {
                      doRemoveAdmins(users, toggle, team.team_id, beforeToggle);
                    } else {
                      doRemoveMemberships(users, toggle, isInternalAdmin, team.team_id, toggleTab);
                    }
                    this.updateState();
                  }}
                  className="fixed-width-md"
                >
                  Confirm
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

RemoveMembershipModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  doRemoveMemberships: PropTypes.func,
  doRemoveAdmins: PropTypes.func,
  type: PropTypes.string,
  isPending: PropTypes.bool,
  team: PropTypes.instanceOf(Object),
  isInternalAdmin: PropTypes.bool,
  toggleTab: PropTypes.func,
  type: PropTypes.string,
  beforeToggle: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doRemoveMemberships: (users, toggle, isInternalAdmin, team_id, toggleTab) => {
    dispatch(removeMemberships(users, isInternalAdmin, team_id, toggleTab));
    toggle();
  },
  doRemoveAdmins: (users, toggle, team_id, beforeToggle) => {
    dispatch(removeAdmins(users, team_id));
    if (beforeToggle) {
      beforeToggle();
    }
    toggle();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveMembershipModal);
