import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Row, Col, Button } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderCheckboxForField from './helpers/renderCheckboxForField';

const validate = (values, actions) => {
  const errors = {};
  if (!values.points) {
    errors.points = 'Number of points is required';
  }
  if (!values.action_date) {
    errors.date = 'Date is required';
  }
  if (!values.desc) {
    errors.date = 'Description is required';
  }

  return errors;
};

class BonusPointsForm extends Component {
  render() {
    const { initialValues = {}, onSubmit, showSubmissionError, minDate, maxDate } = this.props;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col md={6}>
                  <Field
                    name="action_date"
                    component={renderDatepickerForField}
                    label="Date*"
                    stackedlabel
                    {...{
                      itemProps: { row: false },
                      inputProps: {
                        showMonthDropdown: true,
                        showYearDropdown: true,
                        dropdownMode: 'select',
                        minDate,
                        maxDate,
                      },
                    }}
                  />
                </Col>
                <Col>
                  <Field
                    name="points"
                    label="Number of points*"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'number',
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="desc"
                    label="Description*"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs={3}>
                  <Button color="primary" size="lg" type="submit" block>
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

BonusPointsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default BonusPointsForm;
