import moment from 'moment-timezone';
import { INIT_ROUTE_REQUEST } from '../actions/initActions';
import { SUBMIT_RECIPE_SEARCH_REQUEST, CLEAR_RECIPE_SEARCH_FORM } from '../actions/foodActions';
import { AUTH_LOGOUT, AUTH_LOGIN_FAILURE, USER_IS_LOGGED_IN, SWITCH_TEAMS } from '../actions/authActions';
import { SUBMIT_USER_SEARCH_REQUEST, CLEAR_USER_SEARCH_FORM } from '../actions/userActions';
import {
  SUBMIT_TEAM_SEARCH_REQUEST,
  CLEAR_TEAM_SEARCH_FORM,
  GET_ALL_CLIENTS,
  GET_ADMINS,
  GET_TEAM_PROFILE,
  CLEAR_PENDING_CLIENTS,
  GET_DASHBOARD,
  GET_ENGAGEMENT_SUMMARY,
  SUBMIT_CLIENTS_SEARCH_REQUEST,
  CLEAR_CLIENTS_SEARCH_FORM,
} from '../actions/teamActions';
import {
  GET_TEAM_PROFILE_SUCCESS,
  GET_TEAM_MEMBERSHIP_SUCCESS,
  UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS,
  UPDATE_MEMBERSHIP_SUCCESS,
  CANCEL_PLAN_SUCCESS,
  REACTIVATE_MEMBERSHIP_SUCCESS,
  GET_DASHBOARD_SUCCESS,
  GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS,
  GET_ENGAGEMENT_SUMMARY_SUCCESS,
  GET_ALL_TEAM_BRANDING_SUCCESS,
} from '../apiActions/teamApiActions';
import {
  GET_ALL_CLIENTS_SUCCESS,
  ADMIN_SEARCH_SUCCESS,
  PENDING_CLIENT_SEARCH_SUCCESS,
} from '../apiActions/userApiActions';
import { GET_ALL_CHALLENGES } from '../actions/challengeActions';
import {
  GET_ALL_CHALLENGES_SUCCESS,
  GET_CHALLENGE_SUCCESS,
  GET_LEADERBOARD_SUCCESS,
  GET_ACTIONS_SUCCESS,
} from '../apiActions/challengeApiActions';
import { SUBMIT_INTEGRATION_SETUP_REQUEST } from '../actions/integrationActions';
import { CLEAR_CREATE_GROUP_FORM, SUBMIT_CREATE_GROUP_REQUEST } from '../actions/groupActions';

const initialContextState = {
  appState: 'initializing',
};

export default (state = initialContextState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_FAILURE: {
      return {
        ...initialContextState,
        // initRoute: state.initRoute,
        appState: 'loggedOut',
      };
    }
    case USER_IS_LOGGED_IN: {
      return {
        ...state,
        appState: 'loggedIn',
      };
    }
    case INIT_ROUTE_REQUEST: {
      return {
        ...state,
        initRoute: state.initRoute || action.route,
      };
    }
    case SUBMIT_USER_SEARCH_REQUEST: {
      return {
        ...state,
        userSearchForm: action.values,
      };
    }
    case CLEAR_USER_SEARCH_FORM: {
      return {
        ...state,
        userSearchForm: null,
      };
    }
    case SUBMIT_TEAM_SEARCH_REQUEST: {
      return {
        ...state,
        teamSearchForm: action.values,
      };
    }
    case CLEAR_TEAM_SEARCH_FORM: {
      return {
        ...state,
        teamSearchForm: null,
      };
    }
    case GET_TEAM_PROFILE_SUCCESS: {
      return {
        ...state,
        team: action.data,
      };
    }
    case GET_ALL_CLIENTS: {
      return {
        ...state,
        team: {
          ...state.team,
          clients: undefined,
        },
      };
    }
    case GET_ALL_CHALLENGES: {
      return {
        ...state,
        team: {
          ...state.team,
          challenges: undefined,
        },
      };
    }
    case GET_ALL_CHALLENGES_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          challenges: action.data,
        },
      };
    }
    case GET_CHALLENGE_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          challenges: {
            ...state.team.challenges,
            challenge: action.data,
          },
        },
      };
    }
    case GET_LEADERBOARD_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          challenges: {
            ...state.team.challenges,
            leaderboard: action.data,
          },
        },
      };
    }
    case GET_ACTIONS_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          challenges: {
            ...state.team.challenges,
            actions: action.data,
          },
        },
      };
    }
    case GET_ADMINS: {
      return {
        ...state,
        team: {
          ...state.team,
          admins: undefined,
        },
      };
    }
    case GET_DASHBOARD: {
      return {
        ...state,
        team: {
          ...state.team,
          dashboard: undefined,
        },
      };
    }
    case GET_ENGAGEMENT_SUMMARY: {
      let clearData = true;
      if (action.clearData !== undefined) {
        clearData = action.clearData;
      }
      return {
        ...state,
        team: {
          ...state.team,
          engagementSummary: clearData ? undefined : state.team.engagementSummary,
        },
      };
    }
    case GET_ALL_CLIENTS_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          clients: action.data,
        },
      };
    }
    case ADMIN_SEARCH_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          admins: action.data,
        },
      };
    }
    case GET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          dashboard: action.data,
        },
      };
    }
    case GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          ...action.data,
        },
      };
    }
    case GET_ENGAGEMENT_SUMMARY_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          engagementSummary: action.data,
        },
      };
    }
    case GET_TEAM_PROFILE: {
      return {
        ...state,
        team: {
          team_id: action.team_id,
        },
      };
    }
    case PENDING_CLIENT_SEARCH_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          pendingClients: action.data,
        },
      };
    }
    case CLEAR_PENDING_CLIENTS: {
      return {
        ...state,
        team: {
          ...state.team,
          pendingClients: undefined,
        },
      };
    }
    case GET_TEAM_MEMBERSHIP_SUCCESS:
    case UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS:
    case UPDATE_MEMBERSHIP_SUCCESS:
    case CANCEL_PLAN_SUCCESS:
    case REACTIVATE_MEMBERSHIP_SUCCESS: {
      return {
        ...state,
        team: {
          ...state.team,
          membership: action.data,
        },
      };
    }
    case SUBMIT_RECIPE_SEARCH_REQUEST: {
      return {
        ...state,
        recipeSearchForm: action.values,
      };
    }
    case CLEAR_RECIPE_SEARCH_FORM: {
      return {
        ...state,
        recipeSearchForm: undefined,
      };
    }
    case GET_ALL_TEAM_BRANDING_SUCCESS: {
      return {
        ...state,
        allTeamBranding: action.data,
      };
    }
    case AUTH_LOGOUT:
    case SWITCH_TEAMS: {
      return {
        ...initialContextState,
        // initRoute: state.initRoute,
        appState: 'loggedOut',
      };
    }
    case SUBMIT_CLIENTS_SEARCH_REQUEST: {
        return {
            ...state,
            clientsSearchForm: action.values,
        };
    }
    case CLEAR_CLIENTS_SEARCH_FORM: {
        return {
            ...state,
            clientsSearchForm: null,
        };
    }
    case SUBMIT_INTEGRATION_SETUP_REQUEST: {
      return {
        ...state,
        setupIntegrationForm: action.values,
      };
    }
    case SUBMIT_CREATE_GROUP_REQUEST: {
      return {
        ...state,
        createGroupForm: action.values,
      };
    }
    case CLEAR_CREATE_GROUP_FORM: {
        return {
            ...state,
            createGroupForm: null,
        };
    }
    default: {
      return state;
    }
  }
};
