import React from 'react';
import { Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';

class LaunchGuide extends React.Component {
  render() {
    const { team } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Launch Guide" />
          </Col>
        </Row>
        <div className="main-content-container">
          <p>
            Our Launch Guide has everything you need to kick off Macrostax at your gym and start
            getting nutrition clients right away!
          </p>

          <p>
            Simply follow our day-by-day announcement plan so your members learn all about Macrostax
            and how to sign up. The best part? We’ve already made all the materials you need! Click
            the link below to download your:
          </p>

          <ul>
            <li>2-week announcement plan</li>
            <li>Emails</li>
            <li>Gym announcements</li>
            <li>Flyers</li>
            <li>Social media posts</li>
            <li>Blog</li>
          </ul>
          <p>
            <a
              className="mt-3 fixed-width-lg btn btn-primary btn-lg"
              href="https://drive.google.com/drive/folders/1lpeduQJ8wP54WrfJjEKooPzQ4hCL-C-M"
              target="_blank"
            >
              Go To Launch Guide
            </a>
          </p>
        </div>
      </ContentWrapper>
    );
  }
}

LaunchGuide.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LaunchGuide);
