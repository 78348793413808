import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col, Row } from 'reactstrap';
import imageCompression from 'browser-image-compression';
import renderDropdownWithSearchField from './helpers/renderDropdownWithSearchField';
import GroupNameField from './fields/GroupName';
import DropzoneImage from '../components/Common/DropzoneImage';

const validate = (values) => {
  let errors = {};
  if (!values.name) {
    errors = { name: 'Please enter a group name' };
  }
  if (values.members.length === 0) {
    errors = { ...errors, members: 'Please select a member' };
  }
  return errors;
};

const GroupForm = ({
    initialValues,
    onSubmit,
    showSubmissionError,
    onReset,
    onRemoveImage,
    data,
    submitText = '',
  }) => {
  const { admins, members } = data;
  const [imagePhoto, setImagePhoto] = useState(null);
  const imageUrl = imagePhoto?.src || initialValues?.image;

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file, index) => {
      imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        alwaysKeepResolution: true
      }).then((compressedFile) => {
          const src = compressedFile ? URL.createObjectURL(compressedFile) : URL.createObjectURL(file);
          setImagePhoto({ id: index, src, file: compressedFile });
        });
    });
  }, []);

  const onDeleteImage = () => {
    setImagePhoto(null);
    onRemoveImage();
  };

  const onSubmitClick = (values, form) => {
    let vals = false;
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      if (values[Object.keys(values)[i]]) {
        vals = true;
      }
    }
    if (!vals) {
      showSubmissionError({ error: 'Please fill the required values' });
    } else {
      const errors = validate(values);
      if (Object.keys(errors).length === 0) {
        const params = {
          name: values.name,
          admins: values.admins.map((admin) => admin.value),
          members: values.members.map((member) => member.value)
        };

        if (imagePhoto?.file) {
          params.image = imagePhoto.file;
        }

        onSubmit(params);
        onReset();
        onDeleteImage();
        form.reset();
      } else {
        onReset();
        showSubmissionError(errors);
      }
    }
  };

  return (
    <Form
      onSubmit={onSubmitClick}
      initialValues={initialValues}
      decorators={[]}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} className="inline-form" encType="multipart/form-data">
          <div className="side-menu-form">
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="name" className="side-menu-label">1. Photo Group</label>
                  <DropzoneImage
                    imageUrl={imageUrl}
                    onDrop={onDrop}
                    onDelete={onDeleteImage}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="name" className="side-menu-label">2. Group Name *</label>
                  <GroupNameField />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="assignAdmin" className="side-menu-label">3. Assign Admin(s)</label>
                </div>

                <Field
                  name="admins"
                  component={renderDropdownWithSearchField}
                  {...{
                    itemProps: {},
                    inputProps: {
                      options: admins,
                      placeholder: 'Select admins to add to the group',
                    },
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="assignMember" className="side-menu-label">4. Assign Member(s)</label>
                </div>

                <Field
                  name="members"
                  component={renderDropdownWithSearchField}
                  {...{
                    itemProps: {},
                    inputProps: {
                      options: members,
                      extraLabel: 'Pending',
                      conditional: (item) => item.isPending,
                      placeholder: 'Select members to add to the group',
                    },
                    styleProps: { maxHeight: '350px' },
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="side-menu-footer">
            <button
              onClick={onReset}
              type="reset"
              className="close-side-menu-button close-menu-button"
            >
              Close
            </button>

            <button
              className="submit-menu-button"
              type="submit"
              disabled={submitting}
            >
              {submitText}
            </button>
          </div>
        </form>
      )}
    />
    );
};

GroupForm.defaultProps = {
  initialValues: {
    name: '',
    admins: [],
    members: [],
    image: null,
  },
  showSubmissionError: () => ({}),
  onReset: () => ({}),
  onRemoveImage: () => ({}),
  submitText: '',
};

GroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onReset: PropTypes.func,
  submitText: PropTypes.string,
};

export default GroupForm;
