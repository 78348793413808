import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import ENV_VARS from '../../utils/envVars';
import { formatFromNowDate } from '../../utils/dateUtils';

import 'stream-chat-react/dist/css/v2/index.css';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);

const ChatUserModule = ({ currentUser, targetUser }) => {
  const channel = chatClient.channel('messaging', {
    members: [currentUser.user_id, targetUser.user_id],
  });
  return (
    <div className="planner">
      <Row>
        <Col>
          <Chat client={chatClient} theme="str-chat__theme-light">
            <Channel channel={channel}>
              <Window>
                <MessageList disableDateSeparator={false} formatDate={formatFromNowDate} />
                <MessageInput grow={true} />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </Col>
      </Row>
    </div>
  );
};

ChatUserModule.propTypes = {
  currentUser: PropTypes.instanceOf(Object),
  targetUser: PropTypes.instanceOf(Object),
};

export default ChatUserModule;
