import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChangePlanModal from '../../modals/ChangePlanModal';
import { submissionError } from '../../actions/formActions';

class ChangePlanButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePlanModalOpen: false,
    };
  }

  toggleChangePlanModal = () => {
    const { changePlanModalOpen } = this.state;
    this.setState({
      changePlanModalOpen: !changePlanModalOpen,
    });
  };

  render() {
    const { team, type = 'icon', className, showSubmissionError } = this.props;
    const { membership } = team;
    const { changePlanModalOpen } = this.state;
    return (
      <>
        {type === 'icon' ? (
          <button className={`btn-text ${className}`} onClick={this.toggleChangePlanModal}>
            <em className="fa-lg mr-2 far ms-icon- icon-settings-switches-square4" />
            {membership.status === 'pending-cancellation' ? 'Reactivate ' : 'Change '} Plan
          </button>
        ) : (
          <span onClick={this.toggleChangePlanModal} className={`span-link ${className}`}>
            Upgrade to annual plan, save 10%
          </span>
        )}
        <ChangePlanModal
          team={team}
          isOpen={changePlanModalOpen}
          toggle={this.toggleChangePlanModal}
          showSubmissionError={showSubmissionError}
        />
      </>
    );
  }
}

ChangePlanButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  team: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlanButton);
