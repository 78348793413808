export const tzOptions = [
  {
    label: 'Pacific Time (US & Canada)',
    value: 'America/Los_Angeles',
  },
  {
    label: 'Mountain Time (US & Canada)',
    value: 'America/Denver',
  },
  {
    label: 'Central Time (US & Canada)',
    value: 'America/Chicago',
  },
  {
    label: 'Eastern Time (US & Canada)',
    value: 'America/New_York',
  },
  {
    label: 'Abu Dhabi',
    value: 'Asia/Muscat',
  },
  {
    label: 'Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: 'Alaska',
    value: 'America/Juneau',
  },
  {
    label: 'Almaty',
    value: 'Asia/Almaty',
  },
  {
    label: 'American Samoa',
    value: 'Pacific/Pago_Pago',
  },
  {
    label: 'Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    label: 'Arizona',
    value: 'America/Phoenix',
  },
  {
    label: 'Astana',
    value: 'Asia/Dhaka',
  },
  {
    label: 'Athens',
    value: 'Europe/Athens',
  },
  {
    label: 'Atlantic Time (Canada)',
    value: 'America/Halifax',
  },
  {
    label: 'Auckland',
    value: 'Pacific/Auckland',
  },
  {
    label: 'Azores',
    value: 'Atlantic/Azores',
  },
  {
    label: 'Baghdad',
    value: 'Asia/Baghdad',
  },
  {
    label: 'Baku',
    value: 'Asia/Baku',
  },
  {
    label: 'Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    label: 'Beijing',
    value: 'Asia/Shanghai',
  },
  {
    label: 'Belgrade',
    value: 'Europe/Belgrade',
  },
  {
    label: 'Berlin',
    value: 'Europe/Berlin',
  },
  {
    label: 'Bern',
    value: 'Europe/Zurich',
  },
  {
    label: 'Bogota',
    value: 'America/Bogota',
  },
  {
    label: 'Brasilia',
    value: 'America/Sao_Paulo',
  },
  {
    label: 'Bratislava',
    value: 'Europe/Bratislava',
  },
  {
    label: 'Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: 'Brussels',
    value: 'Europe/Brussels',
  },
  {
    label: 'Bucharest',
    value: 'Europe/Bucharest',
  },
  {
    label: 'Budapest',
    value: 'Europe/Budapest',
  },
  {
    label: 'Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    label: 'Cairo',
    value: 'Africa/Cairo',
  },
  {
    label: 'Canberra',
    value: 'Australia/Melbourne',
  },
  {
    label: 'Cape Verde Is.',
    value: 'Atlantic/Cape_Verde',
  },
  {
    label: 'Caracas',
    value: 'America/Caracas',
  },
  {
    label: 'Casablanca',
    value: 'Africa/Casablanca',
  },
  {
    label: 'Central America',
    value: 'America/Guatemala',
  },
  {
    label: 'Chatham Is.',
    value: 'Pacific/Chatham',
  },
  {
    label: 'Chennai',
    value: 'Asia/Kolkata',
  },
  {
    label: 'Chihuahua',
    value: 'America/Chihuahua',
  },
  {
    label: 'Chongqing',
    value: 'Asia/Chongqing',
  },
  {
    label: 'Copenhagen',
    value: 'Europe/Copenhagen',
  },
  {
    label: 'Darwin',
    value: 'Australia/Darwin',
  },
  {
    label: 'Dhaka',
    value: 'Asia/Dhaka',
  },
  {
    label: 'Dublin',
    value: 'Europe/Dublin',
  },
  {
    label: 'Ekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  {
    label: 'Fiji',
    value: 'Pacific/Fiji',
  },
  {
    label: 'Georgetown',
    value: 'America/Guyana',
  },
  {
    label: 'Greenland',
    value: 'America/Godthab',
  },
  {
    label: 'Guadalajara',
    value: 'America/Mexico_City',
  },
  {
    label: 'Guam',
    value: 'Pacific/Guam',
  },
  {
    label: 'Hanoi',
    value: 'Asia/Bangkok',
  },
  {
    label: 'Harare',
    value: 'Africa/Harare',
  },
  {
    label: 'Hawaii',
    value: 'Pacific/Honolulu',
  },
  {
    label: 'Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    label: 'Hobart',
    value: 'Australia/Hobart',
  },
  {
    label: 'Hong Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    label: 'Indiana (East)',
    value: 'America/Indiana/Indianapolis',
  },
  {
    label: 'International Date Line West',
    value: 'Etc/GMT+12',
  },
  {
    label: 'Irkutsk',
    value: 'Asia/Irkutsk',
  },
  {
    label: 'Islamabad',
    value: 'Asia/Karachi',
  },
  {
    label: 'Istanbul',
    value: 'Europe/Istanbul',
  },
  {
    label: 'Jakarta',
    value: 'Asia/Jakarta',
  },
  {
    label: 'Jerusalem',
    value: 'Asia/Jerusalem',
  },
  {
    label: 'Kabul',
    value: 'Asia/Kabul',
  },
  {
    label: 'Kaliningrad',
    value: 'Europe/Kaliningrad',
  },
  {
    label: 'Kamchatka',
    value: 'Asia/Kamchatka',
  },
  {
    label: 'Karachi',
    value: 'Asia/Karachi',
  },
  {
    label: 'Kathmandu',
    value: 'Asia/Kathmandu',
  },
  {
    label: 'Kolkata',
    value: 'Asia/Kolkata',
  },
  {
    label: 'Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
  },
  {
    label: 'Kuala Lumpur',
    value: 'Asia/Kuala_Lumpur',
  },
  {
    label: 'Kuwait',
    value: 'Asia/Kuwait',
  },
  {
    label: 'Kyiv',
    value: 'Europe/Kiev',
  },
  {
    label: 'La Paz',
    value: 'America/La_Paz',
  },
  {
    label: 'Lima',
    value: 'America/Lima',
  },
  {
    label: 'Lisbon',
    value: 'Europe/Lisbon',
  },
  {
    label: 'Ljubljana',
    value: 'Europe/Ljubljana',
  },
  {
    label: 'London',
    value: 'Europe/London',
  },
  {
    label: 'Madrid',
    value: 'Europe/Madrid',
  },
  {
    label: 'Magadan',
    value: 'Asia/Magadan',
  },
  {
    label: 'Marshall Is.',
    value: 'Pacific/Majuro',
  },
  {
    label: 'Mazatlan',
    value: 'America/Mazatlan',
  },
  {
    label: 'Melbourne',
    value: 'Australia/Melbourne',
  },
  {
    label: 'Mexico City',
    value: 'America/Mexico_City',
  },
  {
    label: 'Mid-Atlantic',
    value: 'Atlantic/South_Georgia',
  },
  {
    label: 'Midway Island',
    value: 'Pacific/Midway',
  },
  {
    label: 'Minsk',
    value: 'Europe/Minsk',
  },
  {
    label: 'Monrovia',
    value: 'Africa/Monrovia',
  },
  {
    label: 'Monterrey',
    value: 'America/Monterrey',
  },
  {
    label: 'Montevideo',
    value: 'America/Montevideo',
  },
  {
    label: 'Moscow',
    value: 'Europe/Moscow',
  },
  {
    label: 'Mumbai',
    value: 'Asia/Kolkata',
  },
  {
    label: 'Muscat',
    value: 'Asia/Muscat',
  },
  {
    label: 'Nairobi',
    value: 'Africa/Nairobi',
  },
  {
    label: 'New Caledonia',
    value: 'Pacific/Noumea',
  },
  {
    label: 'New Delhi',
    value: 'Asia/Kolkata',
  },
  {
    label: 'Newfoundland',
    value: 'America/St_Johns',
  },
  {
    label: 'Novosibirsk',
    value: 'Asia/Novosibirsk',
  },
  {
    label: "Nuku'alofa",
    value: 'Pacific/Tongatapu',
  },
  {
    label: 'Osaka',
    value: 'Asia/Tokyo',
  },
  {
    label: 'Paris',
    value: 'Europe/Paris',
  },
  {
    label: 'Perth',
    value: 'Australia/Perth',
  },
  {
    label: 'Port Moresby',
    value: 'Pacific/Port_Moresby',
  },
  {
    label: 'Prague',
    value: 'Europe/Prague',
  },
  {
    label: 'Pretoria',
    value: 'Africa/Johannesburg',
  },
  {
    label: 'Puerto Rico',
    value: 'America/Puerto_Rico',
  },
  {
    label: 'Quito',
    value: 'America/Lima',
  },
  {
    label: 'Rangoon',
    value: 'Asia/Rangoon',
  },
  {
    label: 'Riga',
    value: 'Europe/Riga',
  },
  {
    label: 'Riyadh',
    value: 'Asia/Riyadh',
  },
  {
    label: 'Rome',
    value: 'Europe/Rome',
  },
  {
    label: 'Samara',
    value: 'Europe/Samara',
  },
  {
    label: 'Samoa',
    value: 'Pacific/Apia',
  },
  {
    label: 'Santiago',
    value: 'America/Santiago',
  },
  {
    label: 'Sapporo',
    value: 'Asia/Tokyo',
  },
  {
    label: 'Sarajevo',
    value: 'Europe/Sarajevo',
  },
  {
    label: 'Saskatchewan',
    value: 'America/Regina',
  },
  {
    label: 'Seoul',
    value: 'Asia/Seoul',
  },
  {
    label: 'Singapore',
    value: 'Asia/Singapore',
  },
  {
    label: 'Skopje',
    value: 'Europe/Skopje',
  },
  {
    label: 'Sofia',
    value: 'Europe/Sofia',
  },
  {
    label: 'Solomon Is.',
    value: 'Pacific/Guadalcanal',
  },
  {
    label: 'Srednekolymsk',
    value: 'Asia/Srednekolymsk',
  },
  {
    label: 'Sri Jayawardenepura',
    value: 'Asia/Colombo',
  },
  {
    label: 'St. Petersburg',
    value: 'Europe/Moscow',
  },
  {
    label: 'Stockholm',
    value: 'Europe/Stockholm',
  },
  {
    label: 'Sydney',
    value: 'Australia/Sydney',
  },
  {
    label: 'Taipei',
    value: 'Asia/Taipei',
  },
  {
    label: 'Tallinn',
    value: 'Europe/Tallinn',
  },
  {
    label: 'Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    label: 'Tbilisi',
    value: 'Asia/Tbilisi',
  },
  {
    label: 'Tehran',
    value: 'Asia/Tehran',
  },
  {
    label: 'Tijuana',
    value: 'America/Tijuana',
  },
  {
    label: 'Tokelau Is.',
    value: 'Pacific/Fakaofo',
  },
  {
    label: 'Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    label: 'UTC',
    value: 'Etc/UTC',
  },
  {
    label: 'Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
  },
  {
    label: 'Urumqi',
    value: 'Asia/Urumqi',
  },
  {
    label: 'Vienna',
    value: 'Europe/Vienna',
  },
  {
    label: 'Vilnius',
    value: 'Europe/Vilnius',
  },
  {
    label: 'Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    label: 'Volgograd',
    value: 'Europe/Volgograd',
  },
  {
    label: 'Warsaw',
    value: 'Europe/Warsaw',
  },
  {
    label: 'Wellington',
    value: 'Pacific/Auckland',
  },
  {
    label: 'West Central Africa',
    value: 'Africa/Algiers',
  },
  {
    label: 'Yakutsk',
    value: 'Asia/Yakutsk',
  },
  {
    label: 'Yerevan',
    value: 'Asia/Yerevan',
  },
  {
    label: 'Zagreb',
    value: 'Europe/Zagreb',
  },
  {
    label: 'Zurich',
    value: 'Europe/Zurich',
  },
];
