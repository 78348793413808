import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { isAuthorized } from './utils/authUtils';

const AuthRoute = ({ component: Component, roles, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!user) {
        // not logged in so redirect to login page
        // return <Redirect to="/login" />;
        return null;
      }

      // check if route is restricted by role
      if (roles && !isAuthorized(user.roles, roles)) {
        // role not authorized so redirect to login page
        return <Redirect to="/unauthorized" />;
      }

      // authorized
      return <Component {...props} />;
    }}
  />
);

// TODO: eventually add logic to redirect back to requested route after login
// return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;

AuthRoute.propTypes = {
  user: PropTypes.instanceOf(Object),
  component: PropTypes.instanceOf(Object),
  roles: PropTypes.instanceOf(Array),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(AuthRoute);
