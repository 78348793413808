import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ContentForm from '../../forms/ContentForm';
import CustomFeatureModal from '../../modals/CustomFeatureModal';
import DeleteCustomFeatureModal from '../../modals/DeleteCustomFeatureModal';
import { submissionError } from '../../actions/formActions';
import {
  getCustomFeatures,
  getHiddenFeatures,
  addCustomFeature,
  updateCustomFeature,
} from '../../actions/teamActions';
import { camelCaseToUnderscoreCase } from '../../utils/stringUtils';
import customFeaturesOptions from '../../data/customFeatures.json';
import ChatSettingsForm from '../../forms/ChatSettingsForm';
import { getChatSettings } from '../../actions/chatSettingActions';

const MenuLinks = ({
  doGetCustomFeatures,
  initialValuesChatSettings,
  team,
  showSubmissionError,
  customFeatures,
  hiddenFeatures,
  doGetHiddenFeatures,
  doUpdateCustomFeature,
  doAddCustomFeature,
  doGetChatSettings,
}) => {
  const [customFeatureModalOpen, setCustomFeatureModalOpen] = useState(false);
  const [deleteCustomFeatureModalOpen, setDeleteCustomFeatureModalOpen] = useState(false);
  const [customFeatureModalTitle, setCustomFeatureModalTitle] = useState('Add a Link');
  const [customFeature, setCustomFeature] = useState(undefined);
  const [initialValues, setInitialValues] = useState({});
  const [customLinks, setCustomLinks] = useState([]);

  useEffect(() => {
    doGetCustomFeatures();
    doGetHiddenFeatures();
    doGetChatSettings();
  }, []);

  useEffect(() => {
    onSetInitialValues();
  }, [JSON.stringify(customFeatures), JSON.stringify(hiddenFeatures)]);

  const onSetInitialValues = useCallback(() => {
    if (customFeatures && customFeatures.length) {
      setCustomLinks(customFeatures.filter((f) => f.content.type === 'profileMenuTeamTile'));
    }
    for (let i = 0; i < customFeaturesOptions.length; i += 1) {
      const feat = customFeaturesOptions[i];
      setInitialValues((prev) => ({ ...prev, [`${feat.value}`]: true }));
    }
    if (hiddenFeatures && hiddenFeatures.length) {
      for (let i = 0; i < hiddenFeatures.length; i += 1) {
        setInitialValues((prev) => ({ ...prev, [`${hiddenFeatures[i]}`]: false }));
      }
    }
  }, [customFeatures, hiddenFeatures]);

  const toggleCustomFeatureModal = (title = 'Add a Link') => {
    setCustomFeatureModalOpen(!customFeatureModalOpen);
    setCustomFeatureModalTitle(title);
  };

  const toggleDeleteCustomFeatureModal = () => {
    setDeleteCustomFeatureModalOpen(!deleteCustomFeatureModalOpen);
  };

  return (
    <ContentWrapper>
      <Row>
        <Col xs={12}>
          <PageTitle title="Customize Links" />
        </Col>
      </Row>
      <div className="main-content-container">
        <Row className="mt-2">
          <Col xs={12}>
            <div className="d-flex justify-space-between">
              <h4>Show the following links and content sections within the Macrostax app:</h4>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            {customFeatures !== undefined && hiddenFeatures !== undefined ? (
              <>
                <ContentForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={() => {}}
                  customFeatures={customFeatures}
                  initialValues={initialValues}
                  hiddenFeatures={hiddenFeatures}
                  team={team}
                />
                <ChatSettingsForm
                  initialValues={initialValuesChatSettings}
                  isDisabled={hiddenFeatures.includes('chatMessages')}
                  onSubmit={() => {}}
                />
              </>
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <h4>Custom Links in App Menu</h4>
            <Button
              color="primary"
              onClick={() => {
                setCustomFeature(undefined);
                toggleCustomFeatureModal();
              }}
            >
              <div className="d-flex flex-row align-items-center">
                <em className="font-size-one-point-two-five icon-link mr-2" />
                <div>Add a Link</div>
              </div>
            </Button>
          </Col>
        </Row>
        {customLinks !== undefined ? (
          <>
            {customLinks.length === 0 ? (
              <Row className="mb-2">
                <Col>You don't have any custom links entered.</Col>
              </Row>
            ) : (
              <>
                <Row className="mb-2 mt-4">
                  <Col className="d-flex align-items-end text-bold" md={1} xs={1}>
                    Icon
                  </Col>
                  <Col xs={8} className="text-bold">
                    Title
                  </Col>
                  <Col xs={2} className="text-bold text-center">
                    Actions
                  </Col>
                </Row>
                {customLinks.map((l) => (
                  <Row key={l.id}>
                    <Col xs={1} md={1} className="d-flex flex-row leaderboard-row">
                      <img
                        src={`/img/icons/${camelCaseToUnderscoreCase(l.content.icon).toLowerCase()}.svg`}
                        className="custom-link-icon"
                      />
                    </Col>
                    <Col xs={8} className="leaderboard-row">
                      <a href={l.content.link} target="_blank" rel="noreferrer">
                        {l.content.title}
                      </a>
                    </Col>
                    <Col xs={2} className="leaderboard-row text-center">
                      <span
                        className="span-link"
                        onClick={() => {
                            setCustomFeature(l);
                            toggleCustomFeatureModal('Edit Link');
                        }}
                      >
                        Edit
                      </span>
                      <>
                        <span className="hidden-on-mobile"> | </span>
                        <span className="only-on-mobile"> </span>
                        <span
                          className="span-link"
                          title="Delete"
                          onClick={() => {
                            setCustomFeature(l);
                            toggleDeleteCustomFeatureModal();
                          }}
                        >
                          Delete
                        </span>
                      </>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </>
        ) : null}
      </div>
      <CustomFeatureModal
        isOpen={customFeatureModalOpen}
        toggle={toggleCustomFeatureModal}
        title={customFeatureModalTitle}
        customFeature={customFeature}
        onSubmit={(values) => {
          if (values.id !== undefined && values.id.length > 0) {
            doUpdateCustomFeature(values);
          } else {
            doAddCustomFeature(values);
          }
          toggleCustomFeatureModal();
        }}
      />
      <DeleteCustomFeatureModal
        isOpen={deleteCustomFeatureModalOpen}
        toggle={toggleDeleteCustomFeatureModal}
        customFeature={customFeature}
      />
    </ContentWrapper>
  );
};

MenuLinks.propTypes = {
  showSubmissionError: PropTypes.func,
  team: PropTypes.instanceOf(Object),
  doGetCustomFeatures: PropTypes.func,
  doGetHiddenFeatures: PropTypes.func,
  doGetChatSettings: PropTypes.func,
  customFeatures: PropTypes.instanceOf(Array),
  hiddenFeatures: PropTypes.instanceOf(Array),
  doUpdateCustomFeature: PropTypes.func,
  doAddCustomFeature: PropTypes.func,
  initialValuesChatSettings: PropTypes.object,
};
const mapStateToProps = (state) => ({
  team: state.team,
  customFeatures: state.team.customFeatures,
  hiddenFeatures: state.team.hiddenFeatures,
  currentUser: state.auth.user,
  initialValuesChatSettings: {
    chat_coaches: state.chatSettings.features.chat_coaches,
  },
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doGetHiddenFeatures: () => dispatch(getHiddenFeatures()),
  doGetCustomFeatures: () => dispatch(getCustomFeatures()),
  doUpdateCustomFeature: (params) => dispatch(updateCustomFeature(params)),
  doAddCustomFeature: (params) =>
    dispatch(
      addCustomFeature({
        ...params,
        type: 'profileMenuTeamTile',
      })
    ),
  doGetChatSettings: () => dispatch(getChatSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuLinks);
