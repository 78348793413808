import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import BonusPointsForm from '../forms/BonusPointsForm';
import { submissionError } from '../actions/formActions';

class BonusPointsModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      firstName,
      challengeName,
      showSubmissionError,
      minDate,
      maxDate,
      onSubmit,
    } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/bonusPoints', title: 'Give Bonus Points' });
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle title="Give Bonus Points" showTeam={false} icon="icon-trophy" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <div className="mb-4">Give {firstName} some bonus points for a job well-done:</div>
              <BonusPointsForm
                onSubmit={onSubmit}
                showSubmissionError={showSubmissionError}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

BonusPointsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  firstName: PropTypes.string,
  showSubmissionError: PropTypes.func,
  onSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // userScores: state.challenges.userScores,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusPointsModal);
