import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';

class BasePrint extends Component {
  render() {
    const { children, step } = this.props;
    return (
      <div className="print-wrapper pre-auth">
        <section className="section-container">{children}</section>
      </div>
    );
  }
}

BasePrint.propTypes = {
  children: PropTypes.instanceOf(Object),
  step: PropTypes.number,
};
const mapStateToProps = (state) => ({
  step: state.signup.step,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BasePrint);
