import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';

class CobrandPreviewModal extends Component {
  render() {
    const { isOpen, toggle, logo_background_color, logo_image, team_name } = this.props;
    if (isOpen) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/modal/cobrandPreview',
        title: 'Cobrand Preview',
      });
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium">
        <ModalBody>
          <Row>
            <Col xs={12} className="d-flex flex-row justify-content-center">
              <div id="cobrand-preview-container" className="mt-3 cobrand-preview-container">
                <div className="inner">
                  <div className="plus-button"></div>
                  <div className="bug"></div>
                  {team_name && team_name.length && (
                    <>
                      <div className="team-name">with {team_name}</div>
                    </>
                  )}
                  <div
                    className="logo-image-container"
                    style={{ backgroundColor: logo_background_color }}
                  >
                    <img src={logo_image} className="logo-img" />
                    <div className="powered-by-macrostax">Powered by Macrostax</div>
                  </div>
                </div>
                <div className="iphone-outline"></div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

CobrandPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  logo_image: PropTypes.string,
  logo_background_color: PropTypes.string,
  team_name: PropTypes.string,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CobrandPreviewModal);
