import React from 'react';
import { Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import UserDetailContainer from '../../containers/Users/UserDetailContainer';
import { getUser } from '../../actions/userActions';
import browserHistory from '../../history';

class ClientDetail extends React.Component {
  componentDidMount() {
    const {
      doGetUser,
      match: {
        params: { user_id },
      },
    } = this.props;
    doGetUser(user_id);
  }

  handleBackClick = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { user, team } = this.props;
    const qs = queryString.parse(location.search);
    const backPageName = browserHistory.location?.state?.from || 'Clients';

    return (
      <ContentWrapper className="user-detail-container">
        <Row>
          <Col xs={12}>
            <PageTitle title="Clients" />
            <div className="mb-4 mt-n2">
              {qs.admin && qs.admin.length ? (
                <Link to="/admins">
                  <em className="fa-lg mr-2 fas fa-arrow-left" />
                  Back to Admins
                </Link>
              ) : (
                <button onClick={this.handleBackClick} className="btn btn-link">
                  <em className="fa-lg mr-2 fas fa-arrow-left" />
                  Back to {backPageName}
                </button>
              )}
            </div>
          </Col>
        </Row>
        {user && team && team.team_id ? (
          <div className="main-content-container">
            <UserDetailContainer user={user} />
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

ClientDetail.propTypes = {
  user: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  doGetAllUserMacros: PropTypes.func,
  doGetUser: PropTypes.func,
};
const mapStateToProps = (state) => ({
  user: state.user,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  doGetUser: (user_id) => dispatch(getUser(user_id)),
  doGetAllUserMacros: (user_id, team_id) => dispatch(getAllUserMacros(user_id, team_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
