import { capitalizeFirst } from './stringUtils';

export function formatGoal(teamProfile, goal, capitalize = false) {
  let goalName = teamProfile.goals.find((g) => g.goal_id == goal).goal_name;
  if (capitalize) {
    return goalName
      .split(' ')
      .map((g) => capitalizeFirst(g))
      .join(' ');
  }
  return goalName;
}

export function getGoalOptionsFromTeamProfile(teamProfile) {
  const goalOptions = teamProfile.goals.map((goal) => {
    return {
      value: goal.goal_id,
      label: goal.goal_name,
      phases: goal.phases.map((phase) => {
        return {
          label: `${goal.goal_name} / ${phase.phase_name}`,
          order: phase.display_order,
          value: phase.phase_id,
        };
      }),
    };
  });
  return goalOptions.reverse();
}

export function formatPhase(teamProfile, phase, capitalize = false) {
  let phaseName = teamProfile.goals
    .reduce((acc, g) => [...g.phases, ...acc], [])
    .find((p) => p.phase_id == phase).phase_name;

  if (capitalize) {
    return phaseName
      .split(' ')
      .map((g) => capitalizeFirst(g))
      .join(' ');
  }
  return phaseName;
}

export function getPhaseOptions(selectedGoalsById, goalOptions) {
  return goalOptions.reduce(
    (acc, g) => [
      ...acc,
      ...(selectedGoalsById && selectedGoalsById.indexOf(g.value) != -1 ? g.phases : []),
    ],
    []
  ).reverse();
}

export function formatActivityLevel(teamProfile, activityLevel) {
  return teamProfile.activity_levels.find((aL) => aL.activity_level_id == activityLevel)
    .activity_level_name;
}

export function getActivityLevelOptionsFromTeamProfile(teamProfile) {
  return teamProfile.activity_levels
    .sort((a, b) => (a.display_order < b.display_order ? -1 : 1))
    .map((al) => {
      return { value: al.activity_level_id, label: al.activity_level_name };
    });
}

export function formatDietaryPreferences(teamProfile, dietaryPreferences) {
  return teamProfile.dietary_preferences.reduce(
    (acc, pref) => (dietaryPreferences.includes(pref.uuid) ? [pref.label, ...acc] : acc),
    []
  );
}

export function getDietaryPreferencesOptionsFromTeamProfile(teamProfile) {
  return teamProfile.dietary_preferences.map((pref) => {
    return { value: pref.uuid, label: pref.label };
  });
}

export function formatAdminRoles(teamProfile, roles) {
  return teamProfile.roles.reduce(
    (acc, role) => (roles.includes(role.id) ? [role.name, ...acc] : acc),
    []
  );
}

export function getAdminRolesOptionsFromTeamProfile(teamProfile) {
  return teamProfile.roles.map((role) => {
    return { value: role.id, label: role.name };
  });
}
