// Note: this form is self-contained, meaning all of the dispatch calls are defined
// within this instead of being passed in as props
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Row, Col, Button } from 'reactstrap';
import { formatDateToIso8601 } from '../utils/dateUtils';
import DeleteWeighInModal from '../modals/DeleteWeighInModal';
import { submissionError } from '../actions/formActions';
import { addWeighIn, updateWeighIn, deleteWeighIn } from '../actions/userActions';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';

const validate = (values) => {
  const errors = {};
  if (!values.date) {
    errors.date = 'Date is required';
  }
  if (!values.weight_lbs) {
    errors.weight_lbs = 'Weight is required';
  }
  return errors;
};

class WeighInsForm extends Component {
  constructor(props) {
    super(props);
    const {
      user: { weigh_ins },
    } = this.props;
    this.state = {
      weigh_ins,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      weigh_ins: props.user.weigh_ins,
    };
  }

  saveWeighIn = (weigh_in) => {
    const { date, weight_lbs } = weigh_in;
    // editing an existing weigh-in
    if (weigh_in.id == undefined) {
      this.props.doUpdateWeighIn(this.props.user.user_id, {
        date: formatDateToIso8601(date),
        weight_lbs,
      });
    }
    // adding a new weigh-in
    else {
      this.props.doAddWeighIn(this.props.user.user_id, {
        date: formatDateToIso8601(date),
        weight_lbs,
      });
    }
  };

  editWeighIn = (weigh_in, status) => {
    const { weigh_ins } = this.state;
    const record = weigh_ins.find((w) => w.date === weigh_in.date);
    if (record) {
      record.status = status; // mutate
      this.setState({
        weigh_ins,
      });
    }
  };

  addWeighIn = () => {
    const { weigh_ins } = this.state;
    weigh_ins.push({
      date: moment().toDate(),
      id: uuidv4(),
      weight_lbs: '',
      status: 'edit',
    });
    this.setState({
      weigh_ins,
    });
  };

  cancelEdit = (weigh_in) => {
    // cancelling the edit of an existing weigh-in
    if (weigh_in.id == undefined) {
      const { weigh_ins } = this.state;
      const record = this.state.weigh_ins.find((w) => w.date === weigh_in.date);
      if (record) {
        record.status = undefined; // mutate
        this.setState({ weigh_ins });
      }
    }
    // cancelling a newly-added weigh-in
    else {
      this.setState({
        weigh_ins: this.state.weigh_ins.filter((w) => w.id == undefined),
      });
    }
  };

  toggleDeleteWeighInModal = (date) => {
    const { deleteWeighInModalOpen } = this.state;
    this.setState({
      deleteWeighInModalOpen: !deleteWeighInModalOpen,
      deleteWeighInOnDate: date,
    });
  };

  render() {
    const { showSubmissionError, user } = this.props;
    const { user_id, timezone } = user;
    const { weigh_ins, deleteWeighInModalOpen, weighInId } = this.state;
    return (
      <>
        {weigh_ins.length > 0 && (
          <Row>
            <Col xs={4} className="strong p-2">
              Date
            </Col>
            <Col xs={4} className="text-center strong border-left p-2">
              Weight (lbs)
            </Col>
            <Col xs={4} className="text-center strong border-left p-2">
              Actions
            </Col>
          </Row>
        )}
        {weigh_ins.map((w, idx) => (
          <Form
            key={`${idx}-${w.date}-${w.weight_lbs}`}
            onSubmit={(values) => {
              const errors = validate(values, weigh_ins);
              if (Object.keys(errors).length === 0) {
                this.saveWeighIn(values);
              } else {
                showSubmissionError(errors, false);
              }
            }}
            initialValues={{
              weight_lbs: w.weight_lbs,
              date: moment(w.date).toDate(),
              id: w.id,
            }}
            keepDirtyOnReinitialize={false}
            render={({ handleSubmit, submitting, form, values }) => (
              <form onSubmit={handleSubmit} className="weigh-ins-form">
                <Row className="border-top">
                  <Col xs={4} className="p-2 align-self-center">
                    {w.id == undefined ? (
                      `${moment(w.date).tz(timezone).format('MMMM D, YYYY')}`
                    ) : (
                      <Field
                        name="date"
                        component={renderDatepickerForField}
                        className="text-left"
                        {...{
                          itemProps: {},
                          inputProps: {
                            maxDate: moment().toDate(),
                          },
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    xs={4}
                    className="text-center border-left d-flex align-items-center justify-content-center"
                  >
                    {w.status !== 'edit' ? (
                      `${w.weight_lbs}`
                    ) : (
                      <div className="animated">
                        <Field
                          name="weight_lbs"
                          component={renderInputForField}
                          stackedlabel={false}
                          parse={(value) =>
                            value ? parseFloat(Number.parseFloat(value).toFixed(2)).toString() : ''
                          }
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="text-center border-left p-2">
                    {w.status !== 'edit' ? (
                      <div className="d-flex justify-content-around animated">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.editWeighIn(w, 'edit');
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleDeleteWeighInModal(w.date);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-around animated">
                        <Button color="primary">Save</Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.cancelEdit(w);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </form>
            )}
          />
        ))}
        <Row className="border-top pt-3">
          <Col>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.addWeighIn();
              }}
              disabled={this.state.weigh_ins.filter((w) => w.id != undefined).length > 0}
            >
              Add Weigh-In
            </Button>
          </Col>
        </Row>
        <DeleteWeighInModal
          userId={user_id}
          dateToDelete={this.state.deleteWeighInOnDate}
          isOpen={deleteWeighInModalOpen}
          toggle={this.toggleDeleteWeighInModal}
        />
      </>
    );
  }
}

WeighInsForm.propTypes = {
  user: PropTypes.instanceOf(Object),
  doAddWeighIn: PropTypes.func,
  doUpdateWeighIn: PropTypes.func,
  showSubmissionError: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
  doAddWeighIn: (id, values) => dispatch(addWeighIn(id, values)),
  doUpdateWeighIn: (id, values) => dispatch(updateWeighIn(id, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeighInsForm);
