import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import UserPreviewImportTable from '../components/Tables/UserPreviewImportTable';
import { customSelectStyles } from '../constants';

const ImportUsersByLocationForm = ({
  onLoadUsersByLocation,
  onCleanUsers,
  onSubmit,
  data,
  users,
  isLoading,
  showTable,
  }) => {
    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationOptions = useMemo(() =>
      data.map((location) =>
        ({
          label: location.name,
          value: `${location.id}`
        })
      ), [JSON.stringify(data)]);

    const onSubmitClick = () => {
      const location_ids = selectedLocations.map((location) => parseInt(location));
      onSubmit({ location_ids });
    };

    const areLocationsEmpty = (locationIds) => !locationIds.length;

    const areNewLocationsLessThanPreviousSelected = (locationIds) => locationIds.length < selectedLocations.length;

    const getLocationIdRemoved = (prevLocationIds, newLocationIds) => {
      const locationsRemoved = prevLocationIds.filter((value) => !newLocationIds.includes(value));
      return locationsRemoved.length === 1 ? locationsRemoved[0] : undefined;
    };

    const onSelectLocationsChange = (value) => {
      const locationIds = value ? value.map((v) => v.value) : [];
      setSelectedLocations([...locationIds]);

      if (areLocationsEmpty(locationIds)) {
        onCleanUsers();
      } else if (areNewLocationsLessThanPreviousSelected(locationIds)) {
        onCleanUsers(getLocationIdRemoved(selectedLocations, locationIds));
      } else {
        onLoadUsersByLocation([locationIds[locationIds.length - 1]]);
      }
    };

    return (
      <>
        <div>
          <Row>
            <Col md={6}>
              <Select
                name="locations"
                label="Locations"
                isMulti={true}
                options={locationOptions}
                styles={customSelectStyles}
                isClearable={true}
                onChange={onSelectLocationsChange}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={4}>
              <button
                onClick={onSubmitClick}
                className="btn btn-lg btn-primary fixed-width-lg btn-search-with-reset"
                type="submit"
                disabled={isLoading || !locationOptions.length || !selectedLocations.length}
              >
                Import Members
              </button>
            </Col>
          </Row>

          {showTable && (
            <Col xs={12} className="mt-4">
              <UserPreviewImportTable
                users={users}
              />
            </Col>
          )}
        </div>
      </>
    );
};

ImportUsersByLocationForm.propTypes = {
  data: PropTypes.instanceOf(Array),
  users: PropTypes.instanceOf(Array),
  onLoadUsersByLocation: PropTypes.func.isRequired,
  onCleanUsers: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showTable: PropTypes.bool,
};

export default ImportUsersByLocationForm;
