import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import { removeGroupRequest } from '../actions/groupActions';

const DeleteGroup = ({ isOpen, toggle, groupId, doRemoveGroup }) => {
  const handleRemoveGroup = () => {
    doRemoveGroup(groupId);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-small">
      <ModalHeader toggle={toggle}>
        <PageTitle
          title="Delete Group"
          showTeam={false}
          icon="fa-1x ms-icon- icon-Browser_-Internet_-Web_-Network_-Close-1"
        />
      </ModalHeader>
      <ModalBody className="d-flex flex-direction-column justify-content-between">
        <Row>
          <Col xs={12}>Are you sure you want to delete this group?</Col>
          <Col xs={12}>This action cannot be undone. Please confirm if you want to proceed.</Col>
        </Row>
        <Row className="mt-4">
          <Col xs={12}>
            <div className="d-flex justify-content-end">
              <button
                size="lg"
                onClick={toggle}
                className="fixed-width-md cancel-button-modal mr-3"
              >
                Cancel
              </button>
              <button
                size="lg"
                onClick={handleRemoveGroup}
                className="fixed-width-md error-button-modal"
              >
                Delete
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

DeleteGroup.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  groupId: PropTypes.string,
  doRemoveGroup: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  doRemoveGroup: (id) => dispatch(removeGroupRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGroup);
