import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import { SketchPicker } from 'react-color';

export class RenderColorPickerForFieldComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
    };
  }

  toggleColorPicker = () => {
    const { displayColorPicker } = this.state;
    this.setState({
      displayColorPicker: !displayColorPicker,
    });
  };

  closeColorPicker = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const {
      input,
      label,
      meta: { error },
      itemProps,
      stackedlabel = true,
    } = this.props;
    const selectedColor = input.value;

    const formGroupClassName = itemProps.row ? 'flex-row' : stackedlabel ? 'stacked' : 'not-stacked';
    let labelClassName = '';
    let inputClassName = 'color-picker-container';

    if (itemProps.row) {
      labelClassName = `col-xl-${labelCols} col-form-label`;
      inputClassName = `col-xl-${inputCols} color-picker-container`;
    }

    const showLabel = () => {
      if (label && label.length) {
        return <label className={labelClassName}>{label}</label>;
      }
      return null;
    };

    return (
      <FormGroup {...itemProps} className={formGroupClassName}>
        {showLabel()}
        <div className={inputClassName}>
          <div>
            <div className="d-flex flex-row align-items-center">
              <div className="swatch-container" onClick={this.toggleColorPicker}>
                <div className="swatch" style={{ backgroundColor: selectedColor }} />
              </div>
              <div className="ml-2 caption">
                <em className="fa fa-arrow-left" /> Click to edit
              </div>
            </div>

            <div className="selected-color mt-1">{selectedColor}</div>
            {this.state.displayColorPicker ? (
              <div className="popover">
                <div className="cover" onClick={this.closeColorPicker} />
                <SketchPicker
                  color={selectedColor}
                  onChangeComplete={(color) => input.onChange(color.hex)}
                  presetColors={[]}
                />
              </div>
            ) : null}
          </div>
        </div>
      </FormGroup>
    );
  }
}

RenderColorPickerForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  checkboxIcon: PropTypes.string,
  checkboxIconUnchecked: PropTypes.string,
  refField: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    stackedLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    options: PropTypes.instanceOf(Array),
    multi: PropTypes.bool,
    simpleValue: PropTypes.bool,
  }),
};

export default forwardRef((props, ref) => (
  <RenderColorPickerForFieldComponent innerRef={ref} {...props} />
));
