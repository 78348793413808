import React from 'react';
import Qs from 'qs';
import { Row, Col, Button, Input } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import RecipeDetail from '../../components/RecipeDetail';
import { printRecipe } from '../../actions/printActions';
import ENV_VARS from '../../utils/envVars';

class PrintRecipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPhoto: true,
      showNotes: true,
      showServingInfo: true,
    };
  }

  componentDidMount() {
    const {
      doPrintRecipe,
      match: {
        params: { recipe_id },
      },
    } = this.props;
    doPrintRecipe(recipe_id);
  }

  render() {
    const { recipe = {}, currentUser } = this.props;
    const { showPhoto, showNotes, showServingInfo } = this.state;

    return (
      <div className="print-container">
        <Row className="mt-2">
          <Col xs={12}>
            {recipe && recipe.name ? (
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>{recipe.name}</title>
                  <link
                    rel="canonical"
                    href={`${ENV_VARS.BASE_PRINT_URL}/printrecipe/${recipe.recipe_uuid}`}
                  />
                  <meta property="og:title" content={recipe.name} />
                  <meta
                    property="og:url"
                    content={`${ENV_VARS.BASE_PRINT_URL}/printrecipe/${recipe.recipe_uuid}`}
                  />
                  {recipe.photo_url && recipe.photo_url.length && (
                    <meta property="og:image" content={recipe.photo_url} />
                  )}
                  <meta name="twitter:card" content="summary" />
                  <meta name="twitter:site" content="@macrostax" />
                  <meta name="twitter:creator" content="@macrostax" />
                  <meta property="og:description" content={recipe.intro_line} />
                </Helmet>
                <div className="text-center print-header">
                  <Button
                    color="primary"
                    size="lg"
                    className="fixed-width-sm"
                    onClick={() => window.print()}
                  >
                    Print
                  </Button>
                  <div className="checkbox c-checkbox mt-3 mb-4">
                    <label>
                      <Input
                        type="checkbox"
                        onChange={() => this.setState({ showPhoto: !showPhoto })}
                        checked={showPhoto}
                      />
                      <span className={`fa ${showPhoto ? 'fa-check' : 'fa-close'}`} />
                      Recipe Image
                    </label>
                    <label className="ml-3">
                      <Input
                        type="checkbox"
                        onChange={() => this.setState({ showNotes: !showNotes })}
                        checked={showNotes}
                      />
                      <span className={`fa ${showNotes ? 'fa-check' : 'fa-close'}`} />
                      Notes
                    </label>
                    <label className="ml-3">
                      <Input
                        type="checkbox"
                        onChange={() => this.setState({ showServingInfo: !showServingInfo })}
                        checked={showServingInfo}
                      />
                      <span className={`fa ${showServingInfo ? 'fa-check' : 'fa-close'}`} />
                      Serving Info
                    </label>
                  </div>
                </div>
                <RecipeDetail
                  recipe={recipe}
                  showNotes={showNotes}
                  showServingInfo={showServingInfo}
                  showPhoto={showPhoto}
                  currentUser={currentUser}
                  showName={true}
                />
              </>
            ) : (
              <Row>
                <Col
                  className="d-flex flex-row align-items-center justify-content-center margin-top-200"
                  xs={12}
                >
                  <Loading label="Loading your recipe" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

PrintRecipe.propTypes = {
  recipe: PropTypes.instanceOf(Object),
  currentUser: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  recipe: state.print.recipe,
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doPrintRecipe: (recipe_id) => dispatch(printRecipe(recipe_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintRecipe);
