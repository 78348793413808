export const SUBMIT_RECIPE_SEARCH_REQUEST = 'SUBMIT_RECIPE_SEARCH_REQUEST';
export const CLEAR_RECIPE_SEARCH_FORM = 'CLEAR_RECIPE_SEARCH_FORM';
export const SHARE_RECIPE = 'SHARE_RECIPE';

export function submitRecipeSearchRequest(values) {
  return {
    type: SUBMIT_RECIPE_SEARCH_REQUEST,
    values,
  };
}

export function clearRecipeSearchForm() {
  return {
    type: CLEAR_RECIPE_SEARCH_FORM,
  };
}

export function shareRecipe(values, func) {
  return {
    type: SHARE_RECIPE,
    values,
    func,
  };
}
