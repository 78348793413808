import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import CustomFeatureForm from '../forms/CustomFeatureForm';
import { submissionError } from '../actions/formActions';

class CustomFeatureModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      firstName,
      challengeName,
      showSubmissionError,
      minDate,
      maxDate,
      onSubmit,
      icon = 'icon-link',
      customFeature,
      type = 'profileMenuTeamTile',
    } = this.props;
    let { title = 'Add a Link' } = this.props;
    if (typeof title !== 'undefined' && typeof title !== 'string') {
      title = 'Add a Link';
    }
    if (isOpen) {
      ReactGA.send({
        hitType: 'pageview',
        page: '/modal/customFeature',
        title: 'Custom Feature Modal',
      });
    }

    let initialValues = {};
    if (customFeature !== undefined) {
      initialValues = {
        ...customFeature.content,
        id: customFeature.id,
      };
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium">
        <ModalHeader toggle={() => toggle(undefined, undefined)}>
          <PageTitle title={title} showTeam={false} icon={icon} />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                {title === 'Add a Link' ? (
                  <>Add a new link to your section of the Macrostax app:</>
                ) : title === 'Add Content' ? (
                  <>Add new content to the home screen of the Macrostax app:</>
                ) : (
                  <>
                    Need to make changes? Edit this{' '}
                    {type === 'profileMenuTeamTile' ? 'link' : 'content'}{' '}below:
                  </>
                )}
              </div>
              <CustomFeatureForm
                onSubmit={onSubmit}
                showSubmissionError={showSubmissionError}
                initialValues={initialValues}
                type={type}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

CustomFeatureModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  firstName: PropTypes.string,
  showSubmissionError: PropTypes.func,
  onSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  // userScores: state.challenges.userScores,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFeatureModal);
