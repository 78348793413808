import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import AccountSettingsForm from '../../forms/AccountSettingsForm';
import { submissionError } from '../../actions/formActions';
import { updateUser } from '../../actions/userActions';

class Settings extends React.Component {
  render() {
    const { showSubmissionError, user, doUpdateUser } = this.props;
    const { user_id, phone, email, first_name, last_name /* profilePhoto */ } = user;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="My Account Settings" />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="main-content-container">
              <AccountSettingsForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => {
                  doUpdateUser(user_id, values);
                }}
                showPhotoUpload={false}
                user={user}
                initialValues={{
                  phone,
                  first_name,
                  last_name,
                  email,
                  /* profilePhoto, */
                }}
              />
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Settings.propTypes = {
  showSubmissionError: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    // profilePhoto: PropTypes.string,
  }),
  doUpdateUser: PropTypes.func,
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doUpdateUser: (id, values) => {
    values.phone = values.phone || '';
    dispatch(updateUser(id, values, true));
  },
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
