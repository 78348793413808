import { useEffect } from 'react';

const useAddAndRemoveEvent = (enabled, eventName, callback) => {
  useEffect(() => {
    if (enabled) {
      document.addEventListener(eventName, callback);
    } else {
      document.removeEventListener(eventName, callback);
    }

    return () => {
      document.removeEventListener(eventName, callback);
    };
    }, [enabled]);
};

export default useAddAndRemoveEvent;
