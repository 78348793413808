import { put, take, race, all } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';
import {
  GET_INTEGRATIONS,
  SUBMIT_INTEGRATION_SETUP_REQUEST,
  GET_ALL_LOCATIONS_FROM_INTEGRATION,
  GET_IMPORT_STATUS,
  IMPORT_USERS_REQUEST,
  CANCEL_IMPORT_USERS_REQUEST,
  REQUEST_USERS_BY_LOCATION
} from '../actions/integrationActions';
import {
  CANCEL_IMPORT_USER_FAILURE,
  CANCEL_IMPORT_USER_SUCCESS,
  GET_ALL_LOCATIONS_FROM_INTEGRATION_FAILURE,
  GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS,
  GET_IMPORT_STATUS_FAILURE,
  GET_IMPORT_STATUS_SUCCESS,
  GET_INTEGRATIONS_FAILURE,
  GET_INTEGRATIONS_SUCCESS,
  IMPORT_USER_FAILURE,
  IMPORT_USER_SUCCESS,
  SETUP_INTEGRATION_FAILURE,
  SETUP_INTEGRATION_SUCCESS,
  cancelImportUsers,
  getLocationsIntegration,
  getImportStatus,
  getIntegrations,
  importUsers,
  setupIntegration,
  getUsersByLocation,
  GET_USERS_BY_LOCATION_SUCCESS,
  GET_USERS_BY_LOCATION_FAILURE } from '../apiActions/integrationApiActions';

export default function* integrationSagas() {
  yield all([
    getIntegrationsSaga(),
    getLocationsFromIntegrationSaga(),
    submitSetupIntegrationSaga(),
    getImportStatusSaga(),
    importUsersSaga(),
    cancelImportUsersSaga(),
    getUsersByLocationSaga(),
  ]);
}

function* getIntegrationsSaga() {
  while (true) {
    yield take(GET_INTEGRATIONS);
    yield put(getIntegrations());
    const { failure } = yield race({
      success: take(GET_INTEGRATIONS_SUCCESS),
      failure: take(GET_INTEGRATIONS_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getLocationsFromIntegrationSaga() {
  while (true) {
    const { integrationId } = yield take(GET_ALL_LOCATIONS_FROM_INTEGRATION);

    yield put(getLocationsIntegration(integrationId));

    const { failure } = yield race({
      success: take(GET_ALL_LOCATIONS_FROM_INTEGRATION_SUCCESS),
      failure: take(GET_ALL_LOCATIONS_FROM_INTEGRATION_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getImportStatusSaga() {
  while (true) {
    const { integrationId } = yield take(GET_IMPORT_STATUS);

    yield put(getImportStatus(integrationId));

    const { failure } = yield race({
      success: take(GET_IMPORT_STATUS_SUCCESS),
      failure: take(GET_IMPORT_STATUS_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* submitSetupIntegrationSaga() {
  while (true) {
    const { values, integrationId } = yield take(SUBMIT_INTEGRATION_SETUP_REQUEST);
    yield put(setupIntegration(values, integrationId));

    const { failure } = yield race({
      success: take(SETUP_INTEGRATION_SUCCESS),
      failure: take(SETUP_INTEGRATION_FAILURE),
    });

    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Success!', 'Setup integration Success', 'success'));
    }
  }
}

function* importUsersSaga() {
  while (true) {
    const { values, integrationId } = yield take(IMPORT_USERS_REQUEST);
    yield put(importUsers(values, integrationId));

    const { failure } = yield race({
      success: take(IMPORT_USER_SUCCESS),
      failure: take(IMPORT_USER_FAILURE),
    });

    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* cancelImportUsersSaga() {
  while (true) {
    const { integrationId } = yield take(CANCEL_IMPORT_USERS_REQUEST);
    yield put(cancelImportUsers(integrationId));

    const { failure } = yield race({
      success: take(CANCEL_IMPORT_USER_SUCCESS),
      failure: take(CANCEL_IMPORT_USER_FAILURE),
    });

    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Success!', 'Cancel user import successfully', 'success'));
    }
  }
}

function* getUsersByLocationSaga() {
  while (true) {
    const { integrationId, locations } = yield take(REQUEST_USERS_BY_LOCATION);

    yield put(getUsersByLocation(integrationId, locations));

    const { failure } = yield race({
      success: take(GET_USERS_BY_LOCATION_SUCCESS),
      failure: take(GET_USERS_BY_LOCATION_FAILURE),
    });

    if (failure) {
    yield put(spinnerDecrement());
    yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}
