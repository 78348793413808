import { call, put, take, race, all, delay, select } from 'redux-saga/effects';

import {
  AUTH_LOGIN_REQUEST,
  AUTHORIZE_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  CHANGE_PASSWORD,
  changePassword,
  authLoginRequest,
  authLoginSuccess,
  authLoginFailure,
  authUserIdRetrieved,
  authTokenStored,
  authLogout,
  userIsLoggedIn,
  passwordUpdateRequired,
  putStreamToken,
  setCredentials,
  setTeamsList,
  authorizeRequest,
  SEND_RESET_PASSWORD,
  BEGIN_AUTHENTICATION,
  RESET_PASSWORD,
  GET_STREAM_TOKEN,
  UPSERT_STREAM_USERS,
  SWITCH_TEAMS,
} from '../actions/authActions';
import {
  authUserBasic,
  authorize,
  authUserRefresh,
  changeUserPassword,
  sendResetPassword,
  getStreamToken,
  upsertStreamUsers,
  switchTeams,
  AUTH_BASIC_SUCCESS,
  AUTH_BASIC_FAILURE,
  AUTHORIZE_SUCCESS,
  AUTHORIZE_FAILURE,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAILURE,
  SEND_RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_FAILURE,
  GET_STREAM_TOKEN_SUCCESS,
  GET_STREAM_TOKEN_FAILURE,
  UPSERT_STREAM_USERS_SUCCESS,
  UPSERT_STREAM_USERS_FAILURE,
  SWITCH_TEAMS_SUCCESS,
  SWITCH_TEAMS_FAILURE,
} from '../apiActions/authApiActions';
import {
  getUserProfile,
  getSparseUserProfile,
  AUTH_USER_PROFILE_SUCCESS,
  AUTH_USER_PROFILE_FAILURE,
} from '../apiActions/userApiActions';
import {
  INIT_EMAIL_KEY,
  setLocalStoreValue,
  getLocalStoreValue,
  removeLocalStoreValues,
  JWT_TOKEN_KEY,
  JWT_REFRESH_KEY,
} from '../utils/localStorage';
import { showAlert } from '../actions/globalActions';
import { initializeRoute } from '../actions/initActions';
import { spinnerIncrement, spinnerDecrement } from '../actions/spinnerActions';
import { getTokenData, getTimeUntilTokenExpired, getTokenFromResponse } from '../utils/token';
import { buildErrorMessage } from '../utils/apiCaller';
import { SIGNUP_FLOW_ROUTES } from '../constants';
import browserHistory from '../history';
import { randomIntFromInterval } from '../utils/dataGeneratorUtils';
import ENV_VARS from '../utils/envVars';

import auth from '../data/auth.json';
import { RESET_PASSWORD_REQUEST } from '../actions/userActions';

const { test_accounts } = auth;

export default function* authSagas() {
  yield all([
    authorizeBasicLoginSaga(),
    authenticationSaga(),
    authFailureSaga(),
    changePasswordSaga(),
    sendResetPasswordSaga(),
    resetPasswordSaga(),
    authLogoutSaga(),
    getStreamTokenSaga(),
    upsertStreamUsersSaga(),
    switchTeamsSaga(),
  ]);
}

function* authorizeBasicLoginSaga() {
  while (true) {
    try {
      const { values } = yield take(AUTHORIZE_REQUEST);
      // const { values } = yield take(AUTH_LOGIN_REQUEST);
      const { email, password, team_id } = values;
      yield call(setLocalStoreValue, INIT_EMAIL_KEY, email);

      // dispatch api call action for login
      yield put(authorize(email, password, team_id));
      // yield put(authUserBasic(email, password));
      const { success, failure } = yield race({
        success: take(AUTHORIZE_SUCCESS),
        failure: take(AUTHORIZE_FAILURE),
        // success: take(AUTH_BASIC_SUCCESS),
        // failure: take(AUTH_BASIC_FAILURE),
      });

      if (success) {
        const {
          token,
          refresh,
          password_update_required,
          user_first_name,
          teams_list,
        } = getTokenFromResponse(success.data);

        if (teams_list && teams_list.length > 1) {
          yield put(setCredentials(user_first_name, email, password, teams_list));
          browserHistory.push('/selectteam');
        } else {
          // get the sub (user_id) out of the token
          const decoded = yield call(getTokenData, token);
          let user_id;
          if (decoded && decoded.sub) {
            user_id = decoded.sub;
            const { role, team_id } = decoded;
            yield put(authUserIdRetrieved(user_id, role, team_id));
          }

          //  Set the two tokens in local storage
          yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
          yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);

          yield put(passwordUpdateRequired(password_update_required));

          // get profile for admin user
          yield call(getProfileSaga, user_id);
          if (password_update_required) {
            browserHistory.push('/resetpassword');
          } else {
            yield put(authTokenStored(token));
            yield put(userIsLoggedIn());
          }
          yield put(authLoginSuccess(token, email));
        }
      } else {
        yield put(authLoginFailure(failure));
      }
    } catch (e) {
      yield put(authLoginFailure(e));
    }
  }
}

function* getProfileSaga(user_id) {
  yield put(getSparseUserProfile(user_id));
  const { profileSuccess, profileFailure } = yield race({
    profileSuccess: take(AUTH_USER_PROFILE_SUCCESS),
    profileFailure: take(AUTH_USER_PROFILE_FAILURE),
  });
  if (profileFailure) {
    // couldn't find the user_id. Make sure we didn't just have a race condition
    // where a refresh failure occurred.
    const token = yield call(getLocalStoreValue, JWT_TOKEN_KEY);
    const decoded = yield call(getTokenData, token);
    if (decoded && decoded.sub) {
      yield put(showAlert('Oops!', buildErrorMessage(profileFailure), 'error'));
    } else {
      // the jwt no longer exists.
    }
  }
  return true;
}

function* authorizeRefreshLoginSaga(tokenRefresh) {
  try {
    // api call to refresh token
    yield put(authUserRefresh(tokenRefresh));
    const { success, failure } = yield race({
      success: take(AUTH_REFRESH_SUCCESS),
      failure: take(AUTH_REFRESH_FAILURE),
    });
    if (success) {
      const { data } = success;

      // store token if returned
      const { token, refresh, password_update_required, teams_list } = getTokenFromResponse(data);

      if (teams_list && teams_list.length > 1) {
        yield put(setTeamsList(teams_list));
      }

      yield put(passwordUpdateRequired(password_update_required));

      //  Set the two tokens in local storage
      yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
      yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);

      if (password_update_required) {
        browserHistory.push('/resetpassword');
      }
      return token;
    }
    yield put(authLoginFailure(failure, true));
  } catch (e) {
    yield put(authLoginFailure(e));
  }
  return null;
}

function* changePasswordSaga() {
  while (true) {
    const { values } = yield take(CHANGE_PASSWORD);
    yield put(changeUserPassword(values));
    const { success, failure } = yield race({
      success: take(PASSWORD_CHANGE_SUCCESS),
      failure: take(PASSWORD_CHANGE_FAILURE),
    });
    if (success) {
      yield put(showAlert('Success', 'Password changed!', 'success'));
    } else {
      yield put(showAlert('Oops!', 'Change password failed. Please try again!'));
    }
  }
}

function* sendResetPasswordSaga() {
  while (true) {
    const { values } = yield take(SEND_RESET_PASSWORD);
    const { email } = values;
    yield call(setLocalStoreValue, INIT_EMAIL_KEY, email);
    yield put(sendResetPassword({ username: email }));
    const { success, failure } = yield race({
      success: take(SEND_RESET_PASSWORD_SUCCESS),
      failure: take(SEND_RESET_PASSWORD_FAILURE),
    });
    if (success) {
      yield put(
        showAlert(
          'Sent!',
          `Password reset instructions have been sent to ${email}.`,
          'success',
          '/login'
        )
      );
    } else {
      yield put(showAlert('Oops!', 'Something went wrong. Please try again.', 'error'));
    }
  }
}

function* resetPasswordSaga() {
  while (true) {
    const { values } = yield take(RESET_PASSWORD);
    const { user_id, username, password } = values;
    yield put(changeUserPassword({ user_id, new_password: password }));
    const { success, failure } = yield race({
      success: take(PASSWORD_CHANGE_SUCCESS),
      failure: take(PASSWORD_CHANGE_FAILURE),
    });
    if (success) {
      // const state = yield select();
      // yield put(authLoginRequest({email: username, password}))
      yield put(
        showAlert(
          'Success',
          'Password changed!',
          'success',
          undefined,
          authLoginRequest({ email: username, password })
        )
      );
    } else {
      yield put(showAlert('Oops!', 'Something went wrong. Please try again.', 'error'));
    }
  }
}

function* refreshTokenSaga() {
  // When entered first time, get time left until existing token expires
  const jwtToken = yield call(getLocalStoreValue, JWT_TOKEN_KEY);
  const timeLeft = getTimeUntilTokenExpired(jwtToken);
  if (timeLeft > 60) {
    // if token is more than a minute til expired
    yield put(authTokenStored(jwtToken));
    // set timer to wake up at token expire
    yield race({
      delayed: delay(timeLeft),
      forced: take(AUTH_REFRESH_TOKEN),
    });
  }

  while (true) {
    yield call(fetchNewTokenSaga);
    const newToken = yield call(getLocalStoreValue, JWT_TOKEN_KEY);
    // get time to wake up at token expire
    let delayTime = 60;
    if (newToken) {
      delayTime = getTimeUntilTokenExpired(newToken);
      yield put(authTokenStored(newToken));
    }

    // take first action - timer fires, or manual refresh cmd
    yield race({
      delay: delay(delayTime),
      manual: take(AUTH_REFRESH_TOKEN),
    });
  }
}

function* fetchNewTokenSaga() {
  // retrieve refresh token - exit if absent
  const tokenRefresh = yield call(getLocalStoreValue, JWT_REFRESH_KEY);
  if (!tokenRefresh) {
    return;
  }

  // make refresh API call get new token - exit if error
  const newToken = yield call(authorizeRefreshLoginSaga, tokenRefresh);
  if (newToken == null) {
    yield call(removeLocalStoreValues);
    return;
  }

  // put new token in local storage
  yield call(setLocalStoreValue, JWT_TOKEN_KEY, newToken);
}

function* authenticationSaga() {
  // the kick-off to the authentication process
  // when app starts, get refresh token if exists
  const { username } = yield take(BEGIN_AUTHENTICATION);
  let tokenForRefresh = yield call(getLocalStoreValue, JWT_REFRESH_KEY);

  // see what URL the user is trying to hit and save that to redirect them to
  // after successfully logging them in
  const { location } = browserHistory;
  if (!SIGNUP_FLOW_ROUTES.includes(location.pathname.toLowerCase())) {
    if (location.pathname !== '/') {
      yield put(initializeRoute(location.pathname));
    }

    while (true) {
      if (!tokenForRefresh) {
        // if we don't have a refresh token in store wait for a login and get token
        yield put(authLogout());
        yield take(AUTH_LOGIN_SUCCESS);
        tokenForRefresh = yield call(getLocalStoreValue, JWT_REFRESH_KEY);
      } else {
        // refresh token
        yield call(fetchNewTokenSaga);
        // get user_id out of token
        const newToken = yield call(getLocalStoreValue, JWT_TOKEN_KEY);
        let user_id;
        if (newToken) {
          const decoded = yield call(getTokenData, newToken);
          if (decoded && decoded.sub) {
            user_id = decoded.sub;
            const { role, team_id } = decoded;
            yield put(authUserIdRetrieved(user_id, role, team_id));
          }
          yield call(getProfileSaga, user_id);
          yield put(authTokenStored(newToken));
          yield put(userIsLoggedIn());
          yield put(authLoginSuccess(newToken, username));
        }
      }

      // Logged in - wait for either logout or failed refresh
      const { logOutAction } = yield race({
        logOutAction: take(AUTH_LOGOUT),
        refreshLoop: call(refreshTokenSaga),
      });

      // If logged out - kill the tokens in local store
      if (logOutAction) {
        tokenForRefresh = null;
        yield call(removeLocalStoreValues);
      }
    }
  }
}

function* authFailureSaga() {
  while (true) {
    const { error, fromRefresh } = yield take(AUTH_LOGIN_FAILURE);
    if (!fromRefresh) {
      if (
        error.error &&
        error.error.response &&
        error.error.response.data &&
        error.error.response.data.error &&
        (error.error.response.data.error === 'not_found' ||
          error.error.response.data.error === 'unauthorized')
      ) {
        // browserHistory.push('/signup/app');
        const d = document.createElement('div');
        d.innerHTML = `We don't recognize this email and password combination. Please try again or <a href="/signup">sign up</a> for a ${ENV_VARS.TRIAL_DAYS}-day FREE trial!`;
        yield put(showAlert('Oops!', '', 'error', undefined, undefined, true, undefined, d));
      } else {
        yield put(showAlert('Oops!', buildErrorMessage(error), 'error'));
      }
    } else {
      yield put(authLogout());
    }
  }
}

function* authLogoutSaga() {
  while (true) {
    const { location } = browserHistory;
    const { redirect = true, email, password, team_id } = yield take(AUTH_LOGOUT);
    yield call(removeLocalStoreValues);
    // if (redirect && location.pathname.toLowerCase() !== '/login') {
    if (
      email !== undefined &&
      email.length > 0 &&
      password !== undefined &&
      password.length > 0 &&
      team_id !== undefined &&
      team_id.length > 0
    ) {
      yield put(authorizeRequest({ email, password, team_id }));
    } else {
      if (redirect) {
        browserHistory.push('/login');
      }
    }
  }
}

function* getStreamTokenSaga() {
  while (true) {
    const { user_ids } = yield take(GET_STREAM_TOKEN);
    yield put(getStreamToken(user_ids));
    const { success, failure } = yield race({
      success: take(GET_STREAM_TOKEN_SUCCESS),
      failure: take(GET_STREAM_TOKEN_FAILURE),
    });
    if (success) {
      yield put(putStreamToken(success.data.tokens));
    } else {
      yield put(showAlert('Oops!', 'Something went wrong. Please try again.', 'error'));
    }
  }
}

function* upsertStreamUsersSaga() {
  while (true) {
    const { users } = yield take(UPSERT_STREAM_USERS);
    yield put(upsertStreamUsers(users));
    const { success, failure } = yield race({
      success: take(UPSERT_STREAM_USERS_SUCCESS),
      failure: take(UPSERT_STREAM_USERS_FAILURE),
    });
    if (success) {
      yield put(putStreamToken(success.data.tokens));
    } else {
      yield put(showAlert('Oops!', 'Something went wrong. Please try again.', 'error'));
    }
  }
}

function* switchTeamsSaga() {
  while (true) {
    try {
      const { team_id } = yield take(SWITCH_TEAMS);
      yield put(switchTeams(team_id));
      const { success, failure } = yield race({
        success: take(SWITCH_TEAMS_SUCCESS),
        failure: take(SWITCH_TEAMS_SUCCESS),
      });

      if (success) {
        yield call(removeLocalStoreValues);
        const {
          token,
          refresh,
          password_update_required,
          teams_list,
        } = getTokenFromResponse(success.data);

        if (teams_list && teams_list.length > 1) {
          yield put(setTeamsList(teams_list));
        }

        const decoded = yield call(getTokenData, token);
        let user_id;
        if (decoded && decoded.sub) {
          user_id = decoded.sub;
          const { role, team_id } = decoded;
          yield put(authUserIdRetrieved(user_id, role, team_id));
        }

        yield put(passwordUpdateRequired(password_update_required));

        //  Set the two tokens in local storage
        yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
        yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);

        if (password_update_required) {
          browserHistory.push('/resetpassword');
        }
        browserHistory.push(`/clients?x=${randomIntFromInterval(9999, 9999999)}`);
        // location.reload();
      }
    } catch (e) {
      yield put(authLoginFailure(e));
    }
  }
}
