import { take, all, put, call, race, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { authUserIdRetrieved, authTokenStored, authorizeRequest } from '../actions/authActions';
import {
  SIGN_UP,
  SIGN_UP_BUSINESS,
  SIGN_UP_PLAN,
  SIGN_UP_BILLING,
  SIGN_UP_COMPLETE,
} from '../actions/signupActions';
import {
  createAccount,
  membershipOptions,
  businessTypes,
  createTeam,
  createOnboaringEvent,
  createMembership,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_FAILURE,
} from '../apiActions/signupApiActions';
import { showAlert } from '../actions/globalActions';
import browserHistory from '../history';
import {
  setLocalStoreValue,
  getLocalStoreValue,
  removeLocalStoreValues,
  JWT_TOKEN_KEY,
  JWT_REFRESH_KEY,
  USER_ID_KEY,
} from '../utils/localStorage';
import { getTokenFromResponse, getTokenData } from '../utils/token';
import { buildErrorMessage } from '../utils/apiCaller';
import ENV_VARS from '../utils/envVars';
import trackers from '../trackers';

export default function* authSagas() {
  yield all([
    signUpSaga(),
    signUpBusinessSaga(),
    signUpPlanSaga(),
    signUpBillingSaga(),
    signUpCompleteSaga(),
  ]);
}

function* signUpSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP);
    values.timezone = moment.tz.guess();
    yield put(createAccount(values));
    const { success, failure } = yield race({
      success: take(CREATE_ACCOUNT_SUCCESS),
      failure: take(CREATE_ACCOUNT_FAILURE),
    });

    if (success) {
      const { token, refresh } = getTokenFromResponse(success.data);

      const decoded = yield call(getTokenData, token);
      let user_id;
      if (decoded && decoded.sub) {
        user_id = decoded.sub;
        // yield put(authUserIdRetrieved(user_id));
        setLocalStoreValue(USER_ID_KEY, user_id);
        yield put(authTokenStored(token));
      }

      //  Set the two tokens in local storage
      yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
      yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);

      // prefetch some data for later
      yield put(membershipOptions());
      yield put(businessTypes());

      const state = yield select();
      const { signup } = state;
      trackers.updateTracking('business', { ...signup, ...values });
      browserHistory.push('/signup/business');
    } else {
      if (
        failure.error &&
        failure.error.response &&
        failure.error.response.data &&
        failure.error.response.data.error &&
        (failure.error.response.data.error === 'existing_user' ||
          failure.error.response.data.error === 'active_membership' ||
          failure.error.response.data.error === 'active_owner' ||
          failure.error.response.data.error === 'unauthorized')
      ) {
        // browserHistory.push('/signup/app');
        const d = document.createElement('div');
        if (failure.error.response.data.error === 'active_owner') {
          d.innerHTML = `It looks like you already have an active Macrostax Team subscription!<br /><br />Please <a href="/login">log in</a> to continue or, if you need support, chat directly with our team via the <a href="${ENV_VARS.STAXCHAT_DEEP_LINK}" target="_blank">StaxChat messenger</a> on our website.`;
        } else {
          d.innerHTML = `It looks like you already have an active Macrostax subscription!<br /><br />Please reach out to our team at <a href="mailto:support@macrostax.com">support@macrostax.com</a> or chat directly with our team via the <a href="${ENV_VARS.STAXCHAT_DEEP_LINK}" target="_blank">StaxChat messenger</a> on our website.`;
        }
        yield put(showAlert('Oops!', '', 'error', undefined, undefined, true, undefined, d));
      } else {
        yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
      }
    }
  }
}

function* signUpBusinessSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_BUSINESS);
    if (values.business_type === 'Other') {
      values.business_type = values.other;
      delete values.other;
    }
    if ((values.preferred_contact_method === undefined || values.preferred_contact_method === null) || (values.phone === undefined || values.phone === null || values.phone === '')) {
      values.preferred_contact_method = 'email';
    }
    yield put(createTeam(values));
    const { success, failure } = yield race({
      success: take(CREATE_TEAM_SUCCESS),
      failure: take(CREATE_TEAM_FAILURE),
    });

    if (success) {
      const { token, refresh } = getTokenFromResponse(success.data);

      const decoded = yield call(getTokenData, token);
      let user_id;
      if (decoded && decoded.sub) {
        user_id = decoded.sub;
        setLocalStoreValue(USER_ID_KEY, user_id);
        yield put(authTokenStored(token));
      }

      //  Set the two tokens in local storage
      yield call(setLocalStoreValue, JWT_TOKEN_KEY, token);
      yield call(setLocalStoreValue, JWT_REFRESH_KEY, refresh);

      const state = yield select();
      const { signup } = state;
      trackers.updateTracking('plans', { ...signup, ...values });
      browserHistory.push('/signup/plans');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpPlanSaga() {
  while (true) {
    const { planId } = yield take(SIGN_UP_PLAN);
    yield put(createOnboaringEvent("team_product_selected"));
    const state = yield select();
    const { signup } = state;
    trackers.updateTracking('billing', signup);
    browserHistory.push('/signup/billing');
  }
}

function* signUpBillingSaga() {
  while (true) {
    const { values } = yield take(SIGN_UP_BILLING);
    let state = yield select();
    yield put(
      createMembership({
        product_id: state.signup.planId,
        payment_method_id: values.id,
      })
    );
    const { success, failure } = yield race({
      success: take(CREATE_MEMBERSHIP_SUCCESS),
      failure: take(CREATE_MEMBERSHIP_FAILURE),
    });
    if (success) {
      state = yield select();
      trackers.checkout(state.signup);
      browserHistory.push('/signup/complete');
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* signUpCompleteSaga() {
  while (true) {
    yield take(SIGN_UP_COMPLETE);
    // simulate a login
    const state = yield select();
    const values = {
      onboarding: true,
      email: state.signup.email,
      password: state.signup.password,
    };
    yield put(authorizeRequest(values));
  }
}
