export const PRINT_RECIPE = 'PRINT_RECIPE';
export const CLEAR_RECIPE = 'CLEAR_RECIPE';

export function printRecipe(recipe_id) {
  return {
    type: PRINT_RECIPE,
    recipe_id,
  };
}
export function clearRecipe() {
  return {
    type: CLEAR_RECIPE,
  };
}
