import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import UserInfoFormPartial from './partials/UserInfoFormPartial';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.trim().length < 8) {
    errors.password = 'Password must be at least 8 characters long';
  }
  return errors;
};

class SignUpForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError } = this.props;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <UserInfoFormPartial showPassword={true} />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex">
                  <Button color="primary" size="lg" type="submit" block>
                    Next
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default SignUpForm;
