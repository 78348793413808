import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import Loading from '../components/Common/Loading';
import MacrostaxLinksFormPartial from './partials/MacrostaxLinksFormPartial';
import { submissionError } from '../actions/formActions';
import { addBodyFat, updateBodyFat } from '../actions/userActions';
import { addHiddenFeature, deleteHiddenFeature } from '../actions/teamActions';

const validate = () => {
  const errors = {};

  return errors;
};

class ContentForm extends Component {
  render() {
    const {
      showSubmissionError,
      hiddenFeatures,
      initialValues,
      onSubmit,
      team,
      doAddHiddenFeature,
      doDeleteHiddenFeature,
    } = this.props;
    return (
      <>
        {hiddenFeatures !== undefined && team !== undefined && team.settings !== undefined ? (
          <Form
            onSubmit={(values) => {
              const errors = validate(values);
              if (Object.keys(errors).length === 0) {
                onSubmit(values);
              } else {
                showSubmissionError(errors);
              }
            }}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            render={({ handleSubmit, submitting, form, ...props }) => {
              return (
                <form onSubmit={handleSubmit} className="inline-form">
                  <MacrostaxLinksFormPartial
                    hiddenFeatures={hiddenFeatures}
                    team={team}
                    doAddHiddenFeature={doAddHiddenFeature}
                    doDeleteHiddenFeature={doDeleteHiddenFeature}
                  />
                </form>
              );
            }}
          />
        ) : (
          <Loading />
        )}
      </>
    );
  }
}

ContentForm.propTypes = {
  hiddenFeatures: PropTypes.instanceOf(Array),
  showSubmissionError: PropTypes.func,
  team: PropTypes.instanceOf(Object),
  doAddHiddenFeature: PropTypes.func,
  doDeleteHiddenFeature: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
  doAddBodyFat: (id, values) => dispatch(addBodyFat(id, values)),
  doUpdateBodyFat: (id, values) => dispatch(updateBodyFat(id, values)),
  doAddHiddenFeature: (id) => dispatch(addHiddenFeature(id)),
  doDeleteHiddenFeature: (id) => dispatch(deleteHiddenFeature(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentForm);
