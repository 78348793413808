import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ChallengeTable from '../../components/Tables/ChallengeTable';
import { getAllChallenges } from '../../actions/challengeActions';
import browserHistory from '../../history';

class Challenges extends React.Component {
  componentDidMount() {
    const { doGetAllChallenges, team } = this.props;
    doGetAllChallenges(team.id);
  }

  goToChallengeDetail = (challenge) => {
    browserHistory.push(`/challenges/${challenge.id}`);
  };

  render() {
    const { challenges, user, team } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Challenges" />
          </Col>
        </Row>
        {user && team && challenges ? (
          <div className="main-content-container">
            <Row>
              <Col xs={12} className="challenge-table-container">
                <Button color="primary" size="lg" onClick={() => browserHistory.push('/addchallenge')} className="mb-4">
                  Add a Challenge
                </Button>
                <ChallengeTable
                  challenges={challenges}
                  title="All Challenges"
                  onRowClick={(e, rowData) => this.goToChallengeDetail(rowData)}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

Challenges.propTypes = {
  doGetAllChallenges: PropTypes.func,
  challenges: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  challenges: state.challenges.challenges,
  user: state.auth.user,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  doGetAllChallenges: (team_id) => {
    dispatch(getAllChallenges(team_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
