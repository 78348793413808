import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm password is required';
  } else {
    if (values.password !== values.confirmPassword) {
      errors.password = 'Your passwords do not match';
    } else {
      if (values.password.trim().length < 8) {
        errors.password = 'Your password must be at least 8 characters long';
      }
    }
  }
  return errors;
};

class ResetPasswordForm extends Component {
  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      buttonText = 'Set Password',
    } = this.props;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <Field
                    name="password"
                    label="Password"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'password',
                      },
                    }}
                  />
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'password',
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex ">
                  <Button color="primary" size="lg" type="submit" block>
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  buttonText: PropTypes.string,
};

export default ResetPasswordForm;
