import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import { getAllTeamBranding } from '../../actions/teamActions';
import browserHistory from '../../history';

class TeamBranding extends React.Component {
  componentDidMount() {
    this.props.doGetAllTeamBranding();
  }

  goToTeamDetail = (team_id) => {
    browserHistory.push(`/teams/${team_id}`);
  };

  render() {
    const { allTeamBranding } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Team Branding" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row>
            <Col xs={12}>
              {allTeamBranding && allTeamBranding.length ? (
                <Row>
                  {allTeamBranding.map((t) => (
                    <Col key={t.team_id} onClick={() => this.goToTeamDetail(t.team_id)} md={6}>
                      {t.team_name}
                      <div
                        className="logo-image-container"
                        style={{ backgroundColor: t.primary_background_color }}
                      >
                        <img src={t.primary_logo_path} className="logo-img" />
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Loading />
              )}
            </Col>
          </Row>
        </div>
      </ContentWrapper>
    );
  }
}

TeamBranding.propTypes = {
  allTeamBranding: PropTypes.instanceOf(Array),
  doGetAllTeamBranding: PropTypes.func,
};
const mapStateToProps = (state) => ({
  allTeamBranding: state.context.allTeamBranding,
});
const mapDispatchToProps = (dispatch) => ({
  doGetAllTeamBranding: () => dispatch(getAllTeamBranding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamBranding);
