export const GET_TEAM = 'GET_TEAM';
export const GET_TEAM_PROFILE = 'GET_TEAM_PROFILE';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const REMOVE_MEMBERSHIPS = 'REMOVE_MEMBERSHIPS';
export const REMOVE_ADMINS = 'REMOVE_ADMINS';
export const CHANGE_PLAN = 'CHANGE_PLAN';
export const CANCEL_PLAN = 'CANCEL_PLAN';
export const SUBMIT_TEAM_SEARCH_REQUEST = 'SUBMIT_TEAM_SEARCH_REQUEST';
export const CLEAR_TEAM_SEARCH_FORM = 'CLEAR_TEAM_SEARCH_FORM';
export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
export const ADD_CLIENTS = 'ADD_CLIENTS';
export const ADD_ADMINS = 'ADD_ADMINS';
export const SEND_INVITATION = 'SEND_INVITATION';
export const SEND_INVITATIONS = 'SEND_INVITATIONS';
export const GET_PENDING_CLIENTS = 'GET_PENDING_CLIENTS';
export const CLEAR_PENDING_CLIENTS = 'CLEAR_PENDING_CLIENTS';
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ADMINS = 'GET_ADMINS';
export const GET_TEAM_MEMBERSHIP = 'GET_TEAM_MEMBERSHIP';
export const UPDATE_TEAM_PAYMENT_PROFILE = 'UPDATE_TEAM_PAYMENT_PROFILE';
export const REACTIVATE_MEMBERSHIP = 'REACTIVATE_MEMBERSHIP';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_ENGAGEMENT_SUMMARY = 'GET_ENGAGEMENT_SUMMARY';
export const GET_ENGAGEMENT_SUMMARY_CSV = 'GET_ENGAGEMENT_SUMMARY_CSV';
export const CLEAR_ENGAGEMENT_SUMMARY_CSV = 'CLEAR_ENGAGEMENT_SUMMARY_CSV';
export const GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION = 'GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION';
export const GET_ALL_TEAM_BRANDING = 'GET_ALL_TEAM_BRANDING_FAILURE';
export const GET_CUSTOM_FEATURES = 'GET_CUSTOM_FEATURES';
export const GET_HIDDEN_FEATURES = 'GET_HIDDEN_FEATURES';
export const ADD_CUSTOM_FEATURE = 'ADD_CUSTOM_FEATURE';
export const ARCHIVE_CUSTOM_FEATURE = 'ARCHIVE_CUSTOM_FEATURE';
export const UPDATE_CUSTOM_FEATURE = 'UPDATE_CUSTOM_FEATURE';
export const ADD_HIDDEN_FEATURE = 'ADD_HIDDEN_FEATURE';
export const DELETE_HIDDEN_FEATURE = 'DELETE_HIDDEN_FEATURE';
export const SUBMIT_CLIENTS_SEARCH_REQUEST = 'SUBMIT_CLIENTS_SEARCH_REQUEST';
export const CLEAR_CLIENTS_SEARCH_FORM = 'CLEAR_CLIENTS_SEARCH_FORM';

export function getTeam(team_id) {
  return {
    type: GET_TEAM,
    team_id,
  };
}

export function getAllTeamBranding() {
  return {
    type: GET_ALL_TEAM_BRANDING,
  };
}

export function getDashboard(team_id, start_date, end_date) {
  return {
    type: GET_DASHBOARD,
    team_id,
    start_date,
    end_date,
  };
}

export function getClientStatusAndGoalDistribution(team_id) {
  return {
    type: GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION,
    team_id,
  };
}

export function getEngagementSummary(
  team_id,
  start_date,
  end_date,
  sort_by,
  order,
  page,
  show_count,
  clearData = true,
  group_id,
  user_role_status
) {
  return {
    type: GET_ENGAGEMENT_SUMMARY,
    team_id,
    start_date,
    end_date,
    sort_by,
    order,
    page,
    show_count,
    clearData,
    group_id,
    user_role_status
  };
}

export function clearEngagementSummaryCsv() {
  return {
    type: CLEAR_ENGAGEMENT_SUMMARY_CSV,
  };
}

export function getEngagementSummaryCsv(team_id, start_date, end_date) {
  return {
    type: GET_ENGAGEMENT_SUMMARY_CSV,
    team_id,
    start_date,
    end_date,
  };
}

export function getTeamProfile(team_id) {
  return {
    type: GET_TEAM_PROFILE,
    team_id,
  };
}

export function getAllClients(team_id) {
  return {
    type: GET_ALL_CLIENTS,
    team_id,
  };
}

export function updateTeam(id, payload) {
  return {
    type: UPDATE_TEAM,
    id,
    payload,
  };
}

export function removeMemberships(users, isInternalAdmin = false, team_id, func) {
  return {
    type: REMOVE_MEMBERSHIPS,
    users,
    isInternalAdmin,
    team_id,
    func,
  };
}

export function removeAdmins(users, team_id) {
  return {
    type: REMOVE_ADMINS,
    users,
    team_id,
  };
}

export function updateCreditCard(id, values) {
  return {
    type: UPDATE_CREDIT_CARD,
    id,
    values,
  };
}

export function updateTeamPaymentProfile(team_id, membership_id, id) {
  return {
    type: UPDATE_TEAM_PAYMENT_PROFILE,
    team_id,
    membership_id,
    id,
  };
}

export function changePlan(team_id, membership_id, product_id) {
  return {
    type: CHANGE_PLAN,
    team_id,
    membership_id,
    product_id,
  };
}

export function cancelPlan(team_id, membership_id) {
  return {
    type: CANCEL_PLAN,
    team_id,
    membership_id,
  };
}

export function submitTeamSearchRequest(values) {
  return {
    type: SUBMIT_TEAM_SEARCH_REQUEST,
    values,
  };
}

export function clearTeamSearchForm() {
  return {
    type: CLEAR_TEAM_SEARCH_FORM,
  };
}

export function addClients(values, func) {
  return {
    type: ADD_CLIENTS,
    values,
    func,
  };
}

export function addAdmins(values, func) {
  return {
    type: ADD_ADMINS,
    values,
    func,
  };
}

export function sendInvitations(values, impersonate_team_owner = false, team_id, func) {
  return {
    type: SEND_INVITATIONS,
    values,
    impersonate_team_owner,
    team_id,
    func,
  };
}

export function sendInvitation(user) {
  return {
    type: SEND_INVITATION,
    user,
  };
}

export function getPendingClients(team_id) {
  return {
    type: GET_PENDING_CLIENTS,
    team_id,
  };
}

export function getAdmins(team_id) {
  return {
    type: GET_ADMINS,
    team_id,
  };
}

export function clearPendingClients() {
  return {
    type: CLEAR_PENDING_CLIENTS,
  };
}

export function submitFeedback(values) {
  return {
    type: SUBMIT_FEEDBACK,
    values,
  };
}

export function getTeamMembership(team_id) {
  return {
    type: GET_TEAM_MEMBERSHIP,
    team_id,
  };
}

export function reactivateMembership(team_id, membership_id) {
  return {
    type: REACTIVATE_MEMBERSHIP,
    team_id,
    membership_id,
  };
}

export function getCustomFeatures() {
  return {
    type: GET_CUSTOM_FEATURES,
  };
}

export function getHiddenFeatures() {
  return {
    type: GET_HIDDEN_FEATURES,
  };
}

export function addCustomFeature(params) {
  return {
    type: ADD_CUSTOM_FEATURE,
    params,
  };
}

export function updateCustomFeature(params) {
  return {
    type: UPDATE_CUSTOM_FEATURE,
    params,
  };
}

export function archiveCustomFeature(id) {
  return {
    type: ARCHIVE_CUSTOM_FEATURE,
    id,
  };
}

export function addHiddenFeature(id) {
  return {
    type: ADD_HIDDEN_FEATURE,
    id,
  };
}

export function deleteHiddenFeature(id) {
  return {
    type: DELETE_HIDDEN_FEATURE,
    id,
  };
}

export function submitPendingClientsSearchRequest(values) {
    return {
        type: SUBMIT_CLIENTS_SEARCH_REQUEST,
        values,
    };
}

export function clearClientsSearchForm() {
    return {
        type: CLEAR_CLIENTS_SEARCH_FORM,
    };
}
