import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import { submissionError } from '../actions/formActions';
import { setPasswordForUser } from '../actions/userActions';

class SetPasswordModal extends Component {
  render() {
    const { isOpen, toggle, doSetPasswordForUser, showSubmissionError, user } = this.props;
    const { user_id } = user;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/setPasswordForUser', title: 'Set Password for User' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} onClosed={this.resetState} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle title="Reset Password" showTeam={false} icon="fa-1x fa fa-unlock-alt" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <div className="mb-4">Set a new password for {user.first_name} {user.last_name}:</div>
              <ChangePasswordForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => {
                  doSetPasswordForUser(user_id, values);
                  toggle();
                }}
                onCancel={toggle}
                showOldPassword={false}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

SetPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  doSetPasswordForUser: PropTypes.func,
  showSubmissionError: PropTypes.func,
  user: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doSetPasswordForUser: (user_id, values) => dispatch(setPasswordForUser(user_id, values)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordModal);
