import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import MaterialTable, { MTableToolbar } from 'material-table';
import tableIcons from '../utils/muiIcons';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import CardIcon from '../components/Cards/CardIcon';
import Pill from '../components/Common/Pill';
import { capitalizeFirst } from '../utils/stringUtils';
import {
  getClassNameForSubscriptionStatus,
  getFriendlyNameForUserStatus,
} from '../utils/subscriptionUtils';
import RemoveMembershipModal from '../modals/RemoveMembershipModal';
import { rolesById } from '../enums/roles';

const validate = (values) => {
  // TODO
  return {};
};

const TEAM_OWNER_ROLE_ID = '429f0258-f50d-424e-a017-afa0fe69efa7';

class AdminsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: 'All',
      removeMembershipModalOpen: false,
      usersToRemove: [],
    };
  }

  selectAllOrNone = (form, override) => {
    const { select } = this.state;
    const { users } = this.props;
    const checked = override !== undefined ? override : select === 'All';
    for (let i = 0; i < users.length; i += 1) {
      if (users[i].role_id !== TEAM_OWNER_ROLE_ID) {
        form.change(`user_${users[i].id}`, checked);
      }
    }
    setTimeout(() => {
      this.setState({
        select: select === 'All' ? 'None' : 'All',
      });
    });
  };

  checkValues = (value, values, id) => {
    if (!value) {
      this.setState({
        select: 'All',
      });
    } else {
      values[`user_${id}`] = true;
      const { users } = this.props;
      let selected = 0;
      for (let i = 0; i < users.length; i += 1) {
        if (values[`user_${users[i].id}`]) {
          selected += 1;
        }
      }
      this.setState({
        select: selected === users.length ? 'None' : 'All',
      });
    }
  };

  removeClients = (values) => {
    const { users, showSubmissionError } = this.props;
    const usersToRemove = [];
    for (let i = 0; i < users.length; i += 1) {
      if (values[`user_${users[i].id}`] !== undefined && values[`user_${users[i].id}`] === true) {
        usersToRemove.push(users[i]);
      }
    }
    if (usersToRemove.length === 0) {
      showSubmissionError({ users: 'You must select at least one admin to remove' });
    } else {
      this.setState(
        {
          usersToRemove,
        },
        () => {
          this.toggleRemoveMembershipModal();
        }
      );
    }
  };

  toggleRemoveMembershipModal = () => {
    const { removeMembershipModalOpen } = this.state;
    this.setState({
      removeMembershipModalOpen: !removeMembershipModalOpen,
    });
  };

  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      team,
      isInternalAdmin,
      toggleTab,
      onRowClick,
      users,
      currentUser,
    } = this.props;
    const { select, removeMembershipModalOpen, usersToRemove } = this.state;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row className="mt-3">
                <Col xs={12}>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Select',
                        editable: 'never',
                        render: (rowData) => (
                          <div className="d-flex align-items-center checkbox-row">
                            <Field
                              name={`user_${rowData.id}`}
                              component={renderCheckboxForField}
                              {...{
                                itemProps: {},
                                checkboxIcon: 'fa-check',
                                checkboxIconUnchecked: 'fa-close',
                                inputProps: {
                                  disabled:
                                    rowData.role_id === TEAM_OWNER_ROLE_ID ||
                                    currentUser.user_id === rowData.id,
                                },
                              }}
                            />
                            <OnChange name={`user_${rowData.id}`}>
                              {(value) => {
                                this.checkValues(value, values, rowData.id);
                              }}
                            </OnChange>
                          </div>
                        ),
                        sorting: false,
                        width: null,
                        cellStyle: {
                          width: 100,
                          maxWidth: 100,
                        },
                      },
                      { title: 'Last Name', field: 'last_name', defaultSort: 'asc' },
                      { title: 'First Name', field: 'first_name' },
                      {
                        title: 'Role',
                        field: 'role_id',
                        render: (rowData) => rolesById[rowData.role_id],
                      },
                      {
                        title: 'Admin Status',
                        field: 'user_role_status',
                        render: (rowData) => (
                          <Pill
                            title={getFriendlyNameForUserStatus(rowData.user_role_status)}
                            className={getClassNameForSubscriptionStatus(rowData.user_role_status)}
                          />
                        ),
                      },
                    ]}
                    data={users}
                    title="Manage Admins"
                    key={users.length} // https://github.com/mbrn/material-table/issues/1480
                    options={{
                      padding: 'dense',
                      pageSize: 25,
                      search: true,
                      paging: users.length > 25,
                      sorting: true,
                      thirdSortClick: false,
                      showTitle: true,
                      toolbar: true,
                      pageSizeOptions: [25, 50, 100],
                      debounceInterval: 600,
                    }}
                    style={{
                      backgroundColor: '#f7f7f7',
                    }}
                    icons={tableIcons}
                    onSearchChange={(x) => {
                      if (x !== '') {
                        const searchBar = document.querySelector('#mt-toolbar-container input');
                        if (!searchBar) return;
                        setTimeout(() => searchBar.focus());
                      }
                    }}
                    onRowClick={(e, rowData) => {
                      if (
                        e.target instanceof HTMLInputElement ||
                        e.target instanceof HTMLSpanElement
                      ) {
                        e.stopPropagation();
                      } else {
                        onRowClick(rowData);
                      }
                    }}
                    components={{
                      Toolbar: (props) => (
                        <div id="mt-toolbar-container">
                          <MTableToolbar {...props} />
                          <div className="ml-3">
                            <span
                              className="span-link"
                              onClick={() => this.selectAllOrNone(form, values)}
                            >
                              Select {select}
                            </span>
                          </div>
                        </div>
                      ),
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="lg"
                    className="ml-3 fixed-width-xmd"
                    onClick={() => this.removeClients(values)}
                  >
                    Remove Admins
                  </Button>
                  {/* <Button color="primary" size="lg" type="submit" className="ml-3 fixed-width-xmd">
                    Send Invitations
                  </Button> */}
                </Col>
              </Row>
              <RemoveMembershipModal
                users={usersToRemove}
                isOpen={removeMembershipModalOpen}
                toggle={this.toggleRemoveMembershipModal}
                beforeToggle={() => this.selectAllOrNone(form, false)}
                isPending={true}
                team={team}
                isInternalAdmin={isInternalAdmin}
                toggleTab={toggleTab}
                type="adminUser"
              />
            </form>
          )}
        />
      </div>
    );
  }
}

AdminsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  team: PropTypes.instanceOf(Object),
  isInternalAdmin: PropTypes.bool,
  onRowClick: PropTypes.func,
  currentUser: PropTypes.instanceOf(Object),
};

export default AdminsForm;
