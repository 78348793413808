import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import UserTable from '../../components/Tables/UserTable';
import UserSearchForm from '../../forms/UserSearchForm';
import { submissionError } from '../../actions/formActions';
import { submitUserSearchRequest, clearUserSearchForm } from '../../actions/userActions';
import browserHistory from '../../history';
import { isTeamUser } from '../../utils/authUtils';

class UserSearch extends React.Component {
  goToUserDetail = (user) => {
    browserHistory.push(`/users/${user.id}`);
  };

  componentDidMount() {
    // this.props.search();
  }

  render() {
    const { showSubmissionError, initialValues, search, reset, users, user, team } = this.props;
    const current_user_roles = user.roles;
    const teamAdmin = isTeamUser(current_user_roles);

    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="User Search" />
          </Col>
        </Row>
        {user && team && team.profile ? (
          <div className="main-content-container">
            <Row className="mt-2">
              <Col xs={12}>
                <UserSearchForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={search}
                  onReset={reset}
                  initialValues={initialValues}
                  teamProfile={team.profile}
                  currentUser={user}
                />
              </Col>
            </Row>
            {users && (
              <Row className="mt-5">
                <Col xs={12}>
                  <UserTable
                    users={users}
                    title="Search Results"
                    isTeamView={teamAdmin}
                    onRowClick={(e, rowData) => this.goToUserDetail(rowData)}
                  />
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

UserSearch.propTypes = {
  showSubmissionError: PropTypes.func,
  initialValues: PropTypes.instanceOf(Object),
  search: PropTypes.func,
  reset: PropTypes.func,
  users: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    ...state.context.userSearchForm,
  },
  users: state.clients.userSearchResults,
  user: state.auth.user,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  search: (values) => {
    dispatch(submitUserSearchRequest(values));
  },
  reset: () => {
    dispatch(clearUserSearchForm());
    // dispatch(submitUserSearchRequest());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
