export const GET_ALL_CHALLENGES = 'GET_ALL_CHALLENGES';
export const GET_CHALLENGE = 'GET_CHALLENGE';
export const GET_ACTIONS = 'GET_ACTIONS';
export const ADD_CHALLENGE = 'ADD_CHALLENGE';
export const GET_LEADERBOARD = 'GET_LEADERBOARD';
export const UPSERT_CHALLENGE_CLIENTS = 'UPSERT_CHALLENGE_CLIENTS';
export const GET_USER_SCORES_FOR_CHALLENGE = 'GET_USER_SCORES_FOR_CHALLENGE';
export const ADD_BONUS_POINTS = 'ADD_BONUS_POINTS';
export const UPDATE_CHALLENGE = 'UPDATE_CHALLENGE';
export const ARCHIVE_CHALLENGE = 'ARCHIVE_CHALLENGE';

export function getAllChallenges(team_id) {
  return {
    type: GET_ALL_CHALLENGES,
    team_id,
  };
}

export function getChallenge(challenge_id) {
  return {
    type: GET_CHALLENGE,
    challenge_id,
  };
}

export function getLeaderboard(challenge_id) {
  return {
    type: GET_LEADERBOARD,
    challenge_id,
  };
}

export function getActions() {
  return {
    type: GET_ACTIONS,
  };
}

export function addChallenge(team_id, values) {
  return {
    type: ADD_CHALLENGE,
    team_id,
    values,
  };
}

export function updateChallenge(values) {
  return {
    type: UPDATE_CHALLENGE,
    values,
  };
}

export function upsertChallengeClients(challenge_id, values) {
  return {
    type: UPSERT_CHALLENGE_CLIENTS,
    challenge_id,
    values,
  };
}

export function getUserScoresForChallenge(user_id, challenge_id) {
  return {
    type: GET_USER_SCORES_FOR_CHALLENGE,
    user_id,
    challenge_id,
  };
}

export function addBonusPoints(user_id, challenge_id, values, toggleModal) {
  return {
    type: ADD_BONUS_POINTS,
    user_id,
    challenge_id,
    values,
    toggleModal,
  };
}

export function archiveChallenge(challenge_id) {
  return {
    type: ARCHIVE_CHALLENGE,
    challenge_id,
  };
}
