import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChallengeClientsForm from '../../forms/ChallengeClientsForm';
import { submissionError } from '../../actions/formActions';
import { upsertChallengeClients } from '../../actions/challengeActions';

class ChallengeClientsContainer extends Component {
  render() {
    const { challenge, clients, showSubmissionError, doUpsertChallengeClients, leaderboard } = this.props;
    const { name, id } = challenge;
    // TODO: use leaderboard to set initial values
    const initialValues = {};
    if (leaderboard) {
      for (let i = 0; i < leaderboard.length; i += 1) {
        const lb = leaderboard[i];
        initialValues[`user_${lb.user_id}`] = true;
      }
    }

    return (
      <div className="challenge-detail-container">
        <Row>
          <Col>
            <ChallengeClientsForm
              initialValues={initialValues}
              clients={clients}
              showSubmissionError={showSubmissionError}
              title={name}
              onSubmit={(values) => {
                doUpsertChallengeClients(id, values);
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ChallengeClientsContainer.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  challenge: PropTypes.instanceOf(Object),
  leaderboard: PropTypes.instanceOf(Array),
  showSubmissionError: PropTypes.func,
  doUpsertChallengeClients: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doUpsertChallengeClients: (challenge_id, values) => dispatch(upsertChallengeClients(challenge_id, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeClientsContainer);
