import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import moment from 'moment-timezone';
import { OnChange } from 'react-final-form-listeners';
import UserInfoFormPartial from './partials/UserInfoFormPartial';
import TimezoneField from './fields/TimezoneField';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderSelectForField from './helpers/renderSelectForField';
import { genderOptions } from '../enums/genders';
import WeightUnitsField from './fields/WeightUnitsField';
import FoodUnitsField from './fields/FoodUnitsField';

import {
  getGoalOptionsFromTeamProfile,
  getPhaseOptions,
  getActivityLevelOptionsFromTeamProfile,
  getDietaryPreferencesOptionsFromTeamProfile,
  getAdminRolesOptionsFromTeamProfile,
} from '../utils/teamProfileUtils';

const validate = () => {
  const errors = {};
  return errors;
};

class UserInfoForm extends Component {
  constructor(props) {
    super(props);
    const { teamProfile, initialValues } = this.props;
    const { goal_id } = initialValues;
    const goalOptions = getGoalOptionsFromTeamProfile(teamProfile);
    const phaseOptions = getPhaseOptions(goal_id, goalOptions);
    const activityLevelOptions = getActivityLevelOptionsFromTeamProfile(teamProfile);
    const dietaryPreferencesOptions = getDietaryPreferencesOptionsFromTeamProfile(
      props.teamProfile
    );
    const adminRolesOptions = getAdminRolesOptionsFromTeamProfile(teamProfile);
    this.state = {
      goalOptions,
      phaseOptions,
      activityLevelOptions,
      dietaryPreferencesOptions,
      adminRolesOptions,
    };
  }

  filterPhaseOptions = (goal_id, form) => {
    const { goalOptions } = this.state;
    const phaseOptions = getPhaseOptions(goal_id, goalOptions);
    this.setState(
      {
        phaseOptions,
      },
      () => {
        form.change('phase_id', phaseOptions[0].value);
      }
    );
  };

  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      teamAdmin,
      consumerTeams = [],
    } = this.props;
    const { goalOptions, activityLevelOptions } = this.state;
    const { weight } = initialValues;
    const hasMacroInputs = weight.length > 0;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting, form, ...props }) => {
            const phaseOptions = getPhaseOptions(props.values.goal_id, this.state.goalOptions);
            return (
              <form onSubmit={handleSubmit} className="inline-form">
                {!hasMacroInputs && (
                  <Row>
                    <Col className="mb-3 caption">
                      Note: this client has not yet entered enough data to calculate macro targets
                      so some fields are not currently available to edit.
                    </Col>
                  </Row>
                )}
                <Row>
                  {!teamAdmin && (
                    <Col md={6}>
                      <UserInfoFormPartial showPhone />
                      <Field
                        name="gender"
                        label="Gender"
                        component={renderSelectForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {
                            options: genderOptions,
                            isClearable: false,
                          },
                        }}
                      />
                      <Field
                        name="birthday"
                        label="Birthdate"
                        component={renderDatepickerForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {
                            maxDate: moment().add(-13, 'years').toDate(),
                          },
                        }}
                      />
                      <TimezoneField />
                      <WeightUnitsField />
                      <FoodUnitsField />
                      {/* <PreferredUnitsField /> */}
                    </Col>
                  )}

                  <Col md={6}>
                    {hasMacroInputs && (
                      <>
                        <Field
                          name="height"
                          label="Height (in)"
                          component={renderInputForField}
                          stackedlabel
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                        <Field
                          name="weight"
                          label="Weight (lbs)"
                          component={renderInputForField}
                          stackedlabel
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                        <Field
                          name="goal_weight"
                          label="Goal Weight"
                          component={renderInputForField}
                          stackedlabel
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                        {consumerTeams.length <= 1 || teamAdmin ? (
                          <>
                            <Field
                              name="goal_id"
                              label="Goal"
                              component={renderSelectForField}
                              stackedlabel
                              {...{
                                itemProps: {},
                                inputProps: {
                                  options: goalOptions,
                                  isClearable: false,
                                },
                              }}
                            />
                            <OnChange name="goal_id">
                              {(value) => {
                                this.filterPhaseOptions(value, form);
                              }}
                            </OnChange>
                            <Field
                              name="phase_id"
                              label="Phase"
                              component={renderSelectForField}
                              stackedlabel
                              {...{
                                itemProps: {},
                                inputProps: {
                                  options: phaseOptions,
                                  isClearable: false,
                                },
                              }}
                            />
                            <Field
                              name="activity_level_id"
                              label="Activity Level"
                              component={renderSelectForField}
                              stackedlabel
                              {...{
                                itemProps: {},
                                inputProps: {
                                  options: activityLevelOptions,
                                  isClearable: false,
                                },
                              }}
                            />
                          </>
                        ) : null}
                      </>
                    )}
                    <Field
                      name="dietary_preferences"
                      label="Dietary Preferences"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: this.state.dietaryPreferencesOptions,
                          isMulti: true,
                        },
                      }}
                    />
                    {!teamAdmin ? (
                      <Field
                        name="roles"
                        label="Internal Admin Roles"
                        component={renderSelectForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {
                            options: this.state.adminRolesOptions,
                            isMulti: true,
                          },
                        }}
                      />
                    ) : (
                      <>
                        <TimezoneField />
                        {hasMacroInputs && (
                          <>
                            <WeightUnitsField />
                            <FoodUnitsField />
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button className="btn btn-lg btn-primary" type="submit" disabled={submitting}>
                      Save
                    </button>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

UserInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  teamAdmin: PropTypes.bool,
  teamProfile: PropTypes.instanceOf(Object),
  consumerTeams: PropTypes.instanceOf(Array),
};

export default UserInfoForm;
