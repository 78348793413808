import moment from 'moment';
import numeral from 'numeral';
import { formatWeightFromPounds, formatMeasurementFromInches } from './stringUtils';
import { displayWeightInPreferredUnit, displayMeasurementInPreferredUnit } from './numberUtils';

const { createChartLabelFromDate, localizeDateToTimezone } = require('./dateUtils');

const NO_LEGEND_OPTION = {
  legend: {
    display: false,
  },
};
const RESPONSIVE_OPTION = {
  responsive: true,
};
const MAINTAIN_ASPECT_RATIO_OPTION = {
  maintainAspectRatio: false,
};
const LEGEND_RIGHT_OPTION = {
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        boxWidth: 20,
      },
    },
  },
};
const LEGEND_BOTTOM_OPTION = {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 20,
      },
    },
  },
};
const PIE_COLORS = ['#03B7E5', '#8180CD', '#E1B241', '#61CE99'];
const LINE_COLORS = [...PIE_COLORS];
const LINE_BORDER_COLORS = [
  'rgba(3, 183, 229, 0.5)',
  'rgba(129, 128, 205, 0.5)',
  'rgba(225, 178, 65, 0.5)',
];

export const buildPieChartData = (data, labels) => {
  const options = {
    ...LEGEND_RIGHT_OPTION,
    ...RESPONSIVE_OPTION,
    ...MAINTAIN_ASPECT_RATIO_OPTION,
    animation: false,
  };
  return {
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: PIE_COLORS,
        },
      ],
    },
    options,
  };
};

export const buildSingleStatisticPieChartData = (percent, label, otherLabel = 'Other') => {
  const labels = [`${label} %`, `${otherLabel} %`];
  const options = {
    plugins: {
      ...NO_LEGEND_OPTION,
    },
    ...RESPONSIVE_OPTION,
    ...MAINTAIN_ASPECT_RATIO_OPTION,
  };
  const data = [percent, 100 - percent];
  return {
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: PIE_COLORS,
        },
      ],
    },
    options,
  };
};

export const buildWeighInsLineChartData = (weighIns, preferred_weight_units = 'imperial') => {
  if (!weighIns || !weighIns.length) {
    return;
  }
  const options = {
    plugins: {
      ...NO_LEGEND_OPTION,
    },
    ...RESPONSIVE_OPTION,
  };
  const data = [];
  const labels = [];

  weighIns.forEach((w) => {
    const { date, weight_lbs } = w;
    data.push(displayWeightInPreferredUnit(weight_lbs, preferred_weight_units));
    // data.push(weight_lbs);
    labels.push(createChartLabelFromDate(date));
  });

  const lastWeighIn = weighIns[weighIns.length - 1];
  const firstWeighIn = weighIns[0];
  const days = moment(lastWeighIn.date).diff(moment(firstWeighIn.date), 'd');
  // const weeks = moment(lastBodyFat.date).diff(moment(firstBodyFat.date), 'w');
  const weeks = Math.ceil(days / 7);
  const diff = lastWeighIn.weight_lbs - firstWeighIn.weight_lbs;
  const avg = weeks > 0 ? numeral(diff / weeks).format('0.0') : numeral(diff).format('0.0');

  return {
    data: {
      labels,
      datasets: [
        {
          label: `Weight in ${preferred_weight_units === 'imperial' ? 'lbs' : 'kg'}`,
          data,
          fill: false,
          backgroundColor: LINE_COLORS[0],
          borderColor: LINE_BORDER_COLORS[0],
          trendlineLinear: {
            style: '#8e5ea2',
            lineStyle: 'line',
            width: 1,
          },
        },
      ],
    },
    options,
    // caption: {
    //   label: `Last weigh in on ${localizeDateToTimezone(lastWeighIn.date, null, true).format(
    //     'MMMM D, YYYY'
    //   )}`,
    //   values: [`${lastWeighIn.weight_lbs} lbs`],
    // },
    caption: [
      {
        label: `Last weigh in on ${localizeDateToTimezone(lastWeighIn.date, null, true).format(
          'MMMM D, YYYY'
        )}`,
        values: [formatWeightFromPounds(lastWeighIn.weight_lbs, preferred_weight_units)],
        // values: [`${lastWeighIn.weight_lbs} lbs`],
      },
      {
        label: 'Starting weight',
        values: [formatWeightFromPounds(firstWeighIn.weight_lbs, preferred_weight_units)],
        // values: [`${firstWeighIn.weight_lbs} lbs`],
      },
      {
        label: 'Average weekly weight change',
        values: [formatWeightFromPounds(avg, preferred_weight_units)],
      },
    ],
    legend: {
      weight: LINE_COLORS[0],
      trend: LINE_COLORS[1],
    },
  };
};

export const buildBodyFatLineChartData = (bodyFats) => {
  if (!bodyFats || !bodyFats.length) {
    return;
  }
  const options = {
    plugins: {
      ...NO_LEGEND_OPTION,
    },
    ...RESPONSIVE_OPTION,
  };
  const data = [];
  const labels = [];

  bodyFats.forEach((w) => {
    const { date, body_fat } = w;
    data.push(body_fat);
    labels.push(createChartLabelFromDate(date));
  });

  const lastBodyFat = bodyFats[bodyFats.length - 1];
  const firstBodyFat = bodyFats[0];
  const days = moment(lastBodyFat.date).diff(moment(firstBodyFat.date), 'd');
  // const weeks = moment(lastBodyFat.date).diff(moment(firstBodyFat.date), 'w');
  const weeks = Math.ceil(days / 7);
  const diff = lastBodyFat.body_fat - firstBodyFat.body_fat;
  const avg = weeks > 0 ? numeral(diff / weeks).format('0.0') : numeral(diff).format('0.0');

  return {
    data: {
      labels,
      datasets: [
        {
          label: 'Body Fat Percentage',
          data,
          fill: false,
          backgroundColor: LINE_COLORS[0],
          borderColor: LINE_BORDER_COLORS[0],
          trendlineLinear: {
            style: '#8e5ea2',
            lineStyle: 'line',
            width: 1,
          },
        },
      ],
    },
    options,
    caption: [
      {
        label: `Last measurement on ${localizeDateToTimezone(lastBodyFat.date, null, true).format(
          'MMMM D, YYYY'
        )}`,
        values: [`${lastBodyFat.body_fat}%`],
      },
      {
        label: 'Starting body fat',
        values: [`${firstBodyFat.body_fat}%`],
      },
      {
        label: 'Average weekly body fat percentage change',
        values: [`${avg}%`],
      },
    ],
    legend: {
      weight: LINE_COLORS[0],
      trend: LINE_COLORS[1],
    },
  };
};

export const buildMeasurementsLineChartData = (measurements, preferred_weight_units = 'imperial') => {
  if (!measurements || !measurements.length) {
    return;
  }
  const options = {
    plugins: {
      ...NO_LEGEND_OPTION,
    },
    ...RESPONSIVE_OPTION,
  };
  const legend = {
    chest: LINE_COLORS[0],
    waist: LINE_COLORS[1],
    hips: LINE_COLORS[2],
  };
  const datasets = [
    {
      label: 'Chest',
      backgroundColor: LINE_COLORS[0],
      borderColor: LINE_BORDER_COLORS[0],
      fill: false,
      data: measurements.reduce(
        (acc, m) =>
          m.chest_inches
            ? [...acc, { x: createChartLabelFromDate(m.date), y: displayMeasurementInPreferredUnit(m.chest_inches, preferred_weight_units) }]
            : acc,
        []
      ),
    },
    {
      label: 'Waist',
      backgroundColor: LINE_COLORS[1],
      borderColor: LINE_BORDER_COLORS[1],
      fill: false,
      data: measurements.reduce(
        (acc, m) =>
          m.waist_inches
            ? [...acc, { x: createChartLabelFromDate(m.date), y: displayMeasurementInPreferredUnit(m.waist_inches, preferred_weight_units) }]
            : acc,
        []
      ),
    },
    {
      label: 'Hips',
      backgroundColor: LINE_COLORS[2],
      borderColor: LINE_BORDER_COLORS[2],
      fill: false,
      data: measurements.reduce(
        (acc, m) =>
          m.hips_inches ? [...acc, { x: createChartLabelFromDate(m.date), y: displayMeasurementInPreferredUnit(m.hips_inches, preferred_weight_units) }] : acc,
        []
      ),
    },
  ];
  const labels = measurements.map((m) => createChartLabelFromDate(m.date));
  const lastMeasurements = measurements[measurements.length - 1];
  return {
    data: {
      labels,
      datasets,
    },
    options,
    // caption: {
    //   label: `Last measurement taken on ${localizeDateToTimezone(
    //     moment(lastMeasurements.date).toDate(),
    //     null,
    //     true
    //   ).format('MMMM D, YYYY')}`,
    //   values: [
    //     lastMeasurements.chest_inches && `Chest: ${lastMeasurements.chest_inches} in`,
    //     lastMeasurements.waist_inches && `Waist: ${lastMeasurements.waist_inches} in`,
    //     lastMeasurements.hips_inches && `Hips: ${lastMeasurements.hips_inches} in`,
    //   ],
    // },
    caption: [
      {
        label: `Last measurement taken on ${localizeDateToTimezone(
          moment(lastMeasurements.date).toDate(),
          null,
          true
        ).format('MMMM D, YYYY')}`,
        values: [
          lastMeasurements.chest_inches && `Chest: ${formatMeasurementFromInches(lastMeasurements.chest_inches, preferred_weight_units)}`,
          lastMeasurements.waist_inches && `Waist: ${formatMeasurementFromInches(lastMeasurements.waist_inches, preferred_weight_units)}`,
          lastMeasurements.hips_inches && `Hips: ${formatMeasurementFromInches(lastMeasurements.hips_inches, preferred_weight_units)}`,
        ],
      },
    ],
    legend,
  };
};
