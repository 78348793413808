import { CHAT_SETTINGS_SUCCESS } from '../apiActions/chatSettingApiActions';
import { ADD_HIDDEN_FEATURE_SUCCESS } from '../apiActions/teamApiActions';

const initialUserState = {
  features: {
    chat_coaches: false,
  }
};

export default (state = initialUserState, action) => {
  switch (action.type) {
    case CHAT_SETTINGS_SUCCESS:
      return {
        ...state,
        features: action.data,
      };

    case ADD_HIDDEN_FEATURE_SUCCESS: {
      let { features } = state;
      if (action?.data?.name === 'chatMessages') {
        features = {
          chat_coaches: false,
        };
      }

      return {
        ...state,
        features,
      };
    }

    default:
      return state;
  }
};
