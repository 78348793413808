import { AuthMenu } from '../Menu';
import { getArrayIntersection } from './arrayUtils';
import { allTeamRoles } from '../constants';
import { Roles } from '../enums/roles';

export const isAuthorized = (userRoles, item) => {
  const roles = item.roles ? item.roles : item;
  if (!roles || !roles.length) {
    return true;
  }
  return getArrayIntersection(userRoles, roles).length > 0;
};

export const getRolesForRoute = (route) => {
  const menuRoute = AuthMenu[route];
  if (!menuRoute) {
    return [];
  }
  return menuRoute.roles;
};

export const isTeamUser = (roles) => getArrayIntersection(roles, allTeamRoles).length > 0;

export const userHasRole = (user, role, userRoles) => {
  const r = userRoles && userRoles.length ? userRoles : user.roles;
  if (r && r.length) {
    return r.indexOf(role) > -1;
  }
  return false;
};

export const getInitialRouteForUser = (user, clients = 0) => {
  if (userHasRole(user, Roles.INTERNAL_ADMIN)) {
    return '/users';
  }
  // TODO: make this more robust for other roles (recipe admin, etc.)
  // return clients === 0 ? '/addclients' : '/dashboard';
  return clients === 0 ? '/addclients' : '/clients';
};
