import { put, take, race, all } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';
import { EDIT_CHAT_SETTINGS, GET_CHAT_SETTINGS } from '../actions/chatSettingActions';
import { CHAT_SETTINGS_FAILURE, CHAT_SETTINGS_SUCCESS, EDIT_CHAT_SETTINGS_FAILURE, EDIT_CHAT_SETTINGS_SUCCESS, editChatSettings, fetchChatSettings } from '../apiActions/chatSettingApiActions';

export default function* chatSettingsSaga() {
  yield all([
    getChatSettingsSaga(),
    editChatSettingsSaga(),
  ]);
}

function* getChatSettingsSaga() {
  while (true) {
    yield take(GET_CHAT_SETTINGS);
    yield put(fetchChatSettings());
    const { failure } = yield race({
      success: take(CHAT_SETTINGS_SUCCESS),
      failure: take(CHAT_SETTINGS_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* editChatSettingsSaga() {
  while (true) {
    const { chatCoaches } = yield take(EDIT_CHAT_SETTINGS);
    yield put(editChatSettings(chatCoaches));

    const { failure } = yield race({
      success: take(EDIT_CHAT_SETTINGS_SUCCESS),
      failure: take(EDIT_CHAT_SETTINGS_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}
