import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { changeSetting } from '../../actions/layoutActions';
import SidebarRun from './Sidebar.run';
import IntercomWidget from '../Common/IntercomWidget';
import Menu from '../../Menu';
import InviteClientModal from '../../modals/InviteClientModal';
import FeedbackModal from '../../modals/FeedbackModal';
import ReferralsModal from '../../modals/ReferralsModal';
import SidebarItemHeader from './Sidebar/SidebarItemHeader';
import SidebarItem from './Sidebar/SidebarItem';
import SidebarSubItem from './Sidebar/SidebarSubItem';
import SidebarSubHeader from './Sidebar/SidebarSubHeader';
import { isAuthorized } from '../../utils/authUtils';
import { allTeamRoles } from '../../constants';
import browserHistory from '../../history';
import ENV_VARS from '../../utils/envVars';
import { streamUnreadCount } from '../../actions/authActions';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: {},
      inviteClientModalOpen: false,
      feedbackModalOpen: false,
      referralsModalOpen: false,
    };
  }

  componentDidMount() {
    const { doStreamUnreadCount } = this.props;
    // pass navigator to access router api
    SidebarRun(this.navigator, this.closeSidebar);
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    browserHistory.listen(this.closeSidebar);

    let unreadChats = 0;
    chatClient.on((event) => {
      if (event.total_unread_count !== null && event.total_unread_count !== undefined) {
        unreadChats = event.total_unread_count;
        doStreamUnreadCount(unreadChats);
      }
    });

    // const script = document.createElement('script');
    // script.setAttribute('data-code', 'Vm1uHG');
    // script.setAttribute('class', 'rf-popup-script');
    // script.src = 'https://referral-factory.com/assets/js/popup.js?code=Vm1uHG';
    // document.body.appendChild(script);
  }

  closeSidebar = () => {
    const { doChangeSetting } = this.props;
    doChangeSetting('asideToggled', false);
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    const collapse = {};
    Menu.filter(({ heading }) => !heading).forEach(({ name, path, submenu }) => {
      collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path);
    });
    this.setState({ collapse });
  };

  navigator = (route) => {
    browserHistory.push(route.replace('#', '')); // remove '#' in case of use HashRouter
  };

  getSubRoutes = (item) => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  itemType = (item) => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
  };

  toggleInviteClientModal = () => {
    const { inviteClientModalOpen } = this.state;
    this.setState({
      inviteClientModalOpen: !inviteClientModalOpen,
    });
  };

  toggleFeedbackModal = () => {
    const { feedbackModalOpen } = this.state;
    this.setState({
      feedbackModalOpen: !feedbackModalOpen,
    });
  };

  toggleReferralsModal = () => {
    const { referralsModalOpen } = this.state;
    this.setState({
      referralsModalOpen: !referralsModalOpen,
    });
  };

  toggleReferral = (canRefer) => {
    if (!canRefer) {
      this.toggleReferralsModal();
    }
  };

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths];
    return paths.some((p) => this.props.location.pathname.indexOf(p) > -1);
  }

  toggleItemCollapse(stateName) {
    const { collapse } = this.state;
    // eslint-disable-next-line
    for (let c in collapse) {
      if (collapse[c] === true && c !== stateName) {
        this.setState({
          collapse: {
            [c]: false,
          },
        });
      }
    }
    this.setState({
      collapse: {
        [stateName]: !collapse[stateName],
      },
    });
  }

  render() {
    const { inviteClientModalOpen, feedbackModalOpen, referralsModalOpen } = this.state;
    const { user, unreadChatCount, team } = this.props;
    let roles;
    let first_name;
    let email;
    let canRefer = false;
    if (user) {
      ({ roles, first_name, email } = user);
    }
    if (team && team.membership && team.membership.status === 'active') {
      canRefer = true;
    }
    return (
      <aside className="aside-container">
        <div className="aside-inner">
          {user && (
            <nav data-sidebar-anyclick-close="" className="sidebar">
              {isAuthorized(roles, allTeamRoles) && (
                // <Button color="primary" size="lg" onClick={this.toggleInviteClientModal}>
                <Button color="primary" size="lg" onClick={() => this.navigator('/addclients')}>
                  <em className="fa-lg mr-2 fas fa-user-plus" />
                  Add Clients
                </Button>
              )}
              <ul className="sidebar-nav">
                {Menu.map((item, i) => {
                  // heading
                  if (this.itemType(item) === 'heading' && isAuthorized(roles, item)) {
                    return <SidebarItemHeader item={item} key={i} />;
                  }
                  if (this.itemType(item) === 'menu' && isAuthorized(roles, item)) {
                    return (
                      <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                    );
                  }
                  if (this.itemType(item) === 'submenu' && isAuthorized(roles, item)) {
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={
                          this.state.collapse[item.name] ||
                          this.routeActive(this.getSubRoutes(item))
                        }
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                        unreadChatCount={unreadChatCount}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map(
                          (subitem, idx) =>
                            isAuthorized(roles, subitem) && (
                              <SidebarItem
                                key={idx}
                                item={subitem}
                                isActive={this.routeActive(subitem.path)}
                              />
                            )
                        )}
                      </SidebarSubItem>,
                    ];
                  }
                  return null; // unrecognized item
                })}
                {isAuthorized(roles, allTeamRoles) && (
                  <>
                    <li className="border-top mt-3">
                      <Link to="/launchguide" title="Launch Guide">
                        <em className="icon-notebook" />
                        <span>Launch Guide</span>
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://macrost.ax/app"
                        title="Download Macrostax App"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em className="icon-screen-smartphone" />
                        <span>Download the Macrostax App</span>
                      </a>
                    </li>
                    {/* <li>
                      <div
                        className="nav-item nav-item-standalone d-flex flex-row align-items-center rf-popup-Vm1uHG"
                        onClick={() => this.toggleReferral(true)}
                      >
                        <em className="svg-icon money-sack-light rf-popup-Vm1uHG" />
                        <span className="rf-popup-Vm1uHG">Refer and Earn</span>
                      </div>
                    </li> */}
                    <li>
                      <div
                        className="nav-item nav-item-standalone d-flex flex-row align-items-center"
                        onClick={this.toggleFeedbackModal}
                      >
                        {/* <em className="ms-icon- icon-feedback-outline" /> */}
                        <em className="svg-icon feedback-triangle" />
                        <span>Send Feedback</span>
                      </div>
                    </li>
                    <IntercomWidget />
                    <li>
                      <a
                        href="https://help.macrostax.com"
                        title="Help"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em className="icon-question" />
                        <span>Help Center</span>
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          )}
        </div>
        {/* <InviteClientModal isOpen={inviteClientModalOpen} toggle={this.toggleInviteClientModal} /> */}
        <FeedbackModal isOpen={feedbackModalOpen} toggle={this.toggleFeedbackModal} />
        <ReferralsModal isOpen={referralsModalOpen} toggle={this.toggleReferralsModal} />
      </aside>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.instanceOf(Object),
  doChangeSetting: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  doStreamUnreadCount: PropTypes.func,
  team: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  layout: state.layout,
  user: state.auth.user,
  unreadChatCount: state.team.unreadChatCount,
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({
  doChangeSetting: (setting, val) => dispatch(changeSetting(setting, val)),
  doStreamUnreadCount: (count) => dispatch(streamUnreadCount(count)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
