import { all, call, put, takeEvery, race, take, delay, select } from 'redux-saga/effects';
import { JWT_TOKEN_KEY, getLocalStoreValue } from '../utils/localStorage';
import { apiRequest } from '../utils/apiCaller';
import { authRefreshToken, AUTH_TOKEN_STORED, authLogout } from '../actions/authActions';
import { spinnerIncrement, spinnerDecrement } from '../actions/spinnerActions';

export default function* apiSagas() {
  yield all([apiListener()]);
}

function* apiCaller(action) {
  // This will take a set of data on action and attempt to make API call
  // If 401 is returned (unauth) it will try refreshing token
  // If auth switch is true, retrieve and use current token
  const tokenRequired = action.auth || false;
  const token = tokenRequired ? yield call(getLocalStoreValue, JWT_TOKEN_KEY) : undefined;

  const startSpinner = action.spinner || false;

  const state = yield select();
  const { device } = state;
  const deviceForHeaders = {
    ...device,
  };

  try {
    // if required, start a new loading spinner
    if (startSpinner) {
      yield put(spinnerIncrement());
    }
    // Call the function to make API call, use passed action for success response
    const response = yield call(apiRequest, action.config, token, deviceForHeaders);
    if (response) {
      yield put({ type: action.successAction, data: response.data });
    }
  } catch (error) {
    // General error processing here
    // 401 error on auth'd call means token expired? Refresh token try again
    if (error && error.response && error.response.status === 401 && tokenRequired) {
      yield put(authRefreshToken());
      // Set timeout for success auth token retrieved
      const { tokenRefresh } = yield race({
        tokenRefresh: take(AUTH_TOKEN_STORED),
        timeout: delay(5000),
      });
      if (tokenRefresh) {
        // Token refreshed, recursive call to retrieve and try again
        yield put(spinnerDecrement());
        return yield call(apiCaller, action);
      }
      yield put(spinnerDecrement());
      return yield put(authLogout());
    }
    // All other errors dispatched with specified action
    yield put({ type: action.failureAction, error });
  }

  // If spinner was added, decrement the spinner counter
  if (startSpinner) {
    yield put(spinnerDecrement());
  }
  return {};
}

function* apiListener() {
  // This listens for every api call message and sends to caller saga
  yield takeEvery('API_CALL', apiCaller);
}
