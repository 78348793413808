import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import LogInForm from '../../forms/LogInForm';
import { authLoginRequest, authorizeRequest } from '../../actions/authActions';
import { setSignUpStep } from '../../actions/signupActions';
import { getLocalStoreValue, INIT_EMAIL_KEY } from '../../utils/localStorage';

class Login extends React.Component {
  componentDidMount() {
    const { doSetSignupStep } = this.props;
    doSetSignupStep(-1);
  }
  render() {
    const { showSubmissionError, doLogIn } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col md={4} />
          <Col md={4}>
            <h1>Log In</h1>
            <div className="signup-form-wrapper mt-5">
              <LogInForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => doLogIn(values)}
                initialValues={{
                  email: getLocalStoreValue(INIT_EMAIL_KEY) || '',
                  password: '',
                }}
              />
              <p className="mt-4 text-center caption">
                Need an account? <Link to="/signup">Sign up</Link> for your FREE trial!
              </p>
              <p className="mt-4 text-center caption">
                <Link to="/forgotpassword">Forgot your password?</Link>
              </p>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Login.propTypes = {
  doLogIn: PropTypes.func,
  showSubmissionError: PropTypes.func,
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doLogIn: (values) => dispatch(authorizeRequest(values)),
  // doLogIn: (values) => dispatch(authLoginRequest(values)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
