import { call, put, take, race, all, delay, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { showAlert } from '../actions/globalActions';
import {
  GET_TEAM,
  GET_TEAM_PROFILE,
  UPDATE_TEAM,
  getTeam,
  updateTeam,
  getAdmins,
  SEND_INVITATION,
  SEND_INVITATIONS,
  SUBMIT_TEAM_SEARCH_REQUEST,
  ADD_CLIENTS,
  ADD_ADMINS,
  GET_PENDING_CLIENTS,
  REMOVE_MEMBERSHIPS,
  REMOVE_ADMINS,
  SUBMIT_FEEDBACK,
  GET_ALL_CLIENTS,
  GET_ADMINS,
  GET_TEAM_MEMBERSHIP,
  UPDATE_TEAM_PAYMENT_PROFILE,
  CHANGE_PLAN,
  CANCEL_PLAN,
  REACTIVATE_MEMBERSHIP,
  GET_DASHBOARD,
  GET_ENGAGEMENT_SUMMARY,
  GET_ENGAGEMENT_SUMMARY_CSV,
  GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION,
  GET_ALL_TEAM_BRANDING,
  GET_CUSTOM_FEATURES,
  GET_HIDDEN_FEATURES,
  ADD_HIDDEN_FEATURE,
  DELETE_HIDDEN_FEATURE,
  UPDATE_CUSTOM_FEATURE,
  ADD_CUSTOM_FEATURE,
  ARCHIVE_CUSTOM_FEATURE,
  SUBMIT_CLIENTS_SEARCH_REQUEST,
} from '../actions/teamActions';
import {
  getTeamApi,
  getTeamProfileApi,
  updateTeamApi,
  teams,
  teamSearch,
  addAdminsApi,
  addClientsApi,
  sendInvitationsApi,
  removeMembershipsApi,
  removeAdminsApi,
  sendFeedbackApi,
  getTeamMembership,
  updateTeamPaymentProfile,
  updateMembership,
  cancelPlan,
  reactivateMembership,
  getDashboard,
  getEngagementSummary,
  getEngagementSummaryCsv,
  getClientStatusAndGoalDistribution,
  updateTeamBrand,
  getAllTeamBranding,
  getCustomFeatures,
  getHiddenFeatures,
  addHiddenFeature,
  deleteHiddenFeature,
  updateCustomFeature,
  addCustomFeature,
  archiveCustomFeature,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILURE,
  TEAM_SEARCH_SUCCESS,
  TEAM_SEARCH_FAILURE,
  ADD_CLIENTS_SUCCESS,
  ADD_CLIENTS_FAILURE,
  ADD_ADMINS_SUCCESS,
  ADD_ADMINS_FAILURE,
  SEND_INVITATIONS_SUCCESS,
  SEND_INVITATIONS_FAILURE,
  REMOVE_MEMBERSHIPS_SUCCESS,
  REMOVE_MEMBERSHIPS_FAILURE,
  REMOVE_ADMINS_SUCCESS,
  REMOVE_ADMINS_FAILURE,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  GET_TEAM_PROFILE_SUCCESS,
  GET_TEAM_PROFILE_FAILURE,
  GET_TEAM_MEMBERSHIP_SUCCESS,
  GET_TEAM_MEMBERSHIP_FAILURE,
  UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS,
  UPDATE_TEAM_PAYMENT_PROFILE_FAILURE,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_FAILURE,
  CANCEL_PLAN_SUCCESS,
  CANCEL_PLAN_FAILURE,
  REACTIVATE_MEMBERSHIP_SUCCESS,
  REACTIVATE_MEMBERSHIP_FAILURE,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_SUCCESS,
  GET_ENGAGEMENT_SUMMARY_SUCCESS,
  GET_ENGAGEMENT_SUMMARY_FAILURE,
  GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS,
  GET_ENGAGEMENT_SUMMARY_CSV_FAILURE,
  GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_FAILURE,
  GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS,
  UPDATE_TEAM_BRAND_SUCCESS,
  UPDATE_TEAM_BRAND_FAILURE,
  GET_ALL_TEAM_BRANDING_SUCCESS,
  GET_ALL_TEAM_BRANDING_FAILURE,
  GET_CUSTOM_FEATURES_SUCCESS,
  GET_CUSTOM_FEATURES_FAILURE,
  GET_HIDDEN_FEATURES_SUCCESS,
  GET_HIDDEN_FEATURES_FAILURE,
  ADD_HIDDEN_FEATURE_SUCCESS,
  ADD_HIDDEN_FEATURE_FAILURE,
  DELETE_HIDDEN_FEATURE_SUCCESS,
  DELETE_HIDDEN_FEATURE_FAILURE,
  UPDATE_CUSTOM_FEATURE_SUCCESS,
  UPDATE_CUSTOM_FEATURE_FAILURE,
  ADD_CUSTOM_FEATURE_SUCCESS,
  ADD_CUSTOM_FEATURE_FAILURE,
  ARCHIVE_CUSTOM_FEATURE_SUCCESS,
  ARCHIVE_CUSTOM_FEATURE_FAILURE,
} from '../apiActions/teamApiActions';
import {
  userSearch,
  getAllClientsApi,
  PENDING_CLIENT_SEARCH_SUCCESS,
  PENDING_CLIENT_SEARCH_FAILURE,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  ADMIN_SEARCH_SUCCESS,
  ADMIN_SEARCH_FAILURE,
} from '../apiActions/userApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';

export default function* teamSagas() {
  yield all([
    getTeamSaga(),
    updateTeamSaga(),
    submitTeamSearchSaga(),
    addClientsSaga(),
    addAdminsSaga(),
    sendInvitationSaga(),
    getPendingClientsSaga(),
    submitPendingClientsSaga(),
    sendInvitationsSaga(),
    removeMembershipsSaga(),
    submitFeedbackSaga(),
    getAllClientsSaga(),
    getTeamProfileSaga(),
    getAdminsSaga(),
    getTeamMembershipSaga(),
    updateTeamPaymentProfileSaga(),
    changePlanSaga(),
    cancelPlanSaga(),
    reactivateMembershipSaga(),
    removeAdminsSaga(),
    getDashboardSaga(),
    getDashboardEngagementSummarySaga(),
    getDashboardEngagementSummaryCsvSaga(),
    getClientStatusAndGoalDistributionSaga(),
    getAllTeamBrandingSaga(),
    getCustomFeaturesSaga(),
    getHiddenFeaturesSaga(),
    addHiddenFeatureSaga(),
    deleteHiddenFeatureSaga(),
    addCustomFeatureSaga(),
    updateCustomFeatureSaga(),
    archiveCustomFeatureSaga(),
  ]);
}

function* getTeamSaga() {
  while (true) {
    const { team_id } = yield take(GET_TEAM);
    yield put(getTeamApi(team_id));
    const { success, failure } = yield race({
      success: take(GET_TEAM_SUCCESS),
      failure: take(GET_TEAM_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getAllTeamBrandingSaga() {
  while (true) {
    yield take(GET_ALL_TEAM_BRANDING);
    yield put(getAllTeamBranding());
    const { success, failure } = yield race({
      success: take(GET_ALL_TEAM_BRANDING_SUCCESS),
      failure: take(GET_ALL_TEAM_BRANDING_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getDashboardSaga() {
  while (true) {
    let {
      team_id,
      start_date = moment().add(-30, 'days').toDate(),
      end_date = new Date(),
      timezone = moment.tz.guess(),
    } = yield take(GET_DASHBOARD);
    start_date = moment(start_date).add(-1, 'days').format('YYYY-MM-DD');
    end_date = moment(end_date).add(1, 'days').format('YYYY-MM-DD');

    yield put(getDashboard(team_id, start_date, end_date, timezone));

    const { success, failure } = yield race({
      success: take(GET_DASHBOARD_SUCCESS),
      failure: take(GET_DASHBOARD_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getClientStatusAndGoalDistributionSaga() {
  while (true) {
    const { team_id } = yield take(GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION);
    yield put(getClientStatusAndGoalDistribution(team_id));
    const { success, failure } = yield race({
      success: take(GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS),
      failure: take(GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_FAILURE),
    });

    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getDashboardEngagementSummarySaga() {
  while (true) {
    let {
      team_id,
      sort_by = 'days_active',
      order = 'desc',
      page = 1,
      show_count = 25,
      group_id,
      start_date = moment().add(-30, 'days').toDate(),
      end_date = new Date(),
      user_role_status,
    } = yield take(GET_ENGAGEMENT_SUMMARY);
    start_date = moment(start_date).format('YYYY-MM-DD');
    end_date = moment(end_date).format('YYYY-MM-DD');

    yield put(
      getEngagementSummary(
        team_id,
        start_date,
        end_date,
        sort_by,
        order,
        page,
        show_count,
        group_id,
        user_role_status,
      )
    );

    const { failure } = yield race({
      success: take(GET_ENGAGEMENT_SUMMARY_SUCCESS),
      failure: take(GET_ENGAGEMENT_SUMMARY_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getDashboardEngagementSummaryCsvSaga() {
  while (true) {
    let {
      team_id,
      start_date = moment().add(-30, 'days').toDate(),
      end_date = new Date(),
    } = yield take(GET_ENGAGEMENT_SUMMARY_CSV);

    start_date = moment(start_date).format('YYYY-MM-DD');
    end_date = moment(end_date).format('YYYY-MM-DD');

    yield put(
      getEngagementSummaryCsv(team_id, start_date, end_date)
    );

    const { success, failure } = yield race({
      success: take(GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS),
      failure: take(GET_ENGAGEMENT_SUMMARY_CSV_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getTeamProfileSaga() {
  while (true) {
    const { team_id } = yield take(GET_TEAM_PROFILE);
    yield put(getTeamProfileApi(team_id));
    const { success, failure } = yield race({
      success: take(GET_TEAM_PROFILE_SUCCESS),
      failure: take(GET_TEAM_PROFILE_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getAllClientsSaga() {
  while (true) {
    const { team_id } = yield take(GET_ALL_CLIENTS);
    yield put(getAllClientsApi(team_id));
    const { success, failure } = yield race({
      success: take(GET_ALL_CLIENTS_SUCCESS),
      failure: take(GET_ALL_CLIENTS_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* updateTeamSaga() {
  while (true) {
    const { id, payload } = yield take(UPDATE_TEAM);
    const {
      name = '',
      phone = '',
      email = '',
      address = '',
      address_alt = '',
      city = '',
      state = '',
      zip = '',
      logo_image = '',
    } = payload;
    let { logo_background_color = '#ffffff' } = payload;
    let successfulCalls = 0;
    if (
      logo_image !== undefined &&
      logo_image !== null &&
      logo_image !== '' &&
      logo_image.toLowerCase().indexOf('data:') === 0
    ) {
      // data:image/png;base64,iVBORw0KGgoAAAAN
      const dataParts = logo_image.replace('data:', '').split(';base64,');
      if (
        logo_background_color === null ||
        logo_background_color === undefined ||
        logo_background_color.toLowerCase() === 'transparent' ||
        logo_background_color === ''
      ) {
        logo_background_color = '#ffffff';
      }
      if (dataParts.length === 2) {
        yield put(
          updateTeamBrand({
            primary_logo: dataParts[1],
            primary_logo_type: dataParts[0],
            primary_background_color: logo_background_color,
          })
        );
        const { success, failure } = yield race({
          success: take(UPDATE_TEAM_BRAND_SUCCESS),
          failure: take(UPDATE_TEAM_BRAND_FAILURE),
        });
        if (success) {
          successfulCalls += 1;
        } else {
          yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
        }
      }
    } else {
      // nothing to update
      successfulCalls += 1;
    }
    yield put(
      updateTeamApi({ team_id: id, name, phone, email, address, address_alt, city, state, zip })
    );
    const { success, failure } = yield race({
      success: take(UPDATE_TEAM_SUCCESS),
      failure: take(UPDATE_TEAM_FAILURE),
    });
    if (success) {
      successfulCalls += 1;
    } else {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
    if (successfulCalls === 2) {
      yield put(getTeamApi(id));
      yield put(
        showAlert(
          'Done!',
          `Account settings for ${payload.name} have been sucessfully updated.`,
          'success'
        )
      );
    } else {
      yield put(showAlert('Oops!', 'Something went wrong. Please try again.', 'error'));
    }
  }
}

function* submitTeamSearchSaga() {
  while (true) {
    const { values } = yield take(SUBMIT_TEAM_SEARCH_REQUEST);
    if (!values || Object.keys(values).length === 0) {
      // dispatch api call action for all teams
      yield put(teams());
    } else {
      // dispatch api call action for team search
      const v = {
        ...values,
      };
      if (v.start_created_at) {
        v.start_created_at = moment(v.start_created_at).format('YYYY-MM-DD');
      }
      if (v.end_created_at) {
        v.end_created_at = moment(v.end_created_at).format('YYYY-MM-DD');
      }
      v.timezone = moment.tz.guess();
      yield put(teamSearch(v));
    }
    const { success, failure } = yield race({
      success: take(TEAM_SEARCH_SUCCESS),
      failure: take(TEAM_SEARCH_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addClientsSaga() {
  while (true) {
    let values;
    let func;
    ({ values, func } = yield take(ADD_CLIENTS));
    const { send_invitations, impersonate_team_owner = false, team_id } = values;
    const clients = [];
    // To get to this point, each row should have a valid first_name_x, last_name_x, and email_x.
    // So, find all first_name keys and match those up with the other two fields
    const keys = Object.keys(values);
    const first_names = keys.filter((k) => k.indexOf('first_name') === 0);
    first_names.forEach((f) => {
      const idx = f.replace('first_name_', '');
      if (
        values[`first_name_${idx}`].trim() !== '' &&
        values[`last_name_${idx}`].trim() !== '' &&
        values[`email_${idx}`].trim() !== ''
      ) {
        clients.push({
          first_name: values[`first_name_${idx}`].trim(),
          last_name: values[`last_name_${idx}`].trim(),
          email: values[`email_${idx}`].trim(),
          timezone: moment.tz.guess(),
        });
      }
    });
    const payload = {
      send_invitations,
      impersonate_team_owner,
      clients,
    };
    if (team_id) {
      payload.team_id = team_id;
    }
    yield put(addClientsApi(payload));
    const { success, failure } = yield race({
      success: take(ADD_CLIENTS_SUCCESS),
      failure: take(ADD_CLIENTS_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      const { added, errors } = success.data;
      let html = '';
      let title = 'Done!';
      let type = 'success';
      if (!added || added.length === 0) {
        title = 'Oops!';
        type = 'error';
        func = undefined;
      } else {
        html = `${added.length} client${
          added.length !== 1 ? 's have' : ' has'
        } been successfully added to your account!`;
        if (errors && errors.length) {
          html += ' However, ';
        }
      }

      if (errors && errors.length) {
        html += `${added && added.length ? 'w' : 'W'}e were unable to add the following client${
          errors.length !== 1 ? 's' : ''
        } to your Team:<br /><ul>`;
        errors.map((e) => {
          html += `<li>${e.email}</li>`;
        });
        html += `</ul>This is likely because ${
          errors.length > 1 ? 'these clients already have' : 'this client already has'
        } an active Macrostax subscription. Reach out to `;
        html += `<span class="span-link intercom">StaxChat</span> for assistance with getting ${
          errors.length > 1 ? 'these clients' : 'this client'
        } added to your Team! `;
        html += `Please include the above ${
          errors.length > 1 ? 'list of emails' : 'email'
        } in your message.`;
      }

      const d = document.createElement('div');
      d.style.textAlign = 'left';
      d.innerHTML = html;
      yield put(showAlert(title, '', type, undefined, undefined, true, func, d));
      // yield put(showAlert('Done!', 'Clients added successfully!', 'success', '/clients'));
    }
  }
}

function* addAdminsSaga() {
  while (true) {
    const { values, func } = yield take(ADD_ADMINS);
    const user_ids = [];
    const pocs = [];
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      const key = Object.keys(values)[i];
      if (values[key] === true && key.startsWith('user_')) {
        const user_id = key.replace('user_', '');
        user_ids.push(user_id);
        if (values[`point_of_contact_${user_id}`]) {
          pocs.push(user_id);
        }
      }
    }
    if (user_ids.length) {
      const payload = {
        user_ids,
        point_of_contact_users: pocs,
      };
      yield put(addAdminsApi(payload));
      const { success, failure } = yield race({
        success: take(ADD_ADMINS_SUCCESS),
        failure: take(ADD_ADMINS_FAILURE),
      });
      if (failure) {
        yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
      } else {
        yield call(func);
        const state = yield select();
        yield put(getAdmins(state.team.team_id));
        yield put(
          showAlert(
            'Done!',
            `Team admin${user_ids !== 1 ? 's' : ''} added successfully!`,
            'success',
            '/admins'
          )
        );
      }
    } else {
      yield put(
        showAlert('Oops!', 'Please select at least one client to add as a Team Admin', 'error')
      );
    }
  }
}

function* sendInvitationSaga() {
  while (true) {
    const { user } = yield take(SEND_INVITATION);
    const { user_id, first_name, last_name } = user;
    yield put(sendInvitationsApi({ clients: [user_id] }));
    const { success, failure } = yield race({
      success: take(SEND_INVITATIONS_SUCCESS),
      failure: take(SEND_INVITATIONS_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(
        showAlert(
          'Done!',
          `An invitation has been sent to ${first_name} ${last_name}`,
          'success',
          `/clients/${user_id}`
        )
      );
    }
  }
}

function* getPendingClientsSaga() {
  while (true) {
    const { team_id } = yield take(GET_PENDING_CLIENTS);
    yield put(
      userSearch(
        {
          user_role_status: ['pending', 'invited', 'pre-active', 'onboarding', 'active'],
          team_id,
          role_id: '9b23e53c-625d-4f45-8b4f-2387f456e80f',
        },
        'pending_clients'
      )
    );

    const { success, failure } = yield race({
      success: take(PENDING_CLIENT_SEARCH_SUCCESS),
      failure: take(PENDING_CLIENT_SEARCH_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* submitPendingClientsSaga() {
    while (true) {
        const { values } = yield take(SUBMIT_CLIENTS_SEARCH_REQUEST);
        const role_id = '9b23e53c-625d-4f45-8b4f-2387f456e80f';
        const v = { ...values };
        let params = {};

        if (!v || Object.keys(v).length === 0 || Object.values(v).every((val) => val === '')) {
          params = {
            user_role_status: ['pending', 'invited', 'pre-active', 'onboarding', 'active'],
            role_id,
          };
        } else {
          params = { ...v, role_id };
        }

        yield put(userSearch(params, 'pending_clients'));

        const { failure } = yield race({
            success: take(PENDING_CLIENT_SEARCH_SUCCESS),
            failure: take(PENDING_CLIENT_SEARCH_FAILURE),
        });

        if (failure) {
            yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
        }
    }
}

function* getAdminsSaga() {
  while (true) {
    const { team_id } = yield take(GET_ADMINS);
    yield put(
      getAllClientsApi(team_id, [
        '429f0258-f50d-424e-a017-afa0fe69efa7',
        'dfa90da8-bc36-4d62-9482-b51c9fd0f047',
      ])
    ); // team_owner role_id

    const { success, failure } = yield race({
      success: take(ADMIN_SEARCH_SUCCESS),
      failure: take(ADMIN_SEARCH_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getTeamMembershipSaga() {
  while (true) {
    const { team_id } = yield take(GET_TEAM_MEMBERSHIP);
    yield put(getTeamMembership(team_id));

    const { success, failure } = yield race({
      success: take(GET_TEAM_MEMBERSHIP_SUCCESS),
      failure: take(GET_TEAM_MEMBERSHIP_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* updateTeamPaymentProfileSaga() {
  while (true) {
    const { team_id, membership_id, id } = yield take(UPDATE_TEAM_PAYMENT_PROFILE);
    yield put(updateTeamPaymentProfile(team_id, membership_id, id));

    const { success, failure } = yield race({
      success: take(UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS),
      failure: take(UPDATE_TEAM_PAYMENT_PROFILE_FAILURE),
    });
    if (success) {
      yield put(showAlert('Success!', 'Payment method updated successfully', 'success'));
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* changePlanSaga() {
  while (true) {
    const { team_id, membership_id, product_id } = yield take(CHANGE_PLAN);
    yield put(updateMembership(team_id, membership_id, product_id));

    const { success, failure } = yield race({
      success: take(UPDATE_MEMBERSHIP_SUCCESS),
      failure: take(UPDATE_MEMBERSHIP_FAILURE),
    });
    if (success) {
      yield put(showAlert('Success!', 'Plan updated successfully', 'success'));
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* cancelPlanSaga() {
  while (true) {
    const { team_id, membership_id } = yield take(CANCEL_PLAN);
    yield put(cancelPlan(team_id, membership_id));

    const { success, failure } = yield race({
      success: take(CANCEL_PLAN_SUCCESS),
      failure: take(CANCEL_PLAN_FAILURE),
    });
    if (success) {
      const period_end = success.data.next_bill_date;
      yield put(
        showAlert(
          'Success!',
          `Your plan has been canceled. We are sorry to see you go. Your subscription will remain active until ${moment(
            period_end
          ).format(
            'M/D/YYYY'
          )}, after which time your clients will lose access to the Macrostax app.`,
          'success'
        )
      );
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* reactivateMembershipSaga() {
  while (true) {
    const { team_id, membership_id } = yield take(REACTIVATE_MEMBERSHIP);
    yield put(reactivateMembership(team_id, membership_id));

    const { success, failure } = yield race({
      success: take(REACTIVATE_MEMBERSHIP_SUCCESS),
      failure: take(REACTIVATE_MEMBERSHIP_FAILURE),
    });
    if (success) {
      const period_end = success.data.next_bill_date;
      yield put(showAlert('Success!', 'Your account has been reactivated', 'success'));
    } else {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* sendInvitationsSaga() {
  while (true) {
    const { values, impersonate_team_owner = false, team_id, func } = yield take(SEND_INVITATIONS);
    const users = [];
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      const key = Object.keys(values)[i];
      if (values[key] === true) {
        users.push(key.replace('user_', ''));
      }
    }
    yield put(sendInvitationsApi({ clients: users, impersonate_team_owner, team_id }));
    const { success, failure } = yield race({
      success: take(SEND_INVITATIONS_SUCCESS),
      failure: take(SEND_INVITATIONS_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(
        showAlert(
          'Done!',
          `${users.length} invitation${users.length !== 1 ? 's have ' : ' has '} been sent!`,
          'success',
          !impersonate_team_owner ? '/clients' : undefined,
          undefined,
          true,
          impersonate_team_owner ? func : undefined
        )
      );
    }
  }
}

function* removeMembershipsSaga() {
  while (true) {
    const { users, isInternalAdmin, team_id, func } = yield take(REMOVE_MEMBERSHIPS);
    const payload = {
      clients: users.map((u) => u.id || u.user_id),
    };
    payload.impersonate_team_owner = isInternalAdmin;
    payload.team_id = team_id;
    yield put(removeMembershipsApi(payload));
    const { success, failure } = yield race({
      success: take(REMOVE_MEMBERSHIPS_SUCCESS),
      failure: take(REMOVE_MEMBERSHIPS_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(
        showAlert(
          'Done!',
          `${users.length} client${users.length !== 1 ? 's' : ''} removed successfully!`,
          'success',
          !isInternalAdmin ? '/clients' : undefined,
          undefined,
          true,
          isInternalAdmin ? func : undefined
        )
      );
    }
  }
}

function* removeAdminsSaga() {
  while (true) {
    const { users, team_id } = yield take(REMOVE_ADMINS);
    const payload = {
      user_ids: users.map((u) => u.id || u.user_id),
    };
    yield put(removeAdminsApi(payload));
    const { success, failure } = yield race({
      success: take(REMOVE_ADMINS_SUCCESS),
      failure: take(REMOVE_ADMINS_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(getAdmins(team_id));
      yield put(
        showAlert(
          'Done!',
          `${users.length} admin${users.length !== 1 ? 's' : ''} removed successfully!`,
          'success',
          '/admins',
          undefined,
          true
        )
      );
    }
  }
}

function* submitFeedbackSaga() {
  while (true) {
    const { values } = yield take(SUBMIT_FEEDBACK);
    yield put(sendFeedbackApi(values));
    const { success, failure } = yield race({
      success: take(SEND_FEEDBACK_SUCCESS),
      failure: take(SEND_FEEDBACK_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(
        showAlert(
          'Done!',
          'Thank you for your feedback! Our team reviews all submissions and will reach out personally if we have any follow-up questions.',
          'success'
        )
      );
    }
  }
}

function* getCustomFeaturesSaga() {
  while (true) {
    yield take(GET_CUSTOM_FEATURES);
    yield put(getCustomFeatures());
    const { success, failure } = yield race({
      success: take(GET_CUSTOM_FEATURES_SUCCESS),
      failure: take(GET_CUSTOM_FEATURES_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* getHiddenFeaturesSaga() {
  while (true) {
    yield take(GET_HIDDEN_FEATURES);
    yield put(getHiddenFeatures());
    const { success, failure } = yield race({
      success: take(GET_HIDDEN_FEATURES_SUCCESS),
      failure: take(GET_HIDDEN_FEATURES_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}

function* addHiddenFeatureSaga() {
  while (true) {
    const { id } = yield take(ADD_HIDDEN_FEATURE);
    yield put(addHiddenFeature(id));
    const { success, failure } = yield race({
      success: take(ADD_HIDDEN_FEATURE_SUCCESS),
      failure: take(ADD_HIDDEN_FEATURE_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(getHiddenFeatures());
    }
  }
}

function* deleteHiddenFeatureSaga() {
  while (true) {
    const { id } = yield take(DELETE_HIDDEN_FEATURE);
    yield put(deleteHiddenFeature(id));
    const { success, failure } = yield race({
      success: take(DELETE_HIDDEN_FEATURE_SUCCESS),
      failure: take(DELETE_HIDDEN_FEATURE_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(getHiddenFeatures());
    }
  }
}

function* addCustomFeatureSaga() {
  while (true) {
    const { params } = yield take(ADD_CUSTOM_FEATURE);
    const { type } = params;
    yield put(addCustomFeature(constructCustomFeatureParams(params)));
    const { success, failure } = yield race({
      success: take(ADD_CUSTOM_FEATURE_SUCCESS),
      failure: take(ADD_CUSTOM_FEATURE_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Success!', type === 'profileMenuTeamTile' ? 'Link added successfully' : 'Content added successfully', 'success'));
      yield put(getCustomFeatures());
    }
  }
}

function* updateCustomFeatureSaga() {
  while (true) {
    const { params } = yield take(UPDATE_CUSTOM_FEATURE);
    const { type } = params;
    yield put(updateCustomFeature(constructCustomFeatureParams(params)));
    const { success, failure } = yield race({
      success: take(UPDATE_CUSTOM_FEATURE_SUCCESS),
      failure: take(UPDATE_CUSTOM_FEATURE_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(showAlert('Success!', type === 'profileMenuTeamTile' ? 'Link updated successfully' : 'Content updated successfully', 'success'));
      yield put(getCustomFeatures());
    }
  }
}

const constructCustomFeatureParams = (params) => {
  let { id } = params;
  if (id === undefined) {
    id = uuidv4();
  }
  return {
    id,
    content: {
      ...params,
      id: undefined,
    }
  };
};

function* archiveCustomFeatureSaga() {
  while (true) {
    const { id } = yield take(ARCHIVE_CUSTOM_FEATURE);
    yield put(archiveCustomFeature(id));
    const { success, failure } = yield race({
      success: take(ARCHIVE_CUSTOM_FEATURE_SUCCESS),
      failure: take(ARCHIVE_CUSTOM_FEATURE_FAILURE),
    });
    if (failure) {
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    } else {
      yield put(getCustomFeatures());
    }
  }
}
