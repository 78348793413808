import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import Portion from './Portion';

class Workout extends Component {
  render() {
    const { workouts, workout_id } = this.props;
    const workout = workouts.find((w) => w.uuid === workout_id);
    const { is_checked_off } = workout;
    
    return (
      <div className="workout-container">
        <em className={is_checked_off ? 'fa fa-check-circle' : 'far fa-circle'} />
        Workout
      </div>
    );
  }
}

Workout.propTypes = {
  workouts: PropTypes.instanceOf(Array),
  workout_id: PropTypes.string,
};

export default Workout;
