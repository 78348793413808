import React from 'react';
import { Row, Col, Button, Card, CardHeader, Collapse, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import { submissionError } from '../../actions/formActions';
import { formatCentsAsCurrency } from '../../utils/numberUtils';
import { capitalizeFirst } from '../../utils/stringUtils';
import { signUpPlan, setSignUpStep } from '../../actions/signupActions';
import browserHistory from '../../history';
import faqs from '../../data/faq';
import ENV_VARS from '../../utils/envVars';

class SignUpPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: 'month',
      accordionState: faqs.map(() => false),
    };
  }

  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(3);
    }
  }

  /* id is the index in the accordionState array */
  toggleAccordion = (id) => {
    let accordionState = this.state.accordionState.map((val, i) => {
      return id === i ? !val : false;
    });
    this.setState({
      accordionState,
    });
  };

  render() {
    const { doSignUpPlan, signup } = this.props;
    const { activeButton } = this.state;
    let membershipOptions;
    let activeSubs;
    if (signup && signup.membershipOptions) {
      ({ membershipOptions } = signup);
      activeSubs = membershipOptions.filter((s) => s.interval === activeButton);
    }
    const buttons = [
      {
        title: 'Monthly',
        id: 'month',
      },
      {
        title: 'Yearly',
        id: 'year',
      },
    ];
    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5 text-center plan-selection-header"
          >
            <h1>Choose a plan that fits your business</h1>
            <div className="btn-group btn-group-toggle mt-4">
              {buttons.map((b) => (
                <label
                  key={b.id}
                  className={`btn btn-secondary ${activeButton === b.id && 'active'}`}
                >
                  <input
                    type="radio"
                    name="options"
                    id={b.id}
                    autoComplete="off"
                    defaultChecked={activeButton === b.id}
                    onClick={() => this.setState({ activeButton: b.id })}
                  />{' '}
                  {b.title}
                </label>
              ))}
              <div className="savings-badge">
                <div className="save-label">Save</div>
                <div className="save-value">10%</div>
              </div>
            </div>
            <img src="/img/signup/arrow-up.svg" />
            <h4>
              Try <span className="underlined">FREE</span> for {ENV_VARS.TRIAL_DAYS} days, risk-free!
            </h4>
          </Col>
        </Row>
        {activeSubs && activeSubs.length ? (
          <>
            <Row>
              {activeSubs.map((s, idx) => (
                <Col md={4} key={s.id}>
                  <div
                    key={s.id}
                    className={`subscription-container signup ${s.featured && 'featured'}`}
                  >
                    {s.featured && <div className="featured-callout">{s.callout}</div>}
                    <div className="ml-2 mr-2">
                      <h3>{s.name}</h3>
                      <div className="d-flex align-items-baseline">
                        <div className="price animate__animated animate__fadeIn">
                          ${s.displayPrice}
                        </div>
                        <div className="interval ml-1">/ {s.displayInterval}</div>
                      </div>
                      <div className="card-caption mb-4 pb-3 border-bottom">
                        Billed {s.interval === 'year' && formatCentsAsCurrency(s.price)}{' '}
                        {s.interval.replace('year', 'annual')}
                        ly
                      </div>

                      <div className="mb-4">
                        <h4>
                          <em className="mr-2 far fa-user-circle" />
                          {capitalizeFirst(s.subtitle)}
                        </h4>
                      </div>
                    </div>
                    <Button color="primary" size="lg" block onClick={() => doSignUpPlan(s.id)}>
                      Try Free for {ENV_VARS.TRIAL_DAYS} Days
                    </Button>
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col className="text-center">
                *Active clients are clients that sign up for the Macrostax app on your Team. Invited
                clients do not count toward your active user count.
              </Col>
            </Row>
            <Row className="border-bottom mb-5 pb-5">
              <Col className="margin-top-80 text-center cta-container">
                <h2>Supporting more than 100 active clients?</h2>
                No problem, we can handle any amount of clients. Please reach out to us for special
                pricing.
                <div className="mt-3">
                  <a
                    href="mailto:lindsey@macrostax.com?subject=Macrostax+Team+Inquiry"
                    target="_blank"
                  >
                    <Button className="fixed-width-lg btn-team" size="lg">
                      Contact us
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Loading />
        )}
        <Row className="">
          <Col className="margin-top-20 text-center cta-container">
            <div className="d-flex align-items-center justify-content-center mb-5">
              <h2 className="mr-4 mb-0">Frequently asked questions</h2>
              <img src="/img/signup/max.svg" />
            </div>
            <div className=" d-flex flex-column faq-container">
              {faqs.map((f, idx) => (
                <Card
                  className="card-default mb-2 card-faq"
                  key={f.id}
                  onClick={() => this.toggleAccordion(idx)}
                >
                  <CardHeader>
                    <h4 className="mb-0">
                      <a className="text-inherit">{f.question}</a>
                    </h4>
                    <em
                      className={`icon-arrow-${
                        this.state.accordionState[idx] ? 'down' : 'right'
                      } mr-2`}
                    />
                  </CardHeader>
                  <Collapse isOpen={this.state.accordionState[idx]}>
                    {/* <CardBody>{f.answer}</CardBody> */}
                    <CardBody dangerouslySetInnerHTML={{ __html: f.answer }} />
                  </Collapse>
                </Card>
              ))}
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpPlans.propTypes = {
  doSignUpPlan: PropTypes.func,
  subscriptions: PropTypes.instanceOf(Array),
  signup: PropTypes.instanceOf(Object),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpPlan: (planId) => {
    dispatch(signUpPlan(planId));
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPlans);
