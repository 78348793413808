import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import ReactGA from 'react-ga4';
import PageTitle from '../components/Common/PageTitle';

class CobrandBestPracticesModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/cobrandBestPractices', title: 'Cobrand Best Practices' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} scrollable={true}>
        <ModalHeader toggle={toggle}>
          <PageTitle title="Branding Best Practices" showTeam={false} />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={4}>
              <img
                src="/img/cooplin.webp"
                alt="Cooplin"
              />
            </Col>
            <Col>
              <p>
                When uploading a logo image to add your brand to the Macrostax app, the following tips will ensure that you can maximize your brand presence:
              </p>
              <ul className="breathing-room">
                <li>
                  Horizontal logo images work best (like the logo in our example), though we've tried to support just about any aspect ratio.
                </li>
                <li>
                  Try to crop your image so that there is no additional padding beyond the artwork in the image itself. For example, the raw logo file we used in the example
                  is below. We've added a border to it so you can see how there is no additional padding in the image at all (right-click and save image to see it full-size):
                  <br />
                  <img src="/img/cooplin.png" className="mt-2" style={{border: '1px solid black', width: '50%'}} />
                </li>
                <li>
                  We will automatically stretch your image to the maximum width <em>or</em> height that we can fit, and then add padding around it. We do this so that we can allocate space
                  to the bottom half of the plus (+) button on the home screen, and still have room for the "Powered by Macrostax" text. By cropping your image to just the edge of
                  the artwork itself, you'll maximize the amount of artwork that is visible in the branded area.
                </li>
                <li>
                  Once you've added your image, take advantage of the background color selector to add even more branding. We recommend picking one of your brand colors if your logo is transparent,
                  or, if the image is not transparent, select the background color that is in the image itself for a seamless transition. By clicking on the box you'll get a color picker where you can
                  select any color you like, and/or enter RGB or Hex values if you know which specific color you need.
                  <div className="mt-2 d-flex flex-row align-items-center justify-content-center">
                    <img src="/img/colorpicker.png" style={{ width: '200px' }} />
                    <img src="/img/colorpicker2.png" style={{ width: '200px' }} />
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

CobrandBestPracticesModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default CobrandBestPracticesModal;
