const MICRO_NUTRIENTS_NAMES = Object.freeze({
  fiber: 'fiber',
  added_sugar: 'added sugar',
  sodium: 'sodium',
  cholesterol: 'cholesterol',
  iron: 'iron',
  potassium: 'potassium',
  vitamin_c: 'vitamin c',
  calcium: 'calcium',
});

// Calculates Cholesterol
function getCholesterolTarget() {
  return 300;
}

// Calculates Fiber
function getFiberTarget(calories) {
  // eslint-disable-next-line no-mixed-operators
  return Math.round(calories / 1000 * 14);
}

// Calculates sugar
function getSugarTarget(calories) {
  return Math.round(calories / 40);
}

// Calculates sodium
function getSodiumTarget() {
  return 2300;
}

// Calculates Potassium
function getPotassiumTarget() {
  return 4700;
}

// Calculates Iron
function getIronTarget() {
  return 18;
}

// Calculates Vitamin C
function getVitaminCTarget(gender) {
  if (gender.toLowerCase() === 'female') {
    return 75;
  }

  return 90;
}

// Calculates calcium
function getCalciumTarget(age, gender) {
  if (age <= 18) {
    return 1300;
  }
  if (age <= 50) {
    return 1000;
  }
  if (age <= 70) {
    return gender.toLowerCase() === 'male' ? 1000 : 1200;
  }

  return 1200;
}

  // Given the name of the micronutrient, gives the target amount
export function getTargetFromMicroName({ name, age, gender, calories }) {
  const lowerCaseName = name.toLowerCase();

  switch (lowerCaseName) {
    case MICRO_NUTRIENTS_NAMES.fiber:
      return getFiberTarget(calories);

    case MICRO_NUTRIENTS_NAMES.added_sugar:
      return getSugarTarget(calories);

    case MICRO_NUTRIENTS_NAMES.sodium:
      return getSodiumTarget();

    case MICRO_NUTRIENTS_NAMES.cholesterol:
      return getCholesterolTarget();

    case MICRO_NUTRIENTS_NAMES.iron:
      return getIronTarget();

    case MICRO_NUTRIENTS_NAMES.potassium:
      return getPotassiumTarget();

    case MICRO_NUTRIENTS_NAMES.vitamin_c:
      return getVitaminCTarget(gender);

    case MICRO_NUTRIENTS_NAMES.calcium:
      return getCalciumTarget(age, gender);

    default:
      return null;
  }
}
