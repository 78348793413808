import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import renderSelectForField from './helpers/renderSelectForField';
import {
  getGoalOptionsFromTeamProfile,
  getPhaseOptions,
  getActivityLevelOptionsFromTeamProfile,
} from '../utils/teamProfileUtils';

class GoalForm extends Component {
  constructor(props) {
    super(props);
    const { teamProfile, initialValues } = this.props;
    const { goal_id } = initialValues;
    const goalOptions = getGoalOptionsFromTeamProfile(teamProfile);
    const activityLevelOptions = getActivityLevelOptionsFromTeamProfile(teamProfile);
    const phaseOptions = getPhaseOptions(goal_id, goalOptions);
    this.state = {
      goalOptions,
      activityLevelOptions,
      phaseOptions,
    };
  }

  filterPhaseOptions = (goal_id, form) => {
    const { goalOptions } = this.state;
    const phaseOptions = getPhaseOptions(goal_id, goalOptions);
    this.setState({
      phaseOptions,
    }, () => {
      form.change('phase_id', phaseOptions[0].value);
    });
  }

  render() {
    const { initialValues, onSubmit, showSubmissionError, teamProfile } = this.props;
    const { goalOptions, activityLevelOptions, phaseOptions } = this.state;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            // fields aren't clearable so no need to validate
            onSubmit(values);
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting, form, ...props }) => {
            return (
              <form onSubmit={handleSubmit} className="inline-form">
                <Row>
                  <Col>
                    <Field
                      name="goal_id"
                      label="Goal"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: goalOptions,
                          isClearable: false,
                        },
                      }}
                    />
                    <OnChange name="goal_id">
                      {(value) => {
                        this.filterPhaseOptions(value, form);
                      }}
                    </OnChange>
                    <Field
                      name="phase_id"
                      label="Phase"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: phaseOptions,
                          isClearable: false,
                        },
                      }}
                    />
                    <Field
                      name="activity_level_id"
                      label="Activity Level"
                      component={renderSelectForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          options: activityLevelOptions,
                          isClearable: false,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button className="btn btn-lg btn-primary" type="submit" disabled={submitting}>
                      Save
                    </button>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

GoalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  teamProfile: PropTypes.instanceOf(Object),
};

export default GoalForm;
