import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Button, Col, Input, Row } from 'reactstrap';
import UserAvatar from '../../components/Common/UserAvatar';
import ENV_VARS from '../../utils/envVars';

export class RenderUploadForFieldComponent extends Component {
  constructor(props) {
    super(props);
    const { input } = this.props;
    const files = [];
    if (input && input.value && input.value.length) {
      files.push(input.value);
    }
    this.state = {
      files,
    };
  }

  uploadWidget = () => {
    const { input } = this.props;
    const { onChange } = input;
  };

  createImageItem = (file, index) => (
    <Col md={3} key={index}>
      <img className="img-fluid mb-2" src={file} alt="Item" />
    </Col>
  );

  render() {
    const {
      input,
      label,
      caption,
      interfaceType,
      itemProps,
      inputProps,
      iconClassName,
      team,
      user,
    } = this.props;

    const { files } = this.state;

    let labelClassName = '';
    let inputClassName = 'width-100-percent';

    if (itemProps.row) {
      labelClassName = 'col-xl-3 col-form-label';
      inputClassName = 'col-xl-9';
    }

    const showLabel = () => {
      if (label && label.length) {
        return (
          <label className={labelClassName}>
            {label}
            {caption !== undefined && caption.length ? (
              <div className="caption">{caption}</div>
            ) : null}
          </label>
        );
      }
      return null;
    };
    const showError = () => null;
    const cpt = 'Recommended dimensions are 400 x 400 pixels';
    const ttl = 'Upload photo';
    return (
      <FormGroup {...itemProps} className="d-flex upload-field flex-row centered">
        {/* {showLabel()} */}
        <div className={inputClassName}>
          {interfaceType === 'button' ? (
            <div>
              <Button className="btn btn-outline" onClick={() => this.uploadWidget()}>
                Upload file
              </Button>
              <div className="caption margin-top-5">{cpt}</div>
            </div>
          ) : (
            <Button onClick={this.uploadWidget}>
              <div className="d-flex align-items-center">
                {user ? (
                  <UserAvatar user={user} />
                ) : (
                  <div>
                    {team.logoUrl && <img src={team.logoUrl} className="team-logo-upload" />}
                  </div>
                )}
                {label && (
                  <div className="ml-3 d-flex align-items-center">
                    <em className="fa-2x icon-camera mr-2" />
                    {label}
                  </div>
                )}
              </div>
            </Button>
          )}
          <div className="mt-3 thumbnails">
            {files.length > 0 ? <Row>{files.map(this.createImageItem)}</Row> : null}
          </div>
          <Input {...input} {...inputProps} />
        </div>
        {showError()}
      </FormGroup>
    );
  }
}

RenderUploadForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
  }),
  label: PropTypes.string,
  iconName: PropTypes.string,
  caption: PropTypes.string,
  inputCaption: PropTypes.string,
  stackedlabel: PropTypes.bool,
  iconType: PropTypes.string,
  iconClassName: PropTypes.string,
  refField: PropTypes.string,
  interfaceType: PropTypes.string,
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    multiline: PropTypes.bool,
    rowSpan: PropTypes.number,
  }),
};

export default forwardRef((props, ref) => (
  <RenderUploadForFieldComponent innerRef={ref} {...props} />
));
