import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import ContentWrapper from '../components/Layout/ContentWrapper';
import PageTitle from '../components/Common/PageTitle';

class Unauthorized extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Unauthorized" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row>
            <Col xs={12}>
              Sorry, you do not have access to the requested resource. Please try again.
            </Col>
          </Row>
        </div>
      </ContentWrapper>
    );
  }
}

Unauthorized.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Unauthorized);
