import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import UserInfoFormPartial from './partials/UserInfoFormPartial';
import AddressFormPartial from './partials/AddressFormPartial';
import renderInputForField from './helpers/renderInputForField';
import renderColorPickerForField from './helpers/renderColorPickerForField';
import renderImagePickerForField from './helpers/renderImagePickerForField';
import CobrandPreviewModal from '../modals/CobrandPreviewModal';
import CobrandBestPracticesModal from '../modals/CobrandBestPracticesModal';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  // if (!values.phone) {
  //   errors.phone = 'Phone number is required';
  // }
  if (!values.name) {
    errors.name = 'Name of gym is required';
  }
  return errors;
};

class BusinessSettingsForm extends Component {
  constructor(props) {
    super(props);
    const { initialValues } = props;
    const { logo_background_color = '#ffffff', logo_image = '', name = '' } = initialValues;
    this.state = {
      logo_background_color,
      logo_image,
      cobrandPreviewModalOpen: false,
      cobrandBestPracticesModalOpen: false,
      team_name: name,
    };
  }

  toggleCobrandPreviewModal = () => {
    const { cobrandPreviewModalOpen } = this.state;
    this.setState({
      cobrandPreviewModalOpen: !cobrandPreviewModalOpen,
    });
  };

  toggleCobrandBestPracticesModal = () => {
    const { cobrandBestPracticesModalOpen } = this.state;
    this.setState({
      cobrandBestPracticesModalOpen: !cobrandBestPracticesModalOpen,
    });
  };

  bestPracticesLink = () => {
    return (
      <span className="span-link caption" onClick={this.toggleCobrandBestPracticesModal}>Need help? Here are our tips.</span>
    );
  }

  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      onCancel,
      teamSettings,
      showPhotoUpload = false,
      showCancel = false,
    } = this.props;
    const { logo_background_color, logo_image, cobrandPreviewModalOpen, team_name, cobrandBestPracticesModalOpen } = this.state;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <UserInfoFormPartial
                    showPhone
                    showPhotoUpload={showPhotoUpload}
                    showFirstName={false}
                    showLastName={false}
                    team={teamSettings}
                  />
                  <Field
                    name="name"
                    label="Name of Gym"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                  <OnChange name="name">
                    {(value) => {
                      this.setState({ team_name: value });
                    }}
                  </OnChange>
                  <AddressFormPartial />
                  {logo_image === '' ? (
                    <>
                      <Field
                        name="logo_image"
                        label="Logo Image"
                        component={renderImagePickerForField}
                        stackedlabel
                        backgroundColor={logo_background_color}
                        {...{
                          itemProps: {},
                          inputProps: {
                            type: 'imagePicker',
                          },
                        }}
                      />
                      {this.bestPracticesLink()}
                      <OnChange name="logo_image">
                        {(value) => {
                          this.setState({ logo_image: value || '' });
                        }}
                      </OnChange>
                    </>
                  ) : (
                    <>
                      <label style={{ fontSize: '.75rem' }}>Logo Image</label>
                      <div
                        className="logo-image-container"
                        style={{ backgroundColor: logo_background_color }}
                        onClick={() => this.setState({ logo_image: '' })}
                      >
                        <img src={logo_image} className="logo-img" />
                      </div>
                    </>
                  )}
                  {logo_image !== '' && logo_image.toLowerCase().indexOf('data:') === 0 && (
                    <>
                      <Field
                        name="logo_background_color"
                        label="Logo Background Color"
                        component={renderColorPickerForField}
                        stackedlabel
                        {...{
                          itemProps: {},
                          inputProps: {},
                        }}
                      />
                      <OnChange name="logo_background_color">
                        {(value) => {
                          this.setState({ logo_background_color: value });
                        }}
                      </OnChange>
                    </>
                  )}
                  {logo_image !== '' && logo_background_color !== '' && (
                    <>
                      <div className="mt-1">
                        <span
                          className="span-link caption"
                          onClick={this.toggleCobrandPreviewModal}
                        >
                          Preview in app home screen
                        </span>
                        <span className="ml-2" />|<span className="ml-2" />
                        {this.bestPracticesLink()}
                      </div>
                      <CobrandPreviewModal
                        logo_background_color={logo_background_color}
                        isOpen={cobrandPreviewModalOpen}
                        toggle={this.toggleCobrandPreviewModal}
                        logo_image={logo_image}
                        team_name={team_name}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  {showCancel && (
                    <Button
                      color="secondary"
                      size="lg"
                      className="fixed-width-md mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button color="primary" size="lg" type="submit" className="fixed-width-md">
                    Save
                  </Button>
                </Col>
              </Row>
              <CobrandBestPracticesModal
                isOpen={cobrandBestPracticesModalOpen}
                toggle={this.toggleCobrandBestPracticesModal}
              />
            </form>
          )}
        />
      </div>
    );
  }
}

BusinessSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
  teamSettings: PropTypes.instanceOf(Object),
  showCancel: PropTypes.bool,
};

export default BusinessSettingsForm;
