import { all, take } from 'redux-saga/effects';
import { TOGGLE_SETTING, CHANGE_SETTING } from '../actions/layoutActions';
import { updateBodyClass } from '../utils/layout';

export default function* layoutSagas() {
  yield all([toggleSettingSaga()]);
}

function* toggleSettingSaga() {
  while (true) {
    const { name, value } = yield take([TOGGLE_SETTING, CHANGE_SETTING]);
    updateBodyClass(name, value);
  }
}
