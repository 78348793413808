import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  ButtonToolbar,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

class StatisticSelectCard extends Component {
  constructor(props) {
    super(props);
    const { dropdown } = this.props;
    this.state = {
      ddOpen: false,
      activeDropdownItem: dropdown[0],
    };
  }

  toggleDD = () => {
    const { ddOpen } = this.state;
    this.setState({
      ddOpen: !ddOpen,
    });
  };

  onChange = (option) => {
    const { data, dropdown } = this.props;
    this.setState({
      activeDropdownItem: dropdown.find((d) => d.key === option),
    });
  };

  render() {
    const { header, caption, dropdown = [], data } = this.props;
    const { ddOpen, activeDropdownItem } = this.state;
    const selectedData = data[activeDropdownItem.key];
    const { count, statistic2 } = selectedData;

    return (
      <Card className="card-default card-container mb-3 statistic-card">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <div>{header}</div>
            {dropdown.length > 0 && (
              <ButtonToolbar>
                <ButtonDropdown isOpen={ddOpen} toggle={() => this.toggleDD()}>
                  <DropdownToggle caret>{activeDropdownItem.value}</DropdownToggle>
                  <DropdownMenu>
                    {dropdown.map((d) => (
                      <DropdownItem key={d.key} onClick={() => this.onChange(d.key)}>
                        {d.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </ButtonToolbar>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <div>
            <span className="statistic mr-2">{count || 0}</span>
            {statistic2 && <span>/ {statistic2}</span>}
          </div>

          {caption && <div className="card-caption">{caption}</div>}
        </CardBody>
      </Card>
    );
  }
}

StatisticSelectCard.propTypes = {
  header: PropTypes.string,
  statistics: PropTypes.instanceOf(Array),
  caption: PropTypes.string,
};

export default StatisticSelectCard;
