import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

class Base extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className="wrapper">
        <Header />
        <div className="d-flex">
          <Sidebar />
          <section className="section-container">{children}</section>
        </div>
        <Footer />
      </div>
    );
  }
}

Base.propTypes = {
  children: PropTypes.instanceOf(Object),
};

export default Base;
