import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firstBy, thenBy } from 'thenby';
import {
  ButtonToolbar,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from 'reactstrap';
import UserAvatar from './UserAvatar';
import { authLogout, switchTeams } from '../../actions/authActions';
import { isTeamUser, isAuthorized } from '../../utils/authUtils';
import { Roles } from '../../enums/roles';
import browserHistory from '../../history';

class HeaderUserBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ddOpen: false,
    };
  }

  toggleDD = () => {
    const { ddOpen } = this.state;
    this.setState({
      ddOpen: !ddOpen,
    });
  };

  render() {
    const { user, doLogout, doSwitchTeams, team = {} } = this.props;
    const { first_name, last_name, notifications = [], roles = [], email, password } = user || {};
    let { teams_list = [] } = user || {};
    const badgeCount = notifications.filter((n) => !n.read).length.toString();
    const { ddOpen } = this.state;
    const dropdown = [
      {
        label: 'My Account Settings',
        url: '/settings',
        roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
      },
      /*
      {
        label: 'Notifications',
        url: '/inbox',
        showBadge: true,
        roles: [Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
      },
      */
    ];

    if (teams_list !== undefined && teams_list.length > 1) {
      teams_list = teams_list.sort(firstBy('team_name'));
      for (let i = 0; i < teams_list.length; i += 1) {
        const this_team = teams_list[i];
        const { team_name, team_id } = this_team;
        dropdown.push({
          label: team_name,
          onClick: () => doSwitchTeams(team_id),
        });
      }
    }

    dropdown.push({
      label: 'Log Out',
      onClick: doLogout,
      // roles: [Roles.INTERNAL_ADMIN, Roles.TEAM_OWNER, Roles.TEAM_ADMIN],
    });
    return (
      <div>
        {user && (
          <ButtonToolbar className="header-user-menu">
            <ButtonDropdown
              isOpen={ddOpen}
              toggle={(e) => {
                e.preventDefault();
                this.toggleDD();
              }}
            >
              <DropdownToggle>
                <>
                  <UserAvatar
                    user={user}
                    badgeCount={ddOpen ? '0' : isTeamUser(roles) ? badgeCount : '0'}
                  />
                  <div className="user-block">
                    <div className="user-block-info d-none d-md-flex">
                      <span className="user-block-name">
                        {`${first_name} ${last_name}`}
                        <em className="ml-2 fas fa-bars" />
                        {/* <em className="fa-1x ml-2 fas fa-caret-down" /> */}
                      </span>
                    </div>
                  </div>
                </>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-right">
                {dropdown.map(
                  (d) =>
                    isAuthorized(roles, d) && (
                      <DropdownItem
                        key={d.label}
                        onClick={() => (d.onClick ? d.onClick() : browserHistory.push(d.url))}
                      >
                        {d.label}
                        {d.showBadge && badgeCount > 0 && (
                          <Badge tag="div" className="ml-2" color="danger">
                            {badgeCount}
                          </Badge>
                        )}
                      </DropdownItem>
                    )
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </ButtonToolbar>
        )}
      </div>
    );
  }
}

HeaderUserBlock.propTypes = {
  user: PropTypes.instanceOf(Object),
  doLogout: PropTypes.func,
  doSwitchTeams: PropTypes.func,
  team: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  team: state.team,
});

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(authLogout(true)),
  doSwitchTeams: (team_id) => dispatch(switchTeams(team_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserBlock);
