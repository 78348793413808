import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_TOKEN_STORED,
  AUTH_LOGIN_REQUEST,
  AUTHORIZE_REQUEST,
  AUTH_USER_ID_RETRIEVED,
  PASSWORD_UPDATE_REQUIRED,
  SET_CREDENTIALS,
  SET_TEAMS_LIST,
  SWITCH_TEAMS,
} from '../actions/authActions';
import { AUTH_BASIC_SUCCESS, AUTHORIZE_SUCCESS } from '../apiActions/authApiActions';
import { AUTH_USER_PROFILE_SUCCESS } from '../apiActions/userApiActions';
import { MARK_NOTIFICATION_AS_READ, REMOVE_NOTIFICATION } from '../actions/notificationActions';
import { Roles } from '../enums/roles';
import envVars from '../utils/envVars';
import { randomIntFromInterval } from '../utils/dataGeneratorUtils';

const initialAuthState = {
  isLoggedIn: false,
};

export default (state = initialAuthState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
    case AUTH_TOKEN_STORED: {
      const { user_id } = action;
      return {
        ...state,
        isLoggedIn: true,
        user_id,
      };
    }
    case AUTH_BASIC_SUCCESS:
    case AUTHORIZE_SUCCESS: {
      const { data } = action;
      const { userId, jwtToken, refreshToken } = data;
      return {
        ...state,
        userId,
        jwtToken,
        refreshToken,
      };
    }
    case AUTH_LOGOUT: {
      // case SWITCH_TEAMS: {
      window.RF = undefined;
      return {
        ...initialAuthState,
      };
    }
    case SWITCH_TEAMS: {
      const { team_id } = action;
      return {
        ...state,
        jwtToken: undefined,
        refreshToken: undefined,
        isLoggedIn: false,
        roles: undefined,
        team_id,
      };
    }
    case AUTH_LOGIN_REQUEST:
    case AUTHORIZE_REQUEST: {
      if (action.username) {
        state.username = action.username;
      } else if (action.payload && action.payload.username) {
        state.username = action.payload.username;
      }
      return {
        ...state,
      };
    }
    case MARK_NOTIFICATION_AS_READ: {
      const { id } = action;
      const { user = {} } = state;
      const { notifications = [] } = user;
      const notification = notifications.find((n) => n.id === id);
      if (notification) {
        notification.read = true;
      }
      return {
        ...state,
        user: {
          ...user,
          notifications,
        },
      };
    }
    case REMOVE_NOTIFICATION: {
      const { id } = action;
      const { user = {} } = state;
      const { notifications = [] } = user;
      const idx = notifications.findIndex((n) => n.id === id);
      if (idx !== -1) {
        notifications.splice(idx, 1);
      }
      return {
        ...state,
        user: {
          ...user,
          notifications,
        },
      };
    }
    case AUTH_USER_PROFILE_SUCCESS: {
      const { user_id, first_name, last_name, profile_photo, email, phone } = action.data;
      window.RF = {
        user: {
          first_name,
          email,
        },
      };
      // const script = document.createElement('script');
      // script.setAttribute('data-code', 'Vm1uHG');
      // script.setAttribute('class', 'rf-popup-script');
      // script.id = 'rf-script';
      // script.src = 'https://referral-factory.com/assets/js/popup.js?code=Vm1uHG';
      // document.body.appendChild(script);
      return {
        ...state,
        user: {
          ...state.user,
          user_id,
          first_name,
          last_name,
          profile_photo,
          email,
          phone,
        },
      };
    }
    case AUTH_USER_ID_RETRIEVED: {
      const { role, team_id } = action;
      return {
        ...state,
        user: {
          ...state.user,
          roles: [
            (role === 'team_owner' || role === 'team_admin') &&
            team_id === envVars.MACROSTAX_TEAM_ID
              ? Roles.INTERNAL_ADMIN
              : role,
          ],
          team_id,
        },
      };
    }
    case PASSWORD_UPDATE_REQUIRED: {
      const { required } = action;
      return {
        ...state,
        passwordUpdateRequired: required,
      };
    }
    case SET_CREDENTIALS: {
      const { user_first_name, email, password, teams_list } = action;
      return {
        ...state,
        user: {
          user_first_name,
          email,
          password,
          teams_list,
        },
      };
    }
    case SET_TEAMS_LIST: {
      const { teams_list } = action;
      return {
        ...state,
        user: {
          ...state.user,
          teams_list,
        },
      };
    }
    default:
      return state;
  }
};
