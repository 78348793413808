import Qs from 'qs';
import ENV_VARS from '../utils/envVars';

export const GET_ALL_CHALLENGES_SUCCESS = 'GET_ALL_CHALLENGES_SUCCESS';
export const GET_ALL_CHALLENGES_FAILURE = 'GET_ALL_CHALLENGES_FAILURE';
export const GET_CHALLENGE_SUCCESS = 'GET_CHALLENGE_SUCCESS';
export const GET_CHALLENGE_FAILURE = 'GET_CHALLENGE_FAILURE';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
export const GET_ACTIONS_FAILURE = 'GET_ACTIONS_FAILURE';
export const ADD_CHALLENGE_SUCCESS = 'ADD_CHALLENGE_SUCCESS';
export const ADD_CHALLENGE_FAILURE = 'ADD_CHALLENGE_FAILURE';
export const UPDATE_CHALLENGE_SUCCESS = 'UPDATE_CHALLENGE_SUCCESS';
export const UPDATE_CHALLENGE_FAILURE = 'UPDATE_CHALLENGE_FAILURE';
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAILURE = 'GET_LEADERBOARD_FAILURE';
export const UPSERT_CHALLENGE_CLIENTS_SUCCESS = 'UPSERT_CHALLENGE_CLIENTS_SUCCESS';
export const UPSERT_CHALLENGE_CLIENTS_FAILURE = 'UPSERT_CHALLENGE_CLIENTS_FAILURE';
export const GET_USER_SCORES_FOR_CHALLENGE_SUCCESS = 'GET_USER_SCORES_FOR_CHALLENGE_SUCCESS';
export const GET_USER_SCORES_FOR_CHALLENGE_FAILURE = 'GET_USER_SCORES_FOR_CHALLENGE_FAILURE';
export const ADD_BONUS_POINTS_SUCCESS = 'ADD_BONUS_POINTS_SUCCESS';
export const ADD_BONUS_POINTS_FAILURE = 'ADD_BONUS_POINTS_FAILURE';
export const RECALCULATE_CHALLENGE_SCORES_SUCCESS = 'RECALCULATE_CHALLENGE_SCORES_SUCCESS';
export const RECALCULATE_CHALLENGE_SCORES_FAILURE = 'RECALCULATE_CHALLENGE_SCORES_FAILURE';
export const ARCHIVE_CHALLENGE_SUCCESS = 'ARCHIVE_CHALLENGE_SUCCESS';
export const ARCHIVE_CHALLENGE_FAILURE = 'ARCHIVE_CHALLENGE_FAILURE';

export function getAllChallenges(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/list-challenges',
    method: 'GET',
    params: {
      team_id,
    }
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_ALL_CHALLENGES_SUCCESS,
    failureAction: GET_ALL_CHALLENGES_FAILURE,
  };
}

export function getChallenge(challenge_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge',
    method: 'GET',
    params: { 
      challenge_id
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_CHALLENGE_SUCCESS,
    failureAction: GET_CHALLENGE_FAILURE,
  };
}

export function getLeaderboard(challenge_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge/leaderboard',
    method: 'GET',
    params: { 
      challenge_id
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_LEADERBOARD_SUCCESS,
    failureAction: GET_LEADERBOARD_FAILURE,
  };
}

export function getUserScoresForChallenge(user_id, challenge_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge/scores',
    method: 'GET',
    params: { 
      user_id,
      challenge_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_USER_SCORES_FOR_CHALLENGE_SUCCESS,
    failureAction: GET_USER_SCORES_FOR_CHALLENGE_FAILURE,
  };
}

export function getActions() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/list-actions',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_ACTIONS_SUCCESS,
    failureAction: GET_ACTIONS_FAILURE,
  };
}

export function addChallenge(team_id, values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/create-challenge',
    method: 'POST',
    data: {
      ...values,
    }
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_CHALLENGE_SUCCESS,
    failureAction: ADD_CHALLENGE_FAILURE,
  };
}

export function updateChallenge(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/update-challenge',
    method: 'PUT',
    data: values,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_CHALLENGE_SUCCESS,
    failureAction: UPDATE_CHALLENGE_FAILURE,
  };
}

export function upsertChallengeClients(challenge_id, user_ids) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge/consumers',
    method: 'POST',
    data: {
      challenge_id,
      user_ids,
    }
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPSERT_CHALLENGE_CLIENTS_SUCCESS,
    failureAction: UPSERT_CHALLENGE_CLIENTS_FAILURE,
  };
}

export function addBonusPoints(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge/bonus',
    method: 'POST',
    data: {
      ...values,
    }
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_BONUS_POINTS_SUCCESS,
    failureAction: ADD_BONUS_POINTS_FAILURE,
  };
}

export function recalculateChallengeScores(challenge_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge/recalc',
    method: 'GET',
    params: {
      challenge_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: RECALCULATE_CHALLENGE_SCORES_SUCCESS,
    failureAction: RECALCULATE_CHALLENGE_SCORES_FAILURE,
  };
}

export function archiveChallenge(challenge_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/challenge',
    method: 'DELETE',
    params: {
      challenge_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ARCHIVE_CHALLENGE_SUCCESS,
    failureAction: ARCHIVE_CHALLENGE_FAILURE,
  };
}