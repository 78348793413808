import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Row, Col, Button } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';
import renderSelectForField from './helpers/renderSelectForField';
import { customFeatureIconOptions } from '../enums/customFeatureIcons';
import renderCheckboxForField from './helpers/renderCheckboxForField';

const validate = (values, type) => {
  // type options: [homeFeatureCard, profileMenuTeamTile]
  const errors = {};
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (type === 'profileMenuTeamTile') {
    if (!values.icon) {
      errors.date = 'Icon is required';
    }
    if (!values.title) {
      errors.title = 'Title is required';
    }
    if (!values.link) {
      errors.url = 'URL is required';
    }
  } else if (type === 'homeFeatureCard') {
    if (!values.link) {
      // do nothing, that's ok
    } else {
      const lowerLink = values.link.trim().toLowerCase();
      if (!lowerLink.includes('https://')) {
        errors.url = 'Please enter the url starting with "https://"';
      }
    }
    if (!values.description) {
      errors.description = 'Content is required';
    }
  }
  return errors;
};

class CustomFeatureForm extends Component {
  render() {
    const {
      initialValues = {},
      onSubmit,
      showSubmissionError,
      type = 'profileMenuTeamTile',
    } = this.props;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values, type);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col sm={3}>
                  <Field
                    name="icon"
                    label="Icon*"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: customFeatureIconOptions,
                        isClearable: false,
                      },
                    }}
                  />
                </Col>
                <Col sm={9}>
                  <Field
                    name="title"
                    label="Title*"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="link"
                    label={`URL${type === 'profileMenuTeamTile' ? '*' : ' (optional)'}`}
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                </Col>
              </Row>
              {type === 'homeFeatureCard' && (
                <Row>
                  <Col>
                    <Field
                      name="description"
                      label="Content*"
                      component={renderInputForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          type: 'textarea',
                          rows: 3,
                        },
                      }}
                    />
                  </Col>
                </Row>
              )}
              {type === 'profileMenuTeamTile' && (
                <div className="d-flex align-items-center checkbox-row">
                  <Field
                    name="open_outside_app"
                    component={renderCheckboxForField}
                    {...{
                          itemProps: {},
                          checkboxIcon: 'fa-check',
                          checkboxIconUnchecked: 'fa-close',
                          inputProps: {},
                        }}
                  />
                  <div>
                    Open the URL outside the app
                  </div>
                </div>
              )}
              <Row className="mt-5">
                <Col xs={6} md={3}>
                  <Button color="primary" size="lg" type="submit" block>
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

CustomFeatureForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default CustomFeatureForm;
