import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Row, Col, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import moment from 'moment';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import TeamDashboardContainer from '../../containers/Teams/TeamDashboardContainer';
import { isTeamUser } from '../../utils/authUtils';
import { getEngagementSummary, getClientStatusAndGoalDistribution, getEngagementSummaryCsv, clearEngagementSummaryCsv } from '../../actions/teamActions';
import browserHistory from '../../history';
import Groups from './Groups';
import { getGroups } from '../../actions/groupActions';

const now = moment();
let dashboardStartDate = now.clone().add(-30, 'days');
let dashboardEndDate = now;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'all',
      sampleModalOpen: false,
      onboardingModalOpen: false,
    };
  }

  componentDidMount() {
    const { doGetEngagementSummary, doGetClientStatusAndGoalDistribution } = this.props;
    const {
      location: { search },
    } = browserHistory;

    const searchParams = queryString.parse(search);
    if (searchParams && searchParams.onboarding) {
      this.toggleOnboardingModal();
    }
    doGetEngagementSummary();
    doGetClientStatusAndGoalDistribution();

    if (window.location.hash) {
      setTimeout(() => {
        this.toggleTab(window.location.hash.replace('#', ''), false);
      });
    }
  }

  toggleSampleModal = () => {
    const { sampleModalOpen } = this.state;
    this.setState({
      sampleModalOpen: !sampleModalOpen,
    });
  };

  toggleOnboardingModal = () => {
    const { onboardingModalOpen } = this.state;
    this.setState({
      onboardingModalOpen: !onboardingModalOpen,
    });
  };

  toggleInviteClientModal = () => {
    const { inviteClientModalOpen } = this.state;
    this.setState({
      inviteClientModalOpen: !inviteClientModalOpen,
    });
  };

  updateEngagementSummary = (team_id, startDate, endDate, sortBy, order, page, showCount, clearData = false) => {
    const { doGetEngagementSummary } = this.props;
    dashboardStartDate = startDate;
    dashboardEndDate = endDate;
    doGetEngagementSummary(
      team_id,
      dashboardStartDate,
      dashboardEndDate,
      sortBy,
      order,
      page,
      showCount,
      clearData,
      undefined,
    );
  };

  toggleTab = (activeTab, doPush = true) => {
    this.setState(
      {
        activeTab,
      },
      () => {
        if (activeTab === 'all') {
          this.props.doGetEngagementSummary();
          this.props.doGetClientStatusAndGoalDistribution();
        }
        if (doPush) {
          window.history.pushState('', '', `/dashboard#${activeTab}`);
          this.props.doGetGroups();
        }
      }
    );
  };

  render() {
    const tabButtons = [
      {
        label: 'Clients Summary',
        id: 'all',
        icon: 'fa fa-users',
        onClick: () => this.toggleTab('all'),
      },
      {
        label: 'Groups',
        id: 'groups',
        icon: 'fa fa-user-friends',
        onClick: () => this.toggleTab('groups'),
      },
    ];

    const { team, user, doGetEngagementSummaryCsv, doClearEngagementSummaryCsv } = this.props;
    const { roles } = user;
    const { activeTab } = this.state;

    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Dashboard" />
            {!isTeamUser(roles) && (
              <div className="main-content-container">
                <div className="mb-3">{roles}-specific dashboard will go here...</div>
              </div>
            )}
          </Col>
        </Row>
        {isTeamUser(roles) && team && team.engagementSummary && team.membership && team.counts && team.goal_distribution ? (
          <div className="main-content-container">
            <Row className="mt-4 mb-4">
              <Col xs={12}>
                <Nav tabs className="d-none d-md-flex">
                  {tabButtons.map((t) => (
                    <NavItem
                      key={t.id}
                      className={activeTab === t.id.toLowerCase() ? 'active' : ''}
                    >
                      <NavLink onClick={t.onClick}>
                        {t.icon && <em className={t.icon} />}
                        {t.label}
                      </NavLink>
                      <div className="tab-indicator animated" />
                    </NavItem>
                  ))}
                </Nav>
              </Col>
            </Row>

            <TabContent activeTab={activeTab} className="animated no-top-margin">
              <TabPane tabId="all">
                <TeamDashboardContainer
                  team={team}
                  isTeamOwner={true}
                  doGetEngagementSummary={this.updateEngagementSummary}
                  startDate={dashboardStartDate}
                  endDate={dashboardEndDate}
                  doGetEngagementSummaryCsv={doGetEngagementSummaryCsv}
                  doClearEngagementSummaryCsv={doClearEngagementSummaryCsv}
                />
              </TabPane>
              <TabPane tabId="groups">
                <Groups isActive={activeTab === 'groups'} />
              </TabPane>
            </TabContent>
          </div>
        ) : (
          <div className="mt-5">
            <Loading />
          </div>
        )}
      </ContentWrapper>
    );
  }
}

Dashboard.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  doGetEngagementSummary: PropTypes.func,
  doGetClientStatusAndGoalDistribution: PropTypes.func,
  doGetEngagementSummaryCsv: PropTypes.func,
  doClearEngagementSummaryCsv: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.team,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doGetClientStatusAndGoalDistribution: () => dispatch(getClientStatusAndGoalDistribution()),
  doGetEngagementSummaryCsv: (team_id, start_date, end_date) => dispatch(getEngagementSummaryCsv(team_id, start_date, end_date)),
  doClearEngagementSummaryCsv: () => dispatch(clearEngagementSummaryCsv()),
  doGetEngagementSummary: (
    team_id,
    dashboardStartDate,
    dashboardEndDate,
    sortBy,
    order,
    page,
    showCount,
    clearData
  ) =>
    dispatch(
      getEngagementSummary(
        team_id,
        dashboardStartDate,
        dashboardEndDate,
        sortBy,
        order,
        page,
        showCount,
        clearData
      )
    ),
    doGetGroups: () => dispatch(getGroups()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
