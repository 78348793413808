import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import DomainField from './fields/DomainField';

const validate = () => {
  const errors = {};
  return errors;
};

const SetupIntegrationForm = ({ initialValues, showSubmissionError, onSubmit }) => {
  const onSubmitClick = (values) => {
    let vals = false;
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      if (values[Object.keys(values)[i]]) {
        vals = true;
      }
    }
    if (!vals) {
      showSubmissionError({ error: 'Please enter some search criteria' });
    } else {
      const errors = validate(values);
      if (Object.keys(errors).length === 0) {
        onSubmit(values);
      } else {
        showSubmissionError(errors);
      }
    }
  };

  return (
    <div>
      <Form
        onSubmit={(values) => onSubmitClick(values)}
        initialValues={initialValues}
        decorators={[]}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col md={12}>
                  <DomainField />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <button
                    className="btn btn-lg btn-primary fixed-width-md btn-search-with-reset"
                    type="submit"
                    disabled={submitting}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          );
        }}
      />
    </div>
  );
};

SetupIntegrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
};

export default SetupIntegrationForm;
