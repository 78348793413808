import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import { submissionError } from '../../actions/formActions';
import { signUpComplete, setSignUpStep } from '../../actions/signupActions';
import { formatCentsAsCurrency } from '../../utils/numberUtils';
import browserHistory from '../../history';

class SignUpComplete extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name || !signup.planId) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(5);
    }
  }

  render() {
    const { doSignUpComplete, signup } = this.props;
    let planId;
    let plan;
    let first_name;
    let membershipOptions;
    let membershipConfirmation;
    let hasTrial = false;
    let trialDays;
    let trialEnd;
    let billing_start_date;
    let amount;

    if (signup && signup.membershipOptions && signup.membershipConfirmation) {
      ({ planId, first_name, membershipOptions, membershipConfirmation } = signup);
      ({ billing_start_date, amount } = membershipConfirmation);
      plan = membershipOptions.find((s) => s.id === planId);
      trialEnd = moment(membershipConfirmation.trial_end);
      hasTrial = trialEnd.isAfter(moment());
      trialDays = Math.ceil(trialEnd.diff(moment(), 'days', true));
    }

    return (
      <ContentWrapper>
        <Row>
          <Col md={4} />
          <Col md={4} className="signup-complete-container">
            <h1>You're all set!</h1>
            <div className="mt-5">
              {!plan || !membershipConfirmation ? (
                <Loading />
              ) : (
                <>
                  <p className="mt-3 mb-4">
                    Thanks for joining Macrostax Team, {first_name}! We're excited to take your
                    business to the next level. You are on the:
                  </p>
                  <Row>
                    <Col>
                      <strong>Macrostax Team {plan.name} Plan</strong>
                    </Col>
                  </Row>
                  {hasTrial && (
                    <Row>
                      <Col>{trialDays}-day Free Trial Ends</Col>
                      <Col className="text-right">{trialEnd.format('MMMM D, YYYY')}</Col>
                    </Row>
                  )}
                  <Row>
                    <Col>Next Billing Date</Col>
                    <Col className="text-right">{moment(billing_start_date).format('MMMM D, YYYY')}</Col>
                  </Row>
                  <Row className="border-bottom">
                    <Col>Next Billing Amount</Col>
                    <Col className="text-right">{formatCentsAsCurrency(plan.price)}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <strong>Total Due Today</strong>
                    </Col>
                    <Col className="text-right">
                      <strong>{hasTrial ? '$0.00' : formatCentsAsCurrency(amount)}</strong>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      <Button color="primary" size="lg" block onClick={doSignUpComplete}>
                        Go to Macrostax Team
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpComplete.propTypes = {
  doSignUpComplete: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  subscriptions: PropTypes.instanceOf(Array),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
  subscriptions: state.billing.subscriptions,
});
const mapDispatchToProps = (dispatch) => ({
  doSignUpComplete: () => {
    dispatch(signUpComplete());
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpComplete);
