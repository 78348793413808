export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_BUSINESS = 'SIGN_UP_BUSINESS';
export const SIGN_UP_PLAN = 'SIGN_UP_PLAN';
export const SIGN_UP_BILLING = 'SIGN_UP_BILLING';
export const SIGN_UP_COMPLETE = 'SIGN_UP_COMPLETE';
export const SET_SIGN_UP_STEP = 'SET_SIGN_UP_STEP';

export function signUp(values) {
  return {
    type: SIGN_UP,
    values,
  };
}

export function signUpBusiness(values) {
  return {
    type: SIGN_UP_BUSINESS,
    values,
  };
}

export function signUpPlan(planId) {
  return {
    type: SIGN_UP_PLAN,
    planId,
  };
}

export function signUpBilling(values) {
  return {
    type: SIGN_UP_BILLING,
    values,
  };
}

export function signUpComplete() {
  return {
    type: SIGN_UP_COMPLETE,
  };
}

export function setSignUpStep(step) {
  return {
    type: SET_SIGN_UP_STEP,
    step,
  };
}
