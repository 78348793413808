import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import RecipeIngredients from './RecipeIngredients';
import RecipeDirections from './RecipeDirections';
import RecipeNotes from './RecipeNotes';
import { isTeamUser } from '../../utils/authUtils';
import ShareRecipeModal from '../../modals/ShareRecipeModal';
import { submitUserSearchRequest } from '../../actions/userActions';

class RecipeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareRecipeModalOpen: false,
    };
  }

  getClients = () => {
    const { search } = this.props;
    search({});
  };

  toggleShareRecipeModal = () => {
    const { shareRecipeModalOpen } = this.state;
    this.setState({
      shareRecipeModalOpen: !shareRecipeModalOpen,
    });
  };

  render() {
    const {
      recipe,
      showServingInfo = true,
      showNotes = true,
      showName = false,
      showActions = false,
      currentUser = {},
      team = {},
      clients = [],
    } = this.props;
    let { showPhoto = true } = this.props;
    const { shareRecipeModalOpen } = this.state;
    let recipeId;
    const subs = {
      isMulti: false,
    };
    let hasNotes = false;
    let hasDirections = false;
    if (recipe) {
      recipeId = recipe.recipe_uuid;
      if (recipe.components && recipe.components.length) {
        subs.components = recipe.components;
        subs.isMulti = true;
      } else {
        subs.components = [recipe.parent];
      }
      for (let i = 0; i < subs.components.length; i += 1) {
        if (
          subs.components[i] &&
          subs.components[i].notes &&
          subs.components[i].notes.trim().length > 0
        ) {
          hasNotes = true;
        }
        if (
          subs.components[i] &&
          subs.components[i].directions &&
          subs.components[i].directions.trim().length > 0
        ) {
          hasDirections = true;
          break;
        }
      }
    }
    if (hasDirections === false && subs.isMulti && recipe.parent.directions && recipe.parent.directions.length) {
      hasDirections = true;
    }
    if (recipe.photo_url === undefined || recipe.photo_url.trim() === '') {
      showPhoto = false;
    }
    const current_user_roles = currentUser.roles;
    const teamAdmin = isTeamUser(current_user_roles);
    return (
      <Row>
        <Col>
          {showName && <h1>{recipe.name}</h1>}

          {/* <div className="d-flex flex-row mt-3 mb-3"> */}
          <Row>
            {(showPhoto === true || showActions === true) && (
              <Col xs={12} md={4}>
                {showPhoto && <img src={recipe.photo_url} className="recipe-photo" />}
                {showActions && (
                  <div className={showPhoto ? 'mt-4' : ''}>
                    {/* <a href={`/printrecipe/${recipe.recipe_uuid}`} target="_blank" rel="noreferrer">
                      <img src="/img/icons/printer.svg" /> Print
                    </a> */}
                    <Link
                      to={`/printrecipe/${recipe.recipe_uuid}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <em className="fa-lg mr-2 fas fa-print" />
                      Print
                    </Link>
                    {teamAdmin && (
                      <span
                        className="span-link"
                        onClick={() => {
                          this.getClients();
                          this.toggleShareRecipeModal();
                        }}
                      >
                        <em className="fa-lg ml-4 mr-2 fas fa-share-square" />
                        Share
                      </span>
                    )}
                  </div>
                )}
              </Col>
            )}
            <Col>
              <div className="mb-3 mobile-mt-2">{recipe.intro_line}</div>
              {recipe.prep_time !== undefined &&
                recipe.total_time !== undefined &&
                parseInt(recipe.prep_time) + parseInt(recipe.total_time) > 0 && (
                  <div>
                    {recipe.prep_time} prep | {recipe.total_time} total
                  </div>
                )}

              {showServingInfo && (
                <div className="mt-4">
                  <h4>Serving Info</h4>
                  {subs.components.map((s) => (
                    <div className={subs.isMulti ? 'mb-2' : ''} key={s.display_name}>
                      {subs.isMulti && (
                        <div>
                          <strong>{s.display_name}</strong>
                        </div>
                      )}
                      Makes: {s.serving_info.recipe_serving_common.makes_quantity}{' '}
                      {s.serving_info.recipe_serving_common.makes_unit}
                    </div>
                  ))}
                  <div className="mt-3">
                    <strong>Per serving</strong>
                  </div>
                  <div>
                    C{' '}
                    <strong className="mr-2">
                      {recipe.search_result_form_common.carbs_formatted}g
                    </strong>
                    P{' '}
                    <strong className="mr-2">
                      {recipe.search_result_form_common.protein_formatted}g
                    </strong>
                    F{' '}
                    <strong className="mr-2">
                      {recipe.search_result_form_common.fat_formatted}g
                    </strong>
                    Cal <strong>{recipe.search_result_form_common.calories_formatted}</strong>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          {/* </div> */}

          <div className="mt-4">
            <h3 className="mb-4">Ingredients</h3>
            {subs.components.map((s) => (
              <div className={subs.isMulti ? 'mb-1 sub-recipe' : ''} key={s.display_name}>
                {subs.isMulti && (
                  <div className="sub-recipe-name">
                    <h4>{s.display_name}</h4>
                  </div>
                )}
                <RecipeIngredients component={s} />
              </div>
            ))}
          </div>
          {hasDirections && (
            <div className="mt-4">
              <h3 className="mb-4">Directions</h3>
              {subs.components.map(
                (s) =>
                  s.directions &&
                  s.directions.trim().length > 0 && (
                    <div className={subs.isMulti ? 'mb-1 sub-recipe' : ''} key={s.display_name}>
                      {subs.isMulti && (
                        <div className="sub-recipe-name">
                          <h4>{s.display_name}</h4>
                          <div className="caption">
                            Makes: {s.serving_info.recipe_serving_common.makes_quantity}{' '}
                            {s.serving_info.recipe_serving_common.makes_unit}
                          </div>
                        </div>
                      )}
                      {s.directions && s.directions.length && <RecipeDirections component={s} />}
                    </div>
                  )
              )}
              {subs.isMulti && recipe.parent.directions && recipe.parent.directions.length && (
                <div className="mb-1 sub-recipe">
                  <div className="sub-recipe-name">
                    <h4>Final Steps</h4>
                  </div>
                  {recipe.parent.directions}
                </div>
              )}
            </div>
          )}
          {showNotes && hasNotes && (
            <div className="margin-top-30">
              <h3 className="mb-4">Notes</h3>
              <div className="notes-container">
                {subs.components.map(
                  (s) =>
                    s.notes &&
                    s.notes.trim().length > 0 && (
                      <div className={subs.isMulti ? 'mb-1 sub-recipe' : ''} key={s.display_name}>
                        {subs.isMulti && (
                          <div className="sub-recipe-name">
                            <h4>{s.display_name}</h4>
                          </div>
                        )}
                        <RecipeNotes component={s} />
                      </div>
                    )
                )}
                {subs.isMulti && recipe.parent.notes && recipe.parent.notes.length && (
                  <div className="mb-1 sub-recipe">
                    <div className="sub-recipe-name">
                      <h4>Final Steps</h4>
                    </div>
                    {recipe.parent.notes}
                  </div>
                )}
              </div>
            </div>
          )}
        </Col>
        <ShareRecipeModal
          team={team}
          isOpen={shareRecipeModalOpen}
          toggle={this.toggleShareRecipeModal}
          clients={clients}
          recipeId={recipeId}
        />
      </Row>
    );
  }
}

RecipeDetail.propTypes = {
  recipe: PropTypes.instanceOf(Object),
  currentUser: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Array),
};

const mapStateToProps = (state) => ({
  team: state.team,
  clients: state.clients.userSearchResults,
});
const mapDispatchToProps = (dispatch) => ({
  search: (values) => {
    dispatch(submitUserSearchRequest(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDetail);
