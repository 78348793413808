import jwtDecode from 'jwt-decode';

const getTokenData = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (e) {
    return undefined;
  }
};

const getTimeUntilTokenExpired = (token) => {
  if (!token) {
    return 0;
  }
  const decoded = getTokenData(token);
  const exp = decoded.exp * 1000;
  const msLeft = exp - Date.now();
  return msLeft < 0 ? 0 : msLeft;
};

const getTokenFromResponse = (response) => {
  if (response.token && (response.refresh_token || response.refresh)) {
    return {
      token: response.token,
      refresh: response.refresh_token ? response.refresh_token : response.refresh,
      password_update_required: false,
    };
  }
  if (response.teams_list && response.teams_list.length > 1) {
    const { token, refresh, password_update_required, user_first_name, teams_list } = response;
    return { token, refresh, password_update_required, user_first_name, teams_list };
  }
  let teams = Object.keys(response[response.length - 1]);
  let teams_list;
  if (teams.includes('teams_list')) {
    if (response[response.length - 1].teams_list !== undefined) {
      teams_list = response[response.length - 1].teams_list;
    }
  }
  teams = teams.filter((t) => t !== 'teams_list');
  const lastTeam = teams[teams.length - 1];
  const { token, refresh, password_update_required, user_first_name } = response[
    response.length - 1
  ][lastTeam];
  return { token, refresh, password_update_required, user_first_name, teams_list };
};

export { getTokenData, getTimeUntilTokenExpired, getTokenFromResponse };
