import moment from 'moment-timezone';

export const GET_CHAT_SETTINGS = 'GET_CHAT_SETTINGS';
export const EDIT_CHAT_SETTINGS = 'EDIT_CHAT_SETTINGS';

export function getChatSettings() {
  return {
    type: GET_CHAT_SETTINGS,
  };
}

export function editChatSettings(chatCoaches) {
  return {
    type: EDIT_CHAT_SETTINGS,
    chatCoaches,
  };
}
