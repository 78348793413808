import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ChangePlanButton from '../../components/Common/ChangePlanButton';
import CancelPlanButton from '../../components/Common/CancelPlanButton';
import Loading from '../../components/Common/Loading';
import { formatCentsAsCurrency } from '../../utils/numberUtils';
import { capitalizeFirst } from '../../utils/stringUtils';
import { getPaymentIcon } from '../../utils/paymentUtils';
import PaymentMethodModal from '../../modals/PaymentMethodModal';
import { updateTeamPaymentProfile, getTeam, reactivateMembership } from '../../actions/teamActions';
import { membershipOptions } from '../../apiActions/signupApiActions';
import { isTeamUser } from '../../utils/authUtils';
import { getClassNameForSubscriptionStatus } from '../../utils/subscriptionUtils';

const tz = moment.tz.guess();

class BillingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethodModalOpen: false,
    };
  }

  componentDidMount() {
    const { doMembershipOptions } = this.props;
    doMembershipOptions();
  }

  togglePaymentMethodModal = () => {
    const { paymentMethodModalOpen } = this.state;
    this.setState({
      paymentMethodModalOpen: !paymentMethodModalOpen,
    });
  };

  render() {
    const {
      team,
      showChangePlan = true,
      doUpdateTeamPaymentProfile,
      doReactivateMembership,
      user,
      membership,
    } = this.props;
    let product_name;
    let next_bill_date;
    let next_bill_amount;
    let billing_frequency;
    let billing;
    let card;
    let brand;
    let exp_date;
    let last4;
    let max_clients = 0;
    let active_clients = 0;
    let team_id;
    let status;
    let external_user_id;
    let external_membership_id;
    if (team && membership) {
      ({ team_id } = team);
      ({
        product_name,
        next_bill_date,
        next_bill_amount,
        billing_frequency,
        billing,
        max_clients,
        active_clients,
        status,
        external_user_id,
        external_membership_id,
      } = membership);
      ({ card } = billing);
      ({ brand, exp_date, last4 } = card);
    }
    let seatsRemaining = max_clients - active_clients;
    if (seatsRemaining < 0) {
      seatsRemaining = 0;
    }
    const { paymentMethodModalOpen } = this.state;

    return (
      <>
        {/* <Row className="mt-3">
          <Col className="text-center">
            <img src="/img/max_construction.svg" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={3} />
          <Col className="text-center">
            Billing management is still under construction! If you'd like to modify or cancel your subscription please reach out to our Support team via <span className="span-link" id="Intercom">StaxChat</span>
          </Col>
          <Col md={3} />
        </Row> */}
        {membership ? (
          <>
            <Row className="mt-3">
              <Col xs={6}>
                <h4>Your Plan</h4>
              </Col>
              <Col xs={6} className="text-right">
                {membership.status !== 'pending-cancellation' &&
                  membership.status !== 'canceled' &&
                  showChangePlan && (
                    <>
                      <ChangePlanButton team={team} />
                      <CancelPlanButton team={team} className="ml-2" />
                    </>
                  )}
              </Col>
            </Row>
            <Row className="table-statistics mt-3">
              <Col md={3}>
                <div className="table-header">Active Clients</div>
                <div className="statistic">{active_clients}</div>
                <div className="card-caption">
                  {`You have ${seatsRemaining} spot${seatsRemaining !== 1 ? 's' : ''} remaining`}*
                </div>
              </Col>
              <Col md={3}>
                <div className="table-header mobile-mt-1">Plan</div>
                <div className="statistic">{product_name}</div>
                <div className="card-caption">
                  Up to {max_clients} clients,{' '}
                  {billing_frequency === 'month' ? 'monthly ' : 'yearly '} billing
                </div>
                {/* <div className="card-caption">{subtitle}</div> */}
              </Col>
              {membership.status !== 'canceled' && membership.status !== 'pending-cancellation' && (
                <Col md={3}>
                  <div className="table-header mobile-mt-1">Next Invoice Amount</div>
                  <div className="statistic">
                    ${numeral(next_bill_amount).format('0,000.00')}
                    {/* <span className="sub-stat">/{billing_frequency === 'month' ? 'Month' : 'Year'}</span> */}
                  </div>
                  {billing_frequency === 'month' &&
                    showChangePlan &&
                    membership.status !== 'pending-cancellation' && (
                      <div className="card-caption">
                        <ChangePlanButton type="text" team={team} />
                      </div>
                    )}
                </Col>
              )}
              {membership.status !== 'canceled' && (
                <Col md={3}>
                  <div className="table-header mobile-mt-1">
                    {membership.status === 'pending-cancellation' ? 'Cancelation ' : 'Renewal '}{' '}
                    Date
                  </div>
                  <div className="statistic">
                    {moment(next_bill_date).tz(tz).format('M/D/YYYY')}
                  </div>
                </Col>
              )}
            </Row>
            <Row className="mt-5 mb-3">
              <Col xs={12} sm={isTeamUser(user.roles) ? 6 : 3}>
                <div className="table-header mb-2">Billing Info</div>

                <em className={`fa-2x mr-2 fab ${getPaymentIcon(brand)}`} />
                {`**** ${last4}`}
                {` expires ${moment(exp_date).format('MM/YY')} `}
                {isTeamUser(user.roles) && (
                  <p className="mt-2 card-caption">
                    <span className="span-link" onClick={this.togglePaymentMethodModal}>
                      Update Payment Method
                    </span>
                  </p>
                )}
              </Col>

              {!isTeamUser(user.roles) && (
                <Col xs={12} sm={6}>
                  <div className="table-header mobile-mt-3">Subscription Info</div>
                  <div className="mt-2">
                    <strong>Status:</strong>{' '}
                    <span className={getClassNameForSubscriptionStatus(status)}>
                      {capitalizeFirst(status.replace('cancellation', 'cancelation').replace('_', ' '))}
                    </span>
                  </div>
                  <div>
                    <strong>Customer:</strong>{' '}
                    <a
                      href={`https://dashboard.stripe.com/customers/${external_user_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {external_user_id}
                    </a>
                  </div>
                  <div>
                    <strong>Subscription:</strong>{' '}
                    <a
                      href={`https://dashboard.stripe.com/subscriptions/${external_membership_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {external_membership_id}
                    </a>
                  </div>
                </Col>
              )}
              {membership.status === 'pending-cancellation' && isTeamUser(user.roles) && (
                <Col xs={12} sm={6} className="text-center mt-3">
                  <Button
                    color="primary"
                    size="lg"
                    className="fixed-width-lg"
                    onClick={() => doReactivateMembership(team_id, membership.id)}
                  >
                    Reactivate Account
                  </Button>
                </Col>
              )}
            </Row>
            {membership.status !== 'canceled' && membership.status !== 'pending-cancellation' && (
              <Row>
                <Col className="card-caption">*If you exceed your tier's number of active clients we will automatically upgrade your plan on your renewal date.</Col>
              </Row>
            )}
          </>
        ) : (
          <Loading />
        )}

        <PaymentMethodModal
          team={team}
          isOpen={paymentMethodModalOpen}
          toggle={this.togglePaymentMethodModal}
          onSubmit={(id) => doUpdateTeamPaymentProfile(team_id, membership.id, id)}
        />
      </>
    );
  }
}

BillingContainer.propTypes = {
  team: PropTypes.instanceOf(Object),
  showChangePlan: PropTypes.bool,
  doUpdateTeamPaymentProfile: PropTypes.func,
  doMembershipOptions: PropTypes.func,
  doReactivateMembership: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  membership: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doUpdateTeamPaymentProfile: (team_id, membership_id, id) => {
    dispatch(updateTeamPaymentProfile(team_id, membership_id, id));
  },
  doGetTeamMembership: (team_id) => dispatch(getTeamMembership(team_id)),
  doMembershipOptions: () => dispatch(membershipOptions()),
  doReactivateMembership: (team_id, membership_id) =>
    dispatch(reactivateMembership(team_id, membership_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingContainer);
