import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import BillingContainer from '../../containers/Teams/BillingContainer';

class Billing extends React.Component {
  render() {
    const { team } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Billing" />
          </Col>
        </Row>
        <div className="main-content-container">
          {team && team.team_id ? (
            <BillingContainer team={team} membership={team.membership} />
          ) : <Loading />}
        </div>
      </ContentWrapper>
    );
  }
}

Billing.propTypes = {
  team: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  team: state.team,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
