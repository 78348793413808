import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CreditCardFormWrapper from '../components/Common/CreditCardFormWrapper';
import ENV_VARS from '../utils/envVars';

const stripePromise = loadStripe(ENV_VARS.STRIPE_PUBLISHABLE_KEY);

class CreditCardForm extends Component {
  render() {
    const {
      toggle,
      showSubmissionError,
      showCancel,
      buttonText,
      buttonColClass,
      onSubmit,
    } = this.props;
    return (
      <Elements
        stripe={stripePromise}
        fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat' }]}
      >
        <CreditCardFormWrapper
          toggle={toggle}
          showSubmissionError={showSubmissionError}
          showCancel={showCancel}
          buttonText={buttonText}
          buttonColClass={buttonColClass}
          onSubmit={onSubmit}
        />
      </Elements>
    );
  }
}

CreditCardForm.propTypes = {
  toggle: PropTypes.func,
  showSubmissionError: PropTypes.func,
  showCancel: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonColClass: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default CreditCardForm;
