import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import { getGroups, openSideMenu, setInitialValuesForm } from '../../actions/groupActions';
import GroupTable from '../../components/Tables/GroupTable';
import DeleteGroup from '../../modals/DeleteGroup';
import { getOptions } from '../../utils/dropDownOptions';
import { getAdmins, getAllClients } from '../../actions/teamActions';

const Groups = ({
  doOpenCreateSideMenu,
  doOpenEditSideMenu,
  doGetGroups,
  doGetAllClients,
  doGetAdmins,
  groupList,
  teamId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [groupId, setGroupId] = React.useState(null);

  const handleOpenEditModal = (id) => {
    const group = groupList.find((g) => g.id === id);
      doOpenEditSideMenu(group);
  };

  const handleOpenCreateModal = () => {
    doOpenCreateSideMenu();
  };

  const handleOpenDeleteModal = (id) => {
    setIsOpen(!isOpen);
    setGroupId(id);
  };

  const onMount = async () => {
    await doGetGroups();
    await doGetAllClients(teamId);
    await doGetAdmins(teamId);
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <ContentWrapper>
      <Row>
        <Col xs={12}>
          <PageTitle title="Groups" />
        </Col>
      </Row>

      <div className="main-content-container">
        <Col xs={12}>
          <Row className="justify-content-between">
            <h2>Manage Groups</h2>
            <button onClick={handleOpenCreateModal} className="open-menu-button">
              Create New Group
            </button>
          </Row>
        </Col>

        <Row className="mt-2">
          <Col xs={12}>
            {groupList && (
              <GroupTable
                groups={groupList}
                onEditClick={handleOpenEditModal}
                onDeleteClick={handleOpenDeleteModal}
              />
            )}
          </Col>
        </Row>
      </div>

      {isOpen && <DeleteGroup isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} groupId={groupId} />}
    </ContentWrapper>
  );
};

Groups.propTypes = {
  doGetGroups: PropTypes.func,
  doGetAllClients: PropTypes.func,
  groupList: PropTypes.instanceOf(Array),
  teamId: PropTypes.string,
};
const mapStateToProps = ({ groups, team }) => ({
  isOpen: groups.visible,
  groupList: groups.groupList,
  teamId: team.team_id,
});
const mapDispatchToProps = (dispatch) => ({
  doOpenCreateSideMenu: () => {
    dispatch(setInitialValuesForm({ id: null, name: '', admins: [], members: [], image: null }));
    dispatch(openSideMenu());
  },
  doOpenEditSideMenu: (group) => {
    dispatch(setInitialValuesForm({
      id: group.id,
      name: group.name,
      admins: getOptions(group.admins),
      members: getOptions(group.members),
      image: group.photo,
    }));
    dispatch(openSideMenu());
  },
  doGetGroups: () => dispatch(getGroups()),
  doGetAllClients: (teamId) => dispatch(getAllClients(teamId)),
  doGetAdmins: (teamId) => dispatch(getAdmins(teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
