export const customFeatureIconOptions = [
  {
    label: <img src="/img/icons/blog.svg" height="30px" width="30px"/>,
    value: 'blog',
  },
  {
    label: <img src="/img/icons/body_scale.svg" height="30px" width="30px"/>,
    value: 'bodyScale',
  },
  {
    label: <img src="/img/icons/calendar_day.svg" height="30px" width="30px"/>,
    value: 'calendarDay',
  },
  {
    label: <img src="/img/icons/camera.svg" height="24px" width="24px" style={{marginLeft: '4px'}} />,
    value: 'camera',
  },
  {
    label: <img src="/img/icons/chat.svg" height="24px" width="24px" style={{marginLeft: '4px'}} />,
    value: 'chat',
  },
  {
    label: <img src="/img/icons/facebook_square.svg" height="30px" width="30px"/>,
    value: 'facebookSquare',
  },
  {
    label: <img src="/img/icons/green_thumbs.svg" height="30px" width="30px"/>,
    value: 'greenThumbs',
  },
  {
    label: <img src="/img/icons/instagram.svg" height="30px" width="30px"/>,
    value: 'instagram',
  },
  {
    label: <img src="/img/icons/information.svg" height="30px" width="30px" />,
    value: 'information',
  },
  {
    label: <img src="/img/icons/most_popular.svg" height="24px" width="24px" style={{marginLeft: '4px'}}/>,
    value: 'mostPopular',
  },
  {
    label: <img src="/img/icons/pencil.svg" height="30px" width="30px"/>,
    value: 'pencil',
  },
  {
    label: <img src="/img/icons/plus.svg" height="30px" width="30px"/>,
    value: 'plus',
  },
  {
    label: <img src="/img/icons/profile.svg" height="30px" width="30px"/>,
    value: 'profile',
  },
  {
    label: <img src="/img/icons/progress_photos.svg" height="24px" width="24px" style={{marginLeft: '4px'}}/>,
    value: 'progressPhotos',
  },
  {
    label: <img src="/img/icons/settings.svg" height="30px" width="30px"/>,
    value: 'settings',
  },
  {
    label: <img src="/img/icons/terms.svg" height="30px" width="30px"/>,
    value: 'terms',
  },
  {
    label: <img src="/img/icons/workout.svg" height="30px" width="30px"/>,
    value: 'workout',
  },
  {
    label: <img src="/img/icons/youtube.svg" height="30px" width="30px"/>,
    value: 'youtube',
  },
];
/*
  appStoreDownload,
  blog,
  bodyScale,
  calendarDay,
  camera,
  chat,
  facebookSquare,
  greenThumbs,
  instagram,
  information,
  mostPopular,
  pencil,
  playStoreDownload,
  plus,
  profile,
  progressPhotos,
  settings,
  terms,
  workout,
  youtube;
  */
