import React, { useEffect, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Progress, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ERROR_STATUS, FINISHED_STATUS, IMPORT_PROGRESS_BAR_STYLE, IMPORT_STATUS_TITLE_TEXT, IN_PROGRESS_STATUS, NOT_STARTED_STATUS } from '../../enums/importStatus';

const MILLISECONDS_TO_FULL_PROGRESS = 5000;

const StatusInformationCard = ({ status, onCancel }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const title = IMPORT_STATUS_TITLE_TEXT[status] ?? '';
  const isFinished = useMemo(() => status === FINISHED_STATUS, [status]);
  const isInProgress = useMemo(() => status === IN_PROGRESS_STATUS, [status]);
  const isErrored = useMemo(() => status === ERROR_STATUS, [status]);

  const showErrorProgressBar = () => {
    setProgressBar(100);
    setShowLoading(true);
    setTimeout(() => { setShowLoading(false); }, 3000);
  };

  const showSuccessProgressBar = (interval) => {
    setProgressBar(100);
    clearInterval(interval);
  };

  useEffect(() => {
    let interval;
    if (isInProgress || isFinished) {
      setShowLoading(true);
      let elapsed = 0;

      interval = setInterval(() => {
        elapsed += MILLISECONDS_TO_FULL_PROGRESS / 10;
        setProgressBar((elapsed / MILLISECONDS_TO_FULL_PROGRESS) * 100);
        if (isFinished) {
          showSuccessProgressBar(interval);
        }
      }, MILLISECONDS_TO_FULL_PROGRESS / 10);
    } else if (isErrored) {
      showErrorProgressBar();
    } else {
      setShowLoading(false);
    }

    return () => clearInterval(interval);
  }, [isFinished, isInProgress, isErrored]);

  return showLoading ? (
    <div className="main-content-container" style={{ marginTop: 20 }}>
      <Row className="mt-12">
        <Col xs={12}>
          <p style={{ textAlign: 'center' }}>
            {title}
          </p>

          {isFinished && (
            <p style={{ textAlign: 'center', margintTop: 10 }}>
              Visit the <Link to="/addclients#invites"> Clients Page </Link> to invite them to Macrostax.
            </p>
          )}
        </Col>
        <Col xs={12}>
          <Progress
            value={progressBar}
            color={IMPORT_PROGRESS_BAR_STYLE[status]}
            animated
            className="mt-3"
          />

          {isInProgress && (
            <Row className="justify-content-center align-items-center mt-4">
              <button
                className="btn btn-lg btn-secondary fixed-width-md mt-12"
                type="reset"
                onClick={onCancel}
              >
                Cancel
              </button>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  ) : <></>;
};

StatusInformationCard.propTypes = {
  status: PropTypes.instanceOf(String),
  onCancel: PropTypes.func.isRequired,
};

export default StatusInformationCard;
