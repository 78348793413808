import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';

class Colors extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Design System Colors" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12}>
            <div className="design-system-row blue-100">Blue 100</div>
            <div className="design-system-row blue-200">Blue 200</div>
            <div className="design-system-row blue-300">Blue 300</div>
            <div className="design-system-row blue-400">Blue 400</div>
            <div className="design-system-row blue-600">Blue 600</div>
            <div className="design-system-row blue-700">Blue 700</div>
            <div className="design-system-row blue-800">Blue 800</div>
            <div className="design-system-row purple-100">Purple 100</div>
            <div className="design-system-row purple-200">Purple 200</div>
            <div className="design-system-row purple-300">Purple 300</div>
            <div className="design-system-row purple-400">Purple 400</div>
            <div className="design-system-row purple-600">Purple 600</div>
            <div className="design-system-row purple-800">Purple 800</div>
            <div className="design-system-row gold-200">Gold 200</div>
            <div className="design-system-row gold-300">Gold 300</div>
            <div className="design-system-row gold-400">Gold 400</div>
            <div className="design-system-row gold-600">Gold 600</div>
            <div className="design-system-row gold-700">Gold 700</div>
            <div className="design-system-row gold-800">Gold 800</div>
            <div className="design-system-row green-100">Green 100</div>
            <div className="design-system-row green-200">Green 200</div>
            <div className="design-system-row green-300">Green 300</div>
            <div className="design-system-row green-400">Green 400</div>
            <div className="design-system-row green-600">Green 600</div>
            <div className="design-system-row green-700">Green 700</div>
            <div className="design-system-row green-800">Green 800</div>
            <div className="design-system-row gray-100">Gray 100</div>
            <div className="design-system-row gray-200">Gray 200</div>
            <div className="design-system-row gray-300">Gray 300</div>
            <div className="design-system-row gray-400">Gray 400</div>
            <div className="design-system-row gray-600">Gray 600</div>
            <div className="design-system-row gray-700">Gray 700</div>
            <div className="design-system-row gray-800">Gray 800</div>
            <div className="design-system-row gold-500">Gold 500</div>
            <div className="design-system-row red-500">Red 500</div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Colors.propTypes = {};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Colors);
