import Qs from 'qs';

import ENV_VARS from '../utils/envVars';

export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE';
export const GET_TEAM_PROFILE_SUCCESS = 'GET_TEAM_PROFILE_SUCCESS';
export const GET_TEAM_PROFILE_FAILURE = 'GET_TEAM_PROFILE_FAILURE';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE';
export const TEAM_PROFILE_SUCCESS = 'TEAM_PROFILE_SUCCESS';
export const TEAM_PROFILE_FAILURE = 'TEAM_PROFILE_FAILURE';
export const TEAM_SEARCH_SUCCESS = 'TEAM_SEARCH_SUCCESS';
export const TEAM_SEARCH_FAILURE = 'TEAM_SEARCH_FAILURE';
export const ADD_CLIENTS_SUCCESS = 'ADD_CLIENTS_SUCCESS';
export const ADD_CLIENTS_FAILURE = 'ADD_CLIENTS_FAILURE';
export const SEND_INVITATIONS_SUCCESS = 'SEND_INVITATIONS_SUCCESS';
export const SEND_INVITATIONS_FAILURE = 'SEND_INVITATIONS_FAILURE';
export const REMOVE_MEMBERSHIPS_SUCCESS = 'REMOVE_MEMBERSHIPS_SUCCESS';
export const REMOVE_MEMBERSHIPS_FAILURE = 'REMOVE_MEMBERSHIPS_FAILURE';
export const REMOVE_ADMINS_SUCCESS = 'REMOVE_ADMINS_SUCCESS';
export const REMOVE_ADMINS_FAILURE = 'REMOVE_ADMINS_FAILURE';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';
export const GET_TEAM_MEMBERSHIP_SUCCESS = 'GET_TEAM_MEMBERSHIP_SUCCESS';
export const GET_TEAM_MEMBERSHIP_FAILURE = 'GET_TEAM_MEMBERSHIP_FAILURE';
export const UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS = 'UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS';
export const UPDATE_TEAM_PAYMENT_PROFILE_FAILURE = 'UPDATE_TEAM_PAYMENT_PROFILE_FAILURE';
export const UPDATE_MEMBERSHIP_SUCCESS = 'UPDATE_MEMBERSHIP_SUCCESS';
export const UPDATE_MEMBERSHIP_FAILURE = 'UPDATE_MEMBERSHIP_FAILURE';
export const CANCEL_PLAN_SUCCESS = 'CANCEL_PLAN_SUCCESS';
export const CANCEL_PLAN_FAILURE = 'CANCEL_PLAN_FAILURE';
export const REACTIVATE_MEMBERSHIP_SUCCESS = 'REACTIVATE_MEMBERSHIP_SUCCESS';
export const REACTIVATE_MEMBERSHIP_FAILURE = 'REACTIVATE_MEMBERSHIP_FAILURE';
export const ADD_ADMINS_SUCCESS = 'ADD_ADMINS_SUCCESS';
export const ADD_ADMINS_FAILURE = 'ADD_ADMINS_FAILURE';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';
export const GET_ENGAGEMENT_SUMMARY_SUCCESS = 'GET_ENGAGEMENT_SUMMARY_SUCCESS';
export const GET_ENGAGEMENT_SUMMARY_FAILURE = 'GET_ENGAGEMENT_SUMMARY_FAILURE';
export const GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS = 'GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS';
export const GET_ENGAGEMENT_SUMMARY_CSV_FAILURE = 'GET_ENGAGEMENT_SUMMARY_CSV_FAILURE';
export const GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS =
  'GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS';
export const GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_FAILURE =
  'GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_FAILURE';
export const UPDATE_TEAM_BRAND_SUCCESS = 'UPDATE_TEAM_BRAND_SUCCESS';
export const UPDATE_TEAM_BRAND_FAILURE = 'UPDATE_TEAM_BRAND_FAILURE';
export const GET_ALL_TEAM_BRANDING_SUCCESS = 'GET_ALL_TEAM_BRANDING_SUCCESS';
export const GET_ALL_TEAM_BRANDING_FAILURE = 'GET_ALL_TEAM_BRANDING_FAILURE';
export const GET_CUSTOM_FEATURES_SUCCESS = 'GET_CUSTOM_FEATURES_SUCCESS';
export const GET_CUSTOM_FEATURES_FAILURE = 'GET_CUSTOM_FEATURES_FAILURE';
export const GET_HIDDEN_FEATURES_SUCCESS = 'GET_HIDDEN_FEATURES_SUCCESS';
export const GET_HIDDEN_FEATURES_FAILURE = 'GET_HIDDEN_FEATURES_FAILURE';
export const ADD_CUSTOM_FEATURE_SUCCESS = 'ADD_CUSTOM_FEATURE_SUCCESS';
export const ADD_CUSTOM_FEATURE_FAILURE = 'ADD_CUSTOM_FEATURE_FAILURE';
export const ARCHIVE_CUSTOM_FEATURE_SUCCESS = 'ARCHIVE_CUSTOM_FEATURE_SUCCESS';
export const ARCHIVE_CUSTOM_FEATURE_FAILURE = 'ARCHIVE_CUSTOM_FEATURE_FAILURE';
export const UPDATE_CUSTOM_FEATURE_SUCCESS = 'UPDATE_CUSTOM_FEATURE_SUCCESS';
export const UPDATE_CUSTOM_FEATURE_FAILURE = 'UPDATE_CUSTOM_FEATURE_FAILURE';
export const ADD_HIDDEN_FEATURE_SUCCESS = 'ADD_HIDDEN_FEATURE_SUCCESS';
export const ADD_HIDDEN_FEATURE_FAILURE = 'ADD_HIDDEN_FEATURE_FAILURE';
export const DELETE_HIDDEN_FEATURE_SUCCESS = 'DELETE_HIDDEN_FEATURE_SUCCESS';
export const DELETE_HIDDEN_FEATURE_FAILURE = 'DELETE_HIDDEN_FEATURE_FAILURE';

export function getTeamApi(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team',
    method: 'GET',
    params: {
      team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_TEAM_SUCCESS,
    failureAction: GET_TEAM_FAILURE,
  };
}

export function getAllTeamBranding() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/all-team-branding',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_ALL_TEAM_BRANDING_SUCCESS,
    failureAction: GET_ALL_TEAM_BRANDING_FAILURE,
  };
}

export function getTeamProfileApi(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team',
    method: 'GET',
    params: {
      team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_TEAM_PROFILE_SUCCESS,
    failureAction: GET_TEAM_PROFILE_FAILURE,
  };
}

export function updateTeamApi(team) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/edit-team',
    method: 'PUT',
    data: team,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_TEAM_SUCCESS,
    failureAction: UPDATE_TEAM_FAILURE,
  };
}

export function updateTeamBrand(team) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-brand',
    method: 'PUT',
    data: team,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_TEAM_BRAND_SUCCESS,
    failureAction: UPDATE_TEAM_BRAND_FAILURE,
  };
}

export function getTeamProfile() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-profile',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TEAM_PROFILE_SUCCESS,
    failureAction: TEAM_PROFILE_FAILURE,
  };
}

export function teams() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-search',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TEAM_SEARCH_SUCCESS,
    failureAction: TEAM_SEARCH_FAILURE,
  };
}

export function teamSearch(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-search',
    method: 'GET',
    params: values,
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: true });
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: TEAM_SEARCH_SUCCESS,
    failureAction: TEAM_SEARCH_FAILURE,
  };
}

export function addAdminsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/add-admins',
    method: 'POST',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_ADMINS_SUCCESS,
    failureAction: ADD_ADMINS_FAILURE,
  };
}

export function addClientsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/add-clients',
    method: 'POST',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_CLIENTS_SUCCESS,
    failureAction: ADD_CLIENTS_FAILURE,
  };
}

export function sendInvitationsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/invite-clients',
    method: 'POST',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SEND_INVITATIONS_SUCCESS,
    failureAction: SEND_INVITATIONS_FAILURE,
  };
}

export function removeMembershipsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/revoke-clients',
    method: 'DELETE',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: REMOVE_MEMBERSHIPS_SUCCESS,
    failureAction: REMOVE_MEMBERSHIPS_FAILURE,
  };
}

export function removeAdminsApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/revoke-admins',
    method: 'DELETE',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: REMOVE_ADMINS_SUCCESS,
    failureAction: REMOVE_ADMINS_FAILURE,
  };
}

export function sendFeedbackApi(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/add-feedback',
    method: 'POST',
    data: {
      params: payload,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SEND_FEEDBACK_SUCCESS,
    failureAction: SEND_FEEDBACK_FAILURE,
  };
}

export function getTeamMembership(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-membership',
    method: 'GET',
    params: {
      team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_TEAM_MEMBERSHIP_SUCCESS,
    failureAction: GET_TEAM_MEMBERSHIP_FAILURE,
  };
}

export function getDashboard(team_id, start_date, end_date, timezone) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/dashboard',
    method: 'GET',
    params: {
      team_id,
      start_date,
      end_date,
      timezone,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_DASHBOARD_SUCCESS,
    failureAction: GET_DASHBOARD_FAILURE,
  };
}

export function getClientStatusAndGoalDistribution(team_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/client-counts',
    method: 'GET',
    params: {
      team_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_SUCCESS,
    failureAction: GET_CLIENT_STATUS_AND_GOAL_DISTRIBUTION_FAILURE,
  };
}

export function getEngagementSummary(
  team_id,
  start_date,
  end_date,
  sort_by,
  order,
  page,
  show_count,
  group_id,
  user_role_status,
) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/engagement-summary',
    method: 'GET',
    params: {
      team_id,
      start_date,
      end_date,
      sort_by,
      order,
      page,
      show_count,
      group_id,
      user_role_status,
    },
    paramsSerializer(params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: true });
    },
  };

  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_ENGAGEMENT_SUMMARY_SUCCESS,
    failureAction: GET_ENGAGEMENT_SUMMARY_FAILURE,
  };
}

export function getEngagementSummaryCsv(team_id, start_date, end_date) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/engagement-summary',
    method: 'GET',
    params: {
      team_id,
      start_date,
      end_date,
      type: 'csv',
      show_count: 250000000,
      sort_by: 'days_active',
      order: 'desc',
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_ENGAGEMENT_SUMMARY_CSV_SUCCESS,
    failureAction: GET_ENGAGEMENT_SUMMARY_CSV_FAILURE,
  };
}

export function updateTeamPaymentProfile(team_id, membership_id, id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-payment-profile',
    method: 'PUT',
    params: {
      team_id,
      membership_id,
      payment_method_id: id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_TEAM_PAYMENT_PROFILE_SUCCESS,
    failureAction: UPDATE_TEAM_PAYMENT_PROFILE_FAILURE,
  };
}

export function updateMembership(team_id, membership_id, product_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/team-membership',
    method: 'PUT',
    params: {
      team_id,
      membership_id,
      product_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_MEMBERSHIP_SUCCESS,
    failureAction: UPDATE_MEMBERSHIP_FAILURE,
  };
}

export function cancelPlan(team_id, membership_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/cancel-team-membership',
    method: 'PUT',
    params: {
      team_id,
      membership_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: CANCEL_PLAN_SUCCESS,
    failureAction: CANCEL_PLAN_FAILURE,
  };
}

export function reactivateMembership(team_id, membership_id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/reactivate-team-membership',
    method: 'PUT',
    params: {
      team_id,
      membership_id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: REACTIVATE_MEMBERSHIP_SUCCESS,
    failureAction: REACTIVATE_MEMBERSHIP_FAILURE,
  };
}

export function getCustomFeatures() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/get-custom-features',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_CUSTOM_FEATURES_SUCCESS,
    failureAction: GET_CUSTOM_FEATURES_FAILURE,
  };
}

export function getHiddenFeatures() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/get-hidden-features',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: GET_HIDDEN_FEATURES_SUCCESS,
    failureAction: GET_HIDDEN_FEATURES_FAILURE,
  };
}

export function addCustomFeature(params) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/create-custom-feature',
    method: 'POST',
    data: params,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_CUSTOM_FEATURE_SUCCESS,
    failureAction: ADD_CUSTOM_FEATURE_FAILURE,
  };
}

export function addHiddenFeature(id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/create-hidden-feature',
    method: 'POST',
    params: {
      name: id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ADD_HIDDEN_FEATURE_SUCCESS,
    failureAction: ADD_HIDDEN_FEATURE_FAILURE,
  };
}

export function deleteHiddenFeature(id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/delete-hidden-feature',
    method: 'DELETE',
    params: {
      name: id,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: DELETE_HIDDEN_FEATURE_SUCCESS,
    failureAction: DELETE_HIDDEN_FEATURE_FAILURE,
  };
}

export function updateCustomFeature(params) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/update-custom-feature',
    method: 'PUT',
    data: params,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: UPDATE_CUSTOM_FEATURE_SUCCESS,
    failureAction: UPDATE_CUSTOM_FEATURE_FAILURE,
  };
}

export function archiveCustomFeature(id) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/archive-custom-feature',
    method: 'PUT',
    data: { id },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: ARCHIVE_CUSTOM_FEATURE_SUCCESS,
    failureAction: ARCHIVE_CUSTOM_FEATURE_FAILURE,
  };
}
