import {
  SIGN_UP,
  SIGN_UP_BUSINESS,
  SIGN_UP_PLAN,
  SIGN_UP_BILLING,
  SET_SIGN_UP_STEP,
} from '../actions/signupActions';
import { SET_DEVICE_INFO } from '../actions/deviceActions';
import {
  MEMBERSHIP_OPTIONS_SUCCESS,
  BUSINESS_TYPES_SUCCESS,
  CREATE_MEMBERSHIP_SUCCESS,
} from '../apiActions/signupApiActions';
import testimonials from '../data/testimonials';
import { randomIntFromInterval } from '../utils/dataGeneratorUtils';

const initialState = {
  testimonial: testimonials[randomIntFromInterval(0, testimonials.length - 1)],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP:
    case SIGN_UP_BUSINESS: {
      // TODO: this is in place to show that we are updating a user in state
      // but we shouldn't do this until after a successful response from
      // the back-end services
      const { values } = action;
      return {
        ...state,
        ...values,
      };
    }
    case SIGN_UP_PLAN: {
      const { planId } = action;
      return {
        ...state,
        planId,
      };
    }
    case SIGN_UP_BILLING: {
      const { values } = action;
      return {
        ...state,
        payment: {
          ...values,
        },
      };
    }
    case SET_SIGN_UP_STEP: {
      const { step } = action;
      return {
        ...state,
        step,
      };
    }
    case MEMBERSHIP_OPTIONS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        membershipOptions: data,
      };
    }
    case BUSINESS_TYPES_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        businessTypes: data,
      };
    }
    case CREATE_MEMBERSHIP_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        membershipConfirmation: data,
      };
    }
    case SET_DEVICE_INFO: {
      const { deviceInfo } = action;
      return {
        ...state,
        device: deviceInfo,
      }
    }
    default:
      return state;
  }
};
