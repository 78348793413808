import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { helperCaptions } from '../../enums/helpText';

class CardIcon extends Component {
  render() {
    const { helpText, customText, popoverClassName = '', float = 'right', className = '', id = uuidv4() } = this.props;
    const popoverId = `popover_${id}`;
    const caption = helpText ? helperCaptions[helpText] : customText;
    return (
      <>
        <button
          className={`card-tool ${float === 'right' ? 'float-right' : ''} ${className}`}
          id={popoverId}
          onClick={(e) => {
            e.currentTarget.focus();
            e.preventDefault();
          }}
        >
          <em className="far fa-question-circle" />
        </button>
        <UncontrolledPopover trigger="hover" placement="top" target={popoverId}>
          <PopoverBody className={popoverClassName} dangerouslySetInnerHTML={{ __html: caption }} />
        </UncontrolledPopover>
      </>
    );
  }
}

CardIcon.propTypes = {
  helpText: PropTypes.string,
  customText: PropTypes.string,
  popoverClassName: PropTypes.string,
};

export default CardIcon;
