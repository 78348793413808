import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GroupForm from '../../forms/GroupForm';
import { clearCreateGroupForm, closeSideMenu, setInitialValuesForm, submitCreateGroupRequest, submitUpdateGroupRequest } from '../../actions/groupActions';
import { submissionError } from '../../actions/formActions';
import useAddAndRemoveClass from '../../hooks/useAddAndRemoveClass';

const GroupSideMenu = ({
  isOpen = false,
  doCreateGroup,
  doUpdateGroup,
  doCloseSideMenu,
  doResetForm,
  doSetInitialValues,
  initialValues,
  showSubmissionError,
  admins,
  members
}) => {
  const drawSideMenu = location.pathname === '/groups';
  const isCreateForm = useMemo(() =>
    initialValues?.name === '' &&
    initialValues?.admins?.length === 0 &&
    initialValues?.members?.length === 0,
    [
      initialValues?.name,
      initialValues?.admins,
      initialValues?.members,
    ]
  );
  const submitText = isCreateForm ? 'Create' : 'Save';

  const fillFormData = (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('admins', JSON.stringify(values.admins.map((adminId) => adminId)));
    formData.append('members', JSON.stringify(values.members.map((memberId) => memberId)));

    if (values.image) {
      formData.append('image', values.image);
    }
    return formData;
  };

  const handleSubmit = (values) => {
    const data = fillFormData(values);

    if (isCreateForm) {
      doCreateGroup(data);
    } else {
      doUpdateGroup(initialValues.id, data);
    }
  };

  const handleClose = () => {
    doResetForm();
    doCloseSideMenu();
  };

  const handleRemoveImage = () => {
    doSetInitialValues({ ...initialValues, image: null });
  };

  useAddAndRemoveClass(isOpen, 'no-scroll');

  return (
    drawSideMenu ? (
      <>
        <div className={`side-menu ${isOpen ? 'open' : ''}`}>
          <div className="side-menu-header">
            <h2>{isCreateForm ? 'Create Group' : 'Edit Group'}</h2>

            <button onClick={handleClose} className="close-menu-button">
              &times;
            </button>
          </div>

          <GroupForm
            data={{ admins, members }}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onReset={handleClose}
            onRemoveImage={handleRemoveImage}
            showSubmissionError={showSubmissionError}
            submitText={submitText}
          />
        </div>

        {isOpen && <div className="menu-overlay" onClick={handleClose} />}
      </>
    ) : <></>
  );
};

GroupSideMenu.defaultProps = {
  doCloseSideMenu: () => ({}),
  doResetForm: () => ({}),
  doCreateGroup: () => ({}),
  doUpdateGroup: () => ({}),
  isOpen: false,
  initialValues: {
    id: null,
    name: '',
    admins: [],
    members: [],
    photo: null,
  },
  showSubmissionError: () => ({}),
};

GroupSideMenu.propTypes = {
  doCloseSideMenu: PropTypes.func,
  doResetForm: PropTypes.func,
  doCreateGroup: PropTypes.func,
  doUpdateGroup: PropTypes.func,
  isOpen: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func
};

const mapStateToProps = ({ groups, context }) => ({
  isOpen: groups.visible,
  admins: groups.admins,
  members: groups.members,
  initialValues: {
    ...groups.initialValues,
    ...context.createGroupForm,
  },
});

const mapDispatchToProps = (dispatch) => ({
  doCreateGroup: (values) => dispatch(submitCreateGroupRequest(values)),
  doUpdateGroup: (id, values) => dispatch(submitUpdateGroupRequest(id, values)),
  doCloseSideMenu: () => dispatch(closeSideMenu()),
  doResetForm: () => dispatch(clearCreateGroupForm()),
  doSetInitialValues: (values) => dispatch(setInitialValuesForm(values)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSideMenu);
