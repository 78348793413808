import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import tableIcons from '../../utils/muiIcons';
import { RECIPE_PLACEHOLDER_IMAGE } from '../../constants';
import { isMobileDevice } from '../../utils/global';

class RecipesTable extends Component {
  render() {
    const { recipes, title = 'Search Results', onRowClick, sort = 'score' } = this.props;

    const cols = [
      {
        title: 'Recipe Name',
        field: 'name',
        render: (rowData) => (
          <div className="recipe-search-result">
            <img
              src={
                rowData.photo_url !== undefined && rowData.photo_url.length
                  ? rowData.photo_url
                  : RECIPE_PLACEHOLDER_IMAGE
              }
              className="mr-3"
            />
            {rowData.name}
          </div>
        ),
      },
      { title: 'Score', field: 'score', hidden: true },
      {
        title: '',
        field: 'is_favorite',
        render: (rowData) =>
          rowData.is_favorite !== undefined && rowData.is_favorite === true ? (
            <img src="/img/icons/heart_filled.svg" className="filter-red" />
          ) : null,
        width: null,
        hidden: isMobileDevice(),
        cellStyle: {
          width: 80,
          maxWidth: 80,
          textAlign: 'center',
          justifyContent: 'center',
        },
      },
    ];

    if (sort === 'name') {
      cols[0].defaultSort = 'asc';
    } else {
      cols[1].defaultSort = 'desc';
    }

    const pageSize = recipes.length < 25 ? recipes.length : 25;

    return (
      <MaterialTable
        columns={cols}
        data={recipes}
        title={title}
        key={recipes.length}
        options={{
          // padding: 'dense',
          pageSize,
          search: true,
          paging: recipes.length > pageSize,
          sorting: true,
          thirdSortClick: false,
          showTitle: true,
          toolbar: true,
          pageSizeOptions: [pageSize, 50, 100],
          debounceInterval: 600,
          draggable: false,
        }}
        style={{
          backgroundColor: '#f7f7f7',
        }}
        icons={tableIcons}
        onRowClick={onRowClick}
      />
    );
  }
}

RecipesTable.propTypes = {
  onRowClick: PropTypes.func,
  recipes: PropTypes.instanceOf(Object),
  sort: PropTypes.string,
};

export default RecipesTable;
