import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { OnChange } from 'react-final-form-listeners';
import { submissionError } from '../actions/formActions';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import { editChatSettings } from '../actions/chatSettingActions';

const ChatSettingsForm = ({
  initialValues,
  doEditChatSettings,
  isDisabled = true,
}) => {
  const handleToggle = (value) => {
    doEditChatSettings(value);
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={() => {
        return (
          <form className="inline-form">
            <Row>
              <Col>
                <Field
                  name="chat_coaches"
                  component={renderCheckboxForField}
                  {
                    ...{
                      itemProps: {},
                      checkboxIcon: '',
                      checkboxIconUnchecked: '',
                      inputProps: {
                        disabled: isDisabled,
                      },
                      labelClassName: 'switch switch-lg',
                      label: 'Chat with Coaches',
                    }
                  }
                />
                <OnChange name="chat_coaches">
                  {handleToggle}
                </OnChange>
              </Col>
            </Row>
          </form>
        );
      }}
    />
  );
};

ChatSettingsForm.propTypes = {
  initialValues: PropTypes.object,
  doEditChatSettings: PropTypes.func,
  isDisabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doEditChatSettings: (value) => dispatch(editChatSettings(value)),
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSettingsForm);
