import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Link } from '@material-ui/core';
import Pill from '../Common/Pill';
import tableIcons from '../../utils/muiIcons';

const IntegrationTable = ({
    data,
    title = 'Integrations',
    onRowClick,
  }) => {
    const columns = [
      {
        title: 'Name',
        field: 'title',
        defaultSort: 'asc'
      },
      {
        title: 'Status',
        field: 'enabled',
        render: (rowData) => (
          <Pill
            title={rowData.enabled ? 'Active' : 'Inactive'}
            className={rowData.enabled ? 'active' : 'error'}
          />
        ),
      },
      {
        title: 'Action',
        field: '',
        render: (rowData) => (
          <Link>
            {rowData?.enabled ? 'View details' : 'Activate integration'}
          </Link>
        ),
      },
    ];

    return (
      <MaterialTable
        columns={columns}
        data={data}
        title={title}
        key={data.length}
        options={{
          padding: 'dense',
          pageSize: data.length < 20 ? data.length : 20,
          search: false,
          paging: data.length > 20,
          sorting: true,
          thirdSortClick: false,
          showTitle: true,
          toolbar: true,
          exportButton: false,
          exportAllData: true,
        }}
        style={{
          backgroundColor: '#f7f7f7',
        }}
        icons={tableIcons}
        onRowClick={onRowClick}
      />
    );
};

IntegrationTable.propTypes = {
  title: PropTypes.string,
  onRowClick: PropTypes.func,
  data: PropTypes.instanceOf(Array),
};

export default IntegrationTable;
