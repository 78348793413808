import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import DashboardLeaderboardAlt from '../Leaderboards/DashboardLeaderboardAlt';

class DashboardLeaderboardCardAlt extends Component {
  constructor(props) {
    super(props);
    const now = moment();
    const {
      clients,
      startDate = now.clone().add(-30, 'days'),
      endDate = now,
      engagementSummary,
    } = props;
    const { limit, offset } = engagementSummary;
    this.state = {
      clientListModalOpen: false,
      filteredClients: clients,
      filteredClientsType: '',
      startDate,
      endDate,
      summarySortBy: 'days_active',
      summaryOrder: 'asc',
      summaryShowCount: parseInt(limit),
      summaryPage: parseInt(offset) + 1,
      waitingToDownload: false,
      // summaryShowCount: 25,
      // summaryPage: 1,
    };
  }

  componentDidUpdate() {
    const { exportData, clearEngagementSummaryCsv, engagementSummary } = this.props;
    const showExport = engagementSummary !== undefined && engagementSummary.users !== undefined && engagementSummary.users.length > 0;
    const { waitingToDownload } = this.state;
    if (showExport && exportData && exportData.length && waitingToDownload) {
      this.setState({ waitingToDownload: false }, () => {
        document.getElementById('csv-link').click();
        clearEngagementSummaryCsv();
      });
    }
  }

  sortSummary = (sortBy, order) => {
    const { summaryPage, summaryShowCount } = this.state;
    const { updateSortOrder } = this.props;

    updateSortOrder(sortBy, order, 1, summaryShowCount);
    this.setState(
      {
        summarySortBy: sortBy,
        summaryOrder: order,
        summaryPage: 1,
      },
      () => {
        // updateSortOrder(sortBy, order, summaryPage, summaryShowCount);
      }
    );
  };

  goToPage = (pageNumber) => {
    const { summarySortBy, summaryOrder, summaryPage, summaryShowCount } = this.state;
    const { updateSortOrder } = this.props;
    updateSortOrder(summarySortBy, summaryOrder, summaryPage, summaryShowCount);
  };

  render() {
    const {
      team_id,
      isTeamOwner,
      engagementSummary,
      getEngagementSummaryCsv,
      startDate,
      endDate,
      exportData = [],
      exportHeaders = [],
    } = this.props;
    const { total_users, offset, start_date, end_date } = engagementSummary;
    let { limit } = engagementSummary;
    // note: it appears that the offset coming back 0-based count of users, but the page number to be passed is 1-based (representing limit number)
    limit = parseInt(limit);
    const currentPage = parseInt(offset) / limit + 1;

    // let { startDate, endDate } = this.props;
    // startDate = moment(startDate.format('YYYY-MM-DD'));
    // endDate = moment(endDate.format('YYYY-MM-DD'));

    let hasNextPage = true;
    let hasPrevPage = false;
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    const filename = `client_engagement_summary_${moment(start_date).format('YYYYMMDD')}-${moment(end_date).format('YYYYMMDD')}`;

    hasNextPage = currentPage * limit <= total_users;
    hasPrevPage = prevPage > 0;

    const firstCount = parseInt(offset) + 1;
    const lastCount = firstCount + limit - 1 <= total_users ? firstCount + limit - 1 : total_users;
    const lastPage = Math.ceil(total_users / limit);

    const options = [
      {
        label: '10',
        value: 10,
      },
      {
        label: '25',
        value: 25,
      },
      {
        label: '50',
        value: 50,
      },
      {
        label: '100',
        value: 100,
      },
    ];

    return (
      <Card className="card-default card-container mb-3 green-thumbs-card flex-grow-1 overflow-x-scroll">
        <CardHeader>Client Engagement Summary</CardHeader>
        <CardBody className="min-width-800">
          <DashboardLeaderboardAlt
            isTeamOwner={isTeamOwner}
            team_id={team_id}
            engagementSummary={engagementSummary}
            sortSummary={this.sortSummary}
          />

          {engagementSummary && engagementSummary.users && engagementSummary.users.length > 0 && (
            <div className="mt-4 d-flex flex-row justify-content-between dashboard-pagination mr-2">
              <div className="hidden-on-mobile">
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({ waitingToDownload: true }, () => setTimeout(() => getEngagementSummaryCsv(team_id, startDate, endDate), 500));
                  }}
                >
                  <div className="d-flex flex-row align-items-center">
                    <em className="font-size-one-point-two-five icon-cloud-download mr-2" />
                    <div>Export to CSV</div>
                  </div>
                </Button>
                <CSVLink
                  data={exportData}
                  headers={exportHeaders}
                  filename={filename}
                  // filename={`${exportFilenamePrefix}${moment().format('YYYYMMDDHHmmss')}.csv`}
                  className="hidden"
                  ref={this.csvLink}
                  target="_blank"
                  id="csv-link"
                />
              </div>
              <div className="d-flex flex-row align-items-center">
                <Select
                  className="pagination-size"
                  classNamePrefix="react-select"
                  options={options}
                  value={options.find((o) => o.value === limit)}
                  isClearable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#03b7e5',
                      primary: '#03b7e5',
                    },
                  })}
                  onChange={(value) => {
                    this.setState(
                      {
                        summaryShowCount: value.value,
                        summaryPage: 1,
                      },
                      () => this.goToPage(currentPage)
                    );
                  }}
                />
                <div className="ml-1 mr-4">rows</div>
                <div
                  className={`span-link mr-3 ${!hasPrevPage ? 'disabled' : ''}`}
                  onClick={() => {
                    hasPrevPage
                      ? this.setState(
                          {
                            summaryPage: 1,
                          },
                          () => this.goToPage(1)
                        )
                      : void 0;
                  }}
                >
                  <em className="fa fa-lg fa-angle-double-left" />
                </div>
                <span
                  className={`span-link ${!hasPrevPage ? 'disabled' : ''}`}
                  onClick={() => {
                    hasPrevPage
                      ? this.setState(
                          {
                            summaryPage: prevPage,
                          },
                          () => this.goToPage(prevPage)
                        )
                      : void 0;
                  }}
                >
                  <em className="fa fa-lg fa-angle-left" />
                </span>
                <div className="ml-3 mr-3">
                  {firstCount} - {lastCount} of {total_users}
                </div>
                <div
                  className={`span-link ${!hasNextPage ? 'disabled' : ''}`}
                  onClick={() => {
                    hasNextPage
                      ? this.setState(
                          {
                            summaryPage: nextPage,
                          },
                          () => this.goToPage(prevPage)
                        )
                      : void 0;
                  }}
                >
                  <em className="fa fa-lg fa-angle-right" />
                </div>
                <div
                  className={`span-link ml-3 ${!hasNextPage ? 'disabled' : ''}`}
                  onClick={() => {
                    hasNextPage
                      ? this.setState(
                          {
                            summaryPage: lastPage,
                          },
                          () => this.goToPage(lastPage)
                        )
                      : void 0;
                  }}
                >
                  <em className="fa fa-lg fa-angle-double-right" />
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

DashboardLeaderboardCardAlt.propTypes = {
  isTeamOwner: PropTypes.bool,
  team_id: PropTypes.string,
  startDate: PropTypes.instanceOf(Object),
  endDate: PropTypes.instanceOf(Object),
  getEngagementSummaryCsv: PropTypes.func,
  clearEngagementSummaryCsv: PropTypes.func,
  exportData: PropTypes.instanceOf(Array),
  exportHeaders: PropTypes.instanceOf(Object),
};

export default DashboardLeaderboardCardAlt;
