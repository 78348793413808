import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Row, Col, Button, FormGroup } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment-timezone';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';
import renderSelectForField from './helpers/renderSelectForField';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import { usedMacrostaxOptions } from '../enums/usedMacrostax';
import { makeSimpleValue } from '../utils/formUtils';
import { FORM_TRANSITION_PROPS } from '../constants';

const validate = (values, actions) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name of challenge is required';
  }
  if (!values.start_on_date) {
    errors.start_on_date = 'Start date is required';
  }
  if (!values.through_date) {
    errors.through_date = 'End date is required';
  }
  if (
    values.start_on_date &&
    values.through_date &&
    moment(values.start_on_date).isSameOrAfter(values.through_date)
  ) {
    errors.start_on_date = 'Start date must be before end date';
  }
  for (let i = 0; i < actions.length; i += 1) {
    const action = actions[i];
    const id = action.id;
    const name = action.name;
    if (values[`action_${id}`]) {
      // validate this action
      if (
        !values[`points_${id}`] ||
        isNaN(values[`points_${id}`]) ||
        parseInt(values[`points_${id}`]) === 0
      ) {
        errors[`points_${id}`] = `A point value is required for the "${name}" action`;
      }
    }
  }
  return errors;
};

class ChallengeForm extends Component {
  handleChangeDates = (whichDate, value, change, actions) => {
    for (let i = 0; i < actions.length; i += 1) {
      const action = actions[i];
      const id = action.id;
      change(`${whichDate}_date_${id}`, value);
    }
  };

  render() {
    const {
      initialValues = {},
      onSubmit,
      showSubmissionError,
      actions = [],
      view = 'add',
    } = this.props;
    const actionIds = [];

    for (let i = 0; i < actions.length; i += 1) {
      const action = actions[i];
      actionIds.push(action.id);
      if (initialValues.id === undefined || view === 'edit') {
        initialValues[`action_${action.id}`] = true;
        initialValues[`points_${action.id}`] = action.points;
        if (action.max_awards !== undefined && !isNaN(action.max_awards)) {
          initialValues[`max_awards_${action.id}`] = action.max_awards;
        }
      }
    }
    initialValues.actionIds = actionIds;

    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values, actions);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <h4>Challenge Details</h4>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Field
                    name="name"
                    label="Name of Challenge*"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                  <FormGroup className="stacked centered">
                    <label>Dates of Challenge*</label>
                    <div className="d-flex centered range-container">
                      <Field
                        name="start_on_date"
                        component={renderDatepickerForField}
                        stackedlabel
                        {...{
                          itemProps: { row: false },
                          inputProps: {
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            dropdownMode: 'select',
                          },
                        }}
                      />
                      <OnChange name="start_on_date">
                        {(value) => {
                          this.handleChangeDates('start', value, form.change, actions);
                        }}
                      </OnChange>
                      <div className="caption">to</div>
                      <Field
                        name="through_date"
                        component={renderDatepickerForField}
                        stackedlabel
                        {...{
                          itemProps: { row: false },
                          inputProps: {
                            showMonthDropdown: true,
                            showYearDropdown: true,
                            dropdownMode: 'select',
                          },
                        }}
                      />
                      <OnChange name="through_date">
                        {(value) => {
                          this.handleChangeDates('end', value, form.change, actions);
                        }}
                      </OnChange>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <Field
                    name="description"
                    label="Description (optional)"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                  <Field
                    name="is_visible"
                    label="Challenge is visible to participating clients?*"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: usedMacrostaxOptions,
                        isClearable: false,
                      },
                    }}
                  />
                </Col>
              </Row>
              {view === 'add' && (
                <>
                  <Row>
                    <Col className="mt-3">
                      <h4>Challenge Scoring</h4>
                      Select which user actions should be included in this challenge:
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-2">
                    <Col xs={1} className="d-flex align-items-end justify-content-center text-bold">
                      Apply?
                    </Col>
                    <Col className="d-flex align-items-end text-bold">Daily Action</Col>
                    <Col xs={2} className="d-flex align-items-end justify-content-center text-bold">
                      Points
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-end justify-content-center text-bold text-center"
                    >
                      Max Awards
                      <br />
                      Per Week
                    </Col>
                    {/* <Col className="d-flex align-items-end justify-content-center text-bold">
                  Dates Applicable
                </Col> */}
                  </Row>
                  {actions.map((a) => (
                    <Row key={a.id} className="mb-2 action-row">
                      <Col xs={1} className="text-center">
                        <Field
                          name={`action_${a.id}`}
                          component={renderCheckboxForField}
                          {...{
                            itemProps: {},
                            checkboxIcon: 'fa-check',
                            checkboxIconUnchecked: 'fa-close',
                            inputProps: {},
                          }}
                        />
                      </Col>
                      <Col>
                        {a.name}
                        <div className="caption">{a.description}</div>
                      </Col>
                      <Col xs={2} className="text-center">
                        <Field
                          name={`points_${a.id}`}
                          component={renderInputForField}
                          className="d-flex justify-content-center"
                          stackedlabel={false}
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                              step: 1,
                              className: 'width-3-rem',
                            },
                          }}
                        />
                      </Col>
                      <Col xs={2} className="text-center">
                        <Field
                          name={`max_awards_${a.id}`}
                          component={renderInputForField}
                          className="d-flex justify-content-center"
                          stackedlabel={false}
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                              step: 1,
                              className: 'width-3-rem',
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              )}

              <Row className={view === 'add' ? 'mt-5' : 'mt-3'}>
                <Col xs={3}>
                  <Button color="primary" size="lg" type="submit" block>
                    {view === 'add' ? 'Next' : 'Save'}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

ChallengeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  actions: PropTypes.instanceOf(Array),
  view: PropTypes.string,
};

export default ChallengeForm;
