import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderSelectForField from '../helpers/renderSelectForField';
import usStates from '../../data/usStates.json';

class StateField extends Component {
  render() {
    const { fieldName = 'state', label = 'State or Province', isClearable = false } = this.props;
    return (
      <Field
        name={fieldName}
        label={label}
        stackedlabel
        component={renderSelectForField}
        {...{
          itemProps: {},
          inputProps: {
            options: usStates,
            isClearable,
          },
        }}
      />
    );
  }
}

StateField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  isClearable: PropTypes.bool,
};

export default StateField;
