import { AsYouType } from 'libphonenumber-js';
import numeral from 'numeral';
import { tzOptions } from '../enums/timezones';

export const formatPhone = (input = '') => {
  if (!input) return '';
  const newVal = input.toString().replace('+1', '');
  if (newVal.includes('(') && !newVal.includes(')')) {
    return newVal.replace('(', '');
  }
  return new AsYouType('US').input(newVal);
};

export const parseBool = (value) => (value === undefined ? false : JSON.parse(value));
export const formatBool = (value) => (value === undefined ? false : JSON.parse(value));

export const capitalizeFirst = (value) =>
  value && value.length ? value.charAt(0).toUpperCase() + value.substring(1, value.length) : value;
export const formatLowercase = (value) => (value && value.length ? value.toLowerCase() : value);

export const convertBoolToYesNo = (b) => (b === true ? 'Yes' : 'No');

export const upperCaseToSentenceCase = (str) =>
  str && str.length ? str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase() : '';

export const camelCaseToSentenceCase = (str) => {
  if (!str) {
    return '';
  }
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const camelCaseToUnderscoreCase = (str) => {
  if (!str) {
    return '';
  }
  const result = str.replace(/([A-Z])/g, '_$1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const enumToSentenceCase = (str, separator = '_') => {
  const ret = [];
  const parts = str.split(separator);
  parts.forEach((p) => ret.push(upperCaseToSentenceCase(p)));
  return ret.join(' ');
};

export const formatHeightFromInches = (height_inches, unit = 'imperial') => {
  if (unit === 'imperial') {
    const remainder = numeral(height_inches % 12).format('0');
    const feet = Math.floor(height_inches / 12);
    return `${feet}' ${remainder}"`;
  }
  return `${numeral(height_inches * 2.54).format('0')} cm`;
};

export const formatMeasurementFromInches = (measurement, unit = 'imperial') => {
  if (unit === 'imperial') {
    return `${measurement}"`;
  }
  return `${numeral(measurement * 2.54).format('0')} cm`;
};

export const formatWeightFromPounds = (weight_pounds, unit = 'imperial') => {
  if (unit === 'imperial') {
    return `${weight_pounds} lbs`;
  }
  return `${numeral(weight_pounds / 2.205).format('0.[0]')} kg`;
};

export const arrayToString = (arr) => arr.join(', ');

export const getLabelForValue = (value, data) => {
  const match = data.find((elem) => elem.value === value);
  if (match) {
    return match.label;
  }
  return '';
};

export const arrayOfLabelsToString = (arr, labels) =>
  arrayToString(arr.map((a) => getLabelForValue(a, labels)));

export const getTimezoneLabel = (tzValue) => {
  const tz = tzOptions.find((t) => t.value === tzValue);
  return tz ? tz.label : 'Unknown';
};

export const formatAddress = (obj) => {
  const { address, address_alt, city, state, zip } = obj;
  if (!address) {
    return '--';
  }
  let add = address;
  if (address_alt && address_alt.length) {
    add += ' ';
    if (!Number.isNaN(address_alt.charAt(0))) {
      add += '#';
    }
    add += address_alt;
  }
  add += '<br />';
  add += `${city}, ${state} ${zip}`;
  return add;
};

export const addLineBreaksForDisplay = (string) =>
  string && string.length
    ? string.split('\n').map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
      ))
    : '';

export const validateEmail = (email) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.trim().match(validRegex);
};

export const reCase = (value) => {
  return value.replace(/\w\S*/g, (txt) => {
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
