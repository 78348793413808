import React, { Component } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import renderInputForField from '../helpers/renderInputForField';
import UploadPhoto from '../fields/UploadPhoto';
import PhoneField from '../fields/PhoneField';
import FirstNameField from '../fields/FirstNameField';
import LastNameField from '../fields/LastNameField';
import EmailField from '../fields/EmailField';

class UserInfoFormPartial extends Component {
  render() {
    const {
      showPhone = false,
      showPhotoUpload = false,
      user,
      showFirstName = true,
      showLastName = true,
      showPassword = false,
      showRequired = true,
      team,
    } = this.props;
    const photoType = user ? 'profile photo' : 'logo';
    const passwordLabel = `Password${showRequired && '*'}`;
    return (
      <div>
        {showPhotoUpload && <UploadPhoto team={team} user={user} label={`Upload ${photoType}`} />}
        {showFirstName && <FirstNameField showRequired={showRequired} />}
        {showLastName && <LastNameField showRequired={showRequired} />}
        <EmailField showRequired={showRequired} />
        {showPassword && (
          <Field
            name="password"
            label={passwordLabel}
            component={renderInputForField}
            stackedlabel
            {...{
              itemProps: {},
              inputProps: {
                type: 'password',
              },
            }}
          />
        )}
        {showPhone && <PhoneField />}
      </div>
    );
  }
}

UserInfoFormPartial.propTypes = {
  showPhone: PropTypes.bool,
  showPhotoUpload: PropTypes.bool,
  user: PropTypes.instanceOf(Object),
  showFirstName: PropTypes.bool,
  showLastName: PropTypes.bool,
  showPassword: PropTypes.bool,
  team: PropTypes.instanceOf(Object),
  showRequired: PropTypes.bool,
};

export default UserInfoFormPartial;
