import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Row, Col, Button } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import renderInputForField from './helpers/renderInputForField';
import PhoneField from './fields/PhoneField';
import renderSelectForField from './helpers/renderSelectForField';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import { memberCountOptions } from '../enums/memberCounts';
import { usedMacrostaxOptions } from '../enums/usedMacrostax';
import { preferredContactMethodOptions } from '../enums/preferredContactMethods';
import { makeSimpleValue } from '../utils/formUtils';
import { FORM_TRANSITION_PROPS } from '../constants';

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Name of business is required';
  }
  if (!values.business_type) {
    errors.business_type = 'Type of business is required';
  } else if (values.business_type === 'Other' && !values.other) {
    errors.other = 'Type of business is required';
  }
  if (!values.client_count) {
    errors.client_count = 'Number of current clients is required';
  }
  if (!values.former_customer) {
    errors.former_customer = 'Please specify whether or not you have used the Macrostax app before';
  }
  // if (values.phone && values.phone !== '' && !values.preferred_contact_method) {
  //   errors.preferred_contact_method = 'Please specify how you prefer to be contacted';
  // }
  if (!values.preferred_contact_method) {
    errors.preferred_contact_method = 'Please select a preferred contact method';
  } else {
    if (values.preferred_contact_method === 'text' || values.preferred_contact_method === 'phone' || values.preferred_contact_method === 'any') {
      if (!values.phone) {
        errors.phone = 'Please enter your phone number';
      } else if (values.preferred_contact_method !== 'phone' && (values.opt_in === false || values.opt_in === undefined)) {
        errors.opt_in = 'Please acknowledge that you consent to receive text messages from Macrostax';
      }
    }
  }
  return errors;
};

class SignUpStep2Form extends Component {
  constructor(props) {
    super(props);
    const { initialValues = {} } = props;
    this.state = {
      business_type: initialValues.business_type,
      preferred_contact_method: initialValues.preferred_contact_method,
    };
  }

  handleChangeBusinessType = (val) => {
    this.setState({
      business_type: val,
    });
  };

  handleChangePreferredContactMethod = (val) => {
    this.setState({
      preferred_contact_method: val,
    });
  };

  render() {
    const { initialValues, onSubmit, showSubmissionError, businessTypes } = this.props;
    const { business_type, preferred_contact_method } = this.state;
    const businessTypeOptions = businessTypes.map((t) => ({ label: t, value: t }));
    businessTypeOptions.push({ label: 'Other', value: 'Other' });
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <Field
                    name="name"
                    label="Name of Business*"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'text',
                      },
                    }}
                  />
                  <Field
                    name="business_type"
                    label="Type of Business*"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: businessTypeOptions,
                        isClearable: false,
                      },
                    }}
                  />
                  <OnChange name="business_type">
                    {(value) => {
                      this.handleChangeBusinessType(makeSimpleValue(value), form.change);
                    }}
                  </OnChange>
                  <CSSTransition
                    {...FORM_TRANSITION_PROPS}
                    in={business_type === 'Other'}
                    onExited={() => {}}
                  >
                    <div className="d-flex">
                      <div className="optional-field-connector" />
                      <div className="w-100">
                        <Field
                          name="other"
                          label="Please specify type of business*"
                          component={renderInputForField}
                          stackedlabel
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'text',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </CSSTransition>
                  <Field
                    name="client_count"
                    label="How many current clients do you have? (This will not impact your plan)*"
                    component={renderSelectForField}
                    stackedlabel
                    cardIcon="HOW_MANY_CLIENTS"
                    cardId="HOW_MANY_CLIENTS"
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: memberCountOptions,
                        isClearable: true,
                      },
                    }}
                  />
                  <Field
                    name="former_customer"
                    label="Have you used the Macrostax app before?*"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: usedMacrostaxOptions,
                        isClearable: true,
                      },
                    }}
                  />
                  <Field
                    name="preferred_contact_method"
                    label="How do you prefer to be contacted?*"
                    component={renderSelectForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        options: preferredContactMethodOptions,
                        isClearable: true,
                      },
                    }}
                  />
                  <OnChange name="preferred_contact_method">
                    {(value) => {
                      this.handleChangePreferredContactMethod(value, form.change);
                    }}
                  </OnChange>
                  <CSSTransition
                    {...FORM_TRANSITION_PROPS}
                    in={
                      preferred_contact_method !== '' &&
                      preferred_contact_method !== undefined &&
                      preferred_contact_method !== null &&
                      (preferred_contact_method === 'text' ||
                        preferred_contact_method === 'any' ||
                        preferred_contact_method === 'phone')
                    }
                    onExited={() => {}}
                  >
                    <PhoneField label="Phone Number" />
                  </CSSTransition>
                  <CSSTransition
                    {...FORM_TRANSITION_PROPS}
                    in={
                      preferred_contact_method !== '' &&
                      preferred_contact_method !== undefined &&
                      preferred_contact_method !== null &&
                      (preferred_contact_method === 'text' || preferred_contact_method === 'any')
                    }
                    onExited={() => {}}
                  >
                    <label className="d-flex flex-row align-items-center cursor-pointer">
                      <Field
                        name="opt_in"
                        component={renderCheckboxForField}
                        {...{
                          itemProps: {},
                          checkboxIcon: 'fa-check',
                          checkboxIconUnchecked: 'fa-close',
                          inputProps: {},
                          // htmlLabel:
                          //   'By checking this box, I am consenting to receive SMS text messages from Macrostax. Message and data rates may apply. Message frequency varies. Text HELP for help or STOP to cancel. View <a href="https://www.macrostax.com/terms-conditions/" target="_blank">Terms</a> and <a href="https://www.macrostax.com/privacy-policy/" target="_blank">Privacy</a>.',
                          // labelClassName: 'caption',
                        }}
                      />
                      <div className="caption">
                        By checking this box, I am consenting to receive SMS text messages from
                        Macrostax. Message and data rates may apply. Message frequency varies. Text
                        HELP for help or STOP to cancel. View{' '}
                        <a href="https://www.macrostax.com/terms-conditions/" target="_blank" rel="noreferrer">
                          Terms
                        </a>{' '}
                        and{' '}
                        <a href="https://www.macrostax.com/privacy-policy/" target="_blank" rel="noreferrer">
                          Privacy
                        </a>
                        .
                      </div>
                    </label>
                  </CSSTransition>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex">
                  <Button color="primary" size="lg" type="submit" block>
                    Next
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

SignUpStep2Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  businessTypes: PropTypes.instanceOf(Array),
};

export default SignUpStep2Form;
