import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderSelectForField from '../helpers/renderSelectForField';
import { unitOptions } from '../../enums/units';

class WeightUnitsField extends Component {
  render() {
    const {
      fieldName = 'preferred_weight_units',
      label = 'Weight Units',
      inputCaption,
    } = this.props;
    return (
      <Field
        name={fieldName}
        label={label}
        component={renderSelectForField}
        stackedlabel
        {...{
          itemProps: {},
          inputProps: {
            options: unitOptions,
            isClearable: false,
          },
        }}
      />
    );
  }
}

WeightUnitsField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  inputCaption: PropTypes.string,
};

export default WeightUnitsField;
