export const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const trueOrFalse = () => Math.random() < 0.5;

const dayTypes = ['Rest', 'Workout', 'Refeed'];
const macros = ['carb', 'protein', 'fat'];

const generateMacroValue = (macro, target) => {
  const ret = {};
  const hit = trueOrFalse();
  const hitThreshold = macro === 'fat' ? 2 : 5;
  if (hit) {
    ret.status = 'hit';
    ret.grams = randomIntFromInterval(target - hitThreshold, target + hitThreshold);
  } else {
    ret.status = trueOrFalse() ? 'over' : 'under';
    ret.grams =
      ret.status === 'over'
        ? randomIntFromInterval(target + (hitThreshold + 1), target + 50)
        : randomIntFromInterval(target - 50, target - (hitThreshold + 1));
  }
  return ret;
};

export const generateScoreboard = (targets) => {
  const dayType = dayTypes[randomIntFromInterval(0, 2)];
  const ret = {
    dayType,
  };
  macros.forEach(
    (m) =>
      (ret[m] = {
        status: 'none',
        grams: 0,
      })
  );

  // we'll say the user logs 2 out of every 3 days
  const didLog = !(randomIntFromInterval(0, 2) === 0);
  if (!didLog) {
    return ret;
  }

  macros.forEach((m) => (ret[m] = generateMacroValue(m, targets[m])));
  return ret;
};

export const generateSubscriptionStatus = () => {
  // we'll say that 2/3 of businesses are active
  const isActive = !(randomIntFromInterval(0, 2) === 0);
  if (!isActive) {
    // and the remaining 1/3 is split between canceled and suspended
    const isCanceled = trueOrFalse();
    return isCanceled ? 'canceled' : 'suspended';
  }
  return 'active';
};
