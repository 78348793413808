export const preferredContactMethodOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Text message',
    value: 'text',
  },
  {
    label: 'Any of the above',
    value: 'any',
  },
];
