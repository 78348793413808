// Note: this form is self-contained, meaning all of the dispatch calls are defined
// within this instead of being passed in as props
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Row, Col, Button } from 'reactstrap';
import { formatDateToIso8601 } from '../utils/dateUtils';
import DeleteMeasurementModal from '../modals/DeleteMeasurementModal';
import { submissionError } from '../actions/formActions';
import { addMeasurements, updateMeasurements } from '../actions/userActions';
import renderInputForField from './helpers/renderInputForField';
import renderDatepickerForField from './helpers/renderDatepickerForField';

const validate = (values) => {
  const errors = {};
  if (!values.date) {
    errors.date = 'Date is required';
  }
  if (!values.chest_inches && !values.waist_inches && !values.hips_inches) {
    errors.value = 'At least one measurement is required';
  }
  return errors;
};

class MeasurementsForm extends Component {
  constructor(props) {
    super(props);
    const {
      user: { measurements },
    } = this.props;
    this.state = {
      measurements,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      measurements: props.user.measurements,
    };
  }

  saveMeasurement = (measurement) => {
    const { date, chest_inches, waist_inches, hips_inches } = measurement;
    // editing an existing measurements
    if (measurement.id == undefined) {
      this.props.doUpdateMeasurement(this.props.user.user_id, {
        date: formatDateToIso8601(date),
        chest_inches: chest_inches || '',
        waist_inches: waist_inches || '',
        hips_inches: hips_inches || '',
      });
    }
    // adding a new measurements
    else {
      this.props.doAddMeasurement(this.props.user.user_id, {
        date: formatDateToIso8601(date),
        chest_inches: chest_inches || '',
        waist_inches: waist_inches || '',
        hips_inches: hips_inches || '',
      });
    }
  };

  editMeasurement = (measurement, status) => {
    const { measurements } = this.state;
    const record = measurements.find((m) => m.date === measurement.date);
    if (record) {
      record.status = status; // mutate
      this.setState({
        measurements,
      });
    }
  };

  addMeasurement = () => {
    const { measurements } = this.state;
    measurements.push({
      date: moment().toDate(),
      id: uuidv4(),
      chest_inches: '',
      waist_inches: '',
      hips_inches: '',
      status: 'edit',
    });
    this.setState({
      measurements,
    });
  };

  cancelEdit = (measurement) => {
    const { measurements } = this.state;
    // cancelling the edit of an existing measurement
    if (measurement.id == undefined) {
      const record = this.state.measurements.find((m) => m.date === measurement.date);
      if (record) {
        record.status = undefined; // mutate
        this.setState({ measurements });
      }
    }
    // cancelling a newly-added weigh-in
    else {
      measurements.pop();
      this.setState({ measurements });
    }
  };

  toggleDeleteMeasurementModal = (date) => {
    const { deleteMeasurementModalOpen } = this.state;
    this.setState({
      deleteMeasurementModalOpen: !deleteMeasurementModalOpen,
      deleteMeasurementOnDate: date,
    });
  };

  render() {
    const { showSubmissionError, user } = this.props;
    const { user_id, timezone } = user;
    const { measurements, deleteMeasurementModalOpen, measurementId } = this.state;

    return (
      <>
        <Row>
          <Col xs={3} className="strong p-2">
            Date
          </Col>
          <Col xs={2} className="text-center strong border-left p-2">
            Chest (in)
          </Col>
          <Col xs={2} className="text-center strong border-left p-2">
            Waist (in)
          </Col>
          <Col xs={2} className="text-center strong border-left p-2">
            Hips (in)
          </Col>
          <Col xs={3} className="text-center strong border-left p-2">
            Actions
          </Col>
        </Row>
        {measurements.map((m, idx) => (
          <Form
            key={m.date}
            onSubmit={(values) => {
              const errors = validate(values, measurements);
              if (Object.keys(errors).length === 0) {
                this.saveMeasurement(values);
              } else {
                showSubmissionError(errors, false);
              }
            }}
            initialValues={{
              chest_inches: m.chest_inches,
              waist_inches: m.waist_inches,
              hips_inches: m.hips_inches,
              date: moment(m.date).toDate(),
              id: m.id,
            }}
            keepDirtyOnReinitialize={false}
            render={({ handleSubmit, submitting, form, values }) => (
              <form onSubmit={handleSubmit} className="weigh-ins-form">
                <Row className={`border-top`}>
                  <Col xs={3} className="p-2 align-self-center">
                    {m.status !== 'edit' ? (
                      `${moment(m.date).tz(timezone).format('MMMM D, YYYY')}`
                    ) : (
                      <Field
                        name="date"
                        component={renderDatepickerForField}
                        className="text-left"
                        {...{
                          itemProps: {},
                          inputProps: {
                            maxDate: moment().toDate(),
                          },
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className="text-center border-left d-flex align-items-center justify-content-center"
                  >
                    {m.status !== 'edit' ? (
                      m.chest_inches && `${m.chest_inches}`
                    ) : (
                      <div className="animated">
                        <Field
                          name="chest_inches"
                          component={renderInputForField}
                          stackedlabel={false}
                          parse={(value) =>
                            value ? parseFloat(Number.parseFloat(value).toFixed(2)).toString() : ''
                          }
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className="text-center border-left d-flex align-items-center justify-content-center"
                  >
                    {m.status !== 'edit' ? (
                      m.waist_inches && `${m.waist_inches}`
                    ) : (
                      <div className="animated">
                        <Field
                          name="waist_inches"
                          component={renderInputForField}
                          stackedlabel={false}
                          parse={(value) =>
                            value ? parseFloat(Number.parseFloat(value).toFixed(2)).toString() : ''
                          }
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col
                    xs={2}
                    className="text-center border-left d-flex align-items-center justify-content-center"
                  >
                    {m.status !== 'edit' ? (
                      m.hips_inches && `${m.hips_inches}`
                    ) : (
                      <div className="animated">
                        <Field
                          name="hips_inches"
                          component={renderInputForField}
                          stackedlabel={false}
                          parse={(value) =>
                            value ? parseFloat(Number.parseFloat(value).toFixed(2)).toString() : ''
                          }
                          {...{
                            itemProps: {},
                            inputProps: {
                              type: 'number',
                            },
                          }}
                        />
                      </div>
                    )}
                  </Col>
                  <Col xs={3} className="text-center border-left p-2">
                    {m.status !== 'edit' ? (
                      <div className="d-flex justify-content-around animated">
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.editMeasurement(m, 'edit');
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.toggleDeleteMeasurementModal(m.date);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-around animated">
                        <Button color="primary">Save</Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            this.cancelEdit(m);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </form>
            )}
          />
        ))}
        <Row className="border-top pt-3">
          <Col>
            <Button
              onClick={(e) => {
                e.preventDefault();
                this.addMeasurement();
              }}
              disabled={this.state.measurements.filter((m) => m.id != undefined).length > 0}
            >
              Add Measurements
            </Button>
          </Col>
        </Row>
        <DeleteMeasurementModal
          userId={user_id}
          dateToDelete={this.state.deleteMeasurementOnDate}
          isOpen={deleteMeasurementModalOpen}
          toggle={this.toggleDeleteMeasurementModal}
        />
      </>
    );
  }
}

MeasurementsForm.propTypes = {
  user: PropTypes.instanceOf(Object),
  doAddMeasurement: PropTypes.func,
  doUpdateMeasurement: PropTypes.func,
  showSubmissionError: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors, scroll) => dispatch(submissionError(errors, scroll)),
  doAddMeasurement: (id, values) => dispatch(addMeasurements(id, values)),
  doUpdateMeasurement: (id, values) => dispatch(updateMeasurements(id, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementsForm);
