import React, { Component, forwardRef } from 'react';
import { Row, Col, CardDeck } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Loading from '../../components/Common/Loading';
import StatisticCard from '../../components/Cards/StatisticCard';
import DashboardLeaderboardCardAlt from '../../components/Cards/DashboardLeaderboardCardAlt';
import GoalDistributionCard from '../../components/Cards/GoalDistributionCard';
import { shuffleArray } from '../../utils/arrayUtils';

class TeamDashboardContainer extends Component {
  constructor(props) {
    super(props);
    const now = moment();
    const { clients, startDate = now.clone().add(-30, 'days'), endDate = now, showStatistics = true } = props;
    this.state = {
      clientListModalOpen: false,
      filteredClients: clients,
      filteredClientsType: '',
      startDate,
      endDate,
      summarySortBy: 'days_active',
      summaryOrder: 'asc',
      summaryShowCount: 25,
      summaryPage: 1,
      showStatistics,
    };
  }

  toggleClientListModal = () => {
    const { clientListModalOpen } = this.state;
    this.setState({
      clientListModalOpen: !clientListModalOpen,
    });
  };

  filterClients = (type) => {
    const { clients, team } = this.props;
    const { powerUsers, inactiveUsers } = team;
    // TODO: this is just simulating a subset of users based on the counts above
    const shuffled = shuffleArray(clients);
    const count = type === 'Power' ? powerUsers : inactiveUsers;
    this.setState(
      {
        filteredClients: shuffled.slice(0, count),
        filteredClientsType: type,
      },
      () => this.toggleClientListModal()
    );
  };

  setDates = (whichDate, val) => {
    let {
      startDate,
      endDate,
    } = this.state;
    const {
      summarySortBy,
      summaryOrder,
      summaryPage,
      summaryShowCount,
    } = this.state;
    const { team, doGetEngagementSummary } = this.props;
    const { team_id } = team;
    const m = moment(val);
    if (whichDate === startDate) {
      startDate = m;
      if (m.isAfter(endDate)) {
        endDate = startDate.clone().add(1, 'days');
      }
    } else {
      endDate = m;
      if (m.isBefore(startDate)) {
        startDate = endDate.clone().add(-1, 'days');
      }
    }

    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        doGetEngagementSummary(
          team_id,
          startDate,
          endDate,
          summarySortBy,
          summaryOrder,
          summaryPage,
          summaryShowCount
        );
      }
    );
  };

  updateSortOrder = (sortBy, order, page, showCount) => {
    const { startDate, endDate } = this.state;
    const { team, doGetEngagementSummary } = this.props;
    const { team_id } = team;

    this.setState(
      {
        startDate,
        endDate,
        summarySortBy: sortBy,
        summaryOrder: order,
        summaryPage: page,
        summaryShowCount: showCount,
      },
      () => {
        doGetEngagementSummary(team_id, startDate, endDate, sortBy, order, page, showCount, false);
      }
    );
  };

  render() {
    const { team, isTeamOwner, doGetEngagementSummaryCsv, doClearEngagementSummaryCsv } = this.props;
    const {
      membership,
      engagementSummary,
      team_id,
      counts,
      goal_distribution,
      exportData,
      exportHeaders,
    } = team;
    const { active_clients, max_clients } = membership;
    const {
      startDate,
      endDate,
    } = this.state;

    let pendingInvitations = 0;

    const pendingObj = counts.find((c) => c.clients_pending_invite !== undefined);
    if (pendingObj) {
      pendingInvitations = pendingObj.clients_pending_invite;
    }

    let remainingClients = max_clients - active_clients;
    if (remainingClients < 0) {
      remainingClients = 0;
    }

    const DateInput = forwardRef(({ value, onClick }, ref) => (
      <span className="nls-input" onClick={onClick} ref={ref}>
        {value}
      </span>
    ));

    return (
      <>
        <Row className="d-flex justify-content-between">
          <Col>
            <div className="mb-4 d-flex flex-row dashboard-dates-wrapper">
              <div className="mr-1">Viewing engagement activity between </div>
              <DatePicker
                selected={startDate.toDate()}
                onChange={(val) => this.setDates(startDate, val)}
                dateFormat="MMMM d, yyyy"
                showMonthDropdown
                showYearDropdown
                customInput={<DateInput />}
              />
              <div className="ml-1 mr-1">and </div>
              <DatePicker
                selected={endDate.toDate()}
                onChange={(val) => this.setDates(endDate, val)}
                dateFormat="MMMM d, yyyy"
                showMonthDropdown
                showYearDropdown
                customInput={<DateInput />}
              />
            </div>
            {engagementSummary ? (
              <DashboardLeaderboardCardAlt
                engagementSummary={engagementSummary}
                team_id={team_id}
                isTeamOwner={isTeamOwner}
                startDate={startDate}
                endDate={endDate}
                updateSortOrder={this.updateSortOrder}
                getEngagementSummaryCsv={doGetEngagementSummaryCsv}
                clearEngagementSummaryCsv={doClearEngagementSummaryCsv}
                exportData={exportData}
                exportHeaders={exportHeaders}
              />
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
        {this.state.showStatistics ?
        (
          <Row>
            <Col className="no-flex-on-mobile">
              <StatisticCard
                header="Active Clients"
                statistic={active_clients.toString()}
                caption={`You have ${remainingClients} spot${
                remainingClients !== 1 ? 's' : ''
              } remaining*`}
              />
              <StatisticCard
                header="Pending Client Invitations"
                className="mr-4 ml-4 no-margin-mobile"
                statistic={pendingInvitations.toString()}
              />
              <GoalDistributionCard goalDistribution={goal_distribution} />
            </Col>
          </Row>
        ) : <></>}
        <Row>
          <Col className="card-caption">
            *If you exceed your tier's number of active clients we will automatically upgrade your
            plan on your renewal date.
          </Col>
        </Row>
      </>
    );
  }
}

TeamDashboardContainer.propTypes = {
  team: PropTypes.instanceOf(Object),
  clients: PropTypes.instanceOf(Object),
  isTeamOwner: PropTypes.bool,
  doGetEngagementSummary: PropTypes.func,
  doGetEngagementSummaryCsv: PropTypes.func,
  doClearEngagementSummaryCsv: PropTypes.func,
  showStatistics: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  clients: state.clients,
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDashboardContainer);
