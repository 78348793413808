import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import ENV_VARS from '../../utils/envVars';
import { formatFromNowDate } from '../../utils/dateUtils';
import Loading from '../Common/Loading';

import 'stream-chat-react/dist/css/v2/index.css';

const chatClient = StreamChat.getInstance(ENV_VARS.STREAM_CHAT_ACCOUNT_ID);

const ChatGroupModule = ({ targetChannelId }) => {
  const [channel, setChannel] = useState(null);
  const connectToChannel = useCallback(async () => {
      const channel = chatClient.channel(
        'group', targetChannelId
      );
      setChannel(channel);
  }, [targetChannelId]);

  useEffect(() => {
    if (targetChannelId) {
      connectToChannel();
    }
  }, [targetChannelId]);

  if (!channel) {
    return (
      <div className="mt-5">
        <Loading />
      </div>
    );
  }

  return (
    <div className="planner">
      <Row>
        <Col>
          <Chat client={chatClient} theme="str-chat__theme-light">
            <Channel channel={channel}>
              <Window>
                <MessageList disableDateSeparator={false} formatDate={formatFromNowDate} />
                <MessageInput grow={true} />
              </Window>
              <Thread />
            </Channel>
          </Chat>
        </Col>
      </Row>
    </div>
  );
};

ChatGroupModule.propTypes = {
  targetChannelId: PropTypes.string,
};

export default ChatGroupModule;
