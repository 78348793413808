import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

class RecipeDirections extends Component {
  render() {
    const { component: { directions} } = this.props;
    const dirs = directions.split('\n');
    return (
      <Row>
        <Col>
          <ol className="directions-list">
            {dirs.map((d) => (
              d.trim().length > 0 && (
                <li key={d}>{isNaN(d.charAt(0)) === false || d.substring(0, 2) === '- ' ? d.substring(2, d.length) : d}</li>
              )
            ))}
          </ol>
        </Col>
      </Row>
    );
  }
}

RecipeDirections.propTypes = {
  component: PropTypes.instanceOf(Object),
};

export default RecipeDirections;
