import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import renderInputForField from './helpers/renderInputForField';

const validate = (values, showOldPassword) => {
  const errors = {};
  if (showOldPassword && !values.password) {
    errors.password = 'Password is required';
  }
  if (!values.new_password) {
    errors.new_password = 'New password is required';
  } else if (values.new_password.length < 6) {
    errors.new_password = 'Passwords must be at least 6 characters';
  }
  if (!values.newPassword) {
    errors.newPassword2 = 'Confirm new password is required';
  }
  if (values.new_password !== values.newPassword) {
    errors.newPassword = 'Your passwords do not match';
  }
  return errors;
};

class ChangePasswordForm extends Component {
  render() {
    const { initialValues, onSubmit, showSubmissionError, showOldPassword = true } = this.props;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values, showOldPassword);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  {showOldPassword && (
                    <Field
                      name="password"
                      label="Old Password"
                      component={renderInputForField}
                      stackedlabel
                      {...{
                        itemProps: {},
                        inputProps: {
                          type: 'password',
                        },
                      }}
                    />
                  )}
                  <Field
                    name="new_password"
                    label="New Password"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'password',
                      },
                    }}
                  />
                  <Field
                    name="newPassword"
                    label="Confirm New Password"
                    component={renderInputForField}
                    stackedlabel
                    {...{
                      itemProps: {},
                      inputProps: {
                        type: 'password',
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <button className="btn btn-lg btn-primary" type="submit" disabled={submitting}>
                    Save
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  showOldPassword: PropTypes.bool,
};

export default ChangePasswordForm;
