import React, { Component } from 'react';
import PropTypes from 'prop-types';

/** Component used to display a header on menu when using collapsed/hover mode */
class SidebarSubHeader extends Component {
  render() {
    const {
      item: { name },
    } = this.props;

    return <li className="sidebar-subnav-header">{name}</li>;
  }
}

SidebarSubHeader.propTypes = {
  item: PropTypes.instanceOf(Object),
};

export default SidebarSubHeader;
