import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import Loading from '../components/Common/Loading';

class UserScoresModal extends Component {
  render() {
    const { isOpen, toggle, userScores, firstName, challengeName } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/scoreDetail', title: 'Score Detail' });
    }

    const scores = {};
    let dates = [];
    let lastDate;
    if (userScores && userScores.length) {
      for (let i = 0; i < userScores.length; i += 1) {
        const score = userScores[i];
        if (score.date !== lastDate) {
          scores[score.date] = {
            scores: [],
            total: 0,
          };
          lastDate = score.date;
          dates.push(score.date);
        }
        scores[score.date].scores.push({ ...score });
        scores[score.date].total += score.points;
      }
    }
    dates = dates.reverse();

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium" scrollable={true}>
        <ModalHeader toggle={toggle}>
          <PageTitle title="Score Detail" showTeam={false} icon="icon-trophy" />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              {userScores && userScores.length ? (
                <>
                  <Row>
                    <Col>
                      Here is {firstName}'
                      {firstName.substring(firstName.length - 1, 1) !== 's' ? 's' : ''} scoring
                      breakdown for this challenge:
                    </Col>
                  </Row>
                  {dates.map((d) => (
                    <Row className="score-date-container" key={d}>
                      <Col xs={3} className="text-bold">
                        {moment(d).format('MMMM D')}
                      </Col>
                      <Col>
                        {scores[d].scores.map((s, idx) => (
                          <Row className="score-row" key={`${d}_${idx}`}>
                            <Col>{s.description}</Col>
                            <Col xs={3} className="text-right">{s.points}</Col>
                          </Row>
                        ))}
                        <Row className="total-row score-row">
                          <Col>Total</Col>
                          <Col xs={3} className="text-right">{scores[d].total}</Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                </>
              ) : userScores && !userScores.length ? (
                <Row>
                  <Col>{firstName} has not scored any points yet for this challenge.</Col>
                </Row>
              ) : (
                <Loading />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

UserScoresModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  userScores: PropTypes.instanceOf(Array),
};

const mapStateToProps = (state) => ({
  userScores: state.challenges.userScores,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserScoresModal);
