import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import renderSelectForField from './helpers/renderSelectForField';
import FirstNameField from './fields/FirstNameField';
import LastNameField from './fields/LastNameField';
import EmailField from './fields/EmailField';
import { isTeamUser } from '../utils/authUtils';
import { USER_ROLE_OPTIONS } from '../constants';

const validate = () => {
  const errors = {};
  return errors;
};

class ManageInvitesSearchForm extends Component {
  onResetClick = (form) => {
    const { onReset } = this.props;

    form.reset();
    onReset();
  }

  onSearchClick = (teamAdmin, values) => {
    const { onSubmit, showSubmissionError } = this.props;
    let vals = false;
    for (let i = 0; i < Object.keys(values).length; i += 1) {
      if (values[Object.keys(values)[i]]) {
        vals = true;
      }
    }
    if (!vals && !teamAdmin) {
      showSubmissionError({ error: 'Please enter some search criteria' });
    } else {
      const errors = validate(values);
      if (Object.keys(errors).length === 0) {
        onSubmit(values);
      } else {
        showSubmissionError(errors);
      }
    }
  }

  render() {
    const { initialValues, currentUser } = this.props;
    const current_user_roles = currentUser.roles;
    const teamAdmin = isTeamUser(current_user_roles);

    return (
      <div>
        <Form
          onSubmit={(values) => this.onSearchClick(teamAdmin, values)}
          initialValues={initialValues}
          decorators={[]}
          render={({ handleSubmit, submitting, form, }) => {
            return (
              <form onSubmit={handleSubmit} className="inline-form">
                <Row>
                  <Col md={6}>
                    <FirstNameField />
                    <LastNameField />
                  </Col>
                  <Col med={6}>
                    <EmailField />
                    {teamAdmin && (
                    <Field
                      name="user_role_status"
                      label="Account Status"
                      component={renderSelectForField}
                      initialValue={initialValues.user_role_status}
                      {...{
                          itemProps: {},
                          inputProps: {
                            options: USER_ROLE_OPTIONS,
                            isMulti: true,
                          },
                        }}
                    />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <button
                      className="btn btn-lg btn-secondary fixed-width-md"
                      type="reset"
                      onClick={() => this.onResetClick(form)}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-lg btn-primary fixed-width-md ml-3 btn-search-with-reset"
                      type="submit"
                      disabled={submitting}
                    >
                      Search
                    </button>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

ManageInvitesSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onReset: PropTypes.func,
  currentUser: PropTypes.instanceOf(Object),
};

export default ManageInvitesSearchForm;
