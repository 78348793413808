import React from 'react';
import { Row, Col } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import UserDetailContainer from '../../containers/Users/UserDetailContainer';
import { getUser } from '../../actions/userActions';

class UserDetail extends React.Component {
  componentDidMount() {
    const {
      doGetUser,
      match: {
        params: { user_id },
      },
    } = this.props;
    doGetUser(user_id);
  }

  render() {
    const { user } = this.props;
    const qs = queryString.parse(location.search);
    return (
      <ContentWrapper className="user-detail-container">
        <Row>
          <Col xs={12}>
            <PageTitle title="Users" />
            <div className="mb-4 mt-n2">
              {qs.team_id && qs.team_id.length ? (
                <Link to={`/teams/${qs.team_id}#clients`}>
                  <em className="fa-lg mr-2 fas fa-arrow-left" />
                  Back to Team
                </Link>
              ) : (
                <Link to="/users">
                  <em className="fa-lg mr-2 fas fa-arrow-left" />
                  Back to Users
                </Link>
              )}
            </div>
          </Col>
        </Row>
        {user && user.selected_team_id ? (
          <div className="main-content-container">
            <UserDetailContainer user={user} />
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

UserDetail.propTypes = {
  user: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => ({
  doGetUser: (user_id) => dispatch(getUser(user_id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
