import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isTeamUser } from '../../utils/authUtils';

class PageTitle extends Component {
  render() {
    const { team, title, showTeam = true, icon, user } = this.props;
    const { roles } = user || {};
    const subtitle = team && isTeamUser(roles) ? team.businessName : 'Macrostax Internal Admin';
    return (
      <div className="page-title-container">
        {showTeam && <div className="team-name">{subtitle}</div>}
        <h1>
          {icon && <em className={`${icon}`} />}
          {title}
        </h1>
      </div>
    );
  }
}

PageTitle.propTypes = {
  team: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  showTeam: PropTypes.bool,
  icon: PropTypes.string,
  user: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  team: state.team,
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(PageTitle);
