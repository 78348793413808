import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import Pill from '../Common/Pill';
import tableIcons from '../../utils/muiIcons';
import { capitalizeFirst } from '../../utils/stringUtils';
import { getClassNameForSubscriptionStatus } from '../../utils/subscriptionUtils';
import { renderColumnHeaderWithTooltip } from '../../utils/tableUtils';
import { rolesById } from '../../enums/roles';

class TeamTable extends Component {
  render() {
    const {
      teams,
      title = 'Search Results',
      onRowClick,
      statusColumn = 'subscription_status',
      showClients = true,
      showRole = false,
      showJoinDate = true,
      showStatusTooltip = true,
    } = this.props;

    let totalClientsAdded = 0;
    let totalClientsInvited = 0;
    let totalClientsActive = 0;
    for (let i = 0; i < teams.length; i += 1) {
      const team = teams[i];
      totalClientsAdded += team.clients_total || 0;
      totalClientsInvited += team.clients_invited || 0;
      totalClientsActive += team.clients_active || 0;
    }

    const cols = [
      { title: 'Business Name', field: 'name' },
      // { title: 'Last Session', field: 'lastSession' },
    ];
    if (showJoinDate) {
      cols.push({
        title: 'Join Date',
        field: 'join_date',
        defaultSort: 'desc',
        type: 'datetime',
        render: (rowData) => moment.tz(rowData.join_date, 'UTC').tz('America/Denver').format('M/D/YY'),
      });
    }
    if (showClients) {
      cols.push({
        title: renderColumnHeaderWithTooltip('2', 'Clients Added', 'CLIENTS_ADDED', '', totalClientsAdded),
        field: 'clients_total',
        render: (rowData) => rowData.clients_total || 0,
      });
      cols.push({
        title: renderColumnHeaderWithTooltip(
          '3',
          'Clients Invited',
          'CLIENTS_INVITED',
          '',
          totalClientsInvited
        ),
        field: 'clients_invited',
        render: (rowData) => rowData.clients_invited || 0,
      });
      cols.push({
        title: renderColumnHeaderWithTooltip('4', 'Clients Active', 'CLIENTS_ACTIVE', '', totalClientsActive),
        field: 'clients_active',
        render: (rowData) => (
          <div className={rowData.clients_active > rowData.max_clients ? 'over-quota' : ''}>
            <span>{rowData.clients_active || 0}</span>
          </div>
        ),
      });
      cols.push({
        title: renderColumnHeaderWithTooltip('5', 'Max Clients', 'MAX_CLIENTS', 'nowrap'),
        field: 'max_clients',
      });
    }
    if (showRole) {
      cols.push({
        title: 'Role',
        field: 'role_id',
        render: (rowData) => rolesById[rowData.role_id],
      });
    }
    cols.push({
      title: showStatusTooltip ? renderColumnHeaderWithTooltip('6', 'Status', 'STATUS', 'nowrap') : 'Status',
      field: 'status',
      render: (rowData) => {
        return (
          <Pill
            title={capitalizeFirst(
              rowData.status.replace('cancellation', 'cancelation').replace('_', ' ')
            )}
            className={getClassNameForSubscriptionStatus(rowData.status)}
          />
        );
      },
    });
    return (
      <div className="teams-table">
        <MaterialTable
          columns={cols}
          data={teams}
          title={title}
          key={teams.length}
          options={{
            // padding: 'dense',
            pageSize: teams.length < 10 ? teams.length : 10,
            search: false,
            paging: teams.length > 10,
            thirdSortClick: false,
          }}
          style={{
            backgroundColor: '#f7f7f7',
          }}
          icons={tableIcons}
          onRowClick={onRowClick}
        />
      </div>
    );
  }
}

TeamTable.propTypes = {
  title: PropTypes.string,
  onRowClick: PropTypes.func,
  teams: PropTypes.instanceOf(Object),
  statusColumn: PropTypes.string,
  showClients: PropTypes.bool,
  showRole: PropTypes.bool,
  showJoinDate: PropTypes.bool,
  showStatusTooltip: PropTypes.bool,
};

export default TeamTable;
