import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';
import CreditCardForm from '../../forms/CreditCardForm';
import Testimonial from '../../components/Signup/Testimonial';
import { submissionError } from '../../actions/formActions';
import { signUpBilling, setSignUpStep } from '../../actions/signupActions';
import browserHistory from '../../history';
import ENV_VARS from '../../utils/envVars';

class SignUpBilling extends React.Component {
  componentDidMount() {
    const { signup, doSetSignupStep } = this.props;
    if (!signup || !signup.first_name || !signup.planId) {
      browserHistory.push('/signup');
    } else {
      doSetSignupStep(4);
    }
  }

  render() {
    const { showSubmissionError, doSignUpBilling, signup } = this.props;
    let planId;
    let plan;
    let membershipOptions;
    if (signup && signup.membershipOptions) {
      ({ planId, membershipOptions } = signup);
      plan = membershipOptions.find((s) => s.id === planId);
    }
    const trialEnd = moment().add(ENV_VARS.TRIAL_DAYS, 'days');
    return (
      <ContentWrapper>
        <Row>
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <p>You've selected:</p>
              {!plan ? (
                <Loading />
              ) : (
                <>
                  <h2 className="mt-3">{plan.name} Plan</h2>
                  <p className="mt-3 mb-4">
                    Your card will not be charged until your <strong>{ENV_VARS.TRIAL_DAYS}-day FREE trial</strong>{' '}
                    expires on {trialEnd.format('MMMM D, YYYY')}.
                  </p>
                  <CreditCardForm
                    showSubmissionError={showSubmissionError}
                    showCancel={false}
                    buttonText="Start My Free Trial"
                    onSubmit={(values) => doSignUpBilling(values)}
                    buttonColClass="d-flex"
                  />
                  <div className="mt-4 text-center card-caption">
                    By continuing, you agree to the Macrostax Team <a href="https://www.macrostax.com/terms-conditions-macrostax-team/" target="_blank" rel="noreferrer">terms and conditions</a>.
                    <br />
                    You can cancel at any time!
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col className="d-flex flex-column align-items-center">
            <Testimonial signup={signup} />
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUpBilling.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUpBilling: PropTypes.func,
  signup: PropTypes.instanceOf(Object),
  subscriptions: PropTypes.instanceOf(Array),
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
  subscriptions: state.billing.subscriptions,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUpBilling: (values) => {
    dispatch(signUpBilling(values));
  },
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpBilling);
