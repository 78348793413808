import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import DropdownSubMenu from '../../components/Common/DropdownSubMenu';
import UserAvatar from '../../components/Common/UserAvatar';
import { markNotificationAsRead, removeNotification } from '../../actions/notificationActions';
import browserHistory from '../../history';

class Inbox extends React.Component {
  renderIconForNotification = (n) => {
    const { type } = n;
    switch (type) {
      default: {
        return <UserAvatar user={n.user} showName={false} />;
      }
    }
  };

  onNotificationClick = (n) => {
    const { doMarkNotificationAsRead } = this.props;
    const { type, user = {}, id } = n;
    switch (type) {
      default: {
        doMarkNotificationAsRead(n);
        return browserHistory.push({
          pathname: `/clients/${user.user_id}`,
          state: { from: 'Notifications' }
        });
      }
    }
  };

  render() {
    const { team, user = {}, doMarkNotificationAsRead, doRemoveNotification } = this.props;
    const { notifications = [] } = user;

    return (
      <ContentWrapper>
        <Row>
          <Col xl={8}>
            <PageTitle title="Notifications" />
            <div className="main-content-container">
              {notifications === undefined || !notifications.length ? (
                <div>You have no new notifications</div>
              ) : (
                <Card className="card-default card-container card-large notifications-container">
                  <CardBody>
                    {notifications.map((n, idx) => (
                      <div
                        className={`d-flex justify-content-between align-items-center notification-container ${
                          idx === notifications.length - 1 ? 'border-bottom-0' : ''
                        }`}
                        key={n.id}
                        onClick={(e) => this.onNotificationClick(n)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="mr-3">{this.renderIconForNotification(n)}</div>
                          <div>
                            {n.message}
                            <div className="caption">{moment(n.created_at).fromNow()}</div>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center"
                          onClick={(e) => e.preventDefault()}
                        >
                          <DropdownSubMenu
                            buttons={[
                              {
                                icon: 'fa-lg mr-2 fas fa-check',
                                label: 'Mark as Read',
                                onClick: () => doMarkNotificationAsRead(n),
                              },
                              {
                                icon: 'fa-lg mr-2 far fa-trash-alt',
                                label: 'Remove this Notification',
                                onClick: () => doRemoveNotification(n),
                              },
                            ]}
                            title={<em className="fas fa-ellipsis-h" />}
                            persist={false}
                            showCarat={false}
                          />
                          <div className={`${n.read ? 'read' : 'unread'} ml-3`} />
                        </div>
                      </div>
                    ))}
                  </CardBody>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Inbox.propTypes = {
  team: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  doMarkNotificationAsRead: PropTypes.func,
  doRemoveNotification: PropTypes.func,
};
const mapStateToProps = (state) => ({
  team: state.team,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doMarkNotificationAsRead: (n) => dispatch(markNotificationAsRead(n.id)),
  doRemoveNotification: (n) => dispatch(removeNotification(n.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
