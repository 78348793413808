import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import Loading from '../../components/Common/Loading';

class LoadingScreen extends Component {
  render() {
    return (
      <ContentWrapper>
        <div className="loading-screen d-flex align-items-center justify-content-center">
          <img src="/img/ms-spinner-blue.png" alt="Loading..." className="ms-logo" />
          <div className="ms-line-scale">
            <div />
            <div />
            <div />
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default LoadingScreen;
