export const helperCaptions = {
  POWER_CLIENTS:
    'Power clients are clients who are hitting their green thumbs at least 2 days/week.',
  INACTIVE_CLIENTS:
    'Inactive clients are clients who have not logged into the app in at least 3 weeks.',
  MAKE_ADMIN_USER: 'TODO: Add popover copy here',
  GREEN_THUMBS_LEADERBOARD:
    'These clients are crushing it! We average the number of times they hit all green thumbs each week.',
  DASHBOARD_LEADERBOARD: '',
  ALL_TIME_GREEN_THUMBS:
    'This is the total number of times your clients have hit all three green thumbs.',
  TIME_SAVED_IN_NUTRITION_COACHING:
    '<b>Time saved in nutrition coaching</b> is calculated based on your client list and the average time spent per client for traditional nutrition coaching.',
  ADMIN_USERS: 'Admins can add clients and view and edit client profiles.',
  CUSTOM_MACROS:
    "When you save custom macros, your client's macro targets will be updated in their app. These custom macros will override their Macrostax macro plan. Clients cannot currently customize their own macros.",
  SEND_INVITATIONS:
    'An email invitation is required for your clients to be eligible to sign up for the Macrostax app on your Team. You can either send invites now by checking this box or manage client invites later via the Manage Invites tab.',
  JOIN_DATE:
    'The date the Team owner signed up for the free trial. For reactivating Teams this date only reflects the current active subscription.',
  CLIENTS_INVITED: 'The number of total clients who have received an invitation to join the Team.',
  CLIENTS_ACTIVE:
    'The number of total clients who have successfully signed up for the V2 app. Clients may be in "active," "onboarding," or "pre-active" status.',
  CLIENTS_ADDED:
    'The total number of all clients added to the Team, including clients who have been deactivated.',
  MAX_CLIENTS: "The number of active clients allowed in this Team's current plan tier.",
  HOW_MANY_CLIENTS:
    '<strong>Why do we ask this?</strong><br/><br/>This information helps us understand your business so we can tailor your Macrostax Team experience and provide the best customized support.',
  STATUS:
    '<ul class="no-bullets"><li><b>Active:</b> This Team has an active paid subscription.</li><li><b>Canceled:</b> This Team has a history of a previous free trial or paid subscription that is no longer active.</li><li><b>Past due:</b> This Team has an unpaid invoice and is within the retry period.</li><li><b>Pending cancelation:</b> This Team has initiated a cancelation of either a free trial or paid subscription but has not yet reached their billing end date.</li><li><b>Trialing:</b> This Team is within the free trial period and has not yet paid for a subscription</li></ul>',
  POINT_OF_CONTACT:
    'By making an Admin a Point of Contact, you are enabling an Admin to receive additional important email communication',
};
