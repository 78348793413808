import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CreditCardFormPartial from '../../forms/partials/CreditCardFormPartial';

class CreditCardFormWrapper extends Component {
  render() {
    const {
      toggle,
      showSubmissionError,
      showCancel,
      buttonText,
      buttonColClass,
      onSubmit,
    } = this.props;
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CreditCardFormPartial
            onCancel={toggle}
            stripe={stripe}
            elements={elements}
            showSubmissionError={showSubmissionError}
            showCancel={showCancel}
            buttonText={buttonText}
            buttonColClass={buttonColClass}
            onSubmit={onSubmit}
            initialValues={{}}
          />
        )}
      </ElementsConsumer>
    );
  }
}

CreditCardFormWrapper.propTypes = {
  toggle: PropTypes.func,
  showSubmissionError: PropTypes.func,
  showCancel: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonColClass: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default CreditCardFormWrapper;
