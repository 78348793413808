import ENV_VARS from '../utils/envVars';

export const CHAT_SETTINGS_SUCCESS = 'CHAT_SETTINGS_SUCCESS';
export const CHAT_SETTINGS_FAILURE = 'CHAT_SETTINGS_FAILURE';
export const EDIT_CHAT_SETTINGS_SUCCESS = 'EDIT_CHAT_SETTINGS_SUCCESS';
export const EDIT_CHAT_SETTINGS_FAILURE = 'EDIT_CHAT_SETTINGS_FAILURE';

export function fetchChatSettings() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/chat-settings',
    method: 'GET',
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: CHAT_SETTINGS_SUCCESS,
    failureAction: CHAT_SETTINGS_FAILURE,
  };
}

export function editChatSettings(chatCoaches) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/chat-settings',
    method: 'PATCH',
    data: {
      chat_coaches: chatCoaches,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: EDIT_CHAT_SETTINGS_SUCCESS,
    failureAction: EDIT_CHAT_SETTINGS_FAILURE,
  };
}
