import React from 'react';
import { Row, Col } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import AdminsForm from '../../forms/AdminsForm';
import CardIcon from '../../components/Cards/CardIcon';
import InviteClientModal from '../../modals/InviteClientModal';
import browserHistory from '../../history';
import { submissionError } from '../../actions/formActions';
import { getAdmins } from '../../actions/teamActions';

class Admins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteClientModalOpen: false,
    };
  }

  componentDidMount() {
    const { doGetAdmins, team } = this.props;
    doGetAdmins(team.team_id);
  }

  toggleInviteClientModal = () => {
    const { inviteClientModalOpen } = this.state;
    this.setState({
      inviteClientModalOpen: !inviteClientModalOpen,
    });
  };

  goToUserDetail = (user, team_id) => {
    browserHistory.push({
      pathname: `/clients/${user.id}?admin=true`,
      state: { from: 'Admins' }
    });
  };

  render() {
    const { inviteClientModalOpen } = this.state;
    const { team, admins, showSubmissionError, currentUser } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Admins" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row className="mt-2">
            <Col xs={12}>
              <div className="d-flex justify-space-between">
                <div>
                  What's an admin?
                  <CardIcon helpText="ADMIN_USERS" />
                </div>
                <div>
                  <button className="btn-text" onClick={this.toggleInviteClientModal}>
                    <em className="fa-lg mr-2 far fa fa-user-plus" />
                    Add admins
                  </button>
                  <InviteClientModal
                    isOpen={inviteClientModalOpen}
                    toggle={this.toggleInviteClientModal}
                    type="admin"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              {/* <AdminTable users={admins} title="Admins" toolbar={false} /> */}
              {admins ? (
                <AdminsForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={() => {}}
                  users={admins}
                  initialValues={{}}
                  team={team}
                  currentUser={currentUser}
                  onRowClick={(rowData) => this.goToUserDetail(rowData, team.team_id)}
                />
              ) : (
                <Loading />
              )}
            </Col>
          </Row>
        </div>
      </ContentWrapper>
    );
  }
}

Admins.propTypes = {
  showSubmissionError: PropTypes.func,
  team: PropTypes.instanceOf(Object),
  doGetAdmins: PropTypes.func,
  currentUser: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  team: state.team,
  admins: state.team.admins,
  currentUser: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doGetAdmins: (team_id) => dispatch(getAdmins(team_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
