import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import CancelPlanForm from '../forms/CancelPlanForm';
import { submissionError } from '../actions/formActions';
import { cancelPlan } from '../actions/teamActions';

class CancelPlanModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      step: 1,
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  updateStep = (step) => {
    this.setState({
      step,
    });
  };

  resetState = () => {
    const { initialState } = this.state;
    this.setState({
      ...initialState,
      initialState,
    });
  };

  clickIntercom = () => {
    const { toggle } = this.props;
    const int = document.getElementById('Intercom');
    int.click();
    toggle();
  };

  render() {
    const { isOpen, toggle, doCancelPlan, team, showSubmissionError } = this.props;
    const { team_id, membership } = team;
    const { step } = this.state;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/cancelPlan', title: 'Cancel Plan' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} onClosed={this.resetState} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle
            title="Cancel Plan"
            showTeam={false}
            icon="fa-1x ms-icon- icon-Browser_-Internet_-Web_-Network_-Close-1"
          />
        </ModalHeader>
        <ModalBody>
          {step === 1 && (
            <>
              <Row>
                <Col xs={12}>
                  <p>
                    Are you sure you want to cancel your Macrostax Team subscription? At the end of
                    your current billing period you and your clients will lose access to your data
                    and will no longer be able to log into the Macrostax app.
                  </p>
                  <p>
                    Weren’t able to get started? Reach out to our support team through{' '}
                    <span className="span-link" onClick={this.clickIntercom}>
                    {/* <span className="span-link" id="Intercom" > */}
                      StaxChat
                    </span>{' '}
                    and we’ll walk you through it!
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12}>
                  <div className="d-flex justify-content-end">
                    <Button
                      color="secondary"
                      size="lg"
                      onClick={toggle}
                      className="mr-3 fixed-width-md"
                    >
                      Never Mind
                    </Button>
                    <Button
                      color="primary"
                      size="lg"
                      onClick={() => {
                        doCancelPlan(team_id, membership.id);
                        toggle();
                        // this.updateStep(2);
                      }}
                      className="fixed-width-md"
                    >
                      Cancel Plan
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {step === 2 && (
            <>
              <Row className="animated">
                <Col xs={12} className="label-left-container">
                  <CancelPlanForm
                    onSubmit={(values) => {
                      doCancelPlan(values);
                      this.updateStep(3);
                    }}
                    showSubmissionError={showSubmissionError}
                    onCancel={toggle}
                  />
                </Col>
              </Row>
            </>
          )}
          {step === 3 && (
            <Row>
              <Col xs={12}>
                <div className="callout-container d-flex flex-row justify-content-center align-items-center">
                  Your plan has been canceled. We are sorry to see you go. Your subscription will
                  remain active until{' '}
                  {moment(team.subscription.current_period_end).format('M/D/YYYY')}, after which
                  time your clients will lose access to the Macrostax app.
                </div>
                <div className="mt-5 text-center">
                  <Button color="primary" size="lg" onClick={toggle} className="fixed-width-lg">
                    Done
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

CancelPlanModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  doCancelPlan: PropTypes.func,
  team: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doCancelPlan: (team_id, membership_id) => dispatch(cancelPlan(team_id, membership_id)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelPlanModal);
