import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import UserInfoFormPartial from './partials/UserInfoFormPartial';
import ChangePasswordModal from '../modals/ChangePasswordModal';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.firstname = 'First name is required';
  }
  if (!values.last_name) {
    errors.lastname = 'Last name is required';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  return errors;
};

class AccountSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordModalOpen: false,
    };
  }

  toggleChangePasswordModal = () => {
    const { changePasswordModalOpen } = this.state;
    this.setState({
      changePasswordModalOpen: !changePasswordModalOpen,
    });
  };

  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      onCancel,
      user,
      showPhotoUpload,
    } = this.props;
    const { changePasswordModalOpen } = this.state;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <UserInfoFormPartial showPhone showPhotoUpload={showPhotoUpload} user={user} />
                  <div className="stacked centered form-group">
                    <label>Password</label>
                    <br />
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleChangePasswordModal();
                      }}
                    >
                      Change password...
                    </Button>
                    <ChangePasswordModal
                      isOpen={changePasswordModalOpen}
                      user={user}
                      toggle={this.toggleChangePasswordModal}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <Button color="primary" size="lg" type="submit" className="fixed-width-md">
                    Save
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

AccountSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  onCancel: PropTypes.func,
  user: PropTypes.instanceOf(Object),
};

export default AccountSettingsForm;
