import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import moment from 'moment-timezone';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Input } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import Loading from '../components/Common/Loading';
import ButtonFilters from '../components/Common/ButtonFilters';
import { changePlan } from '../actions/teamActions';
import { formatCentsAsCurrency } from '../utils/numberUtils';

const tz = moment.tz.guess();

class ChangePlanModal extends Component {
  constructor(props) {
    super(props);
    const {
      team: {
        membership: { product_id },
      },
      membershipOptions,
    } = this.props;
    let activeMembership;
    let activeButton = 'month';
    if (membershipOptions && membershipOptions.length) {
      activeMembership = membershipOptions.find((m) => m.id === product_id);
      if (activeMembership) {
        activeButton = activeMembership.interval;
      }
    }
    const initialState = {
      activeButton,
      product_id,
      submitted: false,
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  changePlan = (product_id) => {
    const { doChangePlan, team, showSubmissionError, toggle } = this.props;
    const { membership, team_id } = team;
    if (product_id === membership.product_id) {
      showSubmissionError([
        'You selected the same plan you are already on. Please select a different plan.',
      ]);
    } else {
      doChangePlan(team_id, membership.id, product_id);
      this.setState({
        // submitted: true,
        product_id,
      });
      toggle();
    }
  };

  resetState = () => {
    // const { initialState } = this.state;
    // this.setState({
    //   ...initialState,
    //   initialState,
    // });
  };

  render() {
    const { isOpen, toggle, team } = this.props;
    let { membershipOptions } = this.props;
    const { membership } = team;
    const { activeButton, product_id, submitted } = this.state;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/changePlan', title: 'Change Plan' });
    }
    const buttons = [
      {
        title: 'Monthly',
        id: 'month',
      },
      {
        title: 'Yearly (save 10%)',
        id: 'year',
      },
    ];
    let activeSubs;
    let currentSub;
    if (membershipOptions && membershipOptions.length) {
      activeSubs = membershipOptions.filter((s) => s.interval === activeButton);
      currentSub = activeSubs.find((s) => s.id === product_id);
      if (membership.billing_frequency === 'year') {
        membershipOptions = membershipOptions.filter(m => m.interval === 'year');
      }
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium" onClosed={this.resetState}>
        <ModalHeader toggle={toggle}>
          <PageTitle
            title={membership.status === 'pending-cancellation' ? 'Reactivate Plan' : 'Change Plan'}
            showTeam={false}
            icon="fa-1x far ms-icon- icon-settings-switches-square4 "
          />
        </ModalHeader>
        <ModalBody>
          {membershipOptions ? (
            !submitted ? (
              <>
                {membership.billing_frequency === 'month' && (
                  <Row>
                    <Col xs={12}>
                      <div className="d-flex align-items-center mr-1-between">
                        <ButtonFilters
                          buttons={buttons}
                          onClick={(btn) => this.setState({ activeButton: btn.id })}
                          activeButton={activeButton}
                          buttonClassName="fixed-width-xmd"
                          size="lg"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    {activeSubs.map((s, idx) => (
                      <div
                        key={s.id}
                        onClick={() => this.setState({ product_id: s.id })}
                        className="subscription-container d-flex justify-space-between align-items-center"
                      >
                        <div className="d-flex">
                          <label className="checkbox c-checkbox c-checkbox-rounded mb-0">
                            <Input
                              id="roundedcheckbox20"
                              type="checkbox"
                              checked={product_id === s.id}
                              onChange={() => this.setState({ product_id: s.id })}
                            />
                            <span className="fa fa-check" />
                          </label>
                          <div>{`${s.name}, ${s.subtitle}`}</div>
                        </div>
                        <div className="text-right">
                          ${s.displayPrice}/{s.displayInterval}
                          {s.interval !== s.displayInterval && s.interval !== 'month' && (
                            <div className="card-caption">
                              Billed {formatCentsAsCurrency(s.price)}{' '}
                              {s.interval.replace('year', 'annual')}ly
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col className="caption">
                    *Active clients are clients that sign up for the Macrostax app on your Team.
                    Invited clients do not count toward your active user count.
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        color="secondary"
                        size="lg"
                        onClick={toggle}
                        className="mr-3 fixed-width-md"
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => this.changePlan(product_id)}
                        className="fixed-width-md"
                      >
                        Confirm
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col xs={12}>
                  You successfully changed your plan to{' '}
                  <strong>{`${currentSub.name}, billed ${currentSub.interval.replace(
                    'year',
                    'annual'
                  )}ly`}</strong>
                  . Your new plan starts on{' '}
                  {moment(subscription.current_period_end).tz(tz).format('M/D/YYYY')} and you will
                  be billed {formatCentsAsCurrency(currentSub.price)} on that day. (TODO: this
                  message may change based on return from Stripe).
                  <div className="mt-5 text-center">
                    <Button color="primary" size="lg" onClick={toggle} className="fixed-width-lg">
                      Done
                    </Button>
                  </div>
                </Col>
              </Row>
            )
          ) : (
            <Loading />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

ChangePlanModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  team: PropTypes.instanceOf(Object),
  doChangePlan: PropTypes.func,
  membershipOptions: PropTypes.instanceOf(Array),
  showSubmissionError: PropTypes.func,
};

const mapStateToProps = (state) => ({
  membershipOptions: state.billing.membershipOptions,
});
const mapDispatchToProps = (dispatch) => ({
  doChangePlan: (team_id, membership_id, product_id) => {
    dispatch(changePlan(team_id, membership_id, product_id));
  },
  doMembershipOptions: () => dispatch(membershipOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlanModal);
