import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

class RecipeIngredients extends Component {
  render() {
    const { component: { ingredients} } = this.props;
    const ing = ingredients.split('\n');
    return (
      <Row>
        <Col>
          <ul className="ingredients-list">
            {ing.map((i) => (
              i.trim().length > 0 && (
                <li key={i}>{i.substring(2, i.length)}</li>
              )
            ))}
          </ul>
        </Col>
      </Row>
    );
  }
}

RecipeIngredients.propTypes = {
  component: PropTypes.instanceOf(Object),
};

export default RecipeIngredients;
