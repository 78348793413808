import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, FormGroup } from 'reactstrap';
import Slider from 'rc-slider';
import Select from 'react-select';
import numeral from 'numeral';
import { customSelectStyles } from '../../constants';

import 'rc-slider/assets/index.css';

import alcohol from '../../data/alcohol.json';

class AlcoholCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calcType: 'calories',
      calories: 100,
      ounces: 12,
      abv: 5,
      carbPct: 0.5,
      carbs: 0,
      fats: 0,
      selectedDefault: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.calculateMacros();
  }

  handleInputChange(event) {
    const { target } = event;
    const { type, checked, name, value } = target;
    const val = type === 'checkbox' ? checked : value;
    const { selectedDefault } = this.state;

    this.setState(
      {
        [name]: val,
        selectedDefault: name === 'calories' ? null : selectedDefault,
      },
      () => this.calculateMacros()
    );
  }

  changeCalcType = (c) => {
    this.setState(
      {
        calcType: c,
      },
      () => this.calculateMacros()
    );
  };

  updateDefault = (opt) => {
    const { calories } = this.state;
    this.setState(
      {
        selectedDefault: opt,
        calories: opt && opt.value ? opt.value : calories,
      },
      () => this.calculateMacros()
    );
  };

  changeSlider = (v) => {
    this.setState(
      {
        carbPct: 1 - v / 100,
      },
      () => this.calculateMacros()
    );
  };

  calculateMacros = () => {
    const { calcType, ounces, abv, carbPct } = this.state;
    const fatPct = 1 - carbPct;
    let { calories } = this.state;
    if (calcType === 'abv') {
      calories = ounces * abv * 2.5;
    }
    const carbCals = calories * carbPct;
    const fatCals = calories * fatPct;
    this.setState({
      carbs: Math.round((carbCals / 4) * 2) / 2,
      fats: Math.round((fatCals / 9) * 2) / 2,
    });
  };

  render() {
    const { calcType, calories, ounces, abv, carbs, fats, carbPct, selectedDefault } = this.state;
    return (
      <form className="inline-form">
        <Row className="mt-2 alcohol-calculator">
          <Col xs={12}>
            <div className="mb-4">How would you like to calculate alcohol macros?</div>
            <div className="mb-4">
              <label className="checkbox c-checkbox c-checkbox-rounded">
                <Input
                  id="roundedcheckbox20"
                  type="checkbox"
                  checked={calcType === 'calories'}
                  onChange={() => this.changeCalcType('calories')}
                />
                <span className="fa fa-check" />
                By Calories
              </label>
              <label className="checkbox c-checkbox c-checkbox-rounded ml-5">
                <Input
                  id="roundedcheckbox10"
                  type="checkbox"
                  checked={calcType === 'abv'}
                  onChange={() => this.changeCalcType('abv')}
                />
                <span className="fa fa-check" />
                By ABV &amp; Size
              </label>
            </div>
            <div className="mb-4">
              {calcType === 'calories' ? (
                <div>
                  <div className="default-container">
                    <FormGroup>
                      <label>Choose a Default</label>
                      <Select
                        value={selectedDefault}
                        styles={customSelectStyles}
                        isClearable
                        options={alcohol}
                        onChange={(opt) => this.updateDefault(opt)}
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex">
                    <FormGroup>
                      <label>Calories</label>
                      <Input
                        type="number"
                        name="calories"
                        value={calories}
                        onChange={this.handleInputChange}
                      />
                    </FormGroup>
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <FormGroup>
                    <label>ABV %</label>
                    <Input type="number" name="abv" value={abv} onChange={this.handleInputChange} />
                  </FormGroup>
                  <FormGroup className="ml-3">
                    <label>Serving Size (oz)</label>
                    <Input
                      type="number"
                      name="ounces"
                      value={ounces}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </div>
              )}
            </div>
            <div>
              <div className="slider-container d-flex justify-space-between">
                <div>Carbs</div>
                <div>Fats</div>
              </div>
              <Slider onChange={this.changeSlider} defaultValue={carbPct * 100} />
              <div className="slider-container d-flex justify-space-between">
                <div>{numeral(carbPct).format('0%')}</div>
                <div>{numeral(1 - carbPct).format('0%')}</div>
              </div>
              <div className="mt-4 slider-container d-flex justify-space-between">
                <h3>{numeral(carbs).format('0.[00]')}g</h3>
                <h3>{numeral(fats).format('0.[00]')}g</h3>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

AlcoholCalculator.propTypes = {};

export default AlcoholCalculator;
