import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormGroup, Input, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import useAddAndRemoveEvent from '../../hooks/useAddAndRemoveEvent';

export const ALL_VALUE = 'ALL_VALUE';
const SELECT_ALL_OPTION = { value: ALL_VALUE, label: 'Select All', isActive: true };

const includesSearchQuery = (option, query) => {
  return option.toLowerCase().includes(query.toLowerCase());
};

const DropdownItem = ({ item, selectedItems, handleSelect, conditional, extraLabel }) => (
  <li className="dropdown-list-item">
    <label className="item-text">
      <input
        type="checkbox"
        checked={selectedItems.includes(item)}
        onChange={() => handleSelect(item)}
      />
      {item.label}
    </label>
    {conditional && extraLabel && conditional(item) ? <span className="extra-item-label"> {extraLabel}  </span> : <></>}
  </li>
);

const RenderDropdownWithSearchFieldComponent = ({ input, inputProps, styleProps }) => {
  const { options, extraLabel, conditional, placeholder } = inputProps;
  const [searchQuery, setSearchQuery] = useState('');
  const selectedItems = input.value || [];
  const isAllSelected = selectedItems.length === options.length;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const onInputChange = (value) => input.onChange(value);

  const handleSelectAll = () => {
    const newSelection = selectedItems.length === options.length ? [] : options;
    onInputChange(newSelection);
  };

  const handleSelect = (item) => {
    const prevSelectedItems = input.value || [];
    const newSelection = prevSelectedItems.includes(item)
      ? prevSelectedItems.filter((i) => i.value !== item.value)
      : [...prevSelectedItems, item];

    onInputChange(newSelection);
  };

  const filteredOptions = options
    .filter((option) => {
      return includesSearchQuery(option.label, searchQuery) && !selectedItems.find((i) => i.value === option.value);
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedFilteredOptions = selectedItems
    .filter((option) => includesSearchQuery(option.label, searchQuery) && selectedItems.find((i) => i.value === option.value))
    .sort((a, b) => a.label.localeCompare(b.label));

  const renderOptions = (options) =>
    options.map((option, index) => (
      <DropdownItem
        key={`${option.value}-key`}
        item={option}
        selectedItems={selectedItems}
        handleSelect={handleSelect}
        conditional={conditional}
        extraLabel={extraLabel}
      />
    )
  );

  const renderSelectedValues = () => (
    selectedItems.map((item) => (item.label)).sort((a, b) => a.localeCompare(b)).join(', ')
  );

  const renderPlaceholder = () => (
    <span className={`dropdown-placeholder ${selectedItems.length ? 'selected' : ''}`}>
      {selectedItems.length ? renderSelectedValues() : placeholder}
    </span>
  );

  const renderSeparator = () => (
    selectedFilteredOptions.length && filteredOptions.length ? <div className="dropdown-divider" /> : <></>
  );

  const renderSelectAllOption = () => options.length > 0 && (
    <li key="All-key" className="dropdown-list-item">
      <label className="item-text">
        <input
          type="checkbox"
          checked={isAllSelected}
          onChange={handleSelectAll}
        />
        {SELECT_ALL_OPTION.label}
      </label>
    </li>
  );

  useAddAndRemoveEvent(isDropdownOpen, 'mousedown', handleClickOutside);

  return (
    <FormGroup>
      <Input type="hidden" {...input} />
      <div className="dropdown-with-search" ref={dropdownRef}>
        <div className="dropdown-header" onClick={toggleDropdown}>
          {renderPlaceholder()}
          <em className="fa fa-angle-down mr-2 ml-2 font-size-one-point-two-five" />
        </div>

        {isDropdownOpen && (
          <div className="dropdown-body">
            <div className="search-input-container">
              <span className="search-icon">
                <em className="fa fa-search" />
              </span>
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearch}
                className="search-input"
              />
            </div>
            {options.length === 0 ? (
              <Row className="justify-content-center">
                <span> There are no elements </span>
              </Row>
              ) : <></>}
            <ul className="dropdown-list" style={{ ...styleProps }}>
              {renderSelectAllOption()}

              {renderOptions(selectedFilteredOptions)}

              {renderSeparator()}

              {renderOptions(filteredOptions) }
            </ul>
          </div>
        )}
      </div>
    </FormGroup>
  );
};

RenderDropdownWithSearchFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  styleProps: PropTypes.shape({
    maxHeigh: PropTypes.string,
  }),
};

export default forwardRef((props, ref) => {
  return (
    <RenderDropdownWithSearchFieldComponent innerRef={ref} {...props} />
  );
});
