import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderUserBlock from '../Common/HeaderUserBlock';
import AlertBar from '../Common/AlertBar';
import { toggleSetting } from '../../actions/layoutActions';
import { isTeamUser } from '../../utils/authUtils';
import browserHistory from '../../history';

class Header extends Component {
  toggleCollapsed = (e) => {
    const { doToggleSetting } = this.props;
    e.preventDefault();
    doToggleSetting('isCollapsed');
  };

  toggleAside = (e) => {
    const { doToggleSetting } = this.props;
    e.preventDefault();
    doToggleSetting('asideToggled');
  };

  goBack = () => {
    browserHistory.goBack();
  };

  render() {
    const { type = 'auth', user, team, step } = this.props;
    const showProgress = step && step > 0;
    let roles;
    let settings;
    let name;
    let primary_logo_path;
    let subscription;
    let status;

    if (team) {
      ({ settings, subscription } = team);
      if (settings) {
        ({ name, primary_logo_path } = settings);
      }
      if (subscription) {
        ({ status } = subscription);
      }
    }
    if (user) {
      ({ roles } = user);
    }

    const signupSteps = [
      'Create an account',
      'Tell us about your business',
      'Choose a plan',
      'Billing information',
    ];

    return (
      <header className="topnavbar-wrapper">
        <AlertBar team={team} user={user} />
        {type !== 'pre' ? (
          <nav className="navbar topnavbar">
            {type !== 'noNav' && (
              <>
                <div className="navbar-header">
                  <a className="navbar-brand" href="/">
                    {isTeamUser(roles) ? (
                      <>
                        {primary_logo_path ? (
                          <div className="brand-logo">
                            <img
                              className="img-fluid"
                              src={primary_logo_path}
                              title={`${name}, powered by Macrostax`}
                            />
                            <div className="caption2 mt-2">Powered by Macrostax</div>
                          </div>
                        ) : (
                          <>
                            <div className="team-name">{name}</div>
                            <div className="caption2">Powered by Macrostax</div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="brand-logo">
                        <img className="img-fluid" src="/img/logo.png" alt="Macrostax" />
                      </div>
                    )}
                  </a>
                </div>

                <ul className="navbar-nav mr-auto flex-row">
                  <li className="nav-item">
                    <a
                      href=""
                      className="nav-link sidebar-toggle d-md-none"
                      onClick={this.toggleAside}
                    >
                      <em className="fas fa-bars" />
                    </a>
                  </li>
                </ul>
              </>
            )}

            {type === 'auth' && user && user.first_name && (
              <ul className="navbar-nav flex-row">
                <HeaderUserBlock />
              </ul>
            )}
          </nav>
        ) : (
          <nav className="navbar topnavbar">
            <a className="navbar-brand" href="/">
              <div className="brand-logo">
                <img className="img-fluid" src="/img/team-logo-2.png" alt="Macrostax Team" />
                {/* <img className="img-fluid" src="/img/macrostax-logo.png" alt="Macrostax" /> */}
              </div>
            </a>
            {showProgress && (
              <div className="signup-progress-bar-container">
                {step !== 1 && step !== 5 && (
                  <div className="arrow-back" onClick={this.goBack}>
                    <em className="mr-2 fas fa-arrow-left" />
                  </div>
                )}
                <div className="step-number">
                  {step < 5 ? (
                    <>
                      Step {step}/4: {signupSteps[step - 1]}
                    </>
                  ) : (
                    <>Order Complete</>
                  )}
                </div>
                <div className="progress-bar">
                  <div className={`progress step-${step}`}></div>
                </div>
              </div>
            )}
          </nav>
        )}
      </header>
    );
  }
}

Header.propTypes = {
  doToggleSetting: PropTypes.func,
  type: PropTypes.string,
  step: PropTypes.number,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  team: state.team,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({
  doToggleSetting: (setting) => dispatch(toggleSetting(setting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
