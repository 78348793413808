import moment from 'moment-timezone';
import { validateEmail } from './stringUtils';

export const makeSimpleValue = (value) => {
  if (value !== undefined && value !== null && value.value !== undefined && value.value !== null) {
    return value.value;
  }
  return value;
};

export const validateAddClientForm = (values, rows) => {
  const errors = {};
  let validUsers = 0;
  for (let i = 0; i < rows; i += 1) {
    if (values[`first_name_${i}`] || values[`last_name_${i}`] || values[`email_${i}`]) {
      const email = values[`email_${i}`];
      // validate email because this could be posted from the CSV upload, which doesn't have
      // email type inputs for native browser-based email validation
      if (!validateEmail(email)) {
        errors.email = `${email} is not a valid email address.`;
      } else if (!values[`first_name_${i}`] || !values[`last_name_${i}`] || !email) {
        errors.firstName =
          'When adding a client, their first name, last name, and email are all required.';
      } else {
        validUsers += 1;
      }
    }
  }
  if (validUsers === 0) {
    errors.firstName = 'Please enter at least one client to add.';
  }
  return errors;
};

export const buildChallengePayload = (values) => {
  // {
  //   "name": "testing",
  //   "description": "description",
  //   "start_on_date": "2023-01-20",
  //   "through_date": "2023-02-20",
  //   "is_visible": false,
  //   "actions": [
  //       {
  //           "id": "c46f8d7c-767f-4a36-a553-eb4b135d4913",
  //           "points": "5",
  //           "max_awards": "5"
  //       },
  //       {
  //           "id": "25ec1285-4b16-42af-97c4-e60a530e77a4",
  //           "points": "5"
  //       }
  //   ]
  // }
  const { name, description, start_on_date, through_date, is_visible, actionIds, id } = values;
  const payload = {
    name,
    description,
    start_on_date: moment(start_on_date).format('YYYY-MM-DD'),
    through_date: moment(through_date).format('YYYY-MM-DD'),
    is_visible: is_visible === 'true' || is_visible === true,
    actions: [],
  };

  if (id !== undefined && id.length) {
    payload.id = id;
    payload.challenge_id = id;
  }
  
  for (let a = 0; a < actionIds.length; a += 1) {
    const id = actionIds[a];
    if (values[`action_${id}`] !== undefined && values[`action_${id}`] === true) {
      payload.actions.push({
        id,
        points: values[`points_${id}`],
        max_awards: values[`max_awards_${id}`],
      });
    }
  }
  return payload;
};

const positionCursorAtEnd = (input) => {
  const len = input.value.length;

  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(len, len);
  } else if (input.createTextRange) {
    const t = input.createTextRange();
    t.collapse(true);
    t.moveEnd('character', len);
    t.moveStart('character', len);
    t.select();
  }
};

export const enforceInteger = (evt) => {
  const parsed = parseInt(evt.target.value);
  if (isNaN(parsed)) {
    evt.target.value = 0;
  } else {
    evt.target.value = parsed;
    positionCursorAtEnd(evt.target);
  }
};
