import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import GoalForm from '../forms/GoalForm';
import { submissionError } from '../actions/formActions';
import { updateUser } from '../actions/userActions';

class GoalFormModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      showSubmissionError,
      user,
      teamName,
      teamProfile,
      goal_id,
      phase_id,
      activity_level_id,
      team_id,
      doUpdateUser,
    } = this.props;
    const { user_id, first_name, last_name } = user;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/goalForm', title: 'Update User Settings' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} onClosed={this.resetState} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <div className="d-flex flex-row justify-content-center">
            {/* Note: too lazy to re-create icomoon font with this icon after losing it in a computer swap */}
            <h1>
              <img src="/img/bullseye-arrow.svg" width="32" height="32" className="mt-1 mr-3" />
            </h1>
            <PageTitle title="Update User Settings" showTeam={false} />
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                Update settings for {first_name} {last_name} on the {teamName} Team:
              </div>
              <GoalForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => {
                  doUpdateUser(user_id, values);
                  toggle();
                }}
                onCancel={toggle}
                teamProfile={teamProfile}
                initialValues={{
                  goal_id,
                  phase_id,
                  activity_level_id,
                  team_id,
                  user_id,
                  first_name,
                  last_name,
                }}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

GoalFormModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  doSetPasswordForUser: PropTypes.func,
  showSubmissionError: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  teamName: PropTypes.string,
  teamProfile: PropTypes.instanceOf(Object),
  goal_id: PropTypes.string,
  phase_id: PropTypes.string,
  activity_level_id: PropTypes.string,
  team_id: PropTypes.string,
  doUpdateUser: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doUpdateUser: (user_id, values) => dispatch(updateUser(user_id, values)),
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalFormModal);
