import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import { deleteMeasurements } from '../actions/userActions';

class DeleteMeasurementModal extends Component {
  render() {
    const { isOpen, toggle, doDeleteMeasurement, userId, dateToDelete } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/deleteMeasurement', title: 'Delete Measurements' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} onClosed={this.resetState} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle
            title="Delete Measurements"
            showTeam={false}
            icon="fa-1x ms-icon- icon-Browser_-Internet_-Web_-Network_-Close-1"
          />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>Are you sure you want to delete these measurements?</Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <div className="d-flex justify-content-end">
                <Button
                  color="secondary"
                  size="lg"
                  onClick={toggle}
                  className="mr-3 fixed-width-md"
                >
                  Never Mind
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  onClick={() => {
                    doDeleteMeasurement(userId, dateToDelete);
                    toggle();
                  }}
                  className="fixed-width-md"
                >
                  Confirm
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteMeasurementModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  userId: PropTypes.string,
  dateToDelete: PropTypes.string,
  doDeleteMeasurement: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doDeleteMeasurement: (id, date) => dispatch(deleteMeasurements(id, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMeasurementModal);
