export const updateBodyClass = (stat, val) => {
  let className = '';
  switch (stat) {
    case 'isCollapsed':
      className = 'aside-collapsed';
      break;
    case 'asideToggled':
      className = 'aside-toggled';
      break;
    default:
      className = '';
      break;
  }
  if (className.length) {
    if (val === undefined) {
      document.body.classList.toggle(className);
    } else if (val) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }
};

// TODO: keeping these here as reference for how the theme was using this functionality
export const updateClasses = (state) => {
  updateBodyClass(state.settings.isFixed, 'layout-fixed');
  updateBodyClass(state.settings.isBoxed, 'layout-boxed');
  updateBodyClass(state.settings.isCollapsed, 'aside-collapsed');
  updateBodyClass(state.settings.isCollapsedText, 'aside-collapsed-text');
  updateBodyClass(state.settings.isFloat, 'aside-float');
  updateBodyClass(state.settings.asideHover, 'aside-hover');
  updateBodyClass(state.settings.offsidebarOpen, 'offsidebar-open');
  updateBodyClass(state.settings.asideToggled, 'aside-toggled');
};
