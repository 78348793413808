import { SPINNER_INCREMENT, SPINNER_DECREMENT } from '../actions/spinnerActions';

const initialSpinnerState = {
  spinnerCounter: 0,
  spinnerAnimate: false,
};

export default (state = initialSpinnerState, action) => {
  switch (action.type) {
    case SPINNER_INCREMENT:
      return {
        ...state,
        spinnerCounter: state.spinnerCounter + 1,
        spinnerAnimate: true,
      };
    case SPINNER_DECREMENT:
      return {
        ...state,
        spinnerCounter: state.spinnerCounter > 0 ? state.spinnerCounter - 1 : 0,
        spinnerAnimate: state.spinnerCounter > 1,
      };
    default:
      return state;
  }
};
