import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import TeamTable from '../../components/Tables/TeamTable';
import TeamSearchForm from '../../forms/TeamSearchForm';
import { submissionError } from '../../actions/formActions';
import { submitTeamSearchRequest, clearTeamSearchForm } from '../../actions/teamActions';
import { membershipOptions, businessTypes } from '../../apiActions/signupApiActions';
import browserHistory from '../../history';

class TeamSearch extends React.Component {
  componentDidMount() {
    const { initialValues } = this.props;
    this.props.search(initialValues);
    this.props.doMembershipOptions();
    this.props.doBusinessTypes();
  }

  goToTeamDetail = (team) => {
    browserHistory.push(`/teams/${team.team_id}`);
  };

  render() {
    const { showSubmissionError, initialValues, search, reset, teams, membershipOptions, businessTypes } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Team Search" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row>
            <Col xs={12}>
              {membershipOptions && membershipOptions.length && businessTypes && businessTypes.length ? (
                <TeamSearchForm
                  showSubmissionError={showSubmissionError}
                  onSubmit={search}
                  onReset={reset}
                  initialValues={initialValues}
                  membershipOptions={membershipOptions}
                  businessTypes={businessTypes}
                />
              ) : <Loading />}
              
            </Col>
          </Row>
          {teams && (
            <Row className="mt-5">
              <Col xs={12}>
                <TeamTable
                  teams={teams}
                  title="Search Results"
                  onRowClick={(e, rowData) => this.goToTeamDetail(rowData)}
                />
              </Col>
            </Row>
          )}
        </div>
      </ContentWrapper>
    );
  }
}

TeamSearch.propTypes = {
  showSubmissionError: PropTypes.func,
  initialValues: PropTypes.instanceOf(Object),
  search: PropTypes.func,
  reset: PropTypes.func,
  teams: PropTypes.instanceOf(Array),
  doMembershipOptions: PropTypes.func,
  doBusinessTypes: PropTypes.func,
  membershipOptions: PropTypes.instanceOf(Array),
  businessTypes: PropTypes.instanceOf(Array),
};
const mapStateToProps = (state) => ({
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    name: '',
    ...state.context.teamSearchForm,
  },
  teams: state.team.teamSearchResults,
  membershipOptions: state.signup.membershipOptions,
  businessTypes: state.signup.businessTypes,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  search: (values) => {
    dispatch(submitTeamSearchRequest(values));
  },
  reset: () => {
    dispatch(clearTeamSearchForm());
    dispatch(submitTeamSearchRequest());
  },
  doMembershipOptions: () => dispatch(membershipOptions()),
  doBusinessTypes: () => dispatch(businessTypes([true,false])),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamSearch);
