import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import SignUpForm from '../../forms/SignUpForm';
import { submissionError } from '../../actions/formActions';
import { signUp, setSignUpStep } from '../../actions/signupActions';
import { authLogout } from '../../actions/authActions';
import trackers from '../../trackers';
import ENV_VARS from '../../utils/envVars';

class SignUp extends React.Component {
  componentDidMount() {
    const { doLogout, doSetSignupStep, signup } = this.props;
    // doLogout();
    doSetSignupStep(1);
    trackers.updateTracking('account', signup);
  }

  render() {
    const { showSubmissionError, doSignUp, signup } = this.props;
    const steps = [
      { number: 1, label: 'Create a free account.' },
      { number: 2, label: 'Add your clients.' },
      { number: 3, label: 'Scale your business.' },
    ];
    return (
      <ContentWrapper>
        <Row>
          <Col
            md={12}
            className="d-flex flex-column justify-content-center align-items-center mb-5 text-center"
          >
            <h1>Grow your fitness business today.</h1>
            <h3>
              Sign up for your {ENV_VARS.TRIAL_DAYS}-day <span className="underlined">FREE</span> trial!
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="d-flex flex-column align-items-center mb-3">
            <div className="signup-form-wrapper">
              <SignUpForm
                showSubmissionError={showSubmissionError}
                onSubmit={(values) => doSignUp(values)}
                initialValues={{
                  first_name: signup.first_name,
                  last_name: signup.last_name,
                  email: signup.email,
                  password: signup.password,
                }}
              />
              <p className="mt-4 text-center caption">
                Already have an account? <Link to="/login">Log In</Link>
              </p>
            </div>
          </Col>
          <Col className="d-flex flex-column align-items-center">
            <div className="signup-callout-container">
              <div className="signup-callout-image">
                <img src="/img/signup/sign-up-step-1.webp" />
              </div>
              <div className="signup-steps">
                {steps.map((s) => (
                  <div className="signup-step d-flex" key={s.number}>
                    <div className="signup-step-number mr-3">{s.number}</div>
                    <div className="signup-step-label">{s.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

SignUp.propTypes = {
  showSubmissionError: PropTypes.func,
  doSignUp: PropTypes.func,
  doLogout: PropTypes.func,
  doSetSignupStep: PropTypes.func,
};
const mapStateToProps = (state) => ({
  signup: state.signup,
});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
  doSignUp: (values) => {
    // values.businessType = makeSimpleValue(values.businessType);
    dispatch(signUp(values));
  },
  doLogout: () => dispatch(authLogout(false)),
  doSetSignupStep: (step) => dispatch(setSignUpStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
