import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';

export class RenderCheckboxForFieldComponent extends Component {
  render() {
    const {
      input,
      label,
      htmlLabel,
      checkboxIcon = 'fa-check',
      checkboxIconUnchecked = 'fa-check',
      options,
      meta: { error },
      itemProps,
      inputProps,
    } = this.props;
    let { labelClassName = 'col-form-label' } = this.props;

    const { name, onChange, onBlur, onFocus, id } = input;

    // let labelClassName = 'col-form-label';
    let inputClassName = 'col-form-label';

    if (itemProps && itemProps.row) {
      labelClassName += ' col-xl-3 col-form-label';
      inputClassName += ' col-xl-9';
    }

    const showLabel = () => {
      if (label && label.length) {
        return <label className={labelClassName}>{label}</label>;
      }
      return null;
    };

    const handleChange = (event) => {
      const cb = event.target;
      const val = cb.checked;
      onBlur(val);
      return onChange(val);
    };

    return (
      <FormGroup {...itemProps}>
        {/* {showLabel()} */}
        <div className={inputClassName}>
          <div className="checkbox c-checkbox">
            <label className={labelClassName}>
              <Input
                type="checkbox"
                {...input}
                {...inputProps}
                onChange={handleChange}
                checked={this.props.input.value}
              />
              <span className={`fa ${checkboxIcon}`} />
              {htmlLabel !== undefined && htmlLabel.length > 0 && (
                <div dangerouslySetInnerHTML={{ __html: htmlLabel }} />
              )}
              {label}
            </label>
          </div>
        </div>
      </FormGroup>
    );
  }
}

RenderCheckboxForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  checkboxIcon: PropTypes.string,
  checkboxIconUnchecked: PropTypes.string,
  refField: PropTypes.string,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    stackedLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    options: PropTypes.instanceOf(Array),
    multi: PropTypes.bool,
    simpleValue: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
};

export default forwardRef((props, ref) => (
  <RenderCheckboxForFieldComponent innerRef={ref} {...props} />
));
