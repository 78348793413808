import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';

class Referrals extends React.Component {
  componentDidMount() {
    // const script = document.createElement('script');

    // widget
    // script.id = 'rf-script';
    // script.src = 'https://referral-factory.com/assets/js/widget.js?code=Vm1uHG&title=Refer+Friends&color=000000&color_light=323232';
    // script.async = true;

    // popup
    // script.setAttribute('data-code', 'Vm1uHG');
    // script.setAttribute('class', 'rf-popup-script');
    // script.src = 'https://referral-factory.com/assets/js/popup.js?code=Vm1uHG';

    // document.body.appendChild(script);
  }

  render() {
    // const { user } = this.props;
    // window.RF = {
    //   user: {
    //     first_name: user.first_name,
    //     email: user.email,
    //   },
    // };
    // if (window.RFW) {
      // window.RFW.launch();
    // }

    // const script = document.createElement('script');
    // script.id = 'rf-script';
    // script.src = 'https://referral-factory.com/assets/js/widget.js?code=Vm1uHG&title=Refer+Friends&color=000000&color_light=323232';

    // script['data-code'] = 'Vm1uHG';
    // script.src = 'https://referral-factory.com/assets/js/popup.js?code=Vm1uHG';
    // script.class = 'rf-popup-script';
    // <script class='rf-popup-script' data-code='Vm1uHG' src='https://referral-factory.com/assets/js/popup.js?code=Vm1uHG'></script>

    // script.async = true;

    // document.body.appendChild(script);
    // document.getElementById('referral-container').appendChild(script);
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Referral Link" />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} id="referral-container">
            {/* <Button className="rf-popup-Vm1uHG">Refer Friends</Button> */}
            {/* Some copy here w/ the widget down below */}
            {/* <embed style={{width:'100%', height: '100%'}} src='https://embed.referral-factory.com/Vm1uHG/join'></embed> */}
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

Referrals.propTypes = {
  user: PropTypes.instanceOf(Object),
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
