import { RECIPE_SEARCH_SUCCESS } from '../apiActions/foodApiActions';
import { AUTH_LOGOUT, SWITCH_TEAMS } from '../actions/authActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECIPE_SEARCH_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        recipes: data,
      };
    }
    case AUTH_LOGOUT:
    case SWITCH_TEAMS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
