import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import UserInfoFormPartial from './partials/UserInfoFormPartial';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  return errors;
};

class LogInForm extends Component {
  render() {
    const {
      initialValues,
      onSubmit,
      showSubmissionError,
      showPassword = true,
      buttonText = 'Log In',
    } = this.props;
    return (
      <div>
        <Form
          onSubmit={(values) => {
            const errors = validate(values);
            if (Object.keys(errors).length === 0) {
              onSubmit(values);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              <Row>
                <Col>
                  <UserInfoFormPartial
                    showFirstName={false}
                    showLastName={false}
                    showPassword={showPassword}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex ">
                  <Button color="primary" size="lg" type="submit" block>
                    {buttonText}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  showPassword: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default LogInForm;
