import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'reactstrap';
import renderCheckboxForField from './helpers/renderCheckboxForField';
import CardIcon from '../components/Cards/CardIcon';
import FirstNameField from './fields/FirstNameField';
import LastNameField from './fields/LastNameField';
import EmailField from './fields/EmailField';
import { validateAddClientForm } from '../utils/formUtils';

const validate = (values, rows) => {
  return validateAddClientForm(values, rows);
};

class AddClientForm extends Component {
  constructor(props) {
    super(props);
    const { rows = 5 } = props;
    this.state = {
      rows,
    };
  }

  addRows = () => {
    const { rows } = this.state;
    this.setState({
      rows: rows + 5,
    });
  };

  render() {
    const { initialValues, onSubmit, showSubmissionError } = this.props;
    const { rows } = this.state;
    return (
      <div>
        <Form
          onSubmit={(values, form) => {
            const errors = validate(values, rows);
            if (Object.keys(errors).length === 0) {
              onSubmit(values, form, rows);
            } else {
              showSubmissionError(errors);
            }
          }}
          initialValues={initialValues}
          // keepDirtyOnReinitialize
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="inline-form">
              {Array.from(Array(rows), (e, i) => (
                <Row key={i}>
                  <Col xs={4}>
                    <FirstNameField
                      showRequired={false}
                      showLabel={false}
                      fieldName={`first_name_${i}`}
                    />
                  </Col>
                  <Col xs={4}>
                    <LastNameField
                      showRequired={false}
                      showLabel={false}
                      fieldName={`last_name_${i}`}
                    />
                  </Col>
                  <Col xs={4}>
                    <EmailField showRequired={false} showLabel={false} fieldName={`email_${i}`} />
                  </Col>
                </Row>
              ))}
              <Row>
                <Col sm={4}>
                  <span className="span-link" onClick={this.addRows}>
                    <em className="fa fa-plus mr-1" />
                    Add 5 Rows
                  </span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="d-flex justify-content-end">
                  <div className="d-flex align-items-center checkbox-row">
                    <Field
                      name="send_invitations"
                      component={renderCheckboxForField}
                      {...{
                        itemProps: {},
                        checkboxIcon: 'fa-check',
                        checkboxIconUnchecked: 'fa-close',
                        inputProps: {},
                      }}
                    />
                    <div>
                      Send selected clients an email invitation to join Macrostax. &nbsp;
                      <CardIcon helpText="SEND_INVITATIONS" popoverClassName="text-left" />
                    </div>
                  </div>
                  <Button color="primary" size="lg" type="submit" className="ml-3 fixed-width-md">
                    Add Clients
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}

AddClientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  rows: PropTypes.number,
};

export default AddClientForm;
