import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MealCard from './MealCard';
import Workout from './Workout';
import { DayTypes } from '../../enums/dayTypes';
import { getTargetFromMicroName } from '../../utils/microNutrientsTarget';
import { calculateAge } from '../../utils/dateUtils';
import { reCase } from '../../utils/stringUtils';

class Planner extends Component {
  
  isMicroDetailsEmpty = () => {
    const { plan } = this.props;

    return plan?.day_micros?.length > 0;
  };

  microDetails = (day_macros, day_micros) => {
    const { user } = this.props;

    return day_micros?.map((micro) => {
      const target = user ? getTargetFromMicroName({
        name: micro.name,
        age: calculateAge(user.birthday),
        gender: user.gender,
        calories: day_macros.calories_target,
      }) : null;

      return {
        name: reCase(micro.name),
        value: `${Math.round(micro.amount)} ${target ? `/ ${target}` : ''} ${micro.unit}`,
      };
    });
  };

  render() {
    const { plan, doGetPlan, user } = this.props;
    const { user_id } = user;
    const { day_macros, day_type, schedule, date, meals, workouts } = plan;

    let carbs_percent = (day_macros.carbs_met / day_macros.carbs_target) * 100;
    if (carbs_percent > 100) {
      carbs_percent = 100;
    }
    let protein_percent = (day_macros.protein_met / day_macros.protein_target) * 100;
    if (protein_percent > 100) {
      protein_percent = 100;
    }
    let fat_percent = (day_macros.fat_met / day_macros.fat_target) * 100;
    if (fat_percent > 100) {
      fat_percent = 100;
    }
    let calories_percent = (day_macros.calories_met / day_macros.calories_target) * 100;
    if (calories_percent > 100) {
      calories_percent = 100;
    }

    const DateInput = forwardRef(({ value, onClick }, ref) => (
      <button className="planner-date-input" onClick={onClick} ref={ref}>
        <em className="far fa-calendar-alt" /> {value} - {DayTypes[day_type]}
      </button>
    ));

    return (
      <div className="planner">
        <Row>
          <Col md={8} className="d-flex flex-row align-items-center">
            <em
              className="fa fa-angle-left mr-2 font-size-one-point-five cursor-pointer"
              onClick={() => {
              const newDate = moment(date).add(-1, 'days');
              doGetPlan(user_id, newDate.format('YYYY-MM-DD'));
            }}
            />
            <DatePicker
              selected={moment(date).toDate()}
              onChange={(val) => doGetPlan(user_id, moment(val).format('YYYY-MM-DD'))}
              dateFormat="EEEE MMMM d, yyyy"
              showMonthDropdown
              showYearDropdown
              customInput={<DateInput />}
            />
            <em
              className="fa fa-angle-right ml-2 font-size-one-point-five cursor-pointer"
              onClick={() => {
              const newDate = moment(date).add(1, 'days');
              doGetPlan(user_id, newDate.format('YYYY-MM-DD'));
            }}
            />
          </Col>
        </Row>
        <Row className="border-bottom pb-3 mb-1">
          <Col md={8} className="mt-3">
            <h5>Macro Details</h5>
            <div className="macro-progress-container">
              <div className={`macro-name ${day_macros.carbs_green_thumb ? 'green-thumb' : ''}`}>Carbs</div>
              <div className="macro-progress">
                <div className="macro-progress-value" style={{ width: `${carbs_percent}%` }} />
              </div>
              <div className="macro-values">
                {day_macros.carbs_met} / {day_macros.carbs_target}
                {day_macros.carbs_green_thumb && (
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
                )}
              </div>
            </div>
            <div className="macro-progress-container">
              <div className={`macro-name ${day_macros.protein_green_thumb ? 'green-thumb' : ''}`}>Protein</div>
              <div className="macro-progress">
                <div className="macro-progress-value" style={{ width: `${protein_percent}%` }} />
              </div>
              <div className="macro-values">
                {day_macros.protein_met} / {day_macros.protein_target}
                {day_macros.protein_green_thumb && (
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
                )}
              </div>
            </div>
            <div className="macro-progress-container">
              <div className={`macro-name ${day_macros.fat_green_thumb ? 'green-thumb' : ''}`}>Fat</div>
              <div className="macro-progress">
                <div className="macro-progress-value" style={{ width: `${fat_percent}%` }} />
              </div>
              <div className="macro-values">
                {day_macros.fat_met} / {day_macros.fat_target}
                {day_macros.fat_green_thumb && (
                  <img src="/img/green-thumb-dark.svg" alt="Green Thumb" className="green-thumb" />
                )}
              </div>
            </div>
            <div className="macro-progress-container">
              <div className="macro-name">Calories</div>
              <div className="macro-progress">
                <div className="macro-progress-value" style={{ width: `${calories_percent}%` }} />
              </div>
              <div className="macro-values">
                {day_macros.calories_met} / {day_macros.calories_target}
              </div>
            </div>
          </Col>
        </Row>

        {this.isMicroDetailsEmpty() && (
          <Row className="border-bottom pb-3 mb-1">
            <Col md={8} className="mt-3">
              <h5>Micronutrients</h5>
              {this.microDetails(plan.day_macros, plan.day_micros).map((micro) => (
                <Row key={micro.name} className="micronutrient-chip">
                  <div className="micro-name">{micro.name}</div>
                  <div className="micro-value">{micro.value}</div>
                </Row>
              ))}
            </Col>
          </Row>
        )}

        <Row>
          <Col md={8} className="mt-3">
            <h5 className="mb-4">Schedule</h5>
            {schedule.map((s) => (
              <div key={s.uuid}>
                {s.type === 'Workout' ? (
                  <Workout workouts={workouts} workout_id={s.uuid} />
                ) : (
                  <MealCard meal_id={s.uuid} meals={meals} />
                )}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

Planner.propTypes = {
  plan: PropTypes.instanceOf(Object),
  doGetPlan: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
};

export default Planner;
