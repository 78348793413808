import { combineReducers } from 'redux';
import authReducer from './authReducer';
import spinnerReducer from './spinnerReducer';
import layoutReducer from './layoutReducer';
import teamReducer from './teamReducer';
import clientsReducer from './clientsReducer';
import userReducer from './userReducer';
import billingReducer from './billingReducer';
import signupReducer from './signupReducer';
import contextReducer from './contextReducer';
import deviceReducer from './deviceReducer';
import printReducer from './printReducer';
import foodReducer from './foodReducer';
import challengeReducer from './challengeReducer';
import integrationReducer from './integrationReducer';
import groupReducer from './groupReducer';
import chatSettingReducer from './chatSettingReducer';

export default combineReducers({
  auth: authReducer,
  spinner: spinnerReducer,
  layout: layoutReducer,
  team: teamReducer,
  user: userReducer,
  clients: clientsReducer,
  billing: billingReducer,
  signup: signupReducer,
  context: contextReducer,
  device: deviceReducer,
  print: printReducer,
  food: foodReducer,
  challenges: challengeReducer,
  integrations: integrationReducer,
  groups: groupReducer,
  chatSettings: chatSettingReducer,
});
