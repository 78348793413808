import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import PieChart from '../Charts/PieChart';
import { buildPieChartData } from '../../utils/chartUtils';
import { enumToSentenceCase } from '../../utils/stringUtils';

class GoalDistributionCard extends Component {
  render() {
    const { goalDistribution } = this.props;
    let pie;
    const data = [];
    const labels = [];

    if (goalDistribution && goalDistribution.length) {
      for (let i = 0; i < goalDistribution.length; i += 1) {
        const goal = goalDistribution[i];
        const { goal_name, clients } = goal;
        labels.push(enumToSentenceCase(goal_name, ' '));
        data.push(goal.clients);
      }
  
      pie = buildPieChartData(data, labels);  
    }

    return (
      <Card className="card-default card-container mb-3 green-thumbs-card flex-grow-1">
        <CardHeader>Goal Distribution</CardHeader>
        <CardBody>
          <Row>
            <Col className="mt-2">
              {pie ? (
                <PieChart pie={pie} id="goal-distribution" />
              ) : (
                <div className="card-caption">Your clients' goal distribution will appear here once they sign up for Macrostax</div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

GoalDistributionCard.propTypes = {
  goalDistribution: PropTypes.instanceOf(Array),
};

export default GoalDistributionCard;
