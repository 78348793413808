import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';

class ReferralsModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/referralsModalNotActive', title: 'Refer and Earn' });
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle title="Refer and Earn" showTeam={false} />
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}>
              Sorry! You must have an active subscription in order to participate in our Refer and
              Earn program.
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

ReferralsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralsModal);
