import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import CardIcon from '../../components/Cards/CardIcon';
import { customSelectStyles } from '../../constants';

export class RenderSelectForFieldComponent extends Component {
  render() {
    const {
      input,
      label,
      iconName,
      iconType,
      itemProps,
      inputProps,
      labelCols = 3,
      inputCols = 9,
      cardIcon,
      cardId,
    } = this.props;

    let labelClassName = '';
    let inputClassName = '';

    const { isClearable = true } = inputProps;

    const formGroupClassName = itemProps.row ? 'flex-row' : 'stacked';

    if (itemProps.row) {
      labelClassName = `col-xl-${labelCols} col-form-label`;
      inputClassName = `col-xl-${inputCols}`;
    }

    const showLabel = () => {
      if (label && label.length) {
        return (
          <label className={labelClassName}>
            {label}
            {cardIcon && <CardIcon helpText={cardIcon} id={cardId} float="none" className="ml-2" popoverClassName="text-left" />}
          </label>
        );
      }
      return null;
    };
    const showError = () => null;
    const showIcon = () =>
      iconName !== undefined && iconName.length ? (
        <em className={iconName} type={iconType} />
      ) : null;

    let v = input.value;
    if (
      v !== undefined &&
      v !== null &&
      // !Array.isArray(v) &&
      v.length &&
      inputProps &&
      inputProps.options &&
      inputProps.options.length
    ) {
      if (!Array.isArray(v)) {
        v = inputProps.options.find((o) => o.value === v);
      } else if (typeof v[0] === 'string') {
        v = inputProps.options.filter((o) => v.includes(o.value));
      }
    }

    return (
      <FormGroup {...itemProps} className={formGroupClassName}>
        {showLabel()}
        <div className={inputClassName}>
          {showIcon()}
          {/* note: onBlur override required for redux-form to retain value */}
          <Select
            {...input}
            {...inputProps}
            styles={customSelectStyles}
            value={v}
            isClearable={isClearable}
            // onBlur={() => input.onBlur(inputProps.value)}
            onChange={(value) => {
              if (value === null) {
                input.onChange(undefined);
              } else if (inputProps.isMulti) {
                value ? input.onChange(value.map((v) => v.value)) : input.onChange(value);
              } else {
                value ? input.onChange(value.value) : input.onChange(value);
              }
            }}
          />
        </div>
        {showError()}
      </FormGroup>
    );
  }
}

RenderSelectForFieldComponent.propTypes = {
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    onSubmitEditing: PropTypes.func,
  }),
  label: PropTypes.string,
  labelCols: PropTypes.number,
  stackedlabel: PropTypes.bool,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  refField: PropTypes.string,
  inputCols: PropTypes.number,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.any,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }),
  children: PropTypes.instanceOf(Array),
  itemProps: PropTypes.shape({
    floatingLabel: PropTypes.bool,
    row: PropTypes.bool,
    last: PropTypes.bool,
  }),
  inputProps: PropTypes.shape({
    options: PropTypes.instanceOf(Array),
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
  }),
};

export default forwardRef((props, ref) => (
  <RenderSelectForFieldComponent innerRef={ref} {...props} />
));
