import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import 'loaders.css/loaders.css';
import { spinnerIncrement, spinnerDecrement } from '../actions/spinnerActions';

class SpinnerModal extends Component {
  render() {
    const { visible } = this.props;
    return (
      <Modal
        isOpen={visible}
        className="spinnerModal"
        centered
        onClosed={() => document.body.classList.remove('modal-open')}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img src="/img/ms-spinner.png" alt="Loading..." className="ms-logo" />
          <div className="ms-line-scale white">
            <div />
            <div />
            <div />
          </div>
        </div>
      </Modal>
    );
  }
}

SpinnerModal.propTypes = {
  visible: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  visible: state.spinner.spinnerAnimate,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerModal);
