export const NOT_STARTED_STATUS = 'not_started';
export const IN_PROGRESS_STATUS = 'in_progress';
export const FINISHED_STATUS = 'finished';
export const CANCELLED_STATUS = 'cancelled';
export const ERROR_STATUS = 'error';

export const IMPORT_STATUS_TITLE_TEXT = Object.freeze({
  [IN_PROGRESS_STATUS]: 'Importing ... Please wait ...',
  [FINISHED_STATUS]: 'Clients Imported Successfully.',
  [ERROR_STATUS]: 'An error occurred while importing users. Please try again later.',
});

export const IMPORT_PROGRESS_BAR_STYLE = Object.freeze({
  [IN_PROGRESS_STATUS]: 'info',
  [FINISHED_STATUS]: 'success',
  [ERROR_STATUS]: 'danger',
});
