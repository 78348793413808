import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import numeral from 'numeral';

class DashboardLeaderboardAlt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOrder: 'desc',
      days_activeOrder: 'asc',
      days_loggingOrder: 'asc',
      weight_changeOrder: 'desc',
      body_fat_changeOrder: 'desc',
      green_thumbsOrder: 'asc',
      carbs_hitOrder: 'asc',
      protein_hitOrder: 'asc',
      fat_hitOrder: 'asc',
      calories_hitOrder: 'asc',
    };
  }

  render() {
    const { isTeamOwner = true, team_id, engagementSummary, sortSummary } = this.props;
    const users = engagementSummary.users
    const total_days = engagementSummary.total_days
    const possibleGreenThumbs = total_days * 3;
    
    const updateSortOrder = (column) => {
      const currentOrder = this.state[`${column}Order`];
      const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
      this.setState({
        [`${column}Order`]: newOrder,
      }, () => {
        sortSummary(column, newOrder);
      })
    }

    return (
      <div className="leaderboard">
        {users?.length > 0 && (
          <>
            <Row className="mb-2 mt-3 leaderboard-header">
              <Col xs={3} className="cursor-pointer no-padding-right" onClick={() => updateSortOrder('name')}>Client</Col>
              <Col xs={1} className="text-center no-wrap cursor-pointer no-padding" onClick={() => updateSortOrder('days_active')}>
                Days<br />Active
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('days_logging')}>
                Days<br />Logging
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('green_thumbs')}>
                Green<br />Thumbs
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('carbs_hit')}>
                Carbs<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('protein_hit')}>
                Protein<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('fat_hit')}>
                Fat<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('calories_hit')}>
                Calories<br />Hit
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('weight_change')}>
                Weight<br />Change
              </Col>
              <Col xs={1} className="text-center cursor-pointer no-padding" onClick={() => updateSortOrder('body_fat_change')}>
                Body Fat<br />Change
              </Col>
            </Row>
          </>
        )}
        {users?.map((user, idx) => (
          <Row key={user.user_id} className="mt-1">
            <Col xs={3} className=" no-padding-right">
              <Link
                to={{
                  pathname: `/${isTeamOwner ? 'clients' : 'users'}/${user.user_id}${
                    isTeamOwner ? '' : '?team_id=' + team_id
                  }`,
                  state: { from: 'Dashboard' }
                }}
              >
                {user.name}
              </Link>
            </Col>
            <Col xs={1} className="text-center no-padding">
              {user.days_active}
            </Col>
            <Col xs={1} className="text-center no-padding">{user.days_logging}</Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_thumbs`}>
              {isNaN(user.green_thumbs) || user.green_thumbs === 0 ? (
                  <div>--</div>
                ) : (
              <>
                  <div className="text-center">
                    {/* total green thumbs hit  / total number of possible green thumbs */}
                    { ((user.green_thumbs/possibleGreenThumbs) * 100).toFixed() }%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_thumbs`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Green Thumbs Hit: ${user.green_thumbs}/${possibleGreenThumbs}</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_carbs`}>
              {isNaN(user.carbs_hit) || user.carbs_hit === 0 ? (
                  <div>--</div>
                ) : (
              <>
                  <div className="text-center">
                    { ((user.carbs_hit/total_days) * 100).toFixed() }%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_carbs`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Carbs Green Thumb Hit: ${user.carbs_hit}/${total_days}</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_protein`}>
              {isNaN(user.protein_hit) || user.protein_hit === 0 ? (
                  <div>--</div>
                ) : (
              <>
                  <div className="text-center">
                    { ((user.protein_hit/total_days) * 100).toFixed() }%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_protein`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Protein Green Thumb Hit: ${user.protein_hit}/${total_days}</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_fat`}>
              {isNaN(user.fat_hit) || user.fat_hit === 0 ? (
                  <div>--</div>
                ) : (
              <>
                  <div className="text-center">
                    { ((user.fat_hit/total_days) * 100).toFixed() }%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_fat`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Fat Green Thumb Hit: ${user.fat_hit}/${total_days}</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_calories`}>
              {isNaN(user.calories_hit) || user.calories_hit === 0 ? (
                  <div>--</div>
                ) : (
              <>
                  <div className="text-center">
                    { ((user.calories_hit/total_days) * 100).toFixed() }%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_calories`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Calories Hit +/- 100: ${user.calories_hit}/${total_days}</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_weight`}>
              {user.start_weight == 0 || user.start_weight === null ? (
                <div>--</div>
              ) : (
                <>
                  <div className="text-right flex-grow-1 flex-basis-0 mr-1">
                    {user.start_weight < user.end_weight ? '+' : ''}
                    {numeral(user.weight_change).format('0.0')}
                  </div>
                  <div className=" flex-grow-1 flex-basis-0">lb{user.start_weight !== 1 ? 's' : ''}</div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_weight`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Starting weight: ${user.start_weight} lbs<br />Ending weight: ${user.end_weight} lbs</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
            <Col xs={1} className="d-flex flex-row justify-content-center no-padding" id={`user_${user.user_id}_bodyFat`} >
              {user.start_body_fat == 0 || user.start_body_fat === null ? (
                <div>--</div>
              ) : (
                <>
                  <div className="text-center">
                    {user.start_body_fat < user.end_body_fat ? '+' : ''}
                    {numeral(user.body_fat_change).format('0.0')}%
                  </div>
                  <UncontrolledPopover trigger="hover" placement="top" target={`user_${user.user_id}_bodyFat`}>
                    <PopoverBody className="" dangerouslySetInnerHTML={{ __html: `<div class="caption">Starting body fat: ${user.start_body_fat}%<br />Ending body fat: ${user.end_body_fat}%</div>` }} />
                  </UncontrolledPopover>
                </>
              )}
            </Col>
          </Row>
        ))}
        {users?.length === 0 && (
          <Row>
            <Col className="no-data-label">
              <div>No results yet.</div>
              <p>
                Once your Team starts using the Macrostax app, you'll see a summary of their results
                here.
              </p>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

DashboardLeaderboardAlt.propTypes = {
  leaderboard: PropTypes.instanceOf(Object),
  goal: PropTypes.string,
  lbsVerb: PropTypes.string,
  isTeamOwner: PropTypes.bool,
  team_id: PropTypes.string,
};

export default DashboardLeaderboardAlt;
