export default {
  // Local
  BASE_URL: 'https://app-prod.macrostax.com/api/team/v1',
  BASE_URL_NATIVE: 'https://app-prod.macrostax.com/api/v1',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_ECPNwbD6MiUc3jqz4abqSWjR',
  GA_TRACKING_ID: 'G-M00FRR92K8',
  GTM_TRACKING_ID: 'GTM-MK2ZS8V',
  INTERCOM_APP_ID: 'sisozev5',
  CALENDLY_ACCOUNT_ID: 'lindsey-ceomacrostax',
  MACROSTAX_TEAM_ID: 'ed742cec-da1a-41a7-b005-d06377095732',
  STAXCHAT_DEEP_LINK: 'https://www.macrostax.com/?staxchat=true',
  TRIAL_DAYS: 30,
  FACEBOOK_APP_ID: '5295337127262154',
  STREAM_CHAT_ACCOUNT_ID: 'rwgah52dfz5c',
  BASE_PRINT_URL: 'https://print.macrostax.com',
};
