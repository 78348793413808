export const GET_INTEGRATIONS = 'GET_INTEGRATIONS';
export const GET_ALL_LOCATIONS_FROM_INTEGRATION = 'GET_ALL_LOCATIONS_FROM_INTEGRATION';
export const GET_IMPORT_STATUS = 'GET_IMPORT_STATUS';
export const SUBMIT_INTEGRATION_SETUP_REQUEST = 'SUBMIT_INTEGRATION_SETUP_REQUEST';
export const IMPORT_USERS_REQUEST = 'IMPORT_USERS_REQUEST';
export const CANCEL_IMPORT_USERS_REQUEST = 'CANCEL_IMPORT_USERS_REQUEST';
export const RESET_INTEGRATION_STATUS = 'RESET_INTEGRATION_STATUS';
export const REQUEST_USERS_BY_LOCATION = 'REQUEST_USERS_BY_LOCATION';
export const CLEAN_USERS = 'CLEAN_USERS';

export function getAllIntegrations() {
  return {
    type: GET_INTEGRATIONS,
  };
}

export function getAllLocationsFromIntegration(integrationId) {
  return {
    type: GET_ALL_LOCATIONS_FROM_INTEGRATION,
    integrationId,
  };
}

export function getImportStatus(integrationId) {
  return {
    type: GET_IMPORT_STATUS,
    integrationId,
  };
}

export function submitIntegrationSetupRequest(values, integrationId) {
    return {
        type: SUBMIT_INTEGRATION_SETUP_REQUEST,
        values,
        integrationId,
    };
}

export function importUsersRequest(values, integrationId) {
  return {
      type: IMPORT_USERS_REQUEST,
      values,
      integrationId,
  };
}

export function cancelImportUsersRequest(integrationId) {
  return {
      type: CANCEL_IMPORT_USERS_REQUEST,
      integrationId,
  };
}

export function resetIntegrationStatus() {
  return {
      type: RESET_INTEGRATION_STATUS,
  };
}

export function requestUsersByLocation(integrationId, locations) {
  return {
    type: REQUEST_USERS_BY_LOCATION,
    integrationId,
    locations
  };
}

export function cleanUsers(locationId) {
  return {
    type: CLEAN_USERS,
    locationId,
  };
}
