import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderSelectForField from '../helpers/renderSelectForField';
import { tzOptions } from '../../enums/timezones';

class TimezoneField extends Component {
  render() {
    const { fieldName = 'timezone', label = 'Timezone', inputCaption } = this.props;
    return (
      <Field
        name={fieldName}
        label={label}
        component={renderSelectForField}
        stackedlabel
        {...{
          itemProps: {},
          inputProps: {
            options: tzOptions,
            isClearable: false,
          },
        }}
      />
    );
  }
}

TimezoneField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  inputCaption: PropTypes.string,
};

export default TimezoneField;
