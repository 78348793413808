import React from 'react';
import PropTypes from 'prop-types';

const ContentWrapper = (props) => (
  <div className={`content-wrapper ${props.className}`}>
    {props.unwrap ? <div className="unwrap">{props.children}</div> : props.children}
  </div>
);

ContentWrapper.propTypes = {
  /** add element with 'unwrap' class to expand content area */
  unwrap: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};
ContentWrapper.defaultProps = {
  unwrap: false,
};

export default ContentWrapper;
