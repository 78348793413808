import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import queryString from 'query-string';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import RecipesTable from '../../components/Tables/RecipesTable';
import RecipeSearchForm from '../../forms/RecipeSearchForm';
import { submitRecipeSearchRequest, clearRecipeSearchForm } from '../../actions/foodActions';
import { clearRecipe } from '../../actions/printActions';
import browserHistory from '../../history';
import ENV_VARS from '../../utils/envVars';

class Recipes extends React.Component {
  componentDidMount() {
    const {
      initialValues,
      search,
      recipes,
    } = this.props;
    const qs = queryString.parse(location.search);
    if (qs !== undefined && qs.refresh !== undefined && qs.refresh === 'false' && recipes !== undefined && recipes.length > 0) {
      // don't refresh the search results
    } else {
      search(initialValues);
    }
  }

  goToRecipe = (recipe) => {
    const { doClearRecipe } = this.props;
    doClearRecipe();
    browserHistory.push(`/recipes/${recipe.recipe_id}`);
  };

  render() {
    const { initialValues, search, reset, recipes } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Recipes" />
          </Col>
        </Row>
        <div className="main-content-container">
          <Row>
            <Col xs={12}>
              <RecipeSearchForm onSubmit={search} onReset={reset} initialValues={initialValues} />
            </Col>
          </Row>
          {recipes && (
            <Row className="mt-5">
              <Col xs={12}>
                <RecipesTable
                  recipes={recipes}
                  title="Search Results"
                  // sort={initialValues && initialValues.name && initialValues.name.length ? 'score' : 'name'}
                  sort="score"
                  onRowClick={(e, rowData) => this.goToRecipe(rowData)}
                />
              </Col>
            </Row>
          )}
        </div>
      </ContentWrapper>
    );
  }
}

Recipes.propTypes = {
  doClearRecipe: PropTypes.func,
};
const mapStateToProps = (state) => ({
  initialValues: {
    ...state.context.recipeSearchForm,
  },
  recipes: state.food.recipes,
});
const mapDispatchToProps = (dispatch) => ({
  search: (values) => {
    dispatch(submitRecipeSearchRequest(values));
  },
  reset: () => {
    dispatch(clearRecipeSearchForm());
    dispatch(submitRecipeSearchRequest());
  },
  doClearRecipe: () => dispatch(clearRecipe()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipes);
