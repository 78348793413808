import Qs from 'qs';

import ENV_VARS from '../utils/envVars';

export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';
export const CREATE_MEMBERSHIP_SUCCESS = 'CREATE_MEMBERSHIP_SUCCESS';
export const CREATE_MEMBERSHIP_FAILURE = 'CREATE_MEMBERSHIP_FAILURE';
export const MEMBERSHIP_OPTIONS_SUCCESS = 'MEMBERSHIP_OPTIONS_SUCCESS';
export const MEMBERSHIP_OPTIONS_FAILURE = 'MEMBERSHIP_OPTIONS_FAILURE';
export const BUSINESS_TYPES_SUCCESS = 'BUSINESS_TYPES_SUCCESS';
export const BUSINESS_TYPES_FAILURE = 'BUSINESS_TYPES_FAILURE';
export const CREATE_ONBOARDING_EVENT_SUCCESS = 'CREATE_ONBOARDING_EVENT_SUCCESS';
export const CREATE_ONBOARDING_EVENT_FAILURE = 'CREATE_ONBOARDING_EVENT_FAILURE';

export function createAccount(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/onboarding/create-account',
    method: 'POST',
    data: {
      ...values,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: false,
    spinner: true,
    successAction: CREATE_ACCOUNT_SUCCESS,
    failureAction: CREATE_ACCOUNT_FAILURE,
  };
}

export function createTeam(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/onboarding/create-team',
    method: 'POST',
    data: {
      ...values,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: CREATE_TEAM_SUCCESS,
    failureAction: CREATE_TEAM_FAILURE,
  };
}

export function createOnboaringEvent(type) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/onboarding/create-event',
    method: 'POST',
    data: {
      type,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: false,
    successAction: CREATE_ONBOARDING_EVENT_SUCCESS,
    failureAction: CREATE_ONBOARDING_EVENT_FAILURE,
  };
}

export function createMembership(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/onboarding/create-membership',
    method: 'POST',
    data: {
      ...values,
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: CREATE_MEMBERSHIP_SUCCESS,
    failureAction: CREATE_MEMBERSHIP_FAILURE,
  };
}

export function membershipOptions() {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/products',
    method: 'GET',
    data: {},
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: MEMBERSHIP_OPTIONS_SUCCESS,
    failureAction: MEMBERSHIP_OPTIONS_FAILURE,
  };
}

export function businessTypes(visible = true) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/business-types',
    method: 'GET',
    params: {
      visible,
    },
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: false });
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: BUSINESS_TYPES_SUCCESS,
    failureAction: BUSINESS_TYPES_FAILURE,
  };
}