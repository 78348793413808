export const getClassNameForSubscriptionStatus = (status) => {
  switch (status) {
    // case 'canceled':
    case 'suspended':
    case 'unsubscribed':
    case 'pending':
      return 'warning';
    case 'active':
    case 'pre-active':
    case 'onboarding':
      return 'active';
    case 'revoked':
    case 'removed':
    case 'pending-cancellation':
    case 'canceled':
    case 'past_due':
      return 'error';
    case 'invited':
    case 'trialing':
      return 'invited';
    default:
      return '';
  }
};

/*
pending
invited
pre-active
onboarding
active
revoked
*/

/*
Not invited
Pending invite
Active
Deactivated
*/
export const getFriendlyNameForUserStatus = (status) => {
  switch (status) {
    case 'invited':
      return 'Pending invite';
    case 'pending':
      return 'Not invited';
    case 'pre-active':
      return 'Pre-Active';
    case 'onboarding':
    case 'active':
      return 'Active';
    case 'revoked':
      return 'Deactivated';
    default:
      return status;
  }
};
