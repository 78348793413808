import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ContentWrapper from '../../components/Layout/ContentWrapper';
import PageTitle from '../../components/Common/PageTitle';
import Loading from '../../components/Common/Loading';
import ChallengeTable from '../../components/Tables/ChallengeTable';
import { getChallenge, getActions, getLeaderboard } from '../../actions/challengeActions';
import { getAllClients } from '../../actions/teamActions';
import ChallengeDetailContainer from '../../containers/Challenges/ChallengeDetailContainer';
import browserHistory from '../../history';
import { submitUserSearchRequest } from '../../actions/userActions';

class TeamChallengeDetail extends React.Component {
  componentDidMount() {
    const {
      doGetChallenge,
      doGetActions,
      doGetLeaderboard,
      team,
      doGetAllClients,
      match: {
        params: { challenge_id, team_id },
      },
    } = this.props;

    doGetChallenge(challenge_id);
    doGetLeaderboard(challenge_id);
    doGetActions();
    doGetAllClients(team_id);
  }

  render() {
    const { challenge = {}, user, team, actions, leaderboard, clients, match: {
      params: { team_id },
    }, } = this.props;
    return (
      <ContentWrapper>
        <Row>
          <Col xs={12}>
            <PageTitle title="Challenge Detail" />
            {team_id ? (
              <div className="mb-4 mt-n2">
                <Link to={`/teams/${team_id}#challenges`}>
                  <em className="fa-lg mr-2 fas fa-arrow-left" />
                  Back to Team
                </Link>
              </div>
            ) : null}
          </Col>
        </Row>
        {challenge && challenge.name && actions && leaderboard && clients ? (
          <div className="main-content-container">
            <ChallengeDetailContainer
              challenge={challenge}
              actions={actions}
              leaderboard={leaderboard}
              clients={clients}
              isAdmin={true}
            />
          </div>
        ) : (
          <Loading />
        )}
      </ContentWrapper>
    );
  }
}

TeamChallengeDetail.propTypes = {
  doGetChallenge: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  team: PropTypes.instanceOf(Object),
  actions: PropTypes.instanceOf(Array),
  doGetActions: PropTypes.func,
  leaderboard: PropTypes.instanceOf(Array),
  doGetLeaderboard: PropTypes.func,
  clients: PropTypes.instanceOf(Array),
  doGetAllClients: PropTypes.func,
};
const mapStateToProps = (state) => ({
  challenge:
    state.context && state.context.team && state.context.team.challenges
      ? state.context.team.challenges.challenge
      : {},
  user: state.auth.user,
  team: state.context.team,
  actions:
    state.context && state.context.team && state.context.team.challenges
      ? state.context.team.challenges.actions
      : [],
  leaderboard:
    state.context && state.context.team && state.context.team.challenges
      ? state.context.team.challenges.leaderboard
      : [],
  clients:
    state.context && state.context.team && state.context.team.clients
      ? state.context.team.clients
      : [],
});
const mapDispatchToProps = (dispatch) => ({
  doGetChallenge: (challenge_id) => {
    dispatch(getChallenge(challenge_id));
  },
  doGetLeaderboard: (challenge_id) => {
    dispatch(getLeaderboard(challenge_id));
  },
  doGetActions: () => {
    dispatch(getActions());
  },
  doGetAllClients: (team_id) => {
    dispatch(getAllClients(team_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamChallengeDetail);
