import Qs from 'qs';
import ENV_VARS from '../utils/envVars';

export const RECIPE_SEARCH_SUCCESS = 'RECIPE_SEARCH_SUCCESS';
export const RECIPE_SEARCH_FAILURE = 'RECIPE_SEARCH_FAILURE';
export const SHARE_RECIPE_SUCCESS = 'SHARE_RECIPE_SUCCESS';
export const SHARE_RECIPE_FAILURE = 'SHARE_RECIPE_FAILURE';

export function recipeSearch(values) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/recipe-search',
    method: 'GET',
    params: values,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'comma', encode: true });
    },
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: RECIPE_SEARCH_SUCCESS,
    failureAction: RECIPE_SEARCH_FAILURE,
  };
}

export function shareRecipe(payload) {
  const config = {
    baseURL: ENV_VARS.BASE_URL,
    url: '/share-recipe',
    method: 'POST',
    data: payload,
  };
  return {
    type: 'API_CALL',
    config,
    auth: true,
    spinner: true,
    successAction: SHARE_RECIPE_SUCCESS,
    failureAction: SHARE_RECIPE_FAILURE,
  };
}