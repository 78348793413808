import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import CreditCardForm from '../forms/CreditCardForm';
import { submissionError } from '../actions/formActions';

class PaymentMethodModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      submitted: false,
    };
    this.state = {
      initialState,
      ...initialState,
    };
  }

  updateCard = (values) => {
    const { onSubmit, toggle } = this.props;
    onSubmit(values.id);
    toggle();
  };

  resetState = () => {
    const { initialState } = this.state;
    this.setState({
      ...initialState,
      initialState,
    });
  };

  render() {
    const { isOpen, toggle, onSubmit, showSubmissionError } = this.props;
    const { submitted } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium" onClosed={this.resetState}>
        <ModalBody>
          <PageTitle
            title="Update Payment Method"
            showTeam={false}
            icon="fa-1x far fa-credit-card"
          />
          {submitted ? (
            <Row>
              <Col xs={12}>
                Your payment method has been updated successfully.
                <div className="mt-5 text-center">
                  <Button color="primary" size="lg" onClick={toggle} className="fixed-width-lg">
                    Done
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <CreditCardForm
              toggle={toggle}
              showSubmissionError={showSubmissionError}
              showCancel
              onSubmit={(values) => this.updateCard(values)}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

PaymentMethodModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  showSubmissionError: PropTypes.func,
  onSubmit: PropTypes.func,
  team: PropTypes.instanceOf(Object),
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  showSubmissionError: (errors) => dispatch(submissionError(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModal);
