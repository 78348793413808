import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';

/** Normal items for the sidebar */
class SidebarItem extends Component {
  render() {
    const {
      item: { path, name, icon, label, external = false },
      isActive,
    } = this.props;
    const { color, value } = label || {};

    return (
      <li className={isActive ? 'active' : ''}>
        {external ? (
          <a href={path} target="_blank">
            <span>{name}</span>
          </a>
        ) : (
          <Link to={path} title={name}>
            {label && (
              <Badge tag="div" className="float-right" color={color}>
                {value}
              </Badge>
            )}
            {icon && <em className={icon} />}
            <span>{name}</span>
          </Link>
        )}
      </li>
    );
  }
}

SidebarItem.propTypes = {
  item: PropTypes.instanceOf(Object),
  isActive: PropTypes.bool,
};

export default SidebarItem;
