import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer-container">
        <span>
          &copy;
          {year}{' '}
          <a href="https://macrostax.com" target="_blank" rel="noreferrer">
            Macrostax
          </a>
        </span>
      </footer>
    );
  }
}

export default Footer;
