import {
  GET_ALL_CHALLENGES,
  GET_CHALLENGE,
  GET_ACTIONS,
  GET_LEADERBOARD,
  GET_USER_SCORES_FOR_CHALLENGE,
} from '../actions/challengeActions';
import {
  GET_ALL_CHALLENGES_SUCCESS,
  GET_CHALLENGE_SUCCESS,
  GET_ACTIONS_SUCCESS,
  GET_LEADERBOARD_SUCCESS,
  GET_USER_SCORES_FOR_CHALLENGE_SUCCESS,
  UPDATE_CHALLENGE_SUCCESS,
} from '../apiActions/challengeApiActions';
import { AUTH_LOGOUT, SWITCH_TEAMS } from '../actions/authActions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CHALLENGES: {
      return {
        ...state,
        challenges: undefined,
      };
    }
    case GET_ALL_CHALLENGES_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        challenges: data,
      };
    }
    case GET_CHALLENGE: {
      return {
        ...state,
        challenge: undefined,
      };
    }
    case GET_USER_SCORES_FOR_CHALLENGE: {
      return {
        ...state,
        userScores: undefined,
      };
    }
    case GET_LEADERBOARD: {
      return {
        ...state,
        leaderboard: undefined,
      };
    }
    case GET_CHALLENGE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        challenge: data,
      };
    }
    case GET_LEADERBOARD_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        leaderboard: data,
      };
    }
    case GET_USER_SCORES_FOR_CHALLENGE_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        userScores: data,
      };
    }
    case GET_ACTIONS: {
      return {
        ...state,
        actions: undefined,
      };
    }
    case GET_ACTIONS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        actions: data.reverse(),
      };
    }
    case AUTH_LOGOUT:
    case SWITCH_TEAMS: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
