import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Row, Col, Button } from 'reactstrap';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { STRIPE_ELEMENT_STYLE_PROPS } from '../../constants';

class CreditCardFormPartial extends Component {
  handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements, showSubmissionError, onSubmit } = this.props;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    if (error) {
      showSubmissionError([error.message]);
      // todo: dispatch
    } else {
      onSubmit(paymentMethod);
    }
  };

  render() {
    const {
      onCancel,
      stripe,
      showCancel,
      buttonText = 'Submit',
      buttonColClass = 'd-flex justify-content-end',
    } = this.props;
    return (
      <div className="inline-form">
        <FormGroup className="stacked">
          <label>Card Number</label>
          <div className="stripe-element-container">
            <CardNumberElement options={STRIPE_ELEMENT_STYLE_PROPS} value="4242424242424242" />
          </div>
        </FormGroup>
        <Row>
          <Col xs={8}>
            <FormGroup className="stacked">
              <label>Expiration Date</label>
              <div className="stripe-element-container">
                <CardExpiryElement options={STRIPE_ELEMENT_STYLE_PROPS} />
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="stacked">
              <label>CVC</label>
              <div className="stripe-element-container">
                <CardCvcElement options={STRIPE_ELEMENT_STYLE_PROPS} />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className={buttonColClass}>
            {showCancel && (
              <Button
                color="secondary"
                size="lg"
                onClick={onCancel}
                className="mr-3 fixed-width-md"
              >
                Cancel
              </Button>
            )}
            <Button
              color="primary"
              size="lg"
              disabled={!stripe}
              onClick={this.handleSubmit}
              className={showCancel ? 'fixed-width-md' : ''}
              block={!showCancel}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

CreditCardFormPartial.propTypes = {
  onCancel: PropTypes.func,
  stripe: PropTypes.instanceOf(Object),
  elements: PropTypes.instanceOf(Object),
  showSubmissionError: PropTypes.func,
  buttonText: PropTypes.string,
  showCancel: PropTypes.bool,
  buttonColClass: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default CreditCardFormPartial;
