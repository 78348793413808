import { call, put, take, race, all, delay } from 'redux-saga/effects';
import { showAlert } from '../actions/globalActions';
import {
  PRINT_RECIPE
} from '../actions/printActions';
import {
  printRecipeApi,
  PRINT_RECIPE_SUCCESS,
  PRINT_RECIPE_FAILURE,
} from '../apiActions/printApiActions';
import { spinnerDecrement } from '../actions/spinnerActions';
import { buildErrorMessage } from '../utils/apiCaller';

export default function* userSagas() {
  yield all([
    printRecipeSaga(),
  ]);
}

function* printRecipeSaga() {
  while (true) {
    const { recipe_id } = yield take(PRINT_RECIPE);
    yield put(printRecipeApi(recipe_id));
    const { success, failure } = yield race({
      success: take(PRINT_RECIPE_SUCCESS),
      failure: take(PRINT_RECIPE_FAILURE),
    });
    if (failure) {
      yield put(spinnerDecrement());
      yield put(showAlert('Oops!', buildErrorMessage(failure), 'error'));
    }
  }
}
