import { AUTH_LOGOUT, SWITCH_TEAMS } from '../actions/authActions';
import { USER_SEARCH_SUCCESS } from '../apiActions/userApiActions';
import { CLEAR_USER_SEARCH_FORM } from '../actions/userActions';

const initialClientsState = {};

export default (state = [], action) => {
  switch (action.type) {
    case USER_SEARCH_SUCCESS: {
      return { ...state, userSearchResults: action.data };
    }
    case CLEAR_USER_SEARCH_FORM: {
      return { ...state, userSearchResults: undefined };
    }
    case AUTH_LOGOUT:
    case SWITCH_TEAMS: {
      return {
        ...initialClientsState,
      };
    }
    default:
      return state;
  }
};
