import moment from 'moment-timezone';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_MACRO_TARGETS = 'GET_MACRO_TARGETS';
export const SET_CUSTOM_MACROS = 'SET_CUSTOM_MACROS';
export const RESET_TARGETS = 'RESET_TARGETS';
export const ADD_USER = 'ADD_USER';
export const SUBMIT_USER_SEARCH_REQUEST = 'SUBMIT_USER_SEARCH_REQUEST';
export const CLEAR_USER_SEARCH_FORM = 'CLEAR_USER_SEARCH_FORM';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const ADD_WEIGH_IN = 'ADD_WEIGH_IN';
export const UPDATE_WEIGH_IN = 'UPDATE_WEIGH_IN';
export const DELETE_WEIGH_IN = 'DELETE_WEIGH_IN';
export const ADD_BODY_FAT = 'ADD_BODY_FAT';
export const UPDATE_BODY_FAT = 'UPDATE_BODY_FAT';
export const DELETE_BODY_FAT = 'DELETE_BODY_FAT';
export const ADD_MEASUREMENTS = 'ADD_MEASUREMENTS';
export const UPDATE_MEASUREMENTS = 'UPDATE_MEASUREMENTS';
export const DELETE_MEASUREMENTS = 'DELETE_MEASUREMENTS';
export const GET_PLAN = 'GET_PLAN';
export const GET_MEMBERSHIP = 'GET_MEMBERSHIP';
export const SET_PASSWORD_FOR_USER = 'SET_PASSWORD_FOR_USER';
export const SYNC_INTERCOM_CONTACT = 'SYNC_INTERCOM_CONTACT';
export const GET_DAILY_MACROS = 'GET_DAILY_MACROS';
export const GET_ALL_USER_MACROS = 'GET_ALL_USER_MACROS';

export function getUser(user_id) {
  return {
    type: GET_USER_PROFILE,
    user_id,
  };
}

export function getPlan(user_id, date, switchTab) {
  return {
    type: GET_PLAN,
    user_id,
    date,
    switchTab
  };
}

export function getMembership(user_id) {
  return {
    type: GET_MEMBERSHIP,
    user_id,
  };
}

export function updateUser(id, payload, auth_user = false) {
  return {
    type: UPDATE_USER,
    id,
    payload,
    auth_user,
  };
}

export function getTargets(user_id, team_id) {
  return {
    type: GET_MACRO_TARGETS,
    user_id,
    team_id,
  };
}

export function getDailyMacros(user_id, start_date, end_date) {
  return {
    type: GET_DAILY_MACROS,
    user_id,
    start_date,
    end_date,
  };
}

export function setCustomMacros(user_id, team_id, firstname, lastname, timezone, payload) {
  return {
    type: SET_CUSTOM_MACROS,
    user_id,
    team_id,
    firstname,
    lastname,
    timezone,
    payload,
  };
}

export function resetTargets(user_id, team_id, firstname, lastname, timezone) {
  return {
    type: RESET_TARGETS,
    user_id,
    team_id,
    firstname,
    lastname,
    timezone
  };
}

export function addUser(values) {
  return {
    type: ADD_USER,
    values,
  };
}

export function submitUserSearchRequest(values) {
  return {
    type: SUBMIT_USER_SEARCH_REQUEST,
    values,
  };
}

export function clearUserSearchForm() {
  return {
    type: CLEAR_USER_SEARCH_FORM,
  };
}

export function resetPassword(email) {
  return {
    type: RESET_PASSWORD_REQUEST,
    email,
  };
}

export function setPasswordForUser(user_id, values) {
  return {
    type: SET_PASSWORD_FOR_USER,
    user_id,
    values,
  };
}

export function addWeighIn(id, values) {
  return {
    type: ADD_WEIGH_IN,
    id,
    values,
  };
}

export function updateWeighIn(id, values) {
  return {
    type: UPDATE_WEIGH_IN,
    id,
    values,
  };
}

export function deleteWeighIn(id, date) {
  return {
    type: DELETE_WEIGH_IN,
    id,
    date,
  };
}

export function addBodyFat(id, values) {
  return {
    type: ADD_BODY_FAT,
    id,
    values,
  };
}

export function updateBodyFat(id, values) {
  return {
    type: UPDATE_BODY_FAT,
    id,
    values,
  };
}

export function deleteBodyFat(id, date) {
  return {
    type: DELETE_BODY_FAT,
    id,
    date,
  };
}

export function addMeasurements(id, values) {
  return {
    type: ADD_MEASUREMENTS,
    id,
    values,
  };
}

export function updateMeasurements(id, values) {
  return {
    type: UPDATE_MEASUREMENTS,
    id,
    values,
  };
}

export function deleteMeasurements(id, date) {
  return {
    type: DELETE_MEASUREMENTS,
    id,
    date,
  };
}

export function syncIntercomContact(user_id, team_id) {
  return {
    type: SYNC_INTERCOM_CONTACT,
    user_id,
    team_id,
  };
}

export function getAllUserMacros(user_id, team_id) {
  return {
    type: GET_ALL_USER_MACROS,
    user_id,
    team_id,
    date: moment().format('YYYY-MM-DD'),
  };
}
