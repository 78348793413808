import { getTokenData } from '../utils/token';

export const AUTH_TOKEN_STORED = 'AUTH_TOKEN_STORED';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTHORIZE_REQUEST = 'AUTHORIZE_REQUEST';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_USER_ID_RETRIEVED = 'AUTH_USER_ID_RETRIEVED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const BEGIN_AUTHENTICATION = 'BEGIN_AUTHENTICATION';
export const USER_IS_LOGGED_IN = 'USER_IS_LOGGED_IN';
export const PASSWORD_UPDATE_REQUIRED = 'PASSWORD_UPDATE_REQUIRED';
export const GET_STREAM_TOKEN = 'GET_STREAM_TOKEN';
export const PUT_STREAM_TOKEN = 'PUT_STREAM_TOKEN';
export const CLEAR_STREAM_TOKENS = 'CLEAR_STREAM_TOKENS';
export const STREAM_UNREAD_COUNT = 'STREAM_UNREAD_COUNT';
export const UPSERT_STREAM_USERS = 'UPSERT_STREAM_USERS';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_TEAMS_LIST = 'SET_TEAMS_LIST';
export const SWITCH_TEAMS = 'SWITCH_TEAMS';

export function authTokenStored(token) {
  const decoded = getTokenData(token);
  return {
    type: AUTH_TOKEN_STORED,
    username: decoded.name || 'Guest',
    userId: decoded.sub,
    status: decoded.status,
  };
}

export function authorizeRequest(values) {
  return {
    type: AUTHORIZE_REQUEST,
    values,
  };
}

export function authLoginRequest(values) {
  return {
    type: AUTH_LOGIN_REQUEST,
    values,
  };
}

export function authLoginFailure(err, fromRefresh) {
  return {
    type: AUTH_LOGIN_FAILURE,
    error: err,
    fromRefresh,
  };
}

export function authUserIdRetrieved(user_id, role, team_id) {
  return {
    type: AUTH_USER_ID_RETRIEVED,
    user_id,
    role,
    team_id,
  };
}

export function authLoginSuccess(token) {
  const decoded = getTokenData(token);
  return {
    type: AUTH_LOGIN_SUCCESS,
    user_id: decoded.sub,
    exp: decoded.exp,
  };
}

export function authLogout(redirect, email, password, team_id) {
  return {
    type: AUTH_LOGOUT,
    redirect,
    email,
    password,
    team_id,
  };
}

export function authRefreshToken() {
  return {
    type: AUTH_REFRESH_TOKEN,
  };
}

export function changePassword(values) {
  return {
    type: CHANGE_PASSWORD,
    values,
  };
}

export function resetPassword(values) {
  return {
    type: RESET_PASSWORD,
    values,
  };
}

export function sendResetPassword(values) {
  return {
    type: SEND_RESET_PASSWORD,
    values,
  };
}

export function passwordUpdateRequired(required = false) {
  return {
    type: PASSWORD_UPDATE_REQUIRED,
    required,
  };
}

export function beginAuthentication(username) {
  return {
    type: BEGIN_AUTHENTICATION,
    username,
  };
}

export function userIsLoggedIn() {
  return {
    type: USER_IS_LOGGED_IN,
  };
}

export function getStreamToken(user_ids) {
  return {
    type: GET_STREAM_TOKEN,
    user_ids,
  };
}

export function upsertStreamUsers(users) {
  return {
    type: UPSERT_STREAM_USERS,
    users,
  };
}

export function putStreamToken(tokens) {
  return {
    type: PUT_STREAM_TOKEN,
    tokens,
  };
}

export function clearStreamTokens() {
  return {
    type: CLEAR_STREAM_TOKENS,
  };
}

export function streamUnreadCount(count) {
  return {
    type: STREAM_UNREAD_COUNT,
    count,
  };
}

export function setCredentials(user_first_name, email, password, teams_list) {
  return {
    type: SET_CREDENTIALS,
    user_first_name,
    email,
    password,
    teams_list
  };
}

export function setTeamsList(teams_list) {
  return {
    type: SET_TEAMS_LIST,
    teams_list
  };
}

export function switchTeams(team_id) {
  return {
    type: SWITCH_TEAMS,
    team_id
  };
}
