export const SPINNER_INCREMENT = 'SPINNER_INCREMENT';
export const SPINNER_DECREMENT = 'SPINNER_DECREMENT';

export function spinnerIncrement() {
  return {
    type: SPINNER_INCREMENT,
  };
}

export function spinnerDecrement() {
  return {
    type: SPINNER_DECREMENT,
  };
}
