import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Modal, ModalBody, ModalHeader, Row, Col, Button } from 'reactstrap';
import PageTitle from '../components/Common/PageTitle';
import { archiveChallenge } from '../actions/challengeActions';

class DeleteChallengeModal extends Component {
  render() {
    const { isOpen, toggle, doArchiveChallenge, challenge_id } = this.props;
    if (isOpen) {
      ReactGA.send({ hitType: 'pageview', page: '/modal/deleteChallenge', title: 'Delete Challenge' });
    }
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="modal-medium">
        <ModalHeader toggle={toggle}>
          <PageTitle
            title="Delete Challenge"
            showTeam={false}
            icon="icon-trophy"
          />
        </ModalHeader>
        <ModalBody>
          <>
            <Row>
              <Col xs={12}>Are you sure you want to delete this challenge?</Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12}>
                <div className="d-flex justify-content-end">
                  <Button
                    color="secondary"
                    size="lg"
                    onClick={toggle}
                    className="mr-3 fixed-width-md"
                  >
                    Never Mind
                  </Button>
                  <Button
                    color="primary"
                    size="lg"
                    onClick={() => {
                      doArchiveChallenge(challenge_id);
                      toggle();
                    }}
                    className="fixed-width-md"
                  >
                    Confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        </ModalBody>
      </Modal>
    );
  }
}

DeleteChallengeModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  challenge_id: PropTypes.string,
  doArchiveChallenge: PropTypes.func,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  doArchiveChallenge: (id) => dispatch(archiveChallenge(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteChallengeModal);
