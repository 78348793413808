import ENV_VARS from '../utils/envVars';
import googleAnalytics from './googleAnalytics';
import gtm from './googleTagManager';
import googleAds from './googleAds';
// import facebook from './facebook';

const loadScript = (url, isAsync, callback) => {
  const head = document.head;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  if (isAsync) {
    script.async = true;
  }
  script.src = url;
  script.onreadystatechange = callback;
  if (callback) {
    script.onload = callback;
  }
  head.appendChild(script);
};

const loadTrackingScripts = (signup) => {
  // Google Tag Manager
  gtm.init();
  // loadScript(`//www.googletagmanager.com/gtag/js?id=${ENV_VARS.GTM_TRACKING_ID}`, true, gtm.init);
  // loadScript(`//www.googletagmanager.com/gtag/js?id=${ENV_VARS.GA_TRACKING_ID}`, true, gtm.init);
  // Facebook
  // facebook.init(signup);
};

const updateTracking = (path, signup) => {
  googleAnalytics.update(path);
  // facebook.pageView(signup);
  if (path === 'business') {
    gtm.push('Team Sign Up Flow Step 1');
    // facebook.lead(signup);
  }
  if (path === 'plans') {
    gtm.push('Team Sign Up Flow Step 2');
    // facebook.customizeProduct(signup);
  } 
  if (path === 'billing') {
    gtm.push('Team Sign Up Flow Step 3');
    // facebook.addToCart(signup);
  }
  // if (path === 'billing') {
    
  // }
};

const checkout = (signup) => {
  googleAnalytics.checkout(signup);
  gtm.checkout(signup);
  // googleAds.checkout(signup);
  // facebook.checkout(signup);
};

export default {
  updateTracking,
  loadTrackingScripts,
  checkout,
};
