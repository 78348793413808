import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import CardIcon from './CardIcon';

class StatisticCard extends Component {
  render() {
    const { header, statistic, caption, showHelp, helpText, onCaptionClick, className = '' } = this.props;
    return (
      <Card className={`card-default card-container mb-3 statistic-card ${className}`}>
        <CardHeader>
          {header}
          {showHelp && <CardIcon helpText={helpText} />}
        </CardHeader>
        <CardBody>
          <div className="statistic">{statistic}</div>
          {caption && !onCaptionClick && <div className="card-caption">{caption}</div>}
          {caption && onCaptionClick && (
            <div className="card-caption span-link" onClick={onCaptionClick}>
              {caption}
            </div>
          )}
        </CardBody>
      </Card>
    );
  }
}

StatisticCard.propTypes = {
  header: PropTypes.string,
  statistic: PropTypes.string,
  caption: PropTypes.string,
  showHelp: PropTypes.bool,
  helpText: PropTypes.string,
  onCaptionClick: PropTypes.func,
  className: PropTypes.string,
};

export default StatisticCard;
